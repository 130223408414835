
import {defineComponent, PropType,} from 'vue'

import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'

export default defineComponent({
    name: 'SinglePractice',
    components: {
        TrashIcon,
    },
    props: {
        title: {
            type: String as PropType<string>,
            default: () => '',
        },
        id: {
            type: Number as PropType<number>,
            required: true,
        },
    },
});
