import { ref, } from 'vue'

export const useModal = () => {
    const modalTitle = ref('')
    const modalMessage = ref('')
    const isModalVisible = ref(false)

    const onModalClose = () => {
        isModalVisible.value = false
    }

    const showModal = () => {
        isModalVisible.value = true
    }

    return {
        isModalVisible,
        modalTitle,
        modalMessage,
        onModalClose,
        showModal,
    }
}
