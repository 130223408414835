
import {defineComponent, onMounted, ref,} from 'vue'

import {SelectListItem,} from '@/types/common'

import {get, patch,} from '@/modules/http'

import {useCKed,} from '@/composition/wys'
import {useCommmonNotification,} from '@/composition/notification'

import Btn from '@/components/Generic/btn/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue'
import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'

import {createInput,} from '@/modules/fields.ts'
import {useFieldValidator,} from '@/composition/fieldValidator.ts'
import {isNotEmpty,} from '@/modules/validators'
import ProfileImageLoader from '@/components/Profile/profile-image-loader/index.vue';


export default defineComponent({
    name: 'SchoolProgress',
    components: {
        ProfileImageLoader,
        Btn,
        LoadingIndicator,
        SelectWithSearch,
        FieldBlockInput,
    },
    setup() {
        const {showNotification,} = useCommmonNotification()

        const aboutContent = ref('')
        const isLoadingTab = ref(true)
        const isSaving = ref(false)

        const orgNameField = ref(createInput({
            inputText: 'Название',
            inputType: 'text',
            subText: 'Название',
            inputValue: '',
            validators: [
                isNotEmpty
            ],
        }))
        const orgTypeField = ref<SelectListItem | null>(null);
        const image = ref<string | null>(null);
        const updateImage = (newImage: string) => {
            image.value = newImage;
        }

        const fetchAbout = async () => {
            try {
                const response = await get('/school_moderator/organization/', {})
                if (response.ok) {
                    aboutContent.value = response.parsedBody.about
                    orgNameField.value.inputValue = response.parsedBody.name
                    orgTypeField.value = response.parsedBody.type;
                    image.value = response.parsedBody.photo;
                } else {
                    showNotification('Ошибка при получении данных о школе', 'danger')
                }
            } catch (e) {
                console.error(e)
            }
        }

        const orgTypes = ref<SelectListItem[]>([])
        const getOrganizationTypes = async (q='') => {
            const response = await get<SelectListItem[]>(`/organization_type/?search=${q}`);
            return response.ok ? response.parsedBody.results : [];
        };
        const onTypeSearch = async (q: string) => {
            orgTypes.value = await getOrganizationTypes(q);
        };
        onMounted(async () => {
            await fetchAbout()
            await getOrganizationTypes();
            isLoadingTab.value = false;
        })

        const {validateField,} = useFieldValidator()

        const saveAbout = async () => {
            try {
                const isErrors = validateField(orgNameField.value)
                if (isErrors) {
                    return
                }
                isSaving.value = true
                const response = await patch('/school_moderator/organization/', {
                    about: aboutContent.value.trim(),
                    name: orgNameField.value.inputValue,
                    type: orgTypeField.value?.id,
                    photo: image.value,
                }, {})
                if (response.ok) {
                    showNotification('Изменения сохранены!')
                } else {
                    showNotification('Произошла ошибка!', 'error')
                }
                isSaving.value = false

            } catch (e) {
                console.error(e)
            }
        }

        return {
            ...useCKed(),
            image,
            updateImage,
            isLoadingTab,
            saveAbout,
            orgTypeField,
            onTypeSearch,
            isSaving,
            orgNameField,
            orgTypes,
            aboutContent,
        }
    },

});
