
import {PropType, ref, computed, watch, } from 'vue';
import {SelectListItem,} from '@/types/common';
import {useAutocomplete,} from '@/composition/autocomplete';
import {prop,} from 'vue-class-component';

export default {
    name: 'SelectWithSearch',
    props: {
        options: {
            type: Array as PropType<SelectListItem[]>,
        },

        modelValue: {
            type: Object as any,
        },

        placeholder: {
            type: String as PropType<string>,
        },

        noUploading:{
            type: Boolean as PropType<boolean>,
            default: false,
        },
        endpoint: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
        },
    },
    emits: ['update:modelValue', 'search'],
    setup(props, { emit, }) {
        const showItems = ref(false)
        const searchItems = ref({} as HTMLElement)
        const localValue = ref(props.modelValue?.name)
        const startingValue = props.modelValue?.name || ''
        const noResults = ref(false)

        const localItems = computed(() => props.options)
        const localValueChanged = ref(false)
        const showedItems = ref<any>([])
        watch(localItems, () => {
            showedItems.value = localItems.value
        })

        const page = ref(1)
        const setVisibility = (bool) => {
            showItems.value = bool
        }

        const selectedBefore = ref(props.modelValue)
        const onFocus = () => {
            setVisibility(true)
        }
        const onBlur = () => {
            page.value = 1
            if (selectedBefore.value) {
                emit('update:modelValue', selectedBefore.value)
                localValue.value = selectedBefore.value.name
                if (props.noUploading) emit('search', { s: '', p: 1, } )
                else emit('search', { s: localValue.value, p: 1, } )
                // localValueChanged.value = startingValue !== localValue.value
                // if (!localValueChanged.value) emit('search', { s: '', p: 1, } )
            }
            else {
                emit('update:modelValue', { id:null, name: '', })
            }
            setVisibility(false)
        }
        const select = (e, item: SelectListItem) => {
            e.stopPropagation()
            e.preventDefault()
            setVisibility(false)
            localValue.value = item.name
            selectedBefore.value = item
            emit('update:modelValue', item)
            page.value = 1
        }
        const onSearch = (e) => {
            // localValueChanged.value = startingValue !== localValue.value
            const search: string = e?.target?.value;
            page.value = 1
            noResults.value = false
            emit('search', { s: search, p: page.value, } )
            setVisibility(true)
        }

        const { requestForVariants, } = useAutocomplete()
        const onClick = (e) => {
            setVisibility(true);
        }
        const onListScroll = async ( event: Event ) => {
            if (props.noUploading) return
            if (searchItems.value && (searchItems.value.scrollTop === (searchItems.value.scrollHeight - searchItems.value.offsetHeight))) {
                if (!noResults.value && props.endpoint!=='') {
                    page.value = page.value + 1
                    // const searchString = localValueChanged.value ? localValue.value : ''
                    const searchString = localValue.value
                    const response = await requestForVariants('/' + props.endpoint, searchString, false, page.value)
                    if (response.next_page === null) {
                        noResults.value = true
                    }
                    showedItems.value = [...showedItems.value, ...response.results]
                }
            }
        }

        if (props.noUploading)
            emit('search', { s: '', p: 1, } )
        else emit('search', {s: localValue.value, p: 1, })

        return {
            showItems,
            setVisibility,
            localValue,
            onFocus,
            onBlur,
            select,
            onSearch,
            onClick,
            onListScroll,
            searchItems,
            page,
            showedItems,
        }
    },
}
