
import { defineComponent, reactive, ref, computed, watch, } from 'vue'

//import types
import { UserProfile, } from '@/types/user.ts'

//import common modules
import { get,} from '@/modules/http'
import { createInput, commonFields, } from '@/modules/fields.ts'
import { isNotEmpty, isRepresentativePhone, isRepresentativeEmail, } from '@/modules/validators.ts'

//import composition
import { useCommmonNotification, } from '@/composition/notification.ts'
import { useUserProfileData, } from '@/composition/userProfile.ts'
import { useFieldValidator,} from '@/composition/fieldValidator.ts'
import { useModal,} from '@/composition/modal.ts'

//import components
import FieldBlock from '@/components/Generic/field-block/index.vue'
import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'
import ProfileImageLoader from '@/components/Profile/profile-image-loader/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue';
import SelectListInput from '@/components/Generic/select-list-input/index.vue'

export default defineComponent({
    name: 'ProfileData',
    components: {
        FieldBlock,
        FieldBlockInput,
        ProfileImageLoader,
        ModalWindow,
        LoadingIndicator,
        SelectWithSearch,
        SelectListInput,
    },
    async setup () {
        const { showNotification, } = useCommmonNotification()
        const {  updateUserProfileData,  setOnModerationIcon, getUserProfileData: userData,  } = useUserProfileData()
        const  isLoading = ref(false)
        const  isTabLoaded = ref(false)
        isTabLoaded.value = true
        const lastName = createInput({ subText: 'Фамилия', inputValue: userData.value.last_name || '',validators:[ isNotEmpty ],})
        const firstName = createInput({ subText: 'Имя',  inputValue: userData.value.first_name || '', validators:[ isNotEmpty ],})
        const middleName = createInput({ subText: 'Отчество',  inputValue: userData.value.middle_name || '', })
        const phone = commonFields.phone
        const email = commonFields.email
        const representativeLastName = createInput({ subText: 'Фамилия официального представителя', inputValue: userData.value.representative_last_name || '',})
        const representativeFirstName = createInput({ subText: 'Имя официального представителя',  inputValue: userData.value.representative_first_name || '',})
        const representativeMiddleName = createInput({ subText: 'Отчество официального представителя',  inputValue: userData.value.representative_middle_name || '', })
        const representativePhone = commonFields.phone
        const representativeEmail = commonFields.email
        const date_of_birth = commonFields.date_of_birth
        const snils = commonFields.snils
        const genders = ref([
            { id: 'man', name: 'Мужской', }, { id: 'woman', name: 'Женский', }, { id: '', name: 'Не выбрано', }
        ])
        const selectedGender = ref({ id: '', name: 'Не выбрано', })

        phone.inputValue = userData.value.phone || ''
        phone.inputDisabled = !(userData.value.phone === null)
        email.inputValue = userData.value.email || ''
        if (email.inputValue !== '')
            email.inputDisabled = true

        representativePhone.subText = 'Телефон официального представителя'
        representativePhone.validators = [ isRepresentativePhone ]
        representativePhone.inputValue = userData.value.representative_phone || ''
        representativeEmail.subText = 'Email официального представителя'
        representativeEmail.validators = [ isRepresentativeEmail ]
        representativeEmail.inputValue = userData.value.representative_email || ''
        date_of_birth.inputValue = userData.value.date_of_birth || ''
        snils.inputValue = userData.value.snils || ''
        selectedGender.value = genders.value.find((gender) => gender.id === userData.value.gender) as { id: string; name: string } || ''


        const indexForms = {
            representative_last_name: 0,
            representative_first_name: 1,
            representative_middle_name: 2,
            representative_phone: 3,
            representative_email: 4,
        }

        const forms = reactive ({
            profileForm: [
                lastName,
                firstName,
                middleName,
                date_of_birth,
                email,
                phone,
                snils
            ],
        })

        const fieldsUnderage = reactive([representativeLastName, representativeFirstName, representativeMiddleName, representativePhone, representativeEmail])

        const checkField = (val) => {
            if (val === null) {
                return '';
            }
            return val.toLowerCase()
        }


        const submitDisabled = ref(true)

        const onFormChange = () => {
            submitDisabled.value =  (lastName.inputValue.trim().toLocaleLowerCase() === userData.value.last_name.toLowerCase())
                && (firstName.inputValue.trim().toLocaleLowerCase() === userData.value.first_name.toLowerCase())
                && (middleName.inputValue.trim().toLocaleLowerCase() === userData.value.middle_name.toLowerCase())
                && (email.inputValue.trim().toLocaleLowerCase() === userData.value.email.toLowerCase())
                && (snils.inputValue === userData.value.snils)
                && (selectedGender.value.id === userData.value.gender)
                && (date_of_birth.inputValue === userData.value.date_of_birth)
                && (phone.inputValue.trim().toLocaleLowerCase().replace(/\(|\)|\s|\-/g,'') === userData.value.phone.toLowerCase())
                && (representativeLastName.inputValue.trim().toLocaleLowerCase() === checkField(userData.value.representative_last_name))
                && (representativeFirstName.inputValue.trim().toLocaleLowerCase() === checkField(userData.value.representative_first_name))
                && (representativeMiddleName.inputValue.trim().toLocaleLowerCase() === checkField(userData.value.representative_middle_name))
                && (representativePhone.inputValue.trim().toLocaleLowerCase().replace(/\(|\)|\s|\-/g,'') === checkField(userData.value.representative_phone))
                && (representativeEmail.inputValue.trim().toLocaleLowerCase() === checkField(userData.value.representative_email));
        }

        const onGenderSearch = async (searchString: string, page: 0) => {
            if (searchString === '') {
                return genders.value = [{ id: 'man', name: 'Мужской', }, { id: 'woman', name: 'Женский', }, { id: '', name: 'Не выбрано', }]
            }
            
            genders.value = genders.value.filter((gender) => gender.name.includes(searchString))
        }

        const handleSearch = (handler, { s, p, }, index) => {
            handler(s, p, index);
        }

        const buttonParams = reactive({
            height: '50px',
            // width: '260px',
            loading: isLoading,
            text: 'Отправить на модерацию',
            disabled: submitDisabled,
        })

        const buttons = [
            {
                text: 'Понятно',
                event: 'close',
                class:'',
            }
        ]

        const { modalTitle, isModalVisible, onModalClose, } = useModal()

        // Validate form

        const { validateField, notificationName, } = useFieldValidator()

        const onSubmit = async () => {
            // if (userData.value.verified === false) return
            if (isLoading.value) return
            const validationErrors =  Object.values(forms.profileForm).map(field => validateField(field))
            const validationUnderageErrors = Object.values(fieldsUnderage).map(field => validateField(field))
            const isErrors = validationErrors.filter( error => error === true ).length > 0
            const isUnderageErrors = validationUnderageErrors.filter( error => error === true ).length > 0
            if (isErrors || isUnderageErrors)  { return }
            const data = {
                'last_name': lastName.inputValue,
                'first_name': firstName.inputValue,
                'middle_name': middleName.inputValue,
                'email': email.inputValue,
                'phone': phone.inputValue.replace(/\(|\)|\s|\-/g,''),
                'achievements': '',
                'jobs': '',
                'snils': snils.inputValue || '',
                'gender': selectedGender.value.id,
                'date_of_birth': date_of_birth.inputValue || '',
                'representative_last_name': representativeLastName.inputValue,
                'representative_first_name': representativeFirstName.inputValue,
                'representative_middle_name': representativeMiddleName.inputValue,
                'representative_phone': representativePhone.inputValue.replace(/\(|\)|\s|\-/g,''),
                'representative_email': representativeEmail.inputValue,
            }
            isLoading.value = true
            const response  = await updateUserProfileData(data)
            if (response.ok === true) {
                isModalVisible.value = true
            } else {
                for (const err in response.data) {
                    fieldsUnderage[indexForms[err]].errorMessages = notificationName(err, response.data[err])
                }
            }            
            isLoading.value = false
            setOnModerationIcon(true)
        }

        const image = computed(() => {
            return userData.value.avatar
        })

        const updateImage = async (base64: string) => {
            const data =  { 'upload_avatar': base64, }
            const response  = await updateUserProfileData(data)
            if (response.ok === true) {
                showNotification('Изображение профиля изменено')
            }
        }

        setOnModerationIcon(!userData.value.verified)

        watch(selectedGender, onFormChange, { deep: true, });

        return {
            forms,
            fieldsUnderage,
            genders,
            selectedGender,
            onSubmit,
            setOnModerationIcon,
            buttons,
            userData,
            image,
            updateImage,
            modalTitle,
            isModalVisible,
            onModalClose,
            verified: userData.value.verified,
            isTabLoaded,
            buttonParams,
            submitDisabled,
            onFormChange,
            onGenderSearch,
            handleSearch,
        }
    },
});
