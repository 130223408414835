
import {defineComponent, ref, PropType, onMounted, computed,} from 'vue'

//import types
import { SelectListItem, Practice, } from '@/types/common';

//WYSIWIG

//import func
import { put, get, postFormData, } from '@/modules/http'
import {useRouter,} from 'vue-router'

//import composition
import { useSelectList, } from '@/composition/useSelectList.ts'
import { useAutocomplete, } from '@/composition/autocomplete.ts'

//import components
import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'
import BaseAttached from '@/components/Generic/attached/index.vue';
import SelectListInput from '@/components/Generic/select-list-input/index.vue'
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue';


export default defineComponent({
    name: 'ProfilePracticesGroup',
    components: {
        BaseAttached,
        FieldBlockInput,
        SelectListInput,
        TrashIcon,
        SelectWithSearch,
    },
    props: {
        list: {
            type: Object as PropType<Practice>,
        },
    },
    emits: ['my-event'],
    setup(props, {emit,  }) {
        const router = useRouter()
        const searchGroups = ref<SelectListItem[]>([])
        const {
            list: groups,
            addItem,
            deleteItem: deleteGroup,
        } = useSelectList(props.list?.groups)

        const OnGroupSearch = async (searchString: string, page: number, index: number) => {
            const url = '/profile/practice_groups/?page=1&page_size=999999'
            const response = await get(url);
            const uploaded = response.parsedBody

            searchGroups.value = (page === 1) ? uploaded.results : [...searchGroups.value, ... uploaded.results]
            searchGroups.value =  searchGroups.value.filter(
                pos => !(groups.value.findIndex(el => el.id === pos.id && el.id!==groups.value[index].id) >= 0))

        }
        const handleSearch = (handler, { s, p, }, index) => {
            handler(s, p, index);
        }
        const onReplace = (newValue, oldId, targetArr, replaceIndex) => {            
            const index = targetArr.value.findIndex((pos, index) => pos.id === oldId && index === replaceIndex);
            if (index !== -1) {
                targetArr.value[index] = newValue
            }
            emit('my-event', groups)
        }
        const addGroup = () => {
            if (groups.value.length !== 0 && groups.value[groups.value.length - 1].id === null) {
                return
            }
            addItem()  
            if (router.currentRoute.value.name === 'ProfilePractiesList') {
                const scrollBlock = document.getElementsByClassName('modal-window__wrapper--scroll')[0]
                const listSelect = document.getElementsByClassName('modal-window__message')[0]
                scrollBlock.scrollTo({
                    top: listSelect.clientHeight,
                    behavior: 'smooth',
                })
            }
        }
        const onGroupReplace = (newValue, oldId, index) => {
            onReplace(newValue, oldId, groups, index);
        }
        const testClick = () => {
            emit('my-event', groups)
        }
        const onDelete = (index: number) => {
            deleteGroup(index);
            emit('my-event', groups);
        }

        const disabledAddGroupBtn = computed(() => {
            return groups.value.length !== 0 && groups.value[groups.value.length - 1].id === null ? true : false
        })
        onMounted(() => {
            if (groups.value.length === 0) {
                groups.value.push({
                    id: null,
                    name: '',
                });
            }

        });
        return {
            handleSearch,
            //
            disabledAddGroupBtn,
            groups,
            addGroup,
            onDelete,
            OnGroupSearch,
            onGroupReplace,
            searchGroups,
            testClick,
        }
    },
});
