<template lang="pug">
template(v-if="data && route.path !== '/audit/process'")
    a.select.login-block__back-link.profile__form__back-link(@click="$router.push({path: `/audit/completed`})" )
        img(src="@/assets/img/back.svg")
        span Вернуться
    h4 {{ data.case.name }}
    div.result
        fieldset
            legend Попытка
            div {{data.attempting}} из {{data.case.max_attempting}}
        fieldset
            legend Пользователь
            div {{data.user.full_name}}
        fieldset
            legend Итоговые баллы
            div {{data.points}} из 5
        fieldset
            legend Отправка данных пользователю
            div Данные отправлены
    .recomend
        b Общие рекомендации
        .wysiwyg-block(v-html="data.recommendation")
    Collapse(v-for="(item, index) in data.caseanswer_set" )
        template(#title="") Вопрос {{ index + 1}}
        template(#subtitle="") {{ item.question_text }}
        template(#content="")
            template(v-if="item.answer.length > 0")
                b Ответ пользователя
                .wysiwyg-block(v-html="item.answer")
            .teacher-answer__docs(v-if="item.files.length > 0" :class="[item.answer.length > 0 ? 'teacher-answer__docs-mt' : 'teacher-answer__docs-mt--none']")
                b Документы пользователя
                .teacher-answer__doc(v-for="file in item.files")
                    a(:href="`${file.absolute_url}`" target="_blank")
                        span {{file.name}}                
            .comment-tutor(v-if="item.comment.length > 0")
                b Комментарий
                .wysiwyg-block(v-html="item.comment")
</template>

<script>
import {defineComponent, ref,} from 'vue'
import {useRoute, } from 'vue-router'
// component
import Collapse from '@/components/Generic/collapse/collapse.vue'
// composition
import {useAudit,} from '@/composition/audit'

export default defineComponent({
    components: {
        Collapse,
    },
    setup() {
        document.documentElement.style.overflow = 'auto';
        
        const route = useRoute();
        // const
        const recomendation = ref()
        const points = ref()
        const data = ref(null)
        // func
        const { 
            caseCheckRead, 
            case_answer_comment_update, 
            case_check_update,
        } = useAudit();
        // my events
        const getData = async () => {
            const response = await caseCheckRead(route.params.id);
            data.value = response
        };
        const saveAnswer = async (item) => {
            await case_answer_comment_update(item.id, item);
        } 
        const completeCheck = async () => {
            const data = {
                id: route.params.id,
                points: points.value,
                recommendation: recomendation.value,
            }
            await case_check_update(route.params.id, data);
        } 
        getData()
        return {
            route,
            // consts
            data,
            recomendation,
            points,
            // func
            saveAnswer,
            completeCheck,
            // useAudit
        }
    },
})
</script>


<style lang='scss' scoped>
h4 {
    font-size: 21px;
    line-height: 140%;
    margin-bottom: 30px;
    font-weight: bold;
    color: #1B1B1A;
}
.result {
    display: flex;
}
fieldset {
    margin-bottom: 30px;
    margin-right: 30px;
    border: none;
    line-height: 25px;
    font-weight: 500;
    font-size: 16px;
    legend {
        font-size: 13px;
        color: #6D6D6D;
    }
}
@media screen and (max-width:1035px)  {}
@media screen and (max-width:750px) {
    h4 {
        font-size: 18px;
    }
}
.recomend {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
p {
    margin-bottom: 30px; 
}

.radio__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.field-block__label {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center;
    justify-content: center;
    width: 50px;
}
.field-block__radio {
    margin: 0;
}
.teacher-answer {
    &__docs-mt {
        margin-top: 1rem;
    }
    &__docs-mt--none {
        margin-top: 0;
    }
    &__doc {
        &:nth-last-of-type(1) {
            margin-top: 13px;
        }
        a {
            cursor: pointer;
            text-decoration: underline;
            color: $mainFontColor;
            &:hover {
                color: $accent-color-hover;
                text-decoration: none;
            }
        }
    }
}
.comment-tutor {
    margin-top: 18px;
}
.collapse:last-child {
    padding-bottom: 0;
}
.wysiwyg-block {
    margin-top: 13px;
    margin-bottom: 0;
}
</style>
