
import { defineComponent, PropType, } from 'vue';

import { useAuth, } from '@/composition/state.ts'
import { useToggle, } from '@/composition/toggle.ts'
import { useRedirect, } from '@/composition/redirect.ts'

import TempAvatar from '@/components/Generic/temp-avatar/index.vue'
import {useOnboarding,} from '@/composition/onboarding';

export default defineComponent({
    name: 'UserDropdown',
    components: {
        TempAvatar,
    },
    props: {
        email: {
            type: String as PropType<string>,
        },
        phone: {
            type: String as PropType<string>,
        },
        avatar: {
            type: String as PropType<string>,
        },
        verified: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup () {
        const { logOut, } = useAuth()
        const {
            toggleVisibility,
            isVisible,
        } = useToggle(false)
        const { redirectPublic, } = useRedirect()
        const exit = async () => {
            logOut()
            redirectPublic('');
        }
        const onClickAway = () => {
            isVisible.value = false
        }

        return {
            logOut,
            toggleVisibility,
            isVisible,
            redirectPublic,
            exit,
            onClickAway,
        }
    },
})

