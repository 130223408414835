
import {computed, defineComponent, ref, } from 'vue'
//import composition
import {useOnboarding,} from '@/composition/onboarding';
import {useUserProfileData,} from '@/composition/userProfile';
//import componets
import TitleBlock from '@/components/Generic/title-block/index.vue'
import UserOnboarding from '@/components/Onboarding/user-obnoarding/UserOnboarding.vue';

export default defineComponent({
    name: 'LkCourses',
    components: {
        TitleBlock,
        UserOnboarding,
    },
    setup () {
        const { getUserProfileData: userData, } = useUserProfileData()
        const  userName = computed(() => {
            const middleName =  userData.value?.middle_name ? ' ' + userData.value.middle_name : ''
            return  `${userData.value?.first_name || ''}${middleName},`
        })
        const {
            step,
            open,
            setStep,
            close,  } = useOnboarding()

        userData.value.onboarding_step !== 0 ? setStep(6) : setStep(1)
        
        return {
            step,
            open,
            close,
            userName,
            userData,
        }
    },
});
