import moment from 'moment';

export function useDiagnostic() {
    const acceptBtns = [
        {
            text: 'Да',
            event: 'ok',
            class: '',
        },
        {
            text: 'Нет',
            event: 'close',
            class: 'btn--outline',
        }
    ]
    const normalizeData = (resultData) => {
        const currentData = new Date()
        const serverData = resultData
        if (currentData.toISOString() > serverData) {
            resultData = null
        }
        else if (serverData == null) {
            resultData = null
        }
        else {
            const data = new Date(resultData)
            resultData = moment(data).format('HH:mm DD.MM.YYYY')
        }
        return resultData
    }
    return {
        acceptBtns,
        normalizeData,
    }
}
