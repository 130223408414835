
import {computed, defineComponent, PropType, ref,} from 'vue'
import moment from 'moment'

//import types
import {LKEvent,} from '@/types/common'

//import common
import {post,} from '@/modules/http'

//composition
import {useModal,} from '@/composition/modal'
import {useDisabled,} from '@/composition/globalDisabled'

import {useCommmonNotification,} from '@/composition/notification'
import {useEventItem,} from '@/composition/eventItem';

import ModalWindow from '@/components/Generic/modal-window/index.vue'
import {usePayment,} from '@/composition/payment';
import {useMoodle,} from '@/composition/moodle';

export default defineComponent({
    name: 'EventItem',
    components: {
        ModalWindow,
    },
    emits: ['change', 'block-btns', 'unblock-btns', 'start-change-status', 'end-change-status'],
    props: {
        event: {
            type: Object as PropType<LKEvent>,
            required: true,
        },
        eventType: {
            type: String as PropType<string>,
            default: 'courses',
            validator: (value: string) => ['events', 'courses', 'base_events'].indexOf(value) !== -1,
        },
        disabledBtn: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props, {emit,}) {
        const {
            eventStatus,
            eventType,
            eventDone,
            eventTypeText,
            rejectReason,
            shortDate,
            isFavorites,
            eventSlug,
            toggleFavorites,
        } = useEventItem(props, emit)
        const typeOfEvent = computed(() => props.eventType === 'base_events' ? props.event.type : props.eventType)

        const isSaving = ref(false)

        const isLoadingButton = ref(false)

        const {
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
        } = useModal()

        const {
            disabled,
            activeDisabled,
            isDisabled,
        } = useDisabled()

        const showModal = (status: number, is_paid: boolean) => {
            if (status === 1) {
                modalTitle.value = 'Вы действительно хотите отозвать запрос на участие в событии?'
                isModalVisible.value = true
            }
            if (status === 0) {
                modalTitle.value = 'Вы действительно хотите отправить повторный запрос на участие в событии?'
                isModalVisible.value = true
            }
            if (status === 4 && !is_paid) {
                modalTitle.value = 'Вы действительно хотите отменить участие в событии?'
                isModalVisible.value = true
            }
            if (status === 4 && is_paid) {
                modalTitle.value = 'Вы уверены, что хотите отменить заявку на участие? Если вы уже оплатили событие, то свяжитесь с администрацией сайта для возвращения денежных средств'
                isModalVisible.value = true
            }            
        }

        const buttons = [
            {
                text: 'Да',
                event: 'ok',
                class: '',
            },
            {
                text: 'Нет',
                event: 'close',
                class: 'btn--outline',
            }
        ]

        const {showNotification,} = useCommmonNotification()

        const changeStatus = async () => {
            isModalVisible.value = false
            isSaving.value = true
            emit('start-change-status')
            try {
                const action = (eventStatus.value !== 1 && eventStatus.value !== 4) ? 'create_claim/' : 'reject_claim/'
                let successMessage = (eventStatus.value !== 1) ? 'Заявка отправлена' : 'Заявка отозвана'
                successMessage = (eventStatus.value === 4) ? 'Участие в событии отменено' : successMessage
                const response = await post(`/${eventType.value}/${props.event.slug}/${action}`, {})
                const notiffContent = `Участие в ${eventTypeText} «${props.event.name}» отклонено. Причина отклонения: отклонено пользователем`
                if (response.status === 200) {
                    eventStatus.value = response.parsedBody.status
                    emit('change', props.event.id)
                    const notification = {
                        content: notiffContent,
                        date: moment().format('DD.MM.YYYY'),
                        type: props.eventType,
                        url: eventSlug,
                        viewed: false,
                    }
                    if (eventStatus.value === 4) showNotification(successMessage, 'success', true, notification)
                    else showNotification(successMessage)
                } else {
                    showNotification(response.parsedBody.join(' '), 'danger')
                    emit('change', props.event.id)
                }
            } catch (e) {
                console.error(e)
            }
            emit('end-change-status')
            isSaving.value = false
        }

        const eventTypeReconstruct = {
            'мероприятие': 'event',
            'курс': 'course',
            'олимпиада': 'olympiads',
        }

        /*===================PAYMENT AREA============================*/
        const {createPayment,} = usePayment();
        const isPaymentLoading = ref<boolean>(false);
        const onCreatePayment = async () => {        
            isPaymentLoading.value = true;
            emit('block-btns')
            if (props.eventType === 'base_events') {
                await createPayment(props.event.type, props.event.id, props.event.slug, props.event.name);
            } else {
                await createPayment(props.eventType, props.event.id, props.event.slug, props.event.name);
            }
            isPaymentLoading.value = false;
            emit('unblock-btns')
        };
        /*===================MOODLE============================*/ 
        const {getMoodle,} = useMoodle();  
        const isMoodleLoading = ref<boolean>(false);
        const goMoodle = async () => {
            isMoodleLoading.value = true
            emit('block-btns')
            const eventTypeText = props.event.type === 'courses' ||  props.eventType === 'courses' ? 'Курс недоступен' : 'Олимпиада недоступна';
            await getMoodle(props.eventType === 'base_events' ? props.event.type : props.eventType, eventTypeText, props.event.slug);
            isMoodleLoading.value = false;
            emit('unblock-btns', 'moodle')
        }
        const checkEventType = computed(() => {
            if (props.eventType === 'base_events') return props.event.type
            return props.eventType
        })
        
        return {
            eventStatus,
            changeStatus,
            eventSlug,
            eventDone,
            isFavorites,
            toggleFavorites,
            eventTypeText,
            rejectReason,
            isLoadingButton,
            shortDate,
            isModalVisible,
            modalTitle,
            modalMessage,
            buttons,
            onModalClose,
            showModal,
            isSaving,
            eventTypeReconstruct,
            onCreatePayment,
            isPaymentLoading,
            disabled,
            activeDisabled,
            isDisabled,
            typeOfEvent,
            isMoodleLoading,
            goMoodle,
            checkEventType,
        }
    },
})
