import { reactive, computed, } from 'vue'

import { UserCreds,  } from '@/types/user'
import { post, addGlobalHeaders, resetGlobalHeaders, } from '@/modules/http'
import { getCookie, getDomain, } from '@/modules/helpers'

const userStatus = reactive({
    isModerator: false,
    isApproved: true,
    accessToken: getCookie('token') || false,
})

export const useAuth = () => {
    //mutations
    const setToken = (token: string) => {
        userStatus.accessToken = token
    }
    //getters
    const getToken = () => {
        return userStatus.accessToken
    }
    const isModerator = () => {
        return computed(() => userStatus.isModerator )
    }
    //actions
    const putToken =  (token) => {
        const maxAge = 3600 * 24 * 30;
        const  domain = getDomain()
        document.cookie = `token=${encodeURIComponent(token)}; max-age=${maxAge}; path=/; domain=${domain};`
        addGlobalHeaders( {'Authorization': 'Bearer ' + token,})
    }
    const deleteToken = () => {
        const  domain = getDomain()
        if (domain !=='')
            document.cookie = `token=; max-age=-1; path=/; domain=${domain};`
    }

    const logOut = async() => {
        deleteToken()
        resetGlobalHeaders()
    }

    const logIn = async (userData: UserCreds): Promise<boolean> => {
        try {
            resetGlobalHeaders()
            userData.email = userData.email.replace(/ /g, '')
            // userData.phone = userData.phone.replace(/\(|\)|\s|\-/g,'')
            const response = await post('/jwt/obtain_by_email/', userData)
            if (response.ok && response.parsedBody.token) {
                putToken(response.parsedBody.token)
                return true
            }
            else if (response.status === 400) {
                resetGlobalHeaders()
                deleteToken()
                return false
            }
            else throw Error(response.statusText)
        }
        catch(e) {
            console.error(e)
            return false
        }
        return false
    }
    const verify = async (token: string): Promise<boolean>  => {
        try {
            const response = await post('/jwt/verify/', { token: token, })
            if (response.status === 201) {
                setToken(token)
                return true
            }
            else  {
                resetGlobalHeaders()
                deleteToken()
                return false
            }
        }
        catch(e) {
            console.error(e)
            return  false
        }
    }


    const isLoggedIn = async(): Promise<boolean> => {
        const token =  getCookie('token')
        if (!token) return  false
        return  await  verify(token)
    }

    return {
        logIn,
        logOut,
        verify,
        isLoggedIn,
        getToken,
        setToken,
        isModerator,
        putToken,
    }
}

