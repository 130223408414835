
import { defineComponent, } from "vue";
import TempAvatar from "@/components/Generic/temp-avatar/index.vue";
import { useMessages, } from "@/composition/messages";

export default defineComponent({
    name: "TalkList",
    components: { TempAvatar, },
    props: ["chats"],
    setup() {
        const { dateFormatting, } = useMessages();
        const domen = process.env.VUE_APP_API_BASE_URL

        const getOrganizations = item => {
            return item.organizations.join(", ") || "Не указано";
        };
        const dateLastMessage = item => {
            if (item.last_message !== null) {
                return dateFormatting(item.last_message.created);
            }
            return dateFormatting(item.created);
        };
        return {
            domen,
            dateFormatting,
            dateLastMessage,
            getOrganizations,
        };
    },
});
