import {ref, } from 'vue';
import moment from 'moment';

import {get, } from '@/modules/http';
import {useCommmonNotification,} from '@/composition/notification';
import {useCookie,} from '@/composition/cookie';

export function useResultTests() {
    const {showNotification,} = useCommmonNotification()
    const {getCookie,} = useCookie()
    const isUploadingBtn = ref(false)

    // Получить результаты тестов всей школы
    const resultTestsRead = async (search) => {
        const page = 1
        const pageSize = 10
        const response = await get(`/school_moderator/test_results/?page=${page}&page_size=${pageSize}&search=${search}`)
        if (response.ok) {
            return response.parsedBody
        }
        return null
    };
    // Получить деталку теста для модератора
    const detailTestRead = async (id) => {
        const response = await get(`/school_moderator/test_results/${id}`)
        if (response.ok) {
            return response.parsedBody
        }
        return null
    }

    // Скачать данные в excel
    const downloadTestResults = async() => {
        try {
            isUploadingBtn.value = true
            const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`
            const token = getCookie('token')
            await fetch(`${apiURL}/school_moderator/test_results_export/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if ((response.ok === true) && (response.status === 200)) {
                    return response.blob();
                }
                return null
            }).then((data) => {
                if (data === null) {
                    throw new SyntaxError('Данные необнаружены')
                }
                const Filename = `Test_Result ${moment().format(
                    'DD.MM.YYYY HH-mm'
                )}.xlsx`;
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', Filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                isUploadingBtn.value = false
            })
        } catch(e) {
            showNotification('Произошла ошибка при скачивании документа', 'danger')
            isUploadingBtn.value = false
        }
    }

    const modalInfoData = ref ({
        Header: String,
        id: Number,
        teacher_id: Number,
        username: String,
        test_name: String,
        annotation: String,
        attempting: {
            number: Number,
            max_attempting: Number,
            next: String,
        },
        type: String,
        subject_area: Array,
        competence: Array,
        max_points: Number,
        my_point: Number,
    })    

    const headersResultTests = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Сотрудник',
            value: 'employee',
        },
        { 
            text: 'Название теста',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Попытка',
            value: 'attemptings',
        },
        {
            text: 'Данные о попытке',
            value: 'data_test',
        },
        {
            text: 'Максимальные баллы',
            value: 'max_points',
        },
        {
            text: 'Мои баллы',
            value: 'my_point',
        }
    ]
    const headersDetailTest = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Вопросы',
            value: 'questions',
        },
        { 
            text: 'Ответ сотрудника',
            value: 'answers',
        }
    ]    
    return {
        resultTestsRead,
        headersResultTests,
        headersDetailTest,
        downloadTestResults,
        modalInfoData,
        isUploadingBtn,
        detailTestRead,
    }
}