import {computed, ref,} from 'vue';
import moment from 'moment';
import {post,} from '@/modules/http';
import {useCommmonNotification,} from '@/composition/notification';

export const useEventItem = (props, emit) => {
    const eventStatus = ref<number>(props.event.status)
    const eventType = ref<string>(props.event.type || props.eventType);
    let eventTypeText = (eventType.value === 'courses') ? 'курс' : 'мероприятие'
    const eventDone = ref<boolean>(props.event.is_done)
    const shortDate = ref<string>(moment(props.event.date_at_iso).format('DD.MM.YYYY'))
    const isFavorites = ref<boolean>(props.event.is_favorite)

    if (eventType.value === 'olympiads') {
        eventTypeText = 'олимпиада'
    }

    const rejectReason = computed(() => {
        if (eventStatus.value === 2) {
            const rejectReason = (props.event.reject_reason === '') ?  ' причина не указана' : props.event.reject_reason
            return `Участие в событии отклонено модератором: ${rejectReason}`
        }
        if (eventStatus.value === 3)
            return 'Участие в событии отклонено пользователем'
        return ''
    })

    const eventSlug = `${process.env.VUE_APP_PUBLIC_URL}/${eventType.value}/${props.event.slug}`

    const { showNotification, } = useCommmonNotification()

    const toggleFavorites = async () => {
        try {
            const response = await post(`/${eventType.value}/${props.event.slug}/toggle_favorites/`, {})
            if (response.status === 200) {
                const successMessage = isFavorites.value ? 'Убрано из избранного' : 'Добавлено в избранное'
                isFavorites.value = !isFavorites.value
                showNotification(successMessage)
                emit('change', -props.event.id)
            } else console.error('Ошибка при добавлении в избранное')
        } catch (e) {
            console.error(e)
        }
    }

    return {
        eventStatus,
        eventType,
        eventDone,
        eventTypeText,
        rejectReason,
        shortDate,
        isFavorites,
        eventSlug,
        toggleFavorites,
    }
}
