
import { defineComponent, ref, reactive, computed, watch, } from 'vue'

//import common modules
import { createInput, commonFields, } from '@/modules/fields.ts'
import { isNotEmpty, } from '@/modules/validators.ts'

//import composition
import { useUserProfileData, } from '@/composition/userProfile.ts'
import { useFieldValidator, } from '@/composition/fieldValidator.ts'
import { useOnboarding, } from '@/composition/onboarding';

//import components
import FieldBlock from '@/components/Generic/field-block/index.vue'
import Btn from '@/components/Generic/btn/index.vue'
import WorkEditor from '@/components/Profile/works-editor/WorksEditor.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue';
import SelectListInput from '@/components/Generic/select-list-input/index.vue'

export default defineComponent({
    name:'UserOnboarding',
    components: {
        FieldBlock,
        WorkEditor,
        Btn,
        SelectWithSearch,
        SelectListInput,
    },
    setup () {
        const isLoading = ref(false)
        const { getUserProfileData: userData, updateUserProfileData, } = useUserProfileData()
        const last_name = createInput({ subText: 'Фамилия', labelField: 'last_name', inputValue: userData.value.last_name || '',validators:[ isNotEmpty ], errorMessages: [],})
        const first_name = createInput({ subText: 'Имя', labelField: 'first_name', inputValue: userData.value.first_name || '', validators:[ isNotEmpty ], errorMessages: [],})
        const middle_name = createInput({ subText: 'Отчество', labelField: 'middle_name', inputValue: userData.value.middle_name || '', errorMessages: [],})
        const snils = commonFields.snils
        const phone = commonFields.phone
        const email = commonFields.email
        const date_of_birth = commonFields.date_of_birth
        const underage = ref(userData.value.underage || false)
        const genders = ref([
            { id: 'man', name: 'Мужской', }, { id: 'woman', name: 'Женский', }, { id: '', name: 'Не выбрано', }
        ])
        const selectedGender = ref({ id: '', name: 'Не выбрано', })
        phone.inputValue = userData.value.phone || ''
        email.inputDisabled = true
        email.inputValue = userData.value.email || ''
        snils.inputValue = userData.value.snils || ''
        selectedGender.value = genders.value.find((gender) => gender.id === userData.value.gender) as { id: string; name: string } || { id: '', name: 'Не выбрано', }
        date_of_birth.inputValue = userData.value.date_of_birth || ''


        const indexForms = {
            last_name: 0,
            first_name: 1,
            middle_name: 2,
            email: 3,
        }

        const forms = reactive ({
            profileForm: [
                last_name,
                first_name,
                middle_name,
                date_of_birth,
                email,
                phone,
                snils
            ],
        })

        const buttonParams = reactive({
            loading: isLoading.value,
            text: 'Далее',
            width: '210px',
        })

        const { validateForm, notificationName, } = useFieldValidator()
        const isNoChanges = ref(true)

        const onFormChange = () => {
            if (userData.value.last_name !== null) {
                isNoChanges.value = (last_name.inputValue.trim().toLocaleLowerCase() === userData.value.last_name.toLowerCase())
                    && (first_name.inputValue.trim().toLocaleLowerCase() === userData.value.first_name.toLowerCase())
                    && (middle_name.inputValue.trim().toLocaleLowerCase() === userData.value.middle_name.toLowerCase())
                    && (email.inputValue.trim().toLocaleLowerCase() === userData.value.email.toLowerCase())
                    && (phone.inputValue.trim().replace(/\(|\)|\s|\-/g, '') === userData.value.phone)
                    && (underage.value === userData.value.underage)
                    && (snils.inputValue === userData.value.snils)
                    && (selectedGender.value.id === userData.value.gender)
                    && (date_of_birth.inputValue === userData.value.date_of_birth);
            } else {
                isNoChanges.value = (last_name.inputValue.trim().toLocaleLowerCase() === '')
                    && (first_name.inputValue.trim().toLocaleLowerCase() === '')
                    && (middle_name.inputValue.trim().toLocaleLowerCase() === '')
                    && (email.inputValue.trim().toLocaleLowerCase() === '')
                    && (phone.inputValue.trim().toLocaleLowerCase().replace(/\(|\)|\s|\-/g, '') === '')
                    && (snils.inputValue === '')
                    && (selectedGender.value.id === '')
                    && (date_of_birth.inputValue === '');
            }
        }
        const { step, close, isOnboardingVisible, setStep, saveStep, onClose, } = useOnboarding()

        const onGenderSearch = async (searchString: string, page: 0) => {
            if (searchString === '') {
                return genders.value = [{ id: 'man', name: 'Мужской', }, { id: 'woman', name: 'Женский', }, { id: '', name: 'Не выбрано', }]
            }
            
            genders.value = genders.value.filter((gender) => gender.name.includes(searchString))
        }

        const handleSearch = (handler, { s, p, }, index) => {
            handler(s, p, index);
        }

        const onStepOne = async() => {            
            last_name.inputValue = last_name.inputValue.replace(/ +/g, ' ').trim()
            first_name.inputValue = first_name.inputValue.replace(/ +/g, ' ').trim()
            
            const isErrors = validateForm(forms.profileForm)
            if (isErrors === true) {
                return
            }

            const data = {
                'last_name': last_name.inputValue || '',
                'first_name': first_name.inputValue || '',
                'middle_name': middle_name.inputValue || '',
                'email': email.inputValue,
                'phone': phone.inputValue.replace(/\(|\)|\s|\-/g,''),
                'achievements': '',
                'jobs': '',
                'onboarding_step': step.value,
                'underage': underage.value,
                'snils': snils.inputValue || '',
                'gender': selectedGender.value.id,
                'date_of_birth': date_of_birth.inputValue || '',
            }

            //if there is no change
            if (isNoChanges.value === true) {
                setStep(step.value > 3 ? 5:2)
                await saveStep()
                return
            }
            isLoading.value = true
            const result  = await updateUserProfileData(data)
            if (result.ok) {
                setStep(step.value > 3 ? 5:2)
            }
            else {
                for (const err in result.data) {
                    forms.profileForm[indexForms[err]].errorMessages = notificationName(err, result.data[err])
                }
            }
            isLoading.value = false
        }

        const onStepTwo = () => {
            setStep(step.value > 3 ? 6:3)
        }

        const nextStep = async () => {
            switch(step.value) {
            case 1:
            case 4:
                onStepOne()
                break;
            case 2:
            case 5:
                onStepTwo()
                break;
            case 3:
            case 6:
                setStep(4);
                await saveStep()
                isNoChanges.value = true
                break;
            }
        }

        const prevStep = async () => {
            setStep(step.value - 1)
            await saveStep()
        }

        watch(selectedGender, onFormChange, { deep: true, });

        return {
            forms,
            genders,
            selectedGender,
            underage,
            buttonParams,
            onFormChange,
            isNoChanges,
            isLoading,
            step,
            prevStep,
            nextStep,
            close,
            isOnboardingVisible,
            onClose,
            onGenderSearch,
            handleSearch,
        }
    },

})
