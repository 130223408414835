import { ref, } from  'vue'

export const useDisabled = () => {
    const disabled = ref(false)

    const activeDisabled = () => {
        disabled.value = true
    }

    const isDisabled = () => {
        disabled.value = false
    }

    return {
        disabled,
        activeDisabled,
        isDisabled,
    }
}
