
import {computed, defineComponent, PropType, toRefs, watch, toRef,} from 'vue'

import {SchoolPerson,} from '@/types/school'

import {useModal,} from '@/composition/modal'
import {usePerson,} from '@/composition/person'

import Btn from '@/components/Generic/btn/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import TempAvatar from '@/components/Generic/temp-avatar/index.vue'
import {workStatus,} from '@/modules/constants/status'
import {useUserProfileData,} from '@/composition/userProfile';
export default defineComponent({
    name: 'StaffCard',
    components: {
        Btn,
        ModalWindow,
        TempAvatar,
    },
    props: {
        person: {
            type: Object as PropType<SchoolPerson>,
            required: true,
        },
    },
    emits: ['update:modelValue', 'update'],
    setup(props, {emit,}) {
        const { getUserProfileData: userData, } = useUserProfileData()
        const {
            localPerson,
            makeModer,
            rejectPerson,
            acceptPerson,
            isSaving,
            unMakeModer,
            makeNotWorking,
        } = usePerson(toRef(props, 'person'), emit)

        const teacherLink = `${process.env.VUE_APP_PUBLIC_URL}/teachers`

        const isMe = computed(() => userData.value.id === localPerson.value?.user?.id)

        /* ---renderers--- */
        const position = computed(() => {
            return localPerson.value?.positions ? localPerson.value.positions.map(x => x.name).join(', ') : ''
        })
        const oldPosition = computed(() => {
            return localPerson.value?.old_positions ? localPerson.value.old_positions.map(x => x.name).join(', ') : ''
        })
        const subjects = computed(() => {
            return localPerson.value?.subjects ? localPerson.value.subjects.map(x => x.name).join(', ') : ''
        })
        const oldSubjects = computed(() => {
            return localPerson.value?.old_subjects ? localPerson.value.old_subjects.map(x => x.name).join(', ') : ''
        })
        const qualification = computed(() => {
            return localPerson.value?.qualification?.name
        })
        const oldQualification = computed(() => {
            return localPerson.value?.old_qualification?.name
        })
        /*---*/
        const {isModalVisible, onModalClose,} = useModal()

        let okAction;

        const handleOK = (e) => {
            okAction(e)
            isModalVisible.value = false
        }


        const buttons = [
            {
                text: 'Да',
                event: 'ok',
                class: '',
            },
            {
                text: 'Нет',
                event: 'close',
                class: 'btn--outline',
            }
        ]

        const changeStatus = (status: string) => {
            switch (status) {
            case workStatus.working:
                okAction = acceptPerson;
                break;
            case workStatus.not_working:
                okAction = makeNotWorking;
                break;
            case workStatus.reject:
                okAction = rejectPerson;
                break;
            case workStatus.moderator:
                okAction = makeModer;
                break;
            case workStatus.reject_moderator:
                okAction = unMakeModer;
                break;
            default:
                console.error('no action')
                break;
            }
            isModalVisible.value = true
        }

        return {
            localPerson,
            makeModer,
            rejectPerson,
            acceptPerson,
            isSaving,
            buttons,
            isModalVisible,
            handleOK,
            changeStatus,
            onModalClose,
            position,
            subjects,
            qualification,
            workStatus,
            isMe,
            oldPosition,
            oldSubjects,
            oldQualification,
            teacherLink,
        }
    },
})
