
import { defineComponent, } from 'vue'
//impport modules
import { tabs, } from '@/modules/constants/tabs.ts'
//import composition
import { useTabs, } from '@/composition/tabs.ts'
import { useUserProfileData, } from '@/composition/userProfile'
//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock  from '@/components/Generic/title-block/index.vue'


export default defineComponent({
    components: {
        Tabs,
        TitleBlock,
        props: {
            id: String,
        },
    },
    setup () {
        const { isOnModerationIcon, } =  useUserProfileData()
        const { tabItems, activeTab, } = useTabs(tabs.profile)

        const { setOnModerationIcon,  } = useUserProfileData()
        setOnModerationIcon(false)

        return {
            tabItems,
            activeTab,
            isOnModerationIcon,
        }
    },

})


