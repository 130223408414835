<template lang="pug">
.statistic-block
    .statistic-block__title(v-if="title") {{title}}
    .statistic-block__data
        template(v-for="item in dataTable")
            .statistic-block__item-row(v-if="item.value !== null && item.value !== '0 из 0'")
                a.left-element(v-if="hasLink" :href="item.link" target="_blank") {{item.name}}
                span.left-element(v-else) {{item.name}}
                span {{item.value}}

</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        dataTable: {
            type: Array,
        },
        hasLink: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.statistic-block {
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        color: #1b1b1a;
        margin-bottom: 20px;
    }
    &__item-row {
        width: 50%;
        display: flex;
        justify-content: space-between;            
        margin-bottom: 15px;
        @media (max-width: $media-tablet) {
            width: 100%;
            font-size: 16px;
            line-height: 18px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: #1b1b1a;
            &:hover {
                color: $accent-color-hover;
                cursor: pointer;
            }
        }
        .left-element {
            margin-right: 50px;
            max-width: 400px;
        }
    }  
}
</style>


