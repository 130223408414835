<template lang="pug">
transition(name="fade" appear @enter="onTransitionEnter")
    .modal.modal-fixed.modal--big
        .modal__bg(@click.self="$emit('close')")
        .modal__content
            .modal-window__btn-close(@click.prevent ="$emit('close')")
                .modal-window__btn-close-wrapper
                    span.modal-window__btn-close-line
                    span.modal-window__btn-close-line
            .modal__head
                .modal__title Создание беседы
                .modal__search
                    SelectBlockModal(name_search='Поиск по ФИО' @search="onSearch")
            .modal__body(ref="scroll")
                .modal__lists
                    ColleagueList(
                        :colleagues="availableUsers"
                        btnText="Написать"
                        :loading="loadingModal"
                        @onClick="createDialogueHandler"
                    )
            .modal__footer
                btn.btn.btn--small.btn--do-action(@click="loadMore($event)" :disabled="availableUsers.length === paginationModal.total" text="Загрузить ещё")
</template>

<script>
import { defineComponent, ref, } from "vue";
import {useRouter,} from 'vue-router'

import SelectBlockModal from "@/components/Generic/select-block-modal/index.vue";
import { useMessages, } from "@/composition/messages";

export default defineComponent({
    name: "ModalMessages",
    components: {
        SelectBlockModal,
    },
    emits: ["close", "create-dialogue"],
    async setup(props, {emit,}) {
        const router = useRouter()
        const { availableUsers, paginationModal, loadingModal, fetchAvailableUsers, createDialogue,} = useMessages();

        const searchText = ref("");

        const onSearch = async q => {
            searchText.value = q;
            await fetchAvailableUsers(searchText.value, availableUsers.value.pageSize);
        };

        const scroll = ref(null);

        const loadMore = async () => {
            await fetchAvailableUsers(
                searchText.value,
                availableUsers.value.length + paginationModal.value.pageSize
            );

            const { offsetHeight, scrollHeight, } = scroll.value;

            scroll.value.scrollTo({
                top: scrollHeight + offsetHeight,
                left: 0,
                behavior: "smooth",
            });
        };
        const createDialogueHandler = async (colleague) => {
            const response = await createDialogue(colleague.id)
            document.documentElement.style.overflow = 'visible';
            router.push(`/messages/${response.id}`)
            emit("create-dialogue")
        }

        await fetchAvailableUsers();
        
        return {
            searchText,
            availableUsers,
            paginationModal,
            loadingModal,
            scroll,
            onSearch,
            loadMore,
            fetchAvailableUsers,
            createDialogueHandler,
        };
    },
});
</script>
<style lang="scss" scoped>
.modal__content {
    .modal-window__btn-close {
        @media (max-width: 415px) {
            right: 10px;
            top: 10px;
        }
    }
}
</style>
