import { createApp, } from 'vue'
import Maska from 'maska'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueClickAway from 'vue3-click-away'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import header from '@/components/Generic/Header/TheHeader.vue'
import footer from '@/components/Generic/footer/TheFooter.vue'
import aside from '@/components/Generic/lk-aside/index.vue'
import main from '@/components/Generic/lk-main/LkMain.vue'
import searchBlock from '@/components/Generic/search-block/index.vue'
import titleBlock from '@/components/Generic/title-block/index.vue'
import iconCancel from '@/components/Generic/icon-cancel/index.vue'
import iconDone from '@/components/Generic/icon-done/index.vue'
import selectBlock from '@/components/Generic/select-block/index.vue'
import starFav from '@/components/Generic/star-favorite/index.vue'
import telegramNotif from '@/components/Generic/telegram-notification/index.vue'
import eventCertificates from '@/components/Certificates/CertificateItem.vue'
import colleagueItem from '@/components/Collegues/CollegueItem.vue'
import colleagueList from '@/components/Generic/colleague-list/index.vue'
import checkbox from '@/components/Generic/checkbox-block/index.vue'
import notLists from '@/components/Generic/notifications/index.vue'
import talkList from '@/components/Generic/talk-list/index.vue'
import msgList from '@/components/Generic/msg-list/index.vue'
import Btn from '@/components/Generic/btn/index.vue'
import FieldBlock from '@/components/Generic/field-block/index.vue'
import dialog from '@/plugins/dialog';

const app = createApp(App)
app.directive('maska', Maska.maska)
app.use(router)
app.use(CKEditor)
app.use(VueClickAway)
app.component('Btn', Btn)
app.component('MyHeader', header)
app.component('MyFooter', footer)
app.component('LkAside', aside)
app.component('LkMain', main)
app.component('SearchBlock', searchBlock)
app.component('TitleBlock', titleBlock)
app.component('IconCancel', iconCancel)
app.component('IconDone', iconDone)
app.component('SelectBlock', selectBlock)
app.component('StarFav', starFav)
app.component('Telegram', telegramNotif)
app.component('EventCertificates', eventCertificates);
app.component('ColleagueItem', colleagueItem);
app.component('ColleagueList', colleagueList);
app.component('Checkbox', checkbox);
app.component('NotLists', notLists);
app.component('TalkList', talkList);
app.component('MsgList', msgList);
app.component('FieldBlock', FieldBlock);
app.use(dialog);
router.isReady().then(() => app.mount('#app'))
