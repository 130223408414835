
import {computed, defineComponent, onMounted, ref,} from 'vue'
import { useRouter, } from 'vue-router'

import { Practice, } from '@/types/common'

import { get, del, post,  put,} from '@/modules/http'

import { useCommmonNotification, } from '@/composition/notification.ts'
import { useUserProfileData, } from '@/composition/userProfile.ts'

import SelectBlock from '@/components/Generic/select-block/index.vue'
import SinglePractice from '@/components/Profile/profile-practice/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import { VueDraggableNext, }  from 'vue-draggable-next'
import ProfilePracticesGroup from '@/components/Profile/profile-practice-group/index.vue';

export default defineComponent({
    name: 'ProfilePracticiesList',
    components: {
        SelectBlock,
        SinglePractice,
        ModalWindow,
        LoadingIndicator,
        Draggable: VueDraggableNext,
        ProfilePracticesGroup,
    },
    setup () {
        const router = useRouter()

        const { showNotification, } = useCommmonNotification()

        const { setOnModerationIcon,  } = useUserProfileData()
        setOnModerationIcon(false)

        const delDialogButtons = [
            {
                text: 'Да',
                event: 'ok',
                class: '',
            },
            {
                text: 'Нет',
                event: 'close',
                class:'btn--outline',
            }
        ]

        const addDialogButtons = [
            {
                text: 'Добавить',
                event: 'add-prac',
                class: '',
            }
        ]

        const addInputText = ref('')
        const groups = ref<number[]>([])

        const isDelDialogVisible = ref(false)
        const isAddDialogVisible = ref(false)

        const practices = ref()

        const isLoading  = ref<boolean>(false);

        const fetchPracts = async () => {
            const response = await get<Practice>('/profile/practices/', {})
            practices.value = response.parsedBody
        }

        const isTabLoaded = ref(false)
        onMounted(async ()=> {
            await fetchPracts()
            isTabLoaded.value = true
        })

        const addPrac = () => {
            document.documentElement.style.overflow = 'hidden';
            isAddDialogVisible.value = true
        }

        const errors = ref<string[]>([])
        const addPracRequest = async () => {

            if (addInputText.value.trim() === '') {
                errors.value = ['Это поле не может быть пустым']
                return
            }
            const data = {
                title: addInputText.value,
                groups: groups.value,
            }

            isLoading.value = true
            isAddDialogVisible.value = false
            const response = await post<Practice>('/profile/practices/', data)

            isLoading.value = false
            if (response.ok) {
                practices.value = [  ...practices.value, response.parsedBody ]
                router.push('/profile/practices/' + response.parsedBody.id)
                showNotification('Практика создана')
            }
            document.documentElement.style.overflow = 'visible';
        }

        const toDeleteID = ref(-1)

        const deletePracDialog = (id: number) => {
            toDeleteID.value = id
            isDelDialogVisible.value = true
            document.documentElement.style.overflow = 'hidden';
        }

        const deletePracRequest = async () => {
            isDelDialogVisible.value = false
            if (toDeleteID.value === -1)
                return
            const response = await del(`/profile/practices/${toDeleteID.value}`, {})
            if (response.ok) {
                const index = practices.value.findIndex( (p: {id: number; name: string}) => p.id === toDeleteID.value )
                practices.value.splice(index,1)
            }
        }

        const closeDialog = () => {
            toDeleteID.value = -1;
            isDelDialogVisible.value = false;
            isAddDialogVisible.value = false;
            groups.value = [];
            document.documentElement.style.overflow = 'visible';            
        }

        const savePracsOrder = async () => {
            const ordering = practices.value.map((el) =>{
                return {
                    id: el.id || null,
                    order: el.order || 0,
                }
            })
            try {
                const response = await put('/profile/practices/ordering/', {  practices: ordering,  })
                if (!response.ok) {
                    console.error('Порядок не изменен!')
                }
            }
            catch(e) {
                console.error(e)
            }
        }

        const sortPracs = async () =>{
            practices.value.forEach(
                (el, ind) => {
                    el.order = ind
                }
            )
            await savePracsOrder()
        }
        const testFunction = (data) => {
            const unique = [...new Set(data.value.map(item => item.id))];
            groups.value = unique as number[];
        }
        const isCreateNewFormDisabled = computed(() => {
            return !addInputText.value || !groups.value.length;
        })
        return {
            practices,
            addPrac,
            addPracRequest,
            isDelDialogVisible,
            toDeleteID,
            deletePracDialog,
            deletePracRequest,
            delDialogButtons,
            addDialogButtons,
            isAddDialogVisible,
            closeDialog,
            addInputText,
            groups,
            sortPracs,
            errorMessages:errors,
            isTabLoaded,
            testFunction,
            isCreateNewFormDisabled,
        }
    },
});
