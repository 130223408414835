<template lang="pug">
.test-results
    SearchBlock.title-block__searchBlock(
                name-search='Поиск по ФИО, названию теста, предметной области и профессиональной компетенции'
                is-fullwidth
                @on-search="loadDataWithSearch"
                @on-clear="loadDataWithSearch"
            )
    .tests-result
        .load-btn-data
            btn.btn.btn--do-action(
                text="Скачать в excel"
                @click="downloadTestResults"
                :loading="isUploadingBtn")
        Table(v-if="dataTest != 0 && !activeInputOnSearch" :items="dataTest" :headers="headersResultTests" @rowClick="onRowClick" :is_hover="true")
        .tests-loading(v-else-if="isUploading")
            LoadingIndicator
        p.par(v-else-if="dataTest == 0 && !isUploading" ) Нет подходящих тестов
    .more-btn-block__for-table(v-if="visibleMoreBtn && items != 0")
        btn.btn--outline(v-if="!isUploading"
            text="Показать еще" 
            @mousedown="loadData")
        LoadingIndicator(v-else)
</template>

<script>
import {defineComponent,} from 'vue'

// composition
import {useSearch,} from '@/composition/search';
import {useResultTests,} from '@/composition/schoolTests';

//components
import SearchBlock from '@/components/Generic/search-block/index.vue';
import Table from '@/components/Generic/table/index.vue';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'ResultList',
    components: {
        SearchBlock,
        Table,
        LoadingIndicator,
    },
    props: {
        dataTest: {
            type: Array,
        },
        isUploading: {
            type: Boolean,
        },
        visibleMoreBtn: {
            type: Boolean,
        },
        activeInputOnSearch: {
            type: Boolean,
        },
    },
    emits: ['load-data', 'load-data-on-search', 'open-modal-test'],
    setup(props, {emit, }) {
        const {searchString, onSearch,} = useSearch()
        const {
            headersResultTests,
            downloadTestResults,
            modalInfoData,
            isUploadingBtn,
        } = useResultTests()

        const loadData = () => {
            emit('load-data')
        }

        const loadDataWithSearch = (s) => {
            s !== undefined ? searchString.value = s : searchString.value = ''
            emit('load-data-on-search', searchString.value)
        }
        const onRowClick = async (row) => {
            modalInfoData.value.Header = 'Информация о прохождении тесте'
            modalInfoData.value.id = row.id
            modalInfoData.value.username = `${row.employee}`
            modalInfoData.value.teacher_id = row.teacher_id
            modalInfoData.value.test_name = row.data_test.name
            modalInfoData.value.annotation = row.data_test.annotation
            modalInfoData.value.subject_area = row.subject_area
            modalInfoData.value.competence = row.competence
            modalInfoData.value.type = 'check'
            modalInfoData.value.attempting.max_attempting = row.data_test.max_attempting
            modalInfoData.value.attempting.number = row.my_attempting
            modalInfoData.value.max_points = row.max_points
            modalInfoData.value.my_point = row.my_point
            document.documentElement.style.overflow = 'hidden';
            emit('open-modal-test', modalInfoData.value)
        }

        return {
            headersResultTests,
            downloadTestResults,
            isUploadingBtn,
            //action
            loadData,
            loadDataWithSearch,
            onRowClick,
        }
    },
})
</script>

<style lang="scss">
.test-results {
    .tests-result {
        margin-top: 32px;
        .par {
            margin-top: 20px;
            font-weight: 500;
            font-size: 20px;
        }
        .load-btn-data {
            width: fit-content;
            margin-bottom: 20px;
            margin-left: auto;
        }
    }
    .tests-loading {
        text-align: center;
    }
}

</style>