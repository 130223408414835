
import {defineComponent,} from 'vue';
import TempAvatar from '@/components/Generic/temp-avatar/index.vue';

export default defineComponent({
    name: 'ColleagueList',
    components: {TempAvatar,},
    props: ['colleagues', 'btnText', 'loading'],
    emits: ['on-click'],
    setup(props, {emit,}) {
        const teacherLink = `${process.env.VUE_APP_PUBLIC_URL}/teachers`

        const getOrganizationsText = (colleague) => {
            if (!colleague?.organizations.length) return 'Не указано'
            return colleague?.organizations.join(', ')
        }
        const addToColleagues = async (colleague) => {
            emit('on-click', colleague)
        }

        return {
            teacherLink,
            getOrganizationsText,
            addToColleagues,
        }
    },
})
