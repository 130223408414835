
import {defineComponent,} from 'vue';
import {useDialog,} from '@/components/Generic/Dialog/dialog';

export default defineComponent({
    name: 'Dialog',
    setup() {
        return {
            ...useDialog(),
        }
    },
});
