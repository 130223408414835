
import { defineComponent, PropType, } from 'vue'

import { SelectListItem,  } from '@/types/common'

export default defineComponent({
    name: 'SelectAutocomplete',
    emits: ['select-item', 'highlight-item'],
    props: {
        itemList: {
            type: Array as PropType<SelectListItem[]>,
        },
        isVisible: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        currentSelected: {
            type: Number as PropType<number>,
            default: 0,
        },
    },
})

