import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flex-container flex-container_events",
  key: "loaded"
}
const _hoisted_2 = {
  key: 0,
  class: "grid-items grid-items_events"
}
const _hoisted_3 = {
  key: 1,
  class: "event-block__no-events no-items-to-show"
}
const _hoisted_4 = {
  key: 2,
  class: "more-btn-block"
}
const _hoisted_5 = {
  class: "flex-container flex-container--wrap",
  key: "loading"
}
const _hoisted_6 = { class: "more-btn-block" }
const _hoisted_7 = {
  key: 0,
  class: "flex-containter flex-container_events"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EventItem = _resolveComponent("EventItem")
  const _component_btn = _resolveComponent("btn")
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")
  const _component_SplideSlide = _resolveComponent("SplideSlide")
  const _component_Splide = _resolveComponent("Splide")

  return (!_ctx.isRecommended)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock("div", _hoisted_1, [
                (_ctx.events.length > 0)
                  ? (_openBlock(), _createBlock("div", _hoisted_2, [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.events, (event, index) => {
                        return (_openBlock(), _createBlock(_component_EventItem, {
                          key: event.id,
                          event: event,
                          "event-type": _ctx.eventType,
                          disabledBtn: _ctx.disabled,
                          onChange: _ctx.onEventChange,
                          onBlockBtns: _ctx.allDisabled,
                          onUnblockBtns: _cache[1] || (_cache[1] = $event => (_ctx.unDisabled($event, true))),
                          onStartChangeStatus: _ctx.allDisabled,
                          onEndChangeStatus: _cache[2] || (_cache[2] = $event => (_ctx.unDisabled($event, false)))
                        }, null, 8 /* PROPS */, ["event", "event-type", "disabledBtn", "onChange", "onBlockBtns", "onStartChangeStatus"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  : (_openBlock(), _createBlock("div", _hoisted_3, "Нет подходящих " + _toDisplayString(_ctx.eventTypeTextMore), 1 /* TEXT */)),
                (_ctx.isNeedToUpload && _ctx.upload)
                  ? (_openBlock(), _createBlock("div", _hoisted_4, [
                      _createCommentVNode("done"),
                      (!_ctx.isUploading)
                        ? (_openBlock(), _createBlock(_component_btn, {
                            key: 0,
                            class: "btn--outline",
                            text: "Показать еще",
                            onMousedown: _ctx.fetchEvents
                          }, null, 8 /* PROPS */, ["onMousedown"]))
                        : (_openBlock(), _createBlock(_component_LoadingIndicator, { key: 1 }))
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : (_openBlock(), _createBlock("div", _hoisted_5, [
                _createVNode("div", _hoisted_6, [
                  _createVNode(_component_LoadingIndicator)
                ])
              ]))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
        (_ctx.events.length > 0)
          ? (_openBlock(), _createBlock("div", _hoisted_7, [
              _createVNode(_component_Splide, { options: _ctx.options }, {
                default: _withCtx(() => [
                  _createVNode(_component_SplideSlide, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.events, (event, index) => {
                        return (_openBlock(), _createBlock(_component_EventItem, {
                          key: event.id,
                          event: event,
                          "event-type": _ctx.eventType,
                          onChange: _ctx.onEventChange,
                          onBlockBtns: _ctx.allDisabled,
                          onUnblockBtns: _cache[3] || (_cache[3] = $event => (_ctx.unDisabled($event, true))),
                          onStartChangeStatus: _ctx.allDisabled,
                          onEndChangeStatus: _cache[4] || (_cache[4] = $event => (_ctx.unDisabled($event, false)))
                        }, null, 8 /* PROPS */, ["event", "event-type", "onChange", "onBlockBtns", "onStartChangeStatus"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8 /* PROPS */, ["options"])
            ]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
}