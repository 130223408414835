
import { defineComponent, PropType,  } from 'vue'

import { SelectListItem,  } from '@/types/common'

import SelectAutocomplete from '@/components/Generic/select-autocomplete/index.vue'

import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'

export default defineComponent({
    name: 'SingleHobbie',
    components: {
        SelectAutocomplete,
        TrashIcon,
    },
    emits: ['update:hobbie', 'del-hobbie'],
    props: {
        hobbie: {
            type: String as PropType<string>,
            default: () => '',
        },
        id: {
            type: Number as PropType<number>,
            required: true,
        },
        ind: {
            type: Number as PropType<number>,
            required: true,
        },
        hobbieList: {
            type: Array as PropType<SelectListItem[]>,
            required: true,
        },
    },
})
