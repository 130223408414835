<template lang="pug">
footer.footer
    .container
        .footer__cols
            .footer__col-log-tel
                ul.footer__ul
                    li.footer__logo
                        a.logo.footer-logo(:href="`${publicURL}`")
                            img(src="@/assets/img/logo/vgapo-logo-white-color.svg", alt="Траектория")
                    li.footer__li.footer__link-block
                        a.hover-underline(:href="settingsSite.fb_url")
                            img(src="@/assets/img/insta.svg", alt="")
                        a.hover-underline(href="settingsSite.vk_url")
                            img(src="@/assets/img/vk1.svg", alt="")
                    li.footer__li(v-for="num in settingsSite.phone_number")
                        a.footer__link.hover-underline(:href="`tel:${num}`") {{ num }}
                    li.footer__li(v-for="email in settingsSite.email")
                        a.footer__link.hover-underline(:href="`mailto: ${email}`") {{ email }}
            .footer__cols-menu
                .footer__col-menu
                    ul
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/organization`") Образовательные организации
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/community`") Профессиональные сообщества
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/practice`") Инновационные практики                            
                .footer__col-menu
                    ul.footer__ul
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/teachers`") Педагоги
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/events`") Календарь мероприятий
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/olympiads`") Олимпиады                            
                .footer__col-menu
                    ul.footer__ul
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/courses`") Курсы
                        li.footer__li
                            a.footer__link.hover-underline(:href="`${publicURL}/events`") Новости
        .footer__row
            .footer__copy © Траектория, {{ new Date().getFullYear() }}
            .footer__row-left.footer__about
                template(v-if="flatPages")
                    a.footer_after_link(
                        v-for="page in flatPages"
                        :href="`${publicURL}${page.url}`") {{ page.title}}
</template>

<script>
import { defineComponent, } from 'vue';
import {useEnv,} from '@/composition/env';
import {useSettings,} from '@/composition/settings';
export default defineComponent({
    name: 'Footer',
    setup () {
        const { publicURL, } = useEnv()
        const {  fetchPages, flatPages, settingsSite, } = useSettings(true)
        fetchPages()

        return {
            publicURL,
            flatPages,
            settingsSite,
        }
    },
})
</script>

<style lang="scss" scoped>
.hover-underline {
    &:hover {
        text-decoration: underline;
        text-decoration-skip-ink: none;
        &:after {
            display: none;
        }
    }
}
    footer {
        a {
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #FFFFFF;
            &:hover {
                color: #FFFFFF;
            }
        }


        .link-block {
            padding: 28px 0 31px;
            margin-bottom: 0;

            a {
                margin-right: 16px;
                transition: opacity 0.3s;
                opacity: 1;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .footer_after_link {
            color: #FFFFFF;
            opacity: 0.4;
            float: right;
            margin-right: 0;
            margin-left: 30px;
            font-size: 14px;
            @media (max-width: 830px) {
                margin-left: 0;
                margin-bottom: 10px;
            }

            &:hover {
                opacity: 1;
            }
        }

    }

    .footer-col ul li {
        @media screen and (max-width: 780px) {
            margin-bottom: 5px;
        }

    }

    .footer-logo {
        img {
            width: 202px;
        }
    }

</style>
