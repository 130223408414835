import {RouteRecordRaw,} from 'vue-router';
import auth from '@/middlewares/auth';
import onboard from '@/middlewares/onboard';
import guest from '@/middlewares/guest';
import home from '@/views/home/index.vue';
import lkEventBlock from '@/components/Events/events-block/index.vue';
import lkEvent from '@/views/lk-event/index.vue';
import LkCourses from '@/views/courses/index.vue';
import LkOlympiads from '@/views/olympiads/index.vue';
import profileWork from '@/views/profile/work/index.vue';
import profileInterests from '@/views/profile/interests/index.vue';
import profilePracticesOpen from '@/views/profile/practices/open/index.vue';
import profilePracticesList from '@/views/profile/practices/list/index.vue';
import profilePractices from '@/views/profile/practices/index.vue';
import profileProgress from '@/views/profile/progress/index.vue';
import profileData from '@/views/profile/data/index.vue';
import profile from '@/views/profile/index.vue';
import login from '@/views/login/index.vue';
import restore from '@/views/restore/index.vue';
import registration from '@/views/registration/index.vue';
import school from '@/views/school/index.vue';
import schoolСontacts from '@/views/school/contacts/index.vue';
import schoolAchievements from '@/views/school/achievements/index.vue';
import schoolAbout from '@/views/school/about/index.vue';
import schoolStaff from '@/views/school/staff/index.vue';
import schoolSurveys from '@/views/school/surveys/index.vue';
import schoolTests from '@/views/school/tests/index.vue';
import detailSchoolTest from '@/views/school/tests/id/index.vue';
import certificates from '@/views/certificates/index.vue';
import diagnostics from '@/views/diagnostics/index.vue';
import diagnosticsTests from '@/views/diagnostics/tests/index.vue';
import diagnosticsCases from '@/views/diagnostics/cases/index.vue';
import testNew from '@/views/diagnostics/tests/new/index.vue';
import testProcess from '@/views/diagnostics/tests/process/index.vue';
import testCompleted from '@/views/diagnostics/tests/completed/index.vue';
import testsResult from '@/views/diagnostics/tests/result/index.vue';
import caseNew from '@/views/diagnostics/cases/new/index.vue';
import caseProcess from '@/views/diagnostics/cases/process/index.vue';
import caseCompleted from '@/views/diagnostics/cases/completed/index.vue';
import casesResult from '@/views/diagnostics/cases/result/index.vue';
import audit from '@/views/audit/index.vue';
import auditNew from '@/views/audit/new/index.vue';
import auditProcess from '@/views/audit/process/index.vue';
import auditCheck from '@/views/audit/check/index.vue';
import auditCompleted from '@/views/audit/completed/index.vue';
import auditResult from '@/views/audit/result/index.vue';
import favorites from '@/views/favorites/index.vue';
import settingsGeneral from '@/views/user-settings/general/index.vue';
import settingsPassword from '@/views/user-settings/password/index.vue';
import settings from '@/views/user-settings/index.vue';
import notifications from '@/views/notifications/index.vue';
import onboarding from '@/views/onboarding/index.vue';
import colleagues from '@/views/colleague/index.vue';
import colleagueInput from '@/views/colleague-input/index.vue';
import colleagueOutput from '@/views/colleague-output/index.vue';
import colleagueAdd from '@/views/colleague-add/index.vue';
import colleagueAll from '@/views/collegue-all/index.vue';
import messages from '@/views/messages/index.vue';
import messagesDetail from '@/views/messages/detail/index.vue';
import individualRoute from '@/views/individual-route/index.vue';
import individualRouteMy from '@/views/individual-route/my-route/index.vue';
import individualRouteCompleted from '@/views/individual-route/completed/index.vue';
import routeResult from '@/views/individual-route/result/index.vue';
import monitoring from '@/views/monitoring/index.vue';
import monitoringActual from '@/views/monitoring/actual/index.vue';
import monitoringProcess from '@/views/monitoring/process/index.vue';
import monitoringCompleted from '@/views/monitoring/completed/index.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: 'Главная',
            middleware: [ onboard ],
        },
        component: home,
    },
    {
        path: '/events',
        name: 'lkEvent',
        meta: {
            title: 'Мои Мероприятия',
            middleware: [ onboard  ],
        },
        redirect: '/events/actual',
        children: [
            {
                path: '',
                name: 'lkEventsFilterFuture',
                meta: {
                    title: 'Мои мероприятия - предстоящие',
                    tab: 'Предстоящие',
                },
                props: {
                    filter: '',
                    eventType: 'events',
                },
                component: lkEventBlock,
            },
            {
                path: 'actual',
                name: 'lkEventsFilterFuture',
                meta: {
                    title: 'Мои мероприятия - предстоящие',
                    tab: 'Предстоящие',
                },
                props: {
                    filter: 'actual',
                    eventType: 'events',
                },
                component: lkEventBlock,
            },
            {
                path: 'moderation',
                name: 'lkEventsFilterMod',
                meta: {
                    title: 'Мои мероприятия - на подтверждении',
                    tab: 'На подтверждении',
                },
                props: {
                    filter: 'moderation',
                    eventType: 'events',
                },
                component: lkEventBlock,
            },
            {
                path: 'past',
                name: 'lkEventsFilterPast',
                meta: {
                    title: 'Мои мероприятия - прошедшие',
                    tab: 'Прошедшие',
                },
                props: {
                    filter: 'past',
                    eventType: 'events',
                },
                component: lkEventBlock,
            },
            {
                path: 'rejected',
                name: 'lkEventsFilterRejected',
                meta: {
                    title: 'Мои мероприятия - отклоненные',
                    tab: 'Отклоненные',
                },
                props: {
                    filter: 'rejected',
                    eventType: 'events',
                },
                component: lkEventBlock,
            }
        ],
        component: lkEvent,
    },
    {
        path: '/courses',
        name: 'lkCourses',
        meta: {
            title: 'Мои Курсы',
            middleware: [ onboard ],
        },
        props: {
            filter:'',
            eventType: 'courses',
        },
        redirect: '/courses/actual',
        children: [
            {
                path: 'actual',
                name: 'lkCoursesFilterFuture',
                meta: {
                    title: 'Мои курсы - не пройденные',
                    tab: 'Не пройденные',
                },
                props: {
                    filter: 'actual',
                    eventType: 'courses',
                },
                component: lkEventBlock,
            },
            {
                path: 'moderation',
                name: 'lkCoursesFilterMod',
                meta: {
                    title: 'Мои курсы - на подтверждении',
                    tab: 'На подтверждении',
                },
                props: {
                    filter: 'moderation',
                    eventType: 'courses',
                },
                component: lkEventBlock,
            },
            {
                path: 'past',
                name: 'lkCoursesFilterPast',
                meta: {
                    title: 'Мои курсы - пройденные',
                    tab: 'Пройденные',
                },
                props: {
                    filter: 'past',
                    eventType: 'courses',
                },
                component: lkEventBlock,
            },
            {
                path: 'rejected',
                name: 'lkCoursesFilterRejected',
                meta: {
                    title: 'Мои курсы - отклоненные',
                    tab: 'Отклоненные',
                },
                props: {
                    filter: 'rejected',
                    eventType: 'courses',
                },
                component: lkEventBlock,
            }
        ],
        component: LkCourses,
    },
    {
        path: '/olympiads',
        name: 'lkOlympiads',
        meta: {
            title: 'Мои Олимпиады',
            middleware: [ onboard ],
        },
        props: {
            filter:'',
            eventType: 'olympiads',
        },
        redirect: '/olympiads/actual',
        children: [
            {
                path: 'actual',
                name: 'lkOlympiadsFilterFuture',
                meta: {
                    title: 'Мои олимпиады - не пройденные',
                    tab: 'Не пройденные',
                },
                props: {
                    filter: 'actual',
                    eventType: 'olympiads',
                },
                component: lkEventBlock,
            },
            {
                path: 'moderation',
                name: 'lkOlympiadsFilterMod',
                meta: {
                    title: 'Мои олимпиады - на подтверждении',
                    tab: 'На подтверждении',
                },
                props: {
                    filter: 'moderation',
                    eventType: 'olympiads',
                },
                component: lkEventBlock,
            },
            {
                path: 'past',
                name: 'lkOlympiadsFilterPast',
                meta: {
                    title: 'Мои олимпиады - пройденные',
                    tab: 'Пройденные',
                },
                props: {
                    filter: 'past',
                    eventType: 'olympiads',
                },
                component: lkEventBlock,
            },
            {
                path: 'rejected',
                name: 'lkOlympiadsFilterRejected',
                meta: {
                    title: 'Мои олимпиады - отклоненные',
                    tab: 'Отклоненные',
                },
                props: {
                    filter: 'rejected',
                    eventType: 'olympiads',
                },
                component: lkEventBlock,
            }
        ],
        component: LkOlympiads,
    },    
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            middleware: [ onboard ],
            title: 'Профиль',
        },
        children: [
            {
                path: 'work',
                name: 'ProfileWork',
                meta: {
                    middleware: [ onboard ],
                    title: 'Работа',
                    tab: 'Работа',
                },
                component: profileWork,
            },
            {
                path: 'interest',
                name: 'ProfileInterests',
                meta: {
                    middleware: [ onboard ],
                    title: 'Интересы',
                    tab: 'Интересы',
                },
                component: profileInterests,
            },
            {
                path: 'practices',
                name: 'ProfilePractices',
                meta: {
                    middleware: [ onboard ],
                    title: 'Инновационные практики',
                    tab: 'Инновационные практики',
                },
                children: [
                    {
                        path:':id',
                        name: 'ProfilePractiesOpen',
                        meta: {
                            middleware: [ onboard ],
                            title: 'Инновационные практики',
                            tab: 'Инновационные практики',
                        },
                        component: profilePracticesOpen,
                    },
                    {
                        path:'',
                        name: 'ProfilePractiesList',
                        meta: {
                            middleware: [ onboard ],
                            title: 'Инновационные практики',
                            tab: 'Инновационные практики',
                        },
                        component: profilePracticesList,
                    }
                ],
                component: profilePractices,
            },
            {
                path: 'achievements',
                name: 'ProfileProgress',
                meta: {
                    middleware: [ onboard ],
                    title: 'Достижения',
                    tab: 'Достижения',
                },
                component: profileProgress,
            },
            {
                path: '',
                name: 'ProfileData',
                meta: {
                    title: 'Профиль',
                    tab: 'Персональные данные',
                },
                component: profileData,
            }
        ],
        component: profile,
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'вход',
            middleware: [ guest ],
        },
        component: login,
    },
    {
        path: '/restore',
        name: 'restore',
        meta: {
            title: 'Восстановление пароля',
            middleware: [ guest ],
        },
        component: restore,
    },
    {
        path: '/registration',
        name: 'registration',
        meta: {
            title: 'Регистрация',
            middleware: [ guest ],
        },
        component: registration,
    },
    {
        path: '/school',
        name: 'school',
        redirect: '/school/about',
        component: school,
        meta:{
            middleware: [ onboard ],
            title: 'Школа',
            onlyModerator: true,
        },
        children: [
            {
                path: 'contacts',
                name: 'schoolСontacts',
                component: schoolСontacts,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - контакты',
                    tab: 'Контакты',
                    onlyModerator: true,
                },
            },
            {
                path: 'achievements',
                name: 'schoolAchievements',
                component: schoolAchievements,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - достижения',
                    tab: 'Достижения',
                    onlyModerator: true,
                },
            },
            {
                path: 'about',
                name: 'schoolProgress',
                component: schoolAbout,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - об организации',
                    tab: 'Об организации',
                    onlyModerator: true,
                },
            },
            {
                path: 'staff',
                name: 'schoolStaff',
                component: schoolStaff,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - сотрудники',
                    tab: 'Сотрудники',
                    onlyModerator: true,
                },
            },
            {
                path: 'surveys',
                name: 'schoolSurveys',
                component: schoolSurveys,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - результаты опросов',
                    tab: 'Опросы',
                    onlyModerator: true,
                },
            },            
            {
                path: 'tests',
                name: 'schoolTests',
                component: schoolTests,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - результаты тестирования',
                    tab: 'Тестирования',
                    onlyModerator: true,
                },
            },
            {
                path: 'test/:id',
                name: 'detailSchoolTest',
                component: detailSchoolTest,
                meta: {
                    middleware: [ onboard ],
                    title: 'Моя школа - результаты тестирования',
                    tab: 'Тестирование',
                    onlyModerator: true,
                },
            }
        ],
    },
    {
        path: '/certificates',
        name: 'certificates',
        component: certificates,
        meta: {
            title: 'Сертификаты',
            middleware: [ onboard ],
        },
    },
    {
        path: '/audit',
        redirect: '/audit/new',
        name: 'audit',
        component: audit,
        meta: {
            title: 'Проверки педагогов',
            middleware: [ onboard ],
            onlyTutor: true,
        },
        children: [
            {
                path: 'new',
                name: 'auditNew',
                component: auditNew,
                meta: {
                    middleware: [ onboard ],
                    title: 'Непроверенные кейсы',
                    parentTab: 'Проверки педагогов',
                    tab: 'Непроверенные кейсы',
                    onlyTutor: true,
                },
            },
            {
                path: 'process',
                name: 'auditProcess',
                component: auditProcess,
                meta: {
                    middleware: [ onboard ],
                    title: 'В процессе проверки',
                    parentTab: 'Проверки педагогов',
                    tab: 'В процессе проверки',
                    onlyTutor: true,
                },
                children: [
                    {
                        path: 'check/:id',
                        name: 'auditCheck',
                        component: auditCheck,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Проверка кейса',
                            parentTab: 'В процессе проверки',
                            onlyTutor: true,
                        },
                    }
                ],
            },
            {
                path: 'completed',
                name: 'auditCompleted',
                component: auditCompleted,
                meta: {
                    middleware: [ onboard ],
                    title: 'Проверенные кейсы',
                    parentTab: 'Проверенные кейсы',
                    tab: 'Проверенные кейсы',
                    onlyTutor: true,
                },
                children: [
                    {
                        path: 'result/:id',
                        name: 'auditResult',
                        component: auditResult,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Результат проверки',
                            parentTab: 'Проверенные кейсы',
                            onlyTutor: true,
                        },
                    }
                ],
            }
        ],
    },
    {
        path: '/diagnostics',
        name: 'diagnostics',
        redirect: '/diagnostics/tests/new',
        component: diagnostics,
        meta: {
            title: 'Диагностика',
            middleware: [ onboard ],
        },
        children: [
            {
                path: 'tests',
                name: 'diagnosticsTests',
                redirect: '/diagnostics/tests/new',
                component: diagnosticsTests,
                meta: {
                    middleware: [ onboard ],
                    title: 'Тестирование',
                    tab: 'Тестирование',
                },
                children: [
                    {
                        path: 'new',
                        name: 'testsNew',
                        component: testNew,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Новые',
                            parentTab: 'Тестирование',
                            tab: 'Новые',
                        },
                    },
                    {
                        path: 'process',
                        name: 'testsProcess',
                        component: testProcess,
                        meta: {
                            middleware: [ onboard ],
                            title: 'В процессе',
                            parentTab: 'Тестирование',
                            tab: 'В процессе',
                        },
                    },
                    {
                        path: 'completed',
                        name: 'testsCompleted',
                        component: testCompleted,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Завершенные',
                            parentTab: 'Тестирование',
                            tab: 'Завершенные',
                        },
                        children: [
                            {
                                path: '/diagnostics/tests/completed/result/:id',
                                name: 'testresult',
                                component: testsResult,
                                meta: {
                                    title: 'Результаты',
                                    middleware: [ onboard ],
                                },
                            }
                        ],
                    }
                ],
            },
            {
                path: 'cases',
                name: 'diagnosticsCases',
                redirect: '/diagnostics/cases/new',
                component: diagnosticsCases,
                meta: {
                    middleware: [ onboard ],
                    title: 'Решение кейсов',
                    tab: 'Решение кейсов',
                },
                children: [
                    {
                        path: 'new',
                        name: 'casesNew',
                        component: caseNew,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Новые',
                            parentTab: 'Решение кейсов',
                            tab: 'Новые',
                        },
                    },
                    {
                        path: 'process',
                        name: 'casesProcess',
                        component: caseProcess,
                        meta: {
                            middleware: [ onboard ],
                            title: 'В процессе',
                            parentTab: 'Решение кейсов',
                            tab: 'В процессе',
                        },
                    },
                    {
                        path: 'completed',
                        name: 'casesCompleted',
                        component: caseCompleted,
                        meta: {
                            middleware: [ onboard ],
                            title: 'Завершенные',
                            parentTab: 'Решение кейсов',
                            tab: 'Завершенные',
                        },
                        children: [
                            {
                                path: '/diagnostics/cases/completed/result/:id',
                                name: 'caseresult',
                                component: casesResult,
                                meta: {
                                    title: 'Результаты',
                                    middleware: [ onboard ],
                                },
                            }
                        ],
                    }
                ],
            }
        ],
    },
    {
        path: '/favorites',
        name: 'favorites',
        meta: {
            title: 'Избранное',
            middleware: [ onboard ],
        },
        component: favorites,
    },
    {
        path: '/settings',
        name: 'settings',
        redirect: '/settings/general',
        meta: {
            title: 'Настройки',
            middleware: [ onboard ],
        },
        children: [
            {
                path: 'general',
                name: 'settingsGeneral',
                meta: {
                    title: 'Основные настройки',
                    tab: 'Основные',
                },
                component: settingsGeneral,
            },
            {
                path: 'change-password',
                name: 'settingsPass',
                meta: {
                    title: 'Изменение пароля',
                    tab: 'Изменение пароля',
                },
                component: settingsPassword,
            }
        ],
        component: settings,
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: notifications,
        meta: {
            title: 'Уведомления',
            middleware: [ onboard ],
        },
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        meta: {
            title: 'Подтверждение данных',
            middleware: [ auth ],
        },
        component: onboarding,
    },
    {
        path: '/collegues',
        name: 'collegues',
        redirect: '/collegues/all',
        component: colleagues,
        meta: {
            title: 'Коллеги',
            middleware: [ onboard ],
        },
        children: [
            {
                path: 'add',
                name: 'colleagueAdd',
                component: colleagueAdd,
                meta: {
                    title: 'Добавить коллегу',
                    tab: 'Добавить коллегу',
                    middleware: [ onboard ],
                },
            },
            {
                path: 'all',
                name: 'colleagueAll',
                component: colleagueAll,
                meta: {
                    title: 'Коллеги',
                    tab: 'Коллеги',
                    middleware: [ onboard ],
                },
            },
            {
                path: 'input',
                name: 'colleagueInput',
                component: colleagueInput,
                meta: {
                    title: 'Коллеги - входящие',
                    tab: 'Входящие запросы',
                    middleware: [ onboard ],
                },
            },
            {
                path: 'output',
                name: 'colleagueOutput',
                component: colleagueOutput,
                meta: {
                    title: 'Коллеги - исходящие',
                    middleware: [ onboard ],
                    tab: 'Исходящие запросы',
                },
            }
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: messages,
        meta: {
            title: 'Сообщения',
            middleware: [ onboard ],
        },
        children: [
            {
                path: ':id',
                name: 'messagesDetail',
                component: messagesDetail,
                meta: {
                    middleware: [ onboard ],
                    title: 'Переписка',
                },
            }
        ],
    },
    {
        path: '/individual-route',
        name: 'individual-route',
        redirect: '/individual-route/my-route',
        component: individualRoute,
        meta: {
            title: 'Индивидуальный маршрут',
            middleware: [ onboard ],
        },
        children: [
            {
                path: 'my-route',
                name: 'individualRouteMy',
                component: individualRouteMy,
                meta: {
                    middleware: [ onboard ],
                    title: 'Индивидуальный маршрут',
                    tab: 'Мой маршрут',
                },
            },
            {
                path: 'completed',
                name: 'individualRouteCompleted',
                component: individualRouteCompleted,
                meta: {
                    middleware: [ onboard ],
                    title: 'Индивидуальный маршрут',
                    tab: 'Завершенные маршруты',
                },
                children: [
                    {
                        path: '/individual-route/completed/result/:id',
                        name: 'routeResult',
                        component: routeResult,
                        meta: {
                            title: 'Результаты',
                            middleware: [ onboard ],
                        },
                    }
                ],                
            }
        ],
    },
    {
        path: '/monitoring',
        name: 'monitoring',
        redirect: '/monitoring/actual',
        component: monitoring,
        meta: {
            title: 'Мониторинг',
            middleware: [ onboard ],
        },
        children: [
            {
                path: 'actual',
                name: 'monitoringActual',
                component: monitoringActual,
                meta: {
                    middleware: [ onboard ],
                    title: 'Мониторинг',
                    tab: 'Актуальные',
                },
            },
            {
                path: 'process',
                name: 'monitoringProcess',
                component: monitoringProcess,
                meta: {
                    middleware: [ onboard ],
                    title: 'Мониторинг',
                    tab: 'В процессе',
                },
            },
            {
                path: 'completed',
                name: 'monitoringCompleted',
                component: monitoringCompleted,
                meta: {
                    middleware: [ onboard ],
                    title: 'Мониторинг',
                    tab: 'Завершенные',
                },          
            }
        ],
    }     

]
