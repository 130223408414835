
import { onMounted, watch, } from 'vue'
import CertificateItem from '@/components/Certificates/CertificateItem.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import { defineComponent, } from 'vue'
import {useCerts,} from '@/composition/useCerts';
import {useSearch,} from '@/composition/search';
export default defineComponent({
    name: 'Certificates',
    components: {
        CertificateItem,
        LoadingIndicator,
    },
    setup () {
        const {
            certs,
            totalCount,
            fetchCerts,
            isLoading,
            isUploading,
            isNeedToUpload,
            page,
        } =  useCerts()
        const { searchString, onSearch, } = useSearch()

        watch(searchString, () => {
            page.value = 1
            fetchCerts(searchString.value)
        })

        onMounted(async () => {
            await fetchCerts()
        })
        return {
            certs,
            isLoading,
            isUploading,
            page,
            totalCount,
            searchString,
            onSearch,
            fetchCerts,
            isNeedToUpload,
        }
    },
});
