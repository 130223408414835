import {ref,} from 'vue'

export function checkCaches() {
    const deleteCaches = (caches, reload) => {
        caches.keys().then((listCaches) => {
            listCaches.map((request) => {
                caches.open(request).then((cache) => {
                    cache.keys().then((item) => {
                        const reloadPage = ref(false)
                        if (item.length > 0) {
                            item.map((value) => {
                                cache.delete(value.url)
                            })
                            reloadPage.value = true
                        }
                        return reloadPage.value
                    }).then(response => {
                        if (response === true && reload) {
                            return window.location.reload(true)
                        }
                        return null
                    })
                })
            })
        })
    }
    return {
        deleteCaches,
    }
}
