import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FieldBlock = _resolveComponent("FieldBlock")

  return (_openBlock(), _createBlock(_component_FieldBlock, {
    fields: _ctx.form,
    submitText: "Сохранить",
    onSubmit: _withModifiers(_ctx.changePasswordHandler, ["prevent"]),
    loading: _ctx.loading,
    "is-submit-disabled": _ctx.disabled,
    "non-field-errors": _ctx.nonFieldErrors,
    buttonParams: _ctx.buttonParams
  }, null, 8 /* PROPS */, ["fields", "onSubmit", "loading", "is-submit-disabled", "non-field-errors", "buttonParams"]))
}