
import { defineComponent, PropType,  } from 'vue'
//import composition

import { useEvents,} from '@/composition/events'

//import componets
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'

export default defineComponent({
    name: 'LkCourses',
    components: {
        Tabs,
        TitleBlock,
    },
    props: {
        filter: {
            type: String as PropType<string>,
        },
        eventType: {
            type: String as PropType<string>,
            default: 'courses',
            validator: (value: string) =>  ['events', 'courses', 'all', 'base_events'].indexOf(value) !== -1,
        },
    },
    setup (props) {
        return {
            ...useEvents(props),
        }
    },
});
