<template lang="pug">
Tabs.tab-container--underline(:tab-items="tabItems" :active-tab="activeTab" className="tab-container__inner")
.profile__content.profile__content--column
    p.par(v-if="route.path === '/diagnostics/cases/process' || route.path === '/diagnostics/cases/new' || route.path == '/diagnostics/cases/completed'") Для решения кейса вам будет необходимо дать один или несколько ответов на вопросы открытого типа. Наш специалист проверит данные вами ответы и даст им оценку, а также сформулирует рекомендации по развитию профессиональных компетенций.
router-view(v-slot="{ Component }")
    suspense
        component(:is="Component" :currentPage="currentPage" @on-modal-open="openModal" @modal-close="isModalVisibleQuiz = false" @set-remove-row="setRemoveRow")
//- modal info
ModalWindow(
    :is-visible="isModalVisible"
    isBig="true"
    :title="modalInfoData.modal.Header"
    :buttons="modalInfoBtn"
    :addInput="false"
    @close="closeAll"
    @ok="openAcceptModal"
)
    ModalInfo(:modalData="modalInfoData" :loading="loading" @on-modal-close="closeAll")
//- modal accept
ModalWindow(
    :is-visible="isModalVisibleTwo"
    :title="modalInfoData.modal.HeaderAccept"
    :buttons="acceptBtns"
    @ok="start"
    @close="onModalCloseTwo"
)
ModalWindow(
    :is-visible="isModalVisibleQuiz"
    :title="modalInfoData.modal.HeaderQuiz"
    isBig="true"
    @close="stopQuiz"
)
    Quiz(:quiz="quizData", :connectId="connectId", mode="case" @on-modal-close="endQuiz")
ModalWindow(
    :is-visible="isModalVisibleStopQuiz"
    :title="modalInfoData.modal.HeaderDecline"
    @close="onModalCloseStopQuiz"
    @ok="decline"
    :buttons="acceptBtns"
)
</template>

<script>
import {defineComponent, ref, } from 'vue'
import {useRoute, useRouter,} from 'vue-router';

//import modules
import {tabs,} from '@/modules/constants/tabs'

//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'
import Quiz from '@/components/Generic/quiz/index.vue'

//import composition
import {useTabs,} from '@/composition/tabs'
import {useDiagnostic,} from '@/composition/diagnostic';
import {useCase,} from '@/composition/diagnosticCase';
import {useModal,} from '@/composition/modal';

export default defineComponent({
    components: {
        Tabs,
        ModalWindow,
        ModalInfo,
        Quiz,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        let removeRow
        const setRemoveRow = (row) => {
            removeRow = row
        }
        const {
            tabItems,
            activeTab,
        } = useTabs(tabs.diagnosticCasesNested)
        // composition const
        const {
            acceptBtns,
            normalizeData,
        } = useDiagnostic();
        const {
            caseRead,
            caseResultCreate,
            modalInfoData,
            caseResultList,
        } = useCase();
        const {
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
        } = useModal()
        const {
            isModalVisible: isModalVisibleTwo,
            modalTitle: modalTitleTwo,
            modalMessage: modalMessageTwo,
            onModalClose: onModalCloseTwo,
        } = useModal()
        const {
            isModalVisible: isModalVisibleQuiz,
            modalTitle: modalTitleQuiz,
            modalMessage: modalMessageQuiz,
            onModalClose: onModalCloseQuiz,
        } = useModal()
        const {
            isModalVisible: isModalVisibleStopQuiz,
            modalTitle: modalTitleStopQuiz,
            modalMessage: modalMessageStopQuiz,
            onModalClose: onModalCloseStopQuiz,
        } = useModal()
        //const
        const status = ref()
        const modalInfoBtn = ref()
        const connectId = ref()
        const loading = ref(true)
        const quizData = ref({
            id: Number,
            questions: Array,
            count_questions: Number,
            answer_content_type_id: Number,
        })
        const allResultCases = ref([])
        // function
        const closeAll = () => {
            isModalVisible.value = false
            document.documentElement.style.overflow = 'visible';
        }
        const getAllResults = async () => {
            const answer = await caseResultList();
            allResultCases.value = answer.results
        }
        const openModal = async (response) => {
            loading.value = true
            if (response.attempting.next != null) {
                response.attempting.next = normalizeData(response.attempting.next)
            }
            modalInfoData.value = response
            modalInfoData.value.allAttempts = []
            document.documentElement.style.overflow = 'hidden';
            isModalVisible.value = true

            status.value = response.status_for_user
            
            if (status.value === 'new') modalInfoBtn.value = [
                {
                    text: 'Решить кейс',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'process') modalInfoBtn.value = [
                {
                    text: 'Продолжить решение',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'completed') modalInfoBtn.value = [
                {
                    text: 'Пройти повторно',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'completed' && response.attempting.number === response.attempting.max_attempting) modalInfoBtn.value = []
            if (response.attempting.next !== null) modalInfoBtn.value = []
            if (status.value === 'process' || status.value === 'completed') {
                if (allResultCases.value.length === 0) {
                    await getAllResults()
                }
                const resultAttempts = ref([])
                for (let i = 0; i < allResultCases.value.length; i++) {
                    const element = allResultCases.value[i];                    
                    if (response.id === element.case.id) {
                        if (element.attempting === 1) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Первая попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                                is_completed: element.checked,
                            })
                        } else if (element.attempting === 2) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Вторая попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                                is_completed: element.checked,
                            })
                        } else if (element.attempting === 3) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Третья попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                                is_completed: element.checked,
                            })
                        } else if (element.attempting === 4) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Четвертая попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                                is_completed: element.checked,
                            })
                        } else if (element.attempting === 5) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Пятая попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                                is_completed: element.checked,
                            })
                        } else {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `${element.attempting} попытка прохождения кейса - ${element.checked !== null ? 'пройдена' : 'на проверке'}. `,
                            })
                        }
                        modalInfoData.value.allAttempts = resultAttempts.value
                    }
                }
            }
            loading.value = false
        }

        const openAcceptModal = () => {
            isModalVisibleTwo.value = true
        }
        const start = async () => {
            const status = modalInfoData.value.status_for_user
            if (status !== 'process') {
                const data = {
                    'case': modalInfoData.value.id,
                }
                // get connection id
                const response = await caseResultCreate(data)
                connectId.value = response.id
                const responseQuestions = await caseRead(modalInfoData.value.id)
                modalInfoData.value.questions = responseQuestions.questions
            } else {
                connectId.value = modalInfoData.value.connectId
            }
            quizData.value.id = connectId.value
            quizData.value.count_questions = modalInfoData.value.count_questions
            quizData.value.questions = modalInfoData.value.questions
            quizData.value.answer_content_type_id = modalInfoData.value.answer_content_type_id

            isModalVisible.value = false
            isModalVisibleTwo.value = false
            isModalVisibleQuiz.value = true
            // removeRow(modalInfoData.value.id)
        }
        const stopQuiz = () => {
            isModalVisibleStopQuiz.value = true
        }
        const endQuiz = async () => {
            isModalVisibleQuiz.value = false
            await getAllResults()
        }        
        const decline = async () => {
            isModalVisibleQuiz.value = false
            isModalVisibleStopQuiz.value = false
            document.documentElement.style.overflow = 'visible';
            if (route.path === '/diagnostics/cases/new') {
                router.push('/diagnostics/cases/process')
            }
        }
        return {
            // const
            route,
            router,
            tabItems,
            activeTab,
            modalInfoData,
            modalInfoBtn,
            connectId,
            quizData,
            status,
            loading,
            // function
            openAcceptModal,
            start,
            stopQuiz,
            endQuiz,            
            decline,
            closeAll,
            setRemoveRow,
            getAllResults,
            // emit func
            openModal,
            // modal info
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
            // modal accept
            isModalVisibleTwo,
            modalTitleTwo,
            modalMessageTwo,
            onModalCloseTwo,
            // modal quiz
            isModalVisibleQuiz,
            modalTitleQuiz,
            modalMessageQuiz,
            onModalCloseQuiz,
            // modal decline
            isModalVisibleStopQuiz,
            modalTitleStopQuiz,
            modalMessageStopQuiz,
            onModalCloseStopQuiz,
            //useDiagnostic
            acceptBtns,
            // useCase
            caseResultCreate,
        }
    },

})


</script>

<style lang="scss" scoped>

</style>
