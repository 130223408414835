<template lang="pug">
template(v-if="route.path === `/individual-route/completed/result/${route.params.id}`")
    template(v-if="!loading")
        a.select.login-block__back-link.profile__form__back-link(@click="this.$router.push('/individual-route/completed')" )
            img(src="@/assets/img/back.svg")
            span Вернуться
        .list-events.result__list-events
            .list-events__title Список курсов и мероприятий в маршруте
            Table(:headers="headersOnProcessingOrResult" :items="items" :rowHover="true" :is_hover="true" @rowClick="onRowClick")
        TableStatistic.mini-statistics-block(
            title="Общая статистика"
            :dataTable="dataTableInfo"
        )                  
    template(v-else)
        .loading-indicator
            LoadingIndicator
</template>

<script>
import {defineComponent, onMounted, ref, computed,} from 'vue';
import {useRoute, } from 'vue-router'

//components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import TableStatistic from '@/components/IndividualRoute/TableStatistics.vue';

//composition
import {useIndividualRoute,} from '@/composition/individualRoute';

export default defineComponent ({
    components: {
        LoadingIndicator,
        Table,
        TableStatistic,
    },
    setup() {
        const route = useRoute();
        const loading = ref(true)
        const {
            headersOnProcessingOrResult,
            normalizeData,
            routeResultRead,
            getNumberCompleted,
        } = useIndividualRoute();
        const data = ref([])
        const dataTableInfo = ref([])
        
        // function
        const getData = async () => {
            const response = await routeResultRead(route.params.id);
            data.value = response
        }

        const onRowClick = (row) => {
            const type = row.type === 'Курс' ? 'courses' : 'events'
            window.open(`${process.env.VUE_APP_PUBLIC_URL}/${type}/${row.about.slug}`, "_blank")
        }        

        onMounted(async () => {
            document.documentElement.style.overflow = 'auto';
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
            await getData()
            dataTableInfo.value = [
                {
                    name: 'Количество часов',
                    value: data.value.hours_count !== null ? `${data.value.hours_count} ч.` : '-',
                },
                {
                    name: 'Дата начала',
                    value: data.value.start_date ? normalizeData(data.value.start_date) : '-',
                },
                {
                    name: 'Дата окончания',
                    value: data.value.end_date ? normalizeData(data.value.end_date) : '-',
                },
                {
                    name: 'Участий в мероприятиях',
                    value: getNumberCompleted(data.value.curriculum_event_info, 'event'),
                },
                {
                    name: 'Пройденных курсов',
                    value: getNumberCompleted(data.value.curriculum_event_info, 'course'),
                }                        
            ]
            loading.value = false
        });
        return {
            route,
            loading,
            headersOnProcessingOrResult,
            dataTableInfo,
            items: computed(() => {
                return data.value.curriculum_event_info.map(x => ({
                    id: x.id,
                    date_at: x.about.date_at_iso ? normalizeData(x.about.date_at_iso) : '-',
                    date_end: x.about.date_end_iso ? normalizeData(x.about.date_end_iso) : '-',
                    text: x.about.name,
                    type: x.type === 'event' ? 'Мероприятие' : 'Курс',
                    hours: x.about.hours !== null ? `${x.about.hours} ч.` : '-',
                    competence: x.about.competence !== null ? x.about.competence.name : '-',
                    about: x.about,
                    status: x.passing_status ? 'Пройдено' : 'Не пройдено',
                }))
            }),
            onRowClick,                    
        }
    },
})
</script>

<style lang="scss" scoped>
.result {
    &__list-events {
        margin-top: 0;
    }
}
</style>