
import { defineComponent, PropType, } from 'vue';
import {Cert,} from '@/types/common';
import moment from 'moment';

export default defineComponent({
    name: 'CertificateItem',
    props: {
        cert: {
            type: Object as PropType<Cert>,
            required: true,
        },
    },
    setup(props) {
        const eventLink = `${process.env.VUE_APP_PUBLIC_URL}/events/${props.cert.event.slug}`
        const certDate = moment(props.cert.date).format('DD.MM.YYYY')
        return {
            eventLink,
            certDate,
        }
    },
})
