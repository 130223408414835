<template lang="pug">
myHeader
.lk-page.container.container_aside
    .aside-cont
        lkAside
    main.lk-main
        section.profile
            .container-lk
                TitleBlock(
                    :attention="attention"
                    :attentionText="attentionText"
                ) Индивидуальный маршрут
                Tabs.tab-container--underline(:tab-items="tabItems" :active-tab="activeTab")
                router-view(v-slot="{ Component }")
                    suspense
                        component(
                            :is="Component"
                            @show-attention="showAttention"
                        )
myFooter                        
</template>

<script>
import {defineComponent, ref, computed, } from 'vue'
import {useRoute, } from 'vue-router'
//import modules
import {tabs,} from '@/modules/constants/tabs.ts'
//import composition
import {useTabs,} from '@/composition/tabs.ts'
//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'
import ModalWindow from '@/components/Generic/modal-window';

export default defineComponent({
    components: {
        ModalWindow,
        Tabs,
        TitleBlock,
        props: {
            id: String,
        },
    },
    setup() {

        const route=useRoute();
        const {tabItems,} = useTabs(tabs.individualRoute)
        const attention = ref(false)
        const attentionText = ref('')
        const showAttention = (status) => {
            if (status === 2) {
                attention.value = true
                attentionText.value = 'В процессе составления'
            }
            if (status === 4) {
                attention.value = true
                attentionText.value = 'На проверке'
            }            
            if (status === 7) {
                attention.value = true
                attentionText.value = 'Обработка заявки'
            }
        }
        return {
            tabItems,
            activeTab: computed( () => tabItems.indexOf(tabItems.find(x => x.content === route.meta.parentTab || x.content === route.meta.tab ))),
            attention,
            attentionText,
            showAttention,
        }
    },

})


</script>