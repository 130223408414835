
import { defineComponent, } from 'vue'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide, } from '@splidejs/vue-splide';

import  EventsBlock from '@/components/Events/events-block/index.vue'

export default defineComponent({
    name: 'RecomendedEvents',
    components: {
        EventsBlock,
        Splide,
        SplideSlide,
    },
})
