// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/event.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/icons/course.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/icons/olympiads.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".event-item__name[data-v-46dfef0c] {\n  display: block;\n  position: relative;\n  flex-basis: max-content;\n  overflow: hidden;\n  line-height: 28px;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  padding-left: 32px;\n}\n.event-item__name[data-v-46dfef0c]::before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 24px;\n    height: 24px;\n}\n.event-item__name--event[data-v-46dfef0c]::before {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.event-item__name--course[data-v-46dfef0c]::before {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.event-item__name--olympiads[data-v-46dfef0c]::before {\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
