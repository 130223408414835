import { ref, computed, } from 'vue'

import { post, } from '@/modules/http'

import { Notification, } from '@/types/common'

const message =  ref<string>('')
const type = ref<string>('')
const isVisible = ref<boolean>(false)
const notificationClass = ref('success')

const counter = ref(0)
const increment = () => counter.value = counter.value + 1
const setCount = (count) => {
    counter.value = count
}
export const useCommmonNotification  = () => {
    const showNotification  = async (
        m: string,
        t = 'success',
        count?: boolean,
        notiff?: Notification
    ) => {
        isVisible.value = false
        notificationClass.value = t
        if (isVisible.value === false)  {
            message.value = m
            type.value = t
            isVisible.value = true
            if (count) {
                if (notiff) {
                    // const response  = await post('/notif/', notiff, {}, true)
                    // if (response.ok) {
                    //     increment()
                    // }
                }
            }
        }
    }

    const hideNotification = () => {
        isVisible.value = false
    }


    return {
        message,
        isVisible: computed (() =>  isVisible.value),
        showNotification,
        hideNotification,
        notificationClass: computed(() => notificationClass.value),
        counter: computed(() => counter.value),
        setCount,
    }
}
