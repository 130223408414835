<template lang="pug">
.surveys-results
    SearchBlock.title-block__searchBlock(
                name-search='Поиск по ФИО и названию опроса'
                is-fullwidth
                @on-search="loadDataWithSearch"
                @on-clear="loadDataWithSearch"
            )
    .survey-result
        .load-btn-data
            btn.btn.btn--do-action(
                text="Скачать в excel"
                @click="downloadSurveysResults"
                :loading="isUploadingBtn")
        Table(v-if="dataTest != 0 && !activeInputOnSearch" :items="dataTest" :headers="headersResultSurveys" @rowClick="onRowClick" :is_hover="true")
        .survey-loading(v-else-if="isUploading")
            LoadingIndicator
        p.par(v-else-if="dataTest == 0 && !isUploading" ) Нет подходящих опросов
    .more-btn-block__for-table(v-if="visibleMoreBtn && items != 0")
        btn.btn--outline(v-if="!isUploading"
            text="Показать еще" 
            @mousedown="loadData")
        LoadingIndicator(v-else)
</template>

<script>
import {defineComponent,} from 'vue'

// composition
import {useSearch,} from '@/composition/search';
import {useResultSurveys,} from '@/composition/schoolSurveys';

//components
import SearchBlock from '@/components/Generic/search-block/index.vue';
import Table from '@/components/Generic/table/index.vue';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'ResultList',
    components: {
        SearchBlock,
        Table,
        LoadingIndicator,
    },
    props: {
        dataTest: {
            type: Array,
        },
        isUploading: {
            type: Boolean,
        },
        visibleMoreBtn: {
            type: Boolean,
        },
        activeInputOnSearch: {
            type: Boolean,
        },
    },
    emits: ['load-data', 'load-data-on-search', 'open-modal-test'],
    setup(props, {emit, }) {
        const {searchString, onSearch,} = useSearch()
        const {
            headersResultSurveys,
            downloadSurveysResults,
            modalInfoData,
            isUploadingBtn,
        } = useResultSurveys()

        const loadData = () => {
            emit('load-data')
        }

        const loadDataWithSearch = (s) => {
            s !== undefined ? searchString.value = s : searchString.value = ''
            emit('load-data-on-search', searchString.value)
        }
        const onRowClick = async (row) => {
            modalInfoData.value.Header = 'Информация о прохождении опроса'
            modalInfoData.value.id = row.id
            modalInfoData.value.username = `${row.employee}`
            modalInfoData.value.teacher_id = row.teacher_id
            modalInfoData.value.hidden_in_public = row.data.user.hidden_in_public
            modalInfoData.value.survey_name = row.text
            modalInfoData.value.annotation = row.data.survey.annotation !== null ? row.data.survey.annotation : ''
            modalInfoData.value.auditoryes = row.auditoryes
            modalInfoData.value.quantity_questions = [row.data.count_is_required, row.data.count_not_required]
            modalInfoData.value.quantity_answers = [...modalInfoData.value.quantity_questions, row.data.count_answers_is_required, row.data.count_answers_not_required ]
            document.documentElement.style.overflow = 'hidden';
            emit('open-modal-survey', modalInfoData.value)
        }

        return {
            headersResultSurveys,
            downloadSurveysResults,
            isUploadingBtn,
            //action
            loadData,
            loadDataWithSearch,
            onRowClick,
        }
    },
})
</script>

<style lang="scss">
.surveys-results {
    .survey-result {
        margin-top: 32px;
        .par {
            margin-top: 20px;
            font-weight: 500;
            font-size: 20px;
        }
        .load-btn-data {
            width: fit-content;
            margin-bottom: 20px;
            margin-left: auto;
        }
    }
    .survey-loading {
        text-align: center;
    }
}
</style>