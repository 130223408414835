

import { defineComponent, PropType, ref, } from 'vue'
import FieldBlockInputError from '@/components/Generic/field-block-input-error/index.vue'


export default defineComponent({
    name: 'ModalWindow',
    components: {
        FieldBlockInputError,
    },
    props: {
        title:{
            type: String as PropType<string>,
            required: true,
        },
        message: {
            type: String as PropType<string>,
            default: '',
        },
        buttonText: {
            type: String as PropType<string>,
            default:'OK',
        },
        buttons: {
            type: Array as PropType<object[]>,
            default: () => [],
        },
        addInput: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        inputLabel: {
            type: String as PropType<string | boolean>,
            default: 'false',
        },
        isVisible: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        isBig: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        errors: {
            type: Array as PropType<string[]>,
            default: () => {[]},
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
        scrollModal: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    setup(props, { emit,  }) {
        const closeWindow = () => {
            emit('close')
            // document.documentElement.style.overflow = 'auto';
        }

        const onTransitionEnter = () => {
            const input: HTMLFormElement | null = document.querySelector('#modal-input')
            if (input) { input.focus() }
        }

        const inputElem = ref<HTMLFormElement>({} as HTMLFormElement)


        return {
            props,
            closeWindow,
            inputElem,
            onTransitionEnter,
        }
    },
})
