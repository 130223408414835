

import Tabs from '@/components/Generic/tabs/index.vue'

import {tabs,} from '@/modules/constants/tabs.ts'

import {useTabs,} from '@/composition/tabs.ts'
import ColleagueAdd from '@/views/colleague-add/index.vue';

import {defineComponent, onMounted, ref,} from 'vue'
import {useColleagues,} from '@/composition/colleagues'

export default defineComponent({
    name: 'Colleague',
    components: {
        ColleagueAdd,
        Tabs,
    },
    setup() {
        const {tabItems, activeTab,} = useTabs(tabs.colls)
        const tabsRef = ref(tabItems)
        const {getTabsCount, tabsCount,} = useColleagues()
        const showAddModal = ref(false)
        const getCountByLink = (link) => {
            switch (link) {
            case '/collegues/all':
                return tabsCount.value.colleagues
            case '/collegues/input':
                return tabsCount.value.incoming
            case '/collegues/output':
                return tabsCount.value.outgoing
            }
        }
        const processTabs = async () => {
            await getTabsCount()
            tabsRef.value = tabItems.map(tab => {
                return {
                    ...tab,
                    count: getCountByLink(tab.link),
                }
            })
        }
        const setAddModalVisibility = (bool) => {
            showAddModal.value = bool
            if (!showAddModal.value) {
                processTabs()
            }
        }

        onMounted(async () => {
            await processTabs()
        })
        const onChange = () => {
            processTabs();
        };
        return {
            onChange,
            activeTab,
            tabsCount,
            showAddModal,
            tabItems: tabsRef,
            setAddModalVisibility,
        }
    },
});
