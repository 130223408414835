import { ref, } from 'vue'
import {useRoute, } from 'vue-router'

export const fixedPage = () => {
    const route = useRoute()
    const controlClasses = () => {        
        const addClasses = (elementName, classes) => {
            if (elementName !== undefined) {
                elementName.classList.add(classes)
            }
        }
        const removeClasses = (elementName, classes) => {
            if (elementName !== undefined) {
                elementName.classList.remove(classes)
            }
        }        
        const header = document.getElementsByClassName('header')[0]
        const title = document.getElementsByClassName('title-block')[0]
        const sider = document.getElementsByClassName('aside-cont')[0]

        if (route.name === 'messagesDetail') {
            addClasses(header, 'header__fixed')
            addClasses(sider, 'aside-cont__fixed')
            addClasses(title, 'title-block__fixed')
        } else {
            removeClasses(header, 'header__fixed')
            removeClasses(sider, 'aside-cont__fixed')
            removeClasses(title, 'title-block__fixed')
        }
    }

    return {
        route,
        controlClasses,
    }
}