
import { defineComponent, onMounted, ref, computed, } from 'vue'

import { SelectListItem, } from '@/types/common.ts'

import { post, get, } from '@/modules/http'

import { useSelectList, } from '@/composition/useSelectList.ts'
import { useCommmonNotification, } from '@/composition/notification.ts'
import  { useDeleteDialog, } from '@/composition/deleteDialog.ts'
import { useUserProfileData, } from '@/composition/userProfile'

import SingleHobbie from '@/components/Profile/single-hobbie/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'ProfileInterests',
    components: {
        SingleHobbie,
        ModalWindow,
        LoadingIndicator,
    },
    setup() {
        const isTabLoaded = ref(false)

        const { setOnModerationIcon,  } = useUserProfileData()
        setOnModerationIcon(false)

        const { showNotification, } = useCommmonNotification()

        let hobbies: SelectListItem[] = []

        const fetchHobbies = async () => {
            const response = await get('/profile/hobbies/')
            if (response.ok) {
                hobbies = response.parsedBody
            }
        }

        const {
            list,
            addItem: addHobbie,
            deleteItemByID: deleteHobbieByID,
        } =  useSelectList(hobbies)

        onMounted( async() => {
            await fetchHobbies()
            isTabLoaded.value = true
            list.value = hobbies          
        })

        const buttons = [{
            text: 'OK',
            event: 'close',
        }]

        const isSaving = ref(false)

        const saveHobbies = async () => {
            isSaving.value = true
            // list.value = list.value.filter( el => el.name.trim() !== '' )
            // list.value = [...new Set(list.value)]
            try {
                const response = await post('/profile/hobbies/', list.value)
                if (response.ok) {
                    showNotification('Изменения сохранены')
                    list.value = []
                    list.value.push(...response.parsedBody)
                }
            }
            catch(e) {  showNotification('Ошибка при сохранении интересов'), 'danger'}
            isSaving.value = false
        }
        const { showDeleteDialog, setTitle, } = useDeleteDialog()
        const deleteHobbie = async (id, name, deleteHobbie) => {
            showDeleteDialog(id, name, deleteHobbie)
        }
        setTitle('Вы действительно хотите удалить интерес?')
        const disableBtn = computed(() => {
            if (list.value.length === 0) return true
            const listFilter = list.value.filter(item => item.name.replace(/ +/g, ' ').trim().length === 0)
            return listFilter.length === 0 ? false : true
        }) 

        return {
            hobbiesList: list,
            hobbies,
            addHobbie,
            deleteHobbieByID,
            saveHobbies,
            buttons,
            generateKey: Symbol(Date.now()),
            isTabLoaded,
            isSaving,
            showDeleteDialog,
            deleteHobbie,
            disableBtn,
        }
    },
});
