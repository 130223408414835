

import ColleagueItem from '@/components/Generic/colleague-item/index.vue'
import {defineComponent, watch,} from 'vue'
import {useColleagues,} from '@/composition/colleagues';
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import {useDialog,} from '@/components/Generic/Dialog/dialog';
import {safePromise,} from '@/modules/helpers';

export default defineComponent({
    name: 'Colleague',
    components: {
        ColleagueItem,
        ModalWindow,
    },
    props: {
        fetch: Boolean,
    },
    emit: ['change'],
    async setup(props, {emit,}) {
        const {fetchColleagues, colleagues, pagination, next, removeColleague, } = useColleagues(props)
        watch(() => props.fetch, () => {
            if (props.fetch) {
                fetchColleagues()
            }
        })
        await fetchColleagues()

        const {show,} = useDialog();
        const onRemove = async (id: number) => {
            const [err] = await safePromise(show({
                title: 'Подтверждение операции',
                description: 'Вы уверены, что хотите удалить из коллег?',
            }));
            if (!err) {
                await removeColleague(id);
                emit('change');
            }
        }

        return {
            colleagues,
            pagination,
            next,
            onRemove,
            fetchColleagues,
            removeColleague,
        }
    },
});
