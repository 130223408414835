
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'

import EventBlock from '@/components/Events/events-block/index.vue'
import RecomendedEvents from '@/components/Mainpage/recomended-events/index.vue'
import sliderDots from '@/components/Generic/slider-dots/index.vue'

import { defineComponent, ref, } from 'vue'

import { useEvents, } from '@/composition/events'


export default defineComponent({
    name: 'LkMain',
    components: {
        EventBlock,
        Tabs,
        TitleBlock,
        RecomendedEvents,
        sliderDots,
    },
    setup (){
        const  tabItems = ref([
            {
                content: 'Все',
                count:0,
                type: 'base_events',
            },
            {
                content: 'Мероприятия',
                count:0,
                type: 'events',

            },
            {
                content: 'Курсы',
                count: 0,
                type: 'courses',
            },
            {
                content: 'Олимпиады',
                count: 0,
                type: 'olympiads',
            }            
        ])

        const eventType = ref<string>('base_events')

        const eventCount = ref<number>(0)

        const activeTab = ref<number>(0)

        const {  searchString, getEventCountByFilter, } = useEvents({
            eventType: eventType.value,
            filter: 'actual',
        })

        const changeTab = (tab: number) => {
            switch (tab) {
            case 0: eventType.value = 'base_events'
                break
            case 1: eventType.value = 'events'
                break;
            case 2: eventType.value = 'courses'
                break;
            case 3: eventType.value = 'olympiads'
                break;                
            }
            
            activeTab.value = tab
        }

        tabItems.value.forEach( async (el) => {
            el.count = await getEventCountByFilter(el.type, 'actual')
            eventCount.value = await getEventCountByFilter(el.type, 'actual')
        })

        const onEventsChanged = async( { fromFavorites, s, } ) => {
            if (fromFavorites) return
            tabItems.value.forEach( async el => {
                el.count = await getEventCountByFilter(el.type, 'actual', s)
                if (el.type === 'base_events') {
                    eventCount.value = el.count;
                }
            })
        }

        const goSearch =  async (s: string) => {
            searchString.value = s;
            tabItems.value.forEach( async (el) => {
                el.count = await getEventCountByFilter(el.type, 'actual', searchString.value)
            })
            eventCount.value = await getEventCountByFilter('base_events', 'actual')
        }

        const recCount = ref<number>(0)

        const setRecCount = (count: number) => {
            recCount.value = count
        }

        return {
            tabItems,
            changeTab,
            eventType,
            activeTab,
            goSearch,
            searchString,
            eventCount,
            setRecCount,
            recCount,
            onEventsChanged,
        }
    },
})
