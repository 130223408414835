
import { defineComponent, ref, computed, onMounted, } from 'vue'

import { put,get, } from '@/modules/http'

import { useModal, } from '@/composition/modal'
import { useCommmonNotification, } from '@/composition/notification'
import { useUserProfileData, } from '@/composition/userProfile'
import { useCKed, } from '@/composition/wys'

import ModalWindow from '@/components/Generic/modal-window/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'ProfileProgress',
    components: {
        ModalWindow,
        LoadingIndicator,
    },
    setup () {
        const achContent = ref('')

        const isTabLoaded = ref(false)
        const isSaving = ref(false)
        const { setOnModerationIcon,  } = useUserProfileData()
        setOnModerationIcon(false)

        const { showNotification,  }  = useCommmonNotification()


        const fetchAch = async () => {
            const response = await get<{id: number; content: string }>('/profile/achievements/', {})
            if (response.ok) {
                achContent.value = response.parsedBody.achievements
            }
            else {
                console.error('Ошибка при получении данных о достижениях')
            }
        }

        onMounted(async () => {
            await fetchAch()
            isTabLoaded.value = true
        })


        const { isModalVisible, onModalClose, } =  useModal()

        const saveAch = async () => {
            isSaving.value = true
            try {
                const response = await put('/profile/achievements/', {
                    achievements: achContent.value.trim(),
                })
                if (response.ok) {
                    showNotification('Изменения сохранены!')
                }
            }
            catch(e) {
                console.error(e)
            }
            isSaving.value = false
        }

        const buttons = [{
            text: 'OK',
            event: 'close',
        }]

        const formElem = ref<HTMLElement>({} as HTMLElement)

        const submitDisabled = computed(() => achContent.value === null || achContent.value==='')

        return {
            achContent,
            saveAch,
            isModalVisible,
            buttons,
            onModalClose,
            formElem,
            submitDisabled,
            isTabLoaded,
            isSaving,
            ...useCKed(),
        }
    },
});
