
import { defineComponent, ref, onMounted, onBeforeUnmount, PropType, } from 'vue'

export default defineComponent({
    name: 'CodeExpirationTimer',
    props: {
        remainingTime: {
            type: Number as PropType<number>,
            default: () => {
                return 60
            },
        },
    },
    setup (props, { emit, }) {
        const currentTime = ref(props.remainingTime)

        const isExpired = ref(false)

        let timerID  = 0

        const startTimer  = (): number => {
            timerID = window.setInterval(() => {
                currentTime.value = currentTime.value - 1
                if (currentTime.value === 1) {
                    emit('code-timer-expired')
                }
            }, 1000)
            return timerID
        }

        onMounted (() => {
            timerID = startTimer()
        })

        onBeforeUnmount(() => {
            if (timerID > 0)
                window.clearInterval(timerID)
        })

        return {
            currentTime,
            isExpired,
        }
    },
})

