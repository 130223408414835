import { ref, } from 'vue'
import {useRouter,} from 'vue-router'

//import common
import { tabs, } from '@/modules/constants/tabs.ts'

//import composition
import { useTabs, } from '@/composition/tabs.ts'
import { get, } from '@/modules/http';

export const useEvents = (props): any => {
    const router = useRouter()
    let currentTabs = router.currentRoute.value.fullPath.includes('courses') ? tabs.courses : tabs.events
    if (router.currentRoute.value.fullPath.includes('olympiads')) {
        currentTabs = tabs.olympiads;
    }
    const { eventTabs, activeTab, } = useTabs(currentTabs)

    const eventsCount = ref<number>(0)
    const searchString = ref('')

    const eventTypeText = ref(props.eventType === 'events' ? 'мероприятия' : 'курсы')
    
    const tabItems = ref(currentTabs)

    const getEventCountByFilter = async (eventType: string, eventFilter: string, s = ''): Promise<number> => {
        try {
            const response = await get(`/${eventType}/?&filter=${eventFilter}&search=${s}`)
            if (response.status === 200) {
                return response.parsedBody.count
            }
            else {
                console.error('Ошибка при подсчете количества мероприятий')
            }
        }
        catch (e) {
            console.error(e)
        }
        return 0
    }

    tabItems.value.forEach( async (el) => {
        el.count = await getEventCountByFilter(props.eventType, el.link)
        el.link = `/${props.eventType}/${el.link}`
        eventsCount.value += el.count
    })

    const getEventCountFavorites = async (eventType: string, s=''): Promise<number> => {
        try {
            if (eventType ==='base_events') {
                const response =  await get(`/base_events/?is_favorite=true&search=${s}`)
                return response.parsedBody.count
            }
            else {
                const response = await get(`/${eventType}/?is_favorite=true&search=${s}`)
                if (response.status === 200) {
                    return response.parsedBody.count
                }
                else {
                    console.error('Ошибка при подсчете количества мероприятий')
                }
            }
        }
        catch (e) {
            console.error(e)
        }
        return 0
    }

    //recount events tabs count
    const onEventsChanged = ( { fromFavorites, s, } ) => {
        if (fromFavorites) return
        tabItems.value.forEach( async (el, ind) => {
            if (ind === activeTab.value) {
                el.count = el.count - 1
            }
            else el.count = await getEventCountByFilter(props.eventType, el.link.substring(el.link.lastIndexOf('/') + 1),s)
        })
    }

    //recount current tab
    const onEventsChangedCurrent = (count: number) => {
        tabItems.value[activeTab.value].count = count
    }

    //search and recount
    const goSearch =  (s: string) => {
        searchString.value = s
        tabItems.value.forEach( async el => {
            el.count = await getEventCountByFilter(props.eventType, el.link.substring(el.link.lastIndexOf('/') + 1), s)
        })
    }


    return {
        eventTabs,
        eventsCount,
        activeTab,
        onEventsChanged,
        onEventsChangedCurrent,
        getEventCountFavorites,
        getEventCountByFilter,
        goSearch,
        tabItems,
        searchString,
        eventTypeText,
    }
}
