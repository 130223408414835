import {ref, } from 'vue';
import {get, post, put,} from '@/modules/http';
import { useCommmonNotification, } from '@/composition/notification';

export function useAudit() {
    const { showNotification, } = useCommmonNotification()

    const visibleMoreBtn = ref(false)
    const isUploading = ref(false)
    const next = ref('')
    const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

    const acceptBtns = [
        {
            text: 'Да',
            event: 'checking-case',
            class: '',
        },
        {
            text: 'Нет',
            event: 'close',
            class: 'btn--outline',
        }
    ]
    const headersNew = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Педагог',
            value: 'educator',
        },
        {
            text: 'Название',
            value: 'text',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Номер попытки',
            value: 'attempting',
        }
    ];
    const headersProcess = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Педагог',
            value: 'nane',
        },
        {
            text: 'Название',
            value: 'text',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Номер попытки',
            value: 'attempting',
        }
    ];
    const headersCompleted = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Педагог',
            value: 'nane',
        },
        {
            text: 'Название',
            value: 'text',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Номер попытки',
            value: 'attempting',
        },
        {
            text: 'Баллы',
            value: 'score',
        }
    ];
    const searchConfig = {
        status: false,
        placeholder: 'Поиск по ФИО и названию кейса (поиск по предметной области и проф. компетенциям ещё не доступен)',
    }
    const modalInfoData = ref ({
        modal: {
            Header: String,
            HeaderAccept: String,
            BodyAccept: String,
        },
        type: String,
        name: String,
        username: String,
        teacher_id: Number,
        text: String,
        annotation: String,
        attempting: {
            number: Number,
            max_attempting: Number,
        },
        status: String,
        score: String,
        id: Number,
        subject_area: Array,
        competence: Array,
        questions: Array,
    })
    const caseCheckList = async (status, search) => {
        const page = 1
        const pageSize = 10
        const url = `/case_check/?page=${page}&page_size=${pageSize}&filter=${status}&search=${search}`
        const response = await get(url);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // не работает как надо
    const caseCheckListAll = async () => {
        const page = 1
        const pageSize = 10
        const url = `/case_check/?page=${page}&page_size=${pageSize}`
        const response = await get(url);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const caseCheckRead = async (id) => {
        const url = `/case_check/${id}/`;
        const response = await get(url);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const case_check_status_on_check = async (id, data) => {
        const url = `/case_check/${id}/status_on_check/`
        const response = await post(url, data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // оставить комментарий к вопросу
    const case_answer_comment_update = async (id, data) => {
        const url = `/case_answer_comment/${id}/`
        const response = await put(url, data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // завершить проверку кейса
    const case_check_update = async (id, data) => {
        const url = `/case_check/${id}/`
        const response = await put(url, data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const checkNextUrl = (response) => {
        if (response.next !== null) {
            next.value = response.next.replace(apiURL, '')
            visibleMoreBtn.value = true
            return next.value
        }
        next.value = response.next
        visibleMoreBtn.value = false
        return next.value
    }
    // Подгрузка данных "Показать еще"
    const loadData = async(data) => {
        try{
            isUploading.value = true
            const response = await get(next.value)
            if (response.ok) {
                data.value = data.value.concat(response.parsedBody.results)
                checkNextUrl(response.parsedBody)
                isUploading.value = false
            } else {
                throw new SyntaxError('Произошла ошибка')
            }
        } catch(e) {
            showNotification('Ошибка получения данных', 'error')
            isUploading.value = false
        }
    }

    return {
        // function
        caseCheckList,
        caseCheckListAll,
        caseCheckRead,
        case_check_status_on_check,
        case_answer_comment_update,
        case_check_update,
        checkNextUrl,
        loadData,
        // const
        searchConfig,
        modalInfoData,
        visibleMoreBtn,
        isUploading,
        next,
        // header
        headersNew,
        headersProcess,
        headersCompleted,
        // btns
        acceptBtns,
    }
}

