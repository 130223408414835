export const useEnv = () => {
    return {
        mode: process.env.NODE_ENV,
        isProd: process.env.NODE_ENV === 'prod',
        isDev: process.env.VUE_APP_MODE === 'dev',
        isStage: process.env.VUE_APP_MODE === 'staging',
        apiURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`,
        baseDomain: process.env.VUE_APP_BASE_DOMAIN,
        publicURL: process.env.VUE_APP_PUBLIC_URL,
    }
}
