
import { defineComponent, } from 'vue'
import {useCommmonNotification,} from '@/composition/notification';
import {useCookie,} from '@/composition/cookie';
export default defineComponent({
    name: 'Home',
    setup () {
        const { showNotification, hideNotification, } =  useCommmonNotification()
        const { getCookie, deleteCookie, } =  useCookie()
        const emailConfirmed = getCookie('email-confirmed')
        if (emailConfirmed) {
            deleteCookie('email-confirmed')
            showNotification('Ваша электронная почта одобрена', 'success')
            window.setTimeout(hideNotification, 3000)
        }
    },
});
