

import ColleagueItem from '@/components/Generic/colleague-item/index.vue'
import {useColleagues, } from '@/composition/colleagues';
import {defineComponent, watch,} from 'vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import {useDialog,} from '@/components/Generic/Dialog/dialog';
import {safePromise,} from '@/modules/helpers';

export default defineComponent({
    name: 'Colleague',
    components: {
        ColleagueItem,
        ModalWindow,
    },
    props: {
        fetch: Boolean,
    },
    emit: ['change'],
    async setup(props, {emit,}) {
        const {
            fetchOutgoingColleagues,
            outgoingColleagues,
            pagination,
            cancelOutgoingRequest,
            next,
        } = useColleagues(props)
        pagination.value.page = 1
        watch(() => props.fetch, () => {
            if (props.fetch) {
                fetchOutgoingColleagues()
            }
        })
        await fetchOutgoingColleagues()
        const {show,} = useDialog();
        const onCancel = async (id: number) => {
            const [err] = await safePromise(show({
                title: 'Подтверждение операции',
                description: 'Вы уверены, что хотите отменить запрос на добавление пользователя в коллеги?',
            }));
            if (!err) {
                await cancelOutgoingRequest(id);
                emit('change');
            }
        }
        return {
            outgoingColleagues,
            pagination,
            cancelOutgoingRequest,
            fetchOutgoingColleagues,
            next,
            onCancel,
        }
    },

});
