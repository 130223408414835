<template lang="pug">
label.attached(:class="{disabled: loading}")
    input.attached__input(
        ref="attached"
        name="file"
        type="file"
        v-on:change="onChange"
        :disabled="loading"
    )
    span.attached__text
        slot
        span.attached__loading(v-if="loading")
            img(src="@/assets/img/loading.svg")
    .attached__name(
        v-if="value"
    ) {{ value.name }}
</template>

<script>
export default {
    name: 'BaseAttached',
    props: {
        value: {
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        onChange () {
            this.$emit('update:value', this.$refs.attached.files[0])
        },
    },
}
</script>

<style lang="scss" scoped>
.attached {
    display: block;
    cursor: pointer;
    position: relative;
    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        pointer-events: none;
    }

    &__text {
        position: relative;
        font-size: 18px;
        line-height: 18px;
        color: black;
        padding-left: 30px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 14px;
            height: 2px;
            left: 0;
            top: 10px;
            background-color: black;
        }

        &::after {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
    &__loading {
        margin: 0;
        img {
            filter: invert(1);
            margin: 0 0 0 12px;
        }
    }

    &__name {
        margin-top: 8px;
        font-size: 16px;
        color: darkslategray;
    }
}
.wysiwyg-block__cases {
    .attached {
        &__text {
            @media (max-width: 780px) {
                font-size: 14px;
                &::before,
                &::after {
                    top: 8px;
                }                
            }
        }
    }
}
</style>
