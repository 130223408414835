import { ref, computed, } from 'vue'

const isDelDialogVisible = ref(false)
const delM = ref()
const modalTitle = ref('')

const delDialogButtons = [
    {
        text: 'Да',
        event: 'ok',
        class: '',
    },
    {
        text: 'Нет',
        event: 'close',
        class:'btn--outline',
    }
]
const toDeleteID = ref<number>(-1)

export const useDeleteDialog = () => {

    const closeDeleteDialog = () => {
        isDelDialogVisible.value = false
    }

    const approveDelete = async () => {
        delM.value(toDeleteID.value)
        closeDeleteDialog()
    }

    const showDeleteDialog = (id: number, name: string, delMethod) => {
        delM.value = delMethod
        toDeleteID.value = id
        if (id===null || name.trim()==='') {
            approveDelete()
            return
        }
        isDelDialogVisible.value = true
    }

    const setTitle = (title: string) => {
        modalTitle.value = title
    }

    return {
        isDelDialogVisible: computed(() => isDelDialogVisible.value),
        delDialogButtons,
        showDeleteDialog,
        closeDeleteDialog,
        approveDelete,
        modalTitle: computed(() => modalTitle.value),
        setTitle,
    }
}
