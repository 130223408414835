

import { defineComponent, } from 'vue'

export default defineComponent({
    name: 'NotLists',
    props: {
        notifications: Array,
        default: [],
    },

});
