
import { defineComponent, ref,   } from 'vue'

import WorkEditor from '@/components/Profile/works-editor/WorksEditor.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'ProfileWork',
    components: {
        WorkEditor,
        LoadingIndicator,
    },
    setup () {
        const isTabLoaded = ref(false)

        const onWorksLoaded = () => {
            isTabLoaded.value = true
        }
        return {
            isTabLoaded,
            onWorksLoaded,
        }

    },
});
