import { useAuth, } from '@/composition/state'

export default async (from, next) => {
    const { isLoggedIn, } = useAuth()
    if (await isLoggedIn()){
        return next({
            path: '',
        })
    }
    return next()
}

