import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "msg-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "msg-wrap__day"
}
const _hoisted_3 = { class: "msg-el" }
const _hoisted_4 = { class: "msg-el__img-detail" }
const _hoisted_5 = { class: "msg-el__text msg-el__text--msg" }
const _hoisted_6 = { class: "msg-el__text-title" }
const _hoisted_7 = { class: "msg-el__name" }
const _hoisted_8 = { class: "msg-el__date" }
const _hoisted_9 = { class: "msg-el__text" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_temp_avatar = _resolveComponent("temp-avatar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.msgs, (msg, index) => {
      return (_openBlock(), _createBlock(_Fragment, { key: index }, [
        (msg.showDate)
          ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.dateFormatting(msg.created)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            (msg.sender.avatar !== null)
              ? (_openBlock(), _createBlock("img", {
                  key: 0,
                  src: `${_ctx.domen}/${msg.sender.avatar}`,
                  alt: msg.text
                }, null, 8 /* PROPS */, ["src", "alt"]))
              : (_openBlock(), _createBlock(_component_temp_avatar, {
                  key: 1,
                  typeImage: "img"
                }))
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              _createVNode("span", _hoisted_7, _toDisplayString(msg.sender.full_name), 1 /* TEXT */),
              _createVNode("span", _hoisted_8, _toDisplayString(_ctx.dateFormatting(msg.created, 'exact')), 1 /* TEXT */)
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("span", {
                innerHTML: _ctx.lineBreak(msg.text)
              }, null, 8 /* PROPS */, ["innerHTML"])
            ])
          ])
        ])
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}