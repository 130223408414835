

import ColleagueItem from '@/components/Generic/colleague-item/index.vue'
import {useColleagues, } from '@/composition/colleagues';
import {defineComponent, watch,} from 'vue'
import {safePromise,} from '@/modules/helpers';
import {useDialog,} from '@/components/Generic/Dialog/dialog';

export default defineComponent({
    name: 'Colleague',
    components: {
        ColleagueItem,
    },
    props: {
        fetch: Boolean,
    },
    emit: ['change'],
    async setup(props, {emit,}) {
        const {
            fetchIncomingColleagues,
            incomingColleagues,
            pagination,
            next,
            acceptIncomingRequest,
            rejectIncomingRequest,
        } = useColleagues(props)
        pagination.value.page = 1
        watch(() => props.fetch, () => {
            if (props.fetch) {
                fetchIncomingColleagues()
            }
        })
        await fetchIncomingColleagues()
        const {show,} = useDialog();
        const onAccept = async (id: number) => {
            const [err] = await safePromise(show({
                title: 'Подтверждение операции',
                description: 'Вы уверены, что хотите добавить пользователя в коллеги?',
            }));
            if (!err) {
                await acceptIncomingRequest(id);
                emit('change');
            }
        }
        const onReject = async (id: number) => {
            const [err] = await safePromise(show({
                title: 'Подтверждение операции',
                description: 'Вы уверены, что хотите отклонить заявку?',
            }));
            if (!err) {
                await rejectIncomingRequest(id);
                emit('change');
            }
        }
        return{
            onAccept,
            onReject,
            fetchIncomingColleagues,
            incomingColleagues,
            pagination,
            next,
        }
    },
});
