export const tabs = {
    get profile() {
        return [
            {content: 'Персональные данные', link: '/profile/',},
            {content: 'Работа', link: '/profile/work',},
            {content: 'Интересы', link: '/profile/interest',},
            {content: 'Инновационные практики', link: '/profile/practices',},
            {content: 'Достижения', link: '/profile/achievements',}
        ]
    },
    get school() {
        return [
            {content: 'Об организации', link: '/school/about',},
            {content: 'Сотрудники', link: '/school/staff',},
            {content: 'Достижения', link: '/school/achievements',},
            {content: 'Тестирование', link: '/school/tests',},
            {content: 'Опросы', link: '/school/surveys',},
            {content: 'Контакты', link: '/school/contacts',}
        ]
    },
    get events() {
        return [
            {content: 'Предстоящие', link: 'actual', count: 0,},
            {content: 'На подтверждении', link: 'moderation', count: 0,},
            {content: 'Прошедшие', link: 'past', count: 0,},
            {content: 'Отклоненные', link: 'rejected', count: 0,}
        ]
    },

    get courses() {
        return [
            {content: 'Не пройденные', link: 'actual', count: 0,},
            {content: 'На подтверждении', link: 'moderation', count: 0,},
            {content: 'Пройденные', link: 'past', count: 0,},
            {content: 'Отклоненные', link: 'rejected', count: 0,}
        ]
    },

    get olympiads() {
        return [
            {content: 'Не пройденные', link: 'actual', count: 0,},
            {content: 'На подтверждении', link: 'moderation', count: 0,},
            {content: 'Пройденные', link: 'past', count: 0,},
            {content: 'Отклоненные', link: 'rejected', count: 0,}
        ]
    },    

    get settings() {
        return [
            {content: 'Основные', link: '/settings/general',},
            {content: 'Изменение пароля', link: '/settings/change-password',}
        ]
    },
    get staff() {
        return [
            {id: 0, content: 'Работают', count: '', link: '/school_moderator/user/working/',},
            {id: 1, content: 'Входящие запросы', count: 0, link: '/school_moderator/job_moderation_claim/create_type/',},
            {id: 2, content: 'Изменение данных', count: 0, link: '/school_moderator/job_moderation_claim/change_type/',},
            {id: 3, content: 'Запрос на удаление', count: 0, link: '/school_moderator/job_moderation_claim/delete_type/',},
            {id: 4, content: 'Не работают', count: 0, link: '/school_moderator/user/not_working/',}
        ]
    },
    get colls() {
        return [
            {content: 'Коллеги', link: '/collegues/all',},
            {content: 'Входящие запросы', link: '/collegues/input',},
            {content: 'Исходящие запросы', link: '/collegues/output',}
            // {content: 'Добавить коллегу', link: '/collegues/add',}
        ]
    },
    get diagnostic() {
        return [
            {content: 'Тестирование', link: '/diagnostics/tests',},
            {content: 'Решение кейсов', link: '/diagnostics/cases',}
        ]
    },
    get diagnosticTestsNested() {
        return [
            {content: 'Новые', link: '/diagnostics/tests/new',},
            {content: 'В процессе', link: '/diagnostics/tests/process',},
            {content: 'Завершенные', link: '/diagnostics/tests/completed',}
        ]
    },
    get diagnosticCasesNested() {
        return [
            {content: 'Новые', link: '/diagnostics/cases/new',},
            {content: 'В процессе', link: '/diagnostics/cases/process',},
            {content: 'Завершенные', link: '/diagnostics/cases/completed',}
        ]
    },
    get audit() {
        return [
            {content: 'Непроверенные кейсы', link: '/audit/new',},
            {content: 'В процессе проверки', link: '/audit/process',},
            {content: 'Проверенные кейсы', link: '/audit/completed',}

        ]
    },
    get individualRoute() {
        return [
            {content: 'Мой маршрут', link: '/individual-route/my-route',},
            {content: 'Завершенные маршруты', link: '/individual-route/completed',}
        ]
    },
    get monitoring() {
        return [
            {content: 'Актуальные', link: '/monitoring/actual',},
            {content: 'В процессе', link: '/monitoring/process',},
            {content: 'Завершенные', link: '/monitoring/completed',}
        ]
    },    
}

