<template lang="pug">
transition(name="fade" appear)
    .modal-mask(
        @click.self="onCloseModal"
        )
        .modal-wrapper
            .modal-container
                .modal-window__btn-close(@click.prevent = "onCloseModal")
                    .modal-window__btn-close-wrapper
                        span.modal-window__btn-close-line
                        span.modal-window__btn-close-line
                slot
</template>

<script>
export default {
    emit: ['on-close-modal'],
    setup(_, {emit,}) {
        const onCloseModal = () => {
            emit('on-close-modal')
        }
        return {
            onCloseModal,
        };
    },
};
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    overflow: auto;

}

.modal-wrapper {
    position: absolute;
    min-height: 100px;
    width: fit-content;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    @media(max-width: 599px) {
        width: 98% !important;
        padding: 10px;
        left: 50%;
        margin-left: -49%;
    }

    .modal-close {
        position: absolute;
        right: -10px;
        top: -10px;
        cursor: pointer;

        img {
            width: 36px;
            height: 36px;
        }
    }
}
.modal-container {
    width: 560px;
    margin: 0px auto;
    padding: 50px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    position: relative;
    @media(max-width: 768px) {
        max-width: 520px;
        width: 100%;
    }
}
</style>