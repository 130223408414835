import {createRouter, createWebHistory, RouteRecordRaw,} from 'vue-router'
import { routes, } from './routes'
import {useCommmonNotification,} from '@/composition/notification';
import {middlewarePipeline,} from '@/middlewares/pipeline';

const baseTitle = process.env.VUE_APP_TITLE

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// navigation guards
router.beforeEach(  async (to, from, next)=>{
    const { hideNotification, } = useCommmonNotification()
    hideNotification()
    if (to.path === '/') {
        document.title =  baseTitle
    }
    else {
        const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
        if(nearestWithTitle) document.title = `${baseTitle} - ${nearestWithTitle.meta.title}`
    }
    if (!to.meta.middleware) {
        return next({
            path: '/login',
        })
    }

    const middleware = to.meta.middleware
    return await  middleware[0](from, next, to)
})


export default router
