
import TempAvatar from '@/components/Generic/temp-avatar/index.vue';
import {defineComponent,} from 'vue';

export default defineComponent({
    name: 'ColleagueItem',
    components: {TempAvatar, },
    props: ['items'],
    setup() {
        const teacherLink = `${process.env.VUE_APP_PUBLIC_URL}/teachers`
        const getOrganizations = item => {
            return item.organizations.join(', ') || 'Не указано'
        }
        return {
            getOrganizations,
            teacherLink,
        }
    },
})
