<template lang="pug">
svg(:width="size" :height="size" v-if="typeImage === 'svg'" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg" :class="full ? 'full' : ''")
    rect(width="212" height="212" fill="#F2F2F2")
    path(d="M169.053 192.65V208.724C169.053 210.533 167.586 211.999 165.777 211.999H46.223C44.4141 211.999 42.9475 210.533 42.9475 208.724V192.65C42.9475 175.785 56.6205 162.124 73.4851 162.118C79.4481 162.116 84.6982 159.046 87.719 154.396C98.2636 163.405 113.731 163.41 124.281 154.396C127.302 159.046 132.552 162.116 138.515 162.118C155.38 162.124 169.053 175.785 169.053 192.65Z" fill="#8F1C70")
    path(d="M164.05 53.0846C164.24 53.383 164.187 53.8351 163.944 54.0922C159.605 58.6852 157.181 64.7755 157.181 71.1099V93.4527C157.181 98.5247 153.36 103.5 146.536 103.5V78.2094C146.536 67.3554 137.737 58.5566 126.883 58.5566H85.1209C74.2668 58.5566 65.4681 67.3554 65.4681 78.2094V103.5C59.6316 103.5 55.2323 98.8879 55.2323 93.4527V76.4366C55.1012 70.5121 55.3715 49.6187 70.4632 35.0716C88.0103 18.1575 115.822 19.5422 135.963 28.5915C136.618 28.8858 136.601 29.8238 135.936 30.0973L130.683 32.26C129.897 32.5834 130.063 33.7409 130.907 33.8314L133.459 34.1053C145.674 35.4102 157.017 42.043 164.05 53.0846Z" fill="#1B1B1A")
    path(d="M146.536 104.782C146.536 127.176 128.379 145.316 106.002 145.316C84.9196 145.316 65.4683 128.925 65.4683 103.5V78.2094C65.4683 67.3553 74.267 58.5566 85.1211 58.5566H126.883C137.737 58.5566 146.536 67.3553 146.536 78.2094V104.782Z" fill="#FFEBD2")
    path(d="M139.163 205.04H129.337C127.528 205.04 126.061 203.573 126.061 201.764C126.061 199.955 127.528 198.489 129.337 198.489H139.163C140.972 198.489 142.438 199.955 142.438 201.764C142.438 203.573 140.972 205.04 139.163 205.04Z" fill="white")
img(v-else :src='require(`@/assets/img/user-ava-360px.png`)')
</template>
<script>
import { defineComponent, } from 'vue'

export default defineComponent({
    name: 'TempAvatar',
    props: {
        full: Boolean,
        size: {
            type: Number,
            default: 40,
        },
        typeImage: String,
    },
})
</script>

<style lang="scss" scoped>
.full {
    width: 100%;
    height: 100%;
}
</style>
