export const useSplide = () => {
    const  options = {
        rewind: true,
        width: 1210,
        height: 497,
        gap: '32px',
        arrows: false,
        perPage: 3,
    }
    return {
        options,
    }
}