<template lang="pug">
.collapse(:class="collapseClasses")
  .collapse__head(@click="toggleCollapse")
    .head__content
      img(:src="require('@/assets/icons/collapse.svg')" :style="{transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}" ) 
      b 
        slot(name="title")
    p.subtitle
      slot(name="subtitle")
  .collapse__content(v-if="isOpen == true" :style="{height: isOpen ? '100%' : '0%'}" )
      slot(name="content")
</template>

<script>
import { ref, } from 'vue';
// components
import Btn from '@/components/Generic/btn/index.vue';
export default {
    components: {
        Btn,
    },
    props: {
        collapse: {},
    },
    setup() {
        const isOpen = ref(false);
        const collapseClasses = ref({
            'collapse-open': isOpen,
        });
        const toggleCollapse = () => {
            isOpen.value = !isOpen.value;
        };
        return {
            // func
            toggleCollapse,
            // const
            isOpen,
            collapseClasses,
        };
    },
};
</script>

<style lang="scss" scoped>
.collapse {
  padding-bottom: 30px;
  &__head {
    position: relative;
    cursor: pointer;
    padding: 0;
    .head__content {
      display: flex;
    }
    img {
      margin-right: 20px;
      transition: 0.5s ease;
      transform: rotate(0deg);
      width: 20px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      @media screen and (max-width: 768px){
        &{
          font-size: 18px;
        }
      }
    }
    .subtitle {
      margin-top: 13px;
      margin-left: 39px;
    }
  }
  // & > &__head::before {
  //   content: "";
  //   position: absolute;
  //   top: 8px;
  //   left: 10px;
  //   width: 12px;
  //   height: 12px;
  //   border: 2px solid #f50237;
  //   border-radius: 50px;
  //   transition: 0.3s ease;
  //   transition-property: transform, background-color;
  // }
  // &.collapse-open > &__head::before {
  //   transform: scale(0.5);
  //   background-color: #f50237;
  // }
  &__content {
    height: 0px;
    padding: 1rem 0 0;
    margin-left: 39px;
    overflow: hidden;
    transition: all 0.8s ease;
  }
}
</style>