<template lang="pug">
template(v-if="!loading")
    NewRoute(
        v-if="status === 0"
        @on-modal-open="openModal"
        :tutor="tutor"
    )
    DevelopmentRoute(
        v-if="status === 1"
        :tutor="tutor"
        :dataRoute="dataRoute"
        :loadingTable="loadingTable"
        @update-events="getNewEvents"
        @on-modal-open="openCheckModal"
    )
    WaitingRoute(
       v-if="status === 7 || status === 2"
       :tutor="tutor" 
    )
    OnConformitionRoute(
        v-if="status === 3 || status === 4"
        :status="status"
        :tutor="tutor"
        :dataRoute="dataRoute"
        @on-modal-open="openConfirmationRouteModal"
    )
    ProcessRoute(
        v-if="status === 5"
        :tutor="tutor"
        :dataRoute="dataRoute"
    )
    ModalWindow(
        :is-visible="isModalVisible"
        :title="modalTitle"
        :buttons="buttons"
        :addInput="false"
        @ok="confirm"
        @close="onModalClose"
    )
template(v-else)
    .loading-indicator
        LoadingIndicator
</template>

<script>
import {ref, onMounted,} from 'vue';

// composition
import {useModal,} from '@/composition/modal'
import {useIndividualRoute,} from '@/composition/individualRoute'

// components
import NewRoute from '@/components/IndividualRoute/my-route/NewRoute.vue';
import WaitingRoute from '@/components/IndividualRoute/my-route/WaitingRoute.vue';
import OnConformitionRoute from '@/components/IndividualRoute/my-route/OnConformition.vue';
import DevelopmentRoute from '@/components/IndividualRoute/my-route/DevelopRoute.vue';
import ProcessRoute from '@/components/IndividualRoute/my-route/ProcessRoute.vue';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'

export default {
    components: {
        LoadingIndicator,
        ModalWindow,
        NewRoute,
        WaitingRoute,
        OnConformitionRoute,
        DevelopmentRoute,
        ProcessRoute,
    },
    emits: ['show-attention'],
    setup(_, {emit, }) {
        const {
            isModalVisible,
            modalTitle,
            onModalClose,
        } = useModal()
        const {
            getTutor,
            createRoute,
            getStatusRoute,
            changeStatuRoute,
            getNewListEvent,
        } = useIndividualRoute()
        const loading = ref(true)
        const loadingTable = ref(false)
        const status = ref(0)
        const tutor = ref([])
        const dataRoute = ref([])
        const ownRoute = ref(true)

        const buttons = [
            {
                text: 'Да',
                event: 'ok',
                class: '',
            },
            {
                text: 'Нет',
                event: 'close',
                class: 'btn--outline',
            }
        ]
        // function

        const getDataTutor = async() => {
            const response = await getTutor()
            tutor.value = response
        }
        const getDataRoute = async() => {
            const response = await getStatusRoute()
            if (response.results.length > 0) {
                dataRoute.value = response.results[0]
                status.value = dataRoute.value.status
                emit('show-attention', status.value)
            }
        }
        const openModal = (emitValue) => {
            ownRoute.value = emitValue
            if (emitValue) {
                modalTitle.value = 'Вы уверены, что хотите начать самостоятельно разрабатывать свой маршруте?'
            } else {
                modalTitle.value = 'Вы уверены, что хотите отправить заявку на создание индивидуального маршрута закрепленным за вами тьютором?'
            }
            isModalVisible.value = true
        }
        const openConfirmationRouteModal = (confirm) => {
            ownRoute.value = confirm
            if (confirm) {
                modalTitle.value = 'Вы уверены, что хотите подтвердить сформированный маршрут? После подтверждения вы будете автоматически зарегистрированы на все мероприятия и курсы маршрута.'
            } else {
                modalTitle.value = 'Вы уверены, что хотите отправить маршрут на доработку тьютору? Не забудьте связаться со своим тьютором для обсуждения изменений в маршруте.'
            }
            isModalVisible.value = true
        }
        const openCheckModal = () => {
            modalTitle.value = 'Вы уверен, что хотите отправить на проверку тьютора составленный маршрут?'
            isModalVisible.value = true
        }
        const confirm = async() => {
            loading.value = true
            isModalVisible.value = false
            if (status.value === 0) {
                await createRoute(ownRoute.value ? '1' : '2')
                await getDataRoute()
                loading.value = false
                return
            }
            if (status.value === 1) {
                await changeStatuRoute(dataRoute.value.id, '4')
                await getDataRoute()
                loading.value = false
                return
            }
            if (status.value === 3) {
                await changeStatuRoute(dataRoute.value.id, ownRoute.value === true ? '5' : '2')
                await getDataRoute()
                loading.value = false
                return
            }
        }

        const getNewEvents = async () => {
            loadingTable.value = true
            const response = await getNewListEvent()
            dataRoute.value = response.results[0]
            loadingTable.value = false
        }

        onMounted(async () => {
            await getDataTutor()
            await getDataRoute()
            loading.value = false
        })
        return {
            loading,
            loadingTable,
            status,
            buttons,
            isModalVisible,
            modalTitle,
            tutor,
            dataRoute,
            // function
            openModal,
            onModalClose,
            openCheckModal,
            openConfirmationRouteModal,
            confirm,
            getNewEvents,
        }
    },
}
</script>

<style lang="scss">
.route__content .par {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
        color: #8F1C70;
        font-weight: 600;
        &:hover {
            color: inherit;
            cursor: pointer;
        }
    }
}
.list-events {
    margin-top: 30px;
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.2;
        color: #1b1b1a;
        margin-bottom: 18px;
    }
    &__header-block {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        .update-btn {
            margin-left: 30px;
        }
    }
}
.my-tutor__block {
    margin-top: 30px;
}
.buttons-container {
    margin-top: 40px;
    display: flex;
    .btn-left {
        margin-right: 40px;
        @media (max-width: 520px) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    @media (max-width: 520px) {
        flex-direction: column;
        .route-btn {
            max-width: 230px;
        }
    }
}
.mini-statistics-block {
    margin-top: 40px;
}
</style>
