import {ref,} from 'vue'

export const useSearch = (action?) => {
    const searchString = ref<string>('')

    const onSearch = (s: string) => {
        searchString.value = s
        if (action)
            action(searchString.value)
    }

    return {
        searchString,
        onSearch,
    }
}
