
import { defineComponent, ref, reactive, } from 'vue'
import { useRouter, } from 'vue-router'

//import types
import { UserCreds, } from '@/types/user.ts'

//import composition
import { useCodeChecker, } from '@/composition/codeChecker.ts'
import { useFieldValidator, } from '@/composition/fieldValidator.ts'
import { useModal, } from '@/composition/modal.ts'
import { useAuth, } from '@/composition/state.ts'

//import func
import { post,  getFlatPage, } from '@/modules/http.ts'

//import components
import CodeExpirationTimer from '@/components/Generic/code-expiration-timer/index.vue'
import FieldBlock from '@/components/Generic/field-block/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'


import { commonFields, } from '@/modules/fields.ts'
import LoginLogo from '@/components/Login/LoginLogo.vue';

export default defineComponent({
    name: 'Registration',
    components: {
        FieldBlock,
        CodeExpirationTimer,
        ModalWindow,
        LoginLogo,
    },
    setup() {
        let tryRegChecker = true;
        const router = useRouter()
        const isEmailAndPasswordSent = ref(false)
        const emailField = commonFields.email
        const codeField = commonFields.code
        const passwordField = commonFields.newPass
        passwordField.subText = 'Пароль'


        const { logIn, } = useAuth()

        const forms =  reactive({
            regFormFields: {
                emailField,
                passwordField,
            },
            codeFormFields: {
                codeField,
            },
        })


        const { validateForm, validateRegOnServer, correctValidatePass, } = useFieldValidator()

        const isLoading = ref(false)

        const regButtonParams = reactive({
            height: '60px',
            loading: isLoading.value,
            text: 'Зарегистрироваться',
        })

        const codeButtonParams = reactive({
            height: '60px',
            loading: isLoading.value,
            text: 'Подтвердить',
        })

        const {
            requestCode,
            isRequestForCodeSend,
            remainingTime,
            wrongCodeRequestCounter,
            resendCode:resend,
            refreshTimer,
            timerExpired,
            showTimer,
        } = useCodeChecker('/email_confirmation/', 60)


        // sending form data to validate on server and get registation code
        const submitRegForm = async () => {
            // check if phone and password already validated
            if (!isRequestForCodeSend.value  ) {
                const isErrors = validateForm(forms.regFormFields)
                if (isErrors === true)  { return }
            }
            const userData: UserCreds = {
                email: emailField.inputValue.replace(/ /g, ''),
                password: passwordField.inputValue,
            }
            isLoading.value = true
            const result: boolean | {email: string[]; password: string[]} = await validateRegOnServer(userData)
            isLoading.value = false
            if (result === true ) {
                remainingTime.value = 60
                // call backend to send code to user phone
                if (!isEmailAndPasswordSent.value)  {
                    isEmailAndPasswordSent.value = true
                    await requestCode(userData);
                }
            }
            else if (typeof result !== 'boolean') {
                forms.regFormFields.emailField.errorMessages = !!result.email ? result.email : []
                forms.regFormFields.passwordField.errorMessages = !!result.password ? correctValidatePass(result.password) : []
            }
            else throw new Error('Error sending validation request from registration page')
        }


        // trying to register with recived code
        const tryReg = async () => {
            if (tryRegChecker) {
                tryRegChecker = false
                setTimeout(() => {tryRegChecker = true}, 1000)

                const isErrors = validateForm(forms.codeFormFields)
                if (isErrors)  { return }
                const userData: UserCreds = {
                    email: emailField.inputValue.replace(/ /g, ''),
                    password: passwordField.inputValue,
                    code: codeField.inputValue,
                }
                try {
                    isLoading.value = true
                    const response = await post<UserCreds>('/register/', userData )
                    isLoading.value = false
                    switch (response.status) {
                    case 201: const loginResult = await logIn(userData)
                        sessionStorage.removeItem('reg-email')
                        if (loginResult) router.push('/')
                        break
                    case 400:  forms.codeFormFields.codeField.errorMessages = response.parsedBody.code
                        break
                    case 406: forms.codeFormFields.codeField.errorMessages = response.parsedBody.code
                        break
                    default: throw new Error(response.statusText)
                    }

                }
                catch (e) {
                    console.error(e)
                    isLoading.value = false
                }
                isLoading.value = false
            }
        }

        const { modalTitle, modalMessage, isModalVisible, onModalClose, } =  useModal()

        const Terms = {
            content: '',
            title: '',
        }

        const Agrement = {
            content: '',
            title: '',
        }

        const showTerms = async () => {
            if (Terms.title === '') {
                const response = await getFlatPage(12)
                Terms.title = response.parsedBody.title
                Terms.content = response.parsedBody.description
            }
            modalTitle.value = Terms.title
            modalMessage.value = Terms.content
            isModalVisible.value = true
        }

        const showAgr = async () => {
            if (Agrement.title === '') {
                const response = await getFlatPage(10)
                Agrement.title = response.parsedBody.title
                Agrement.content = response.parsedBody.description
            }
            modalTitle.value = Agrement.title
            modalMessage.value = Agrement.content
            isModalVisible.value = true
        }

        const resendCode =  () => {
            forms.codeFormFields.codeField.inputValue = ''
            forms.codeFormFields.codeField.errorMessages = []
            resend()
        }

        return{
            forms,
            submitRegForm,
            isEmailAndPasswordSent,
            isRequestForCodeSend,
            tryReg,
            remainingTime,
            resendCode,
            wrongCodeRequestCounter,
            requestCode,
            refreshTimer,
            timerExpired,
            showTimer,
            onModalClose,
            modalTitle,
            modalMessage,
            isModalVisible,
            showTerms,
            showAgr,
            regButtonParams,
            codeButtonParams,
            isLoading,
        }
    },
});
