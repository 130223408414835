
import { defineComponent, computed, watch, onMounted, } from 'vue'
import {useRouter,} from 'vue-router';
import {checkCaches,} from '@/composition/deleteCaches'
import {fixedPage, } from '@/composition/fixedBlock.ts'

export default defineComponent({
    name: 'App',
    setup() {
        const router = useRouter();
        const {deleteCaches,} = checkCaches()
        const {controlClasses, } = fixedPage()
        const getPath = computed(() => {
            return router.currentRoute.value.path
        })
        // created
        deleteCaches(caches, true)
        onMounted(() => {
            controlClasses()
        })
        watch(getPath, () => {
            if (getPath.value === '/') {
                deleteCaches(caches, true)
            } else {
                deleteCaches(caches, false)
            }
            controlClasses()
        })

        return {
            deleteCaches,
            getPath,
        }
    },
})
