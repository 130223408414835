
import { defineComponent,reactive, ref, } from 'vue'
import { useRouter, } from 'vue-router'

//import types
import { UserCreds, } from '@/types/user'

//import composition
import { useFieldValidator, } from '@/composition/fieldValidator.ts'
import { useCodeChecker, } from '@/composition/codeChecker.ts'
import { useAuth, } from '@/composition/state.ts'

//import func
import {  commonFields, } from '@/modules/fields'
import { post, } from '@/modules/http.ts'

//import components
import FieldBlock from '@/components/Generic/field-block/index.vue'
import CodeExpirationTimer from '@/components/Generic/code-expiration-timer/index.vue'
import LoginLogo from '@/components/Login/LoginLogo.vue';

export default defineComponent({
    name: 'Restore',
    components: {
        FieldBlock,
        CodeExpirationTimer,
        LoginLogo,
    },
    setup(){
        const { logIn, } = useAuth()
        const isLoading = ref(false)
        const emailField = commonFields.email
        const codeField = commonFields.code
        const newPasswordField = commonFields.newPass
        const repeatNewPasswordField = commonFields.repeatPass

        const forms = reactive({
            resetFormFields: {
                emailField,
            },
            codeFormFields: {
                codeField,
            },
            passwordChangeFormFields: {
                newPasswordField,
                repeatNewPasswordField,
            },
        })

        const {
            requestCode,
            isRequestForCodeSend,
            remainingTime,
            wrongCodeRequestCounter,
            resendCode: resend,
            refreshTimer,
            timerExpired,
            showTimer,
        } = useCodeChecker('/password_reset/email_confirmation/', 60)

        const isCodeRecieved = ref(false)
        const isCodeSendToUser = ref(false)
        const isCodeOk = ref(false)

        let onCodeRequest = false

        const resendCode =  () => {
            forms.codeFormFields.codeField.inputValue = ''
            forms.codeFormFields.codeField.errorMessages = []
            resend()
        }

        const { validateForm, correctValidatePass, } = useFieldValidator()


        const submitRestoreForm = async () => {

            if (onCodeRequest === true) return

            const isErrors = validateForm(forms.resetFormFields)
            if (isErrors === true)  { return }

            emailField.inputDisabled = true
            onCodeRequest = true
            isLoading.value = true

            const result: boolean | string[] = await requestCode( { email: emailField.inputValue, })
            onCodeRequest = false
            isLoading.value = false
            emailField.inputDisabled = false
            if (typeof result !== 'boolean') forms.resetFormFields.emailField.errorMessages = result
            else { return false }
        }

        let token = ''

        const obtainRestoreToken = async () => {
            if (wrongCodeRequestCounter.value > 3 || onCodeRequest === true) return

            const isErrors = validateForm(forms.codeFormFields)
            if (isErrors)  { return }
            isLoading.value = true
            codeField.inputDisabled = true
            onCodeRequest = true
            const response = await post('/password_reset/obtain_token/', {
                email: emailField.inputValue.replace(/ /g, ''),
                code: codeField.inputValue,
            })
            onCodeRequest = false
            if (response.ok) {
                sessionStorage.removeItem('reg-email')
                token = response.parsedBody.token
                isRequestForCodeSend.value = false
                isCodeRecieved.value = true
                isCodeOk.value = true
                codeField.inputDisabled = false
                isLoading.value = false
                return response.parsedBody.token

            }
            else {
                wrongCodeRequestCounter.value  = wrongCodeRequestCounter.value + 1
                forms.codeFormFields.codeField.errorMessages = [ response.parsedBody.code ]
                codeField.inputDisabled = false
                isLoading.value = false
                return false
            }
            isLoading.value = false
            return false
        }

        const router = useRouter()

        const changeUserPassword = async (token: string) => {
            if (token === '') {
                throw new Error('token is empty')
            }
            if (onCodeRequest === true) return

            forms.passwordChangeFormFields.newPasswordField.errorMessages = []
            forms.passwordChangeFormFields.repeatNewPasswordField.errorMessages = []

            if (newPasswordField.inputValue !== repeatNewPasswordField.inputValue) {
                forms.passwordChangeFormFields.repeatNewPasswordField.errorMessages = ['Пароли не сопадают']
                return
            }
            const isErrors = validateForm(forms.passwordChangeFormFields)
            if (isErrors)  { return }

            if (newPasswordField.inputValue === repeatNewPasswordField.inputValue) {
                onCodeRequest = true
                isLoading.value = true

                const response = await post('/password_reset/confirm/', {
                    password: newPasswordField.inputValue,
                    password2: repeatNewPasswordField.inputValue,
                    token,
                })
                onCodeRequest = false
                if (response.ok) {
                    const userData: UserCreds = {
                        email: emailField.inputValue.replace(/ /g, ''),
                        password: newPasswordField.inputValue,
                    }
                    const loginResult = await logIn(userData)
                    if (loginResult) {
                        router.push('/')
                    }
                } else {
                    forms.passwordChangeFormFields.newPasswordField.errorMessages = !!response.parsedBody['password'] ? correctValidatePass(response.parsedBody['password']) : []
                    forms.passwordChangeFormFields.repeatNewPasswordField.errorMessages = !!response.parsedBody['password2'] ? correctValidatePass(response.parsedBody['password2']) : []
                }
                
                isLoading.value = false
            }
            isLoading.value = false
        }


        const submitPasswordChangeForm = async() => {
            await changeUserPassword(token)
            return
        }        

        return{
            forms,
            submitRestoreForm,
            submitPasswordChangeForm,
            changeUserPassword,
            isRequestForCodeSend,
            isCodeSendToUser,
            isCodeRecieved,
            obtainRestoreToken,
            isCodeOk,
            remainingTime,
            refreshTimer,
            resendCode,
            timerExpired,
            showTimer,
            isLoading,
        }
    },

});
