
import { defineComponent, reactive, ref, } from 'vue'
import { useRouter, } from 'vue-router'

//import types
import { UserCreds, } from '@/types/user.ts'

//import composition
import { useAuth, } from '@/composition/state.ts'
import { useFieldValidator, } from '@/composition/fieldValidator.ts'

//import func
import {  commonFields, } from '@/modules/fields.ts'

//import components
import FieldBlock from '@/components/Generic/field-block/index.vue'
import LoginLogo from '@/components/Login/LoginLogo.vue';

export default defineComponent({
    name: 'Login',
    components: {
        LoginLogo,
        FieldBlock,
    },
    setup() {
        const emailField = commonFields.email
        const passwordField = commonFields.pass


        const forms = reactive({
            loginForm: {
                emailField,
                passwordField,
            },
        })

        const isLoading = ref(false)

        const buttonParams = reactive({
            height: '60px',
            loading: isLoading,
            text: 'Войти',
        })
        const router = useRouter()

        const { validateForm, } = useFieldValidator()
        const { logIn, } = useAuth()
        const sendLoginForm = async () => {

            const isErrors = validateForm(forms.loginForm)
            if (isErrors === true)  { return }

            isLoading.value = true

            emailField.inputDisabled = true
            passwordField.inputDisabled = true

            const userData: UserCreds = {
                email: emailField.inputValue.replace(/ /g, ''),
                password: passwordField.inputValue,
            }
            const result: boolean = await logIn(userData)
            if (result) {
                isLoading.value = false
                router.push({ path: '/', })
            }
            else {
                emailField.inputDisabled = false
                forms.loginForm.passwordField.inputDisabled = false
                forms.loginForm.emailField.errorMessages = ['Неверно введен email или пароль']
                isLoading.value = false
            }
            isLoading.value = false
        }
        return {
            forms,
            sendLoginForm,
            router,
            buttonParams,
        }
    },
});
