
import { defineComponent,} from 'vue'
//impport common modules
import { tabs, } from '@/modules/constants/tabs.ts'

//import composition

import { useTabs, } from '@/composition/tabs.ts'
//import components

import Tabs from '@/components/Generic/tabs/index.vue'

import TitleBlock  from '@/components/Generic/title-block/index.vue'

export default defineComponent({
    name: 'UserPasswordChange',
    components: {
        TitleBlock,
        Tabs,
    },
    setup () {
        const { tabItems, activeTab, } = useTabs(tabs.settings)
        return {
            tabItems,
            activeTab,
        }
    },
});
