import { useRouter, } from 'vue-router'

export function useRedirect() {
    const router = useRouter()
    const redirect = (e: Event | null, path: string) => {
        if (e !== null) {
            e.preventDefault()
            e.stopPropagation()
        }
        router.push(path)
    }
    
    const redirectPublic = ( path: string) => {
        window.location.href = process.env.VUE_APP_PUBLIC_URL +  path;
    }

    return {
        redirect,
        redirectPublic,
    }
}
