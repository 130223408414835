
import {defineComponent, onMounted, ref,} from 'vue'

import {get, patch,} from '@/modules/http'

import {useCKed,} from '@/composition/wys'
import {useCommmonNotification,} from '@/composition/notification'
// import { useSchool, } from "@/composition/school"

import ModalWindow from '@/components/Generic/modal-window/index.vue'
import Btn from '@/components/Generic/btn/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'SchoolProgress',
    components: {
        ModalWindow,
        Btn,
        LoadingIndicator,
    },
    setup() {
        const {showNotification,} = useCommmonNotification()

        const achContent = ref('')
        const isLoadingTab = ref(true)
        const isSaving = ref(false)

        const fetchAchivment = async () => {
            try {
                const response = await get('/school_moderator/organization/', {})
                if (response.ok) {
                    achContent.value = response.parsedBody.achievements
                } else {
                    showNotification('Ошибка при получении данных достижений школы', 'danger')
                }
            } catch (e) {
                console.error(e)
            }
        }

        onMounted(async () => {
            await fetchAchivment()
            isLoadingTab.value = false;
        })

        const saveAch = async () => {
            try {
                isSaving.value = true
                const response = await patch('/school_moderator/organization/', {
                    achievements: achContent.value.trim(),
                }, {})
                if (response.ok) {
                    showNotification('Изменения сохранены!')
                }
                isSaving.value = false

            } catch (e) {
                console.error(e)
            }
        }

        return {
            ...useCKed(),
            isLoadingTab,
            achContent,
            saveAch,
            isSaving,
        }
    },

});
