import { useAuth, } from '@/composition/state'
import {useUserProfileData,} from '@/composition/userProfile';

export default async (from, next, to) => {
    const { isLoggedIn, logOut,  } = useAuth()
    const isLog = await  isLoggedIn()
    if (!isLog) {
        return  next({
            path: '/login',
        })
    }
    const {  fetchUserProfileData, getUserProfileData, } = useUserProfileData()
    await fetchUserProfileData()
    if (!getUserProfileData.value?.profile_verified) {
        return  next({
            path: '/onboarding',
        })
    }
    if (to.meta.onlyModerator && !getUserProfileData.value?.is_school_moderator) {
        return next('/')
    }
    return next()
}
