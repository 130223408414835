import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-stuff" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Tabs = _resolveComponent("Tabs")
  const _component_SearchBlock = _resolveComponent("SearchBlock")
  const _component_StaffList = _resolveComponent("StaffList")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      class: "tab-container--underline tab-container--small",
      "tab-items": _ctx.tabItems,
      activeTab: _ctx.activeTab,
      "non-router": "",
      onChangeTab: _ctx.changeTab,
      className: "tab-container__inner"
    }, null, 8 /* PROPS */, ["tab-items", "activeTab", "onChangeTab"]),
    _createVNode(_component_SearchBlock, {
      class: "title-block__searchBlock",
      "name-search": "Поиск по ФИО, должности, предмету, статусу",
      "is-fullwidth": "",
      onOnSearch: _ctx.onSearch,
      onOnClear: _ctx.onSearch
    }, null, 8 /* PROPS */, ["onOnSearch", "onOnClear"]),
    _createVNode(_component_StaffList, {
      filter: _ctx.filter,
      onUpdate: _ctx.updateCount,
      onUpdateCurrent: _ctx.updateCountCurrent,
      "search-string": _ctx.searchString
    }, null, 8 /* PROPS */, ["filter", "onUpdate", "onUpdateCurrent", "search-string"])
  ]))
}