
import {defineComponent, PropType, ref, onMounted, watch, computed,} from 'vue'

import { Work, SelectListItem, } from '@/types/common'

import { useAutocomplete, } from '@/composition/autocomplete.ts'
import { useSelectList, } from '@/composition/useSelectList.ts'

import SelectListInput from '@/components/Generic/select-list-input/index.vue'
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue';

export default defineComponent({
    name: 'SingleWork',
    components: {
        SelectWithSearch,
        SelectListInput,
        TrashIcon,
    },
    emits: ['change', 'delete-work'],
    props: {
        work: {
            type: Object as PropType<Work>,
            required: true,
        },
        excludeOrgs: {
            type: Array as PropType<SelectListItem[]>,
            default: () => [],
        },
    },
    setup (props, { emit, }) {

        const org = ref(props.work.educational_organization)

        const qual = ref(props.work.qualification)
        
        const {
            list: positions,
            addItem: addPosition,
            deleteItem: deletePosition,
        } = useSelectList(props.work.positions)

        const {
            list: subjects,
            addItem: addSubject,
            deleteItem: deleteSubject,
        } = useSelectList(props.work.subjects)


        const { requestForVariants, } = useAutocomplete()

        const orgs = ref<SelectListItem[]>([])
        const searchSubjects = ref<SelectListItem[]>([])
        const searchPositions = ref<SelectListItem[]>([])
        const quals = ref<SelectListItem[]>([])


        const onOrgSearch = async (searchString: string, page: number) => {
            const uploaded =   await requestForVariants('/organization', searchString, false, page)
            if (uploaded.results) {
                orgs.value = (page === 1) ? uploaded.results : [...orgs.value, ...uploaded.results]
                orgs.value = orgs.value.filter(pos => !(props.excludeOrgs.findIndex(el => el.id === pos.id && el.id!==org.value.id) >= 0))
            }
        }

        const onQualSearch = async (searchString: string, page: number) => {
            const uploaded = await requestForVariants('/qualification', searchString, false, 1)
            quals.value = (page === 1) ? uploaded.results : [...quals.value, ... uploaded.results]
        }

        const OnPosSearch = async (searchString: string, page: number, index: number) => {
            const uploaded = await requestForVariants('/position', searchString, false, page)
            
            searchPositions.value = (page === 1) ? uploaded.results : [...searchPositions.value, ... uploaded.results]
            searchPositions.value =  searchPositions.value.filter(
                pos => !(positions.value.findIndex(el => el.id === pos.id && el.id!==positions.value[index].id) >= 0))
        }

        const OnSubSearch = async (searchString: string, page: number, index: number) => {
            const uploaded = await requestForVariants('/subject', searchString, false, page)
            searchSubjects.value = (page === 1) ? uploaded.results : [...searchSubjects.value, ... uploaded.results]
            searchSubjects.value =  searchSubjects.value.filter(
                sub => !(subjects.value.findIndex(el => el.id === sub.id && el.id!==subjects.value[index].id) >= 0))
        }

        const onReplace = (newValue, oldId, targetArr, replaceIndex) => {
            const index = targetArr.value.findIndex((pos, index) => pos.id === oldId && index === replaceIndex);
            if (index !== -1) {
                targetArr.value[index] = newValue
            }
        }
        const onPositionReplace = (newValue, oldId, index) => {
            onReplace(newValue, oldId, positions, index);
        }
        const onSubjectReplace = (newValue, oldId, index) => {
            onReplace(newValue, oldId, subjects, index);
        }
        const handleSearch = (handler, { s, p, }, index) => {
            handler(s, p, index);
        }
        watch([org, qual, positions, subjects], () => {
            emit('change', {
                'educational_organization': org,
                qualification: qual,
                positions,
                subjects,
                id: props.work.id,
            })
        }, {deep: true,})
        const organizationInputDisabled = computed(() => {
            return typeof props.work.id !== 'symbol'
        })
        return {
            addSubject,
            addPosition,
            deleteSubject,
            deletePosition,
            onOrgSearch,
            OnSubSearch,
            OnPosSearch,
            onQualSearch,
            positions,
            subjects,
            orgs,
            org,
            searchSubjects,
            searchPositions,
            quals,
            qual,
            onPositionReplace,
            onSubjectReplace,
            handleSearch,
            organizationInputDisabled,
        }
    },
});
