
import {defineComponent, onMounted, ref,} from 'vue'

import {get, patch,} from '@/modules/http'

import {InputField,} from '@/types/forms'


// import { useSchool, } from "@/composition/school"
import Btn from '@/components/Generic/btn/index.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue'
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'

import {commonFields, createInput,} from '@/modules/fields.ts'
import {useFieldValidator,} from '@/composition/fieldValidator.ts'
import {useAutocomplete,} from '@/composition/autocomplete'
import {useCKed,} from '@/composition/wys'
import {useCommmonNotification,} from '@/composition/notification'
import {useDeleteDialog,} from '@/composition/deleteDialog'
import {SelectListItem,} from '@/types/common'
import YandexMap from '@/components/Generic/yandex-map/index.vue';


export default defineComponent({
    name: 'SchoolProgress',
    components: {
        YandexMap,
        Btn,
        LoadingIndicator,
        FieldBlockInput,
        SelectWithSearch,
        TrashIcon,
        ModalWindow,
    },
    setup() {
        const {showNotification,} = useCommmonNotification()

        const {requestForAddress,} = useAutocomplete()

        const contactsContent = ref('')
        const isLoadingTab = ref(true)
        const isSaving = ref(false)

        const phones = ref<string[]>([])

        const coords = ref<number[]>([])

        const email = ref(commonFields.email)

        const phoneFields = ref<InputField[]>([])

        const address = ref(commonFields.address)

        const matchAddresses = ref<SelectListItem[]>([])
        const onCoordsChanged = (coordsArr) => {
            coords.value = coordsArr
        }
        const fetchContacts = async () => {
            try {
                const response = await get('/school_moderator/organization/', {})
                if (response.ok) {
                    contactsContent.value = response.parsedBody.contacts || ''
                    phones.value = response.parsedBody.phones || []
                    email.value.inputValue = response.parsedBody.email || ''
                    address.value.inputValue = response.parsedBody.address || ''
                    matchAddresses.value = await requestForAddress(response.parsedBody.address)
                    const [x, y] = (response.parsedBody?.coordinates || '').split(',')
                    if (!x || !y) {
                        coords.value = [48.733601625335176, 44.49221287219231]
                    } else {
                        coords.value = [x, y]
                    }

                } else {
                    showNotification('Ошибка при получении контактов школы', 'danger')
                }
            } catch (e) {
                console.error(e)
            }
        }


        onMounted(async () => {
            await fetchContacts()
            if (phones.value.length > 0)
                for (let i = 0; i < phones.value.length; i++) {
                    phoneFields.value.push(createInput({
                        inputText: 'Телефон',
                        inputValue: phones.value[i],
                        inputType: commonFields.phone.inputType,
                        validators: commonFields.phone.validators,
                        inputMask: commonFields.phone.inputMask,
                    }))
                }
            else {
                phoneFields.value.push(createInput({
                    inputText: 'Телефон',
                    inputValue: '+7 ',
                    inputType: commonFields.phone.inputType,
                    validators: commonFields.phone.validators,
                    inputMask: commonFields.phone.inputMask,
                })
                )
            }
            isLoadingTab.value = false;
        })

        const {validateField,} = useFieldValidator()

        const saveContacts = async () => {
            try {
                let isPhoneErrors = false
                for (let i = 0; i < phoneFields.value.length; i++) {
                    isPhoneErrors = validateField(phoneFields.value[i])
                }

                const isEmailErrors = validateField(email.value)
                if (isPhoneErrors || isEmailErrors) return

                for (let i = 0; i < phoneFields.value.length; i++) {
                    phones.value[i] = phoneFields.value[i].inputValue
                }

                isSaving.value = true
                const response = await patch('/school_moderator/organization/', {
                    contacts: contactsContent.value.trim(),
                    phones: phones.value,
                    email: email.value.inputValue,
                    address: address.value.inputValue,
                    coordinates: `${coords.value[0]},${coords.value[1]}`,
                    x: coords.value[0],
                    y: coords.value[1],
                }, {})
                if (response.ok) {
                    showNotification('Изменения сохранены!')
                } else {
                    const errors = response.parsedBody
                    for(const err in errors) {
                        const errMessages = errors[err]
                        errMessages.forEach(e => {
                            showNotification(e, 'danger')
                        })
                    }
                }
                isSaving.value = false

            } catch (e) {
                console.error(e)
            }
        }

        const addPhone = () => {
            phoneFields.value.push(createInput({
                inputText: 'Телефон',
                inputValue: '+7 ',
                inputType: commonFields.phone.inputType,
                validators: commonFields.phone.validators,
                inputMask: commonFields.phone.inputMask,
            })
            )
        }


        const onAddressSearch = async (address: string) => {
            matchAddresses.value = await requestForAddress(address)
        }


        const deletePhone = (index: number) => {
            phoneFields.value.splice(index, 1)
        }

        const {showDeleteDialog, setTitle,} = useDeleteDialog()
        setTitle('Вы действительно хотите удалить телефон?')

        return {
            ...useCKed(),
            isLoadingTab,
            email,
            saveContacts,
            addPhone,
            phoneFields,
            onAddressSearch,
            address,
            matchAddresses,
            deletePhone,
            showDeleteDialog,
            contactsContent,
            isSaving,
            onCoordsChanged,
            coords,
        }
    },

});
