import { ref, } from 'vue'
import moment  from 'moment'

//import types
import { UserCreds, } from '@/types/user'

//import func
import { post, } from '@/modules/http'
import { InputField, } from '@/types/forms'

export const useCodeChecker =  (endpoint: string, codeExpirationTime: number, loadingFlag = {}) => {
    const isRequestForCodeSend = ref(false)


    const wrongCodeRequestCounter = ref(0)

    const remainingTime = ref(codeExpirationTime)
    const showTimer = ref(false)

    const timerExpired = () => {
        showTimer.value = false
    }

    const refreshTimer = () => {
        remainingTime.value = codeExpirationTime
        showTimer.value = true
    }

    const createdTimeDiff = (createdАt: string): number => {
        const now =  moment()
        const createdAtMoment = moment(createdАt)
        const elapsedSeconds = 60 - now.diff(createdAtMoment, 'seconds');
        return  (elapsedSeconds >= 0) ? elapsedSeconds: 0
    }
    // trying to get code
    // @return true  if code is sent to user, else return error message
    const requestCode = async (userData: UserCreds): Promise<boolean> => {
        userData.email = userData.email.replace(/ /g, '')

        // userData.phone = userData.phone.replace(/\(|\)|\s|\-/g, '')
        sessionStorage.setItem('reg-email', userData.email)
        const response = await post(endpoint, { email: userData.email, })
        switch (response.status) {
        case 201:
            refreshTimer()
            isRequestForCodeSend.value = true
            return true
            // return response.parsedBody.sent
            break
        case 400:
            const errorMessages = response.parsedBody.email
            return errorMessages
            break
        case 409:
            remainingTime.value = createdTimeDiff(response.parsedBody.created_at)
            isRequestForCodeSend.value = true
            showTimer.value = true
            return true
            break
        default: throw new Error(response.statusText)
        }
    }

    const resendCode = () => {
        wrongCodeRequestCounter.value = 0
        const email = sessionStorage.getItem('reg-email') as string
        if (email !== '' || email !== null)
            requestCode({
                email: sessionStorage.getItem('reg-email') as string,
            })
        else console.error('Телефона в сторе нет либо он неверный')
    }

    return {
        isRequestForCodeSend,
        remainingTime,
        wrongCodeRequestCounter,
        requestCode,
        resendCode,
        refreshTimer,
        timerExpired,
        showTimer,
    }
}

