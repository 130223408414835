
import { defineComponent, PropType, } from "vue";

import { useEvents,} from '@/composition/events'

import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from "@/components/Generic/title-block/index.vue";

export default defineComponent({
    name: "LkOlympiads",
    components: {
        Tabs,
        TitleBlock,
    },
    props: {
        filter: {
            type: String as PropType<string>,
        },
        eventType: {
            type: String as PropType<string>,
            default: 'olympiads',
            validator: (value: string) =>  ['events', 'courses', 'olympiads', 'all', 'base_events'].indexOf(value) !== -1,
        },
    },
    setup (props) {
        return {
            ...useEvents(props),
        }
    },    
});
