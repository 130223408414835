import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "select-block__autocomplete slide-down" }
const _hoisted_2 = { class: "select-block__autocomplete-variants-wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "select-block__autocomplete-variant"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_ctx.itemList.length > 0)
        ? (_openBlock(true), _createBlock(_Fragment, { key: 0 }, _renderList(_ctx.itemList, (item, index) => {
            return (_openBlock(), _createBlock("div", {
              class: ["select-block__autocomplete-variant", {'select-block__autocomplete-variant--highlighted' : _ctx.currentSelected === index }],
              key: item.id,
              onClick: _withModifiers($event => (_ctx.$emit('select-item', item)), ["prevent"]),
              onMouseover: $event => (_ctx.$emit('highlight-item', index))
            }, _toDisplayString(item.name), 43 /* TEXT, CLASS, PROPS, HYDRATE_EVENTS */, ["onClick", "onMouseover"]))
          }), 128 /* KEYED_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      (_ctx.isVisible && _ctx.itemList.length === 0)
        ? (_openBlock(), _createBlock("div", _hoisted_3, "Результатов нет"))
        : _createCommentVNode("v-if", true)
    ])
  ], 512 /* NEED_PATCH */)), [
    [_vShow, _ctx.isVisible]
  ])
}