import {ref,} from 'vue'
import {useCommmonNotification,} from '@/composition/notification';
import {get, post,} from '@/modules/http'

export const useColleagues = (props={}) => {
    const pagination = ref({
        page: 1,
        pageSize: 8,
        total: 0,
        next: undefined,
    })
    const loading = ref(false)
    const colleagues = ref([])
    const incomingColleagues = ref([])
    const outgoingColleagues = ref([])
    const notColleagues = ref([])
    const {showNotification,} = useCommmonNotification()
    const next = async (e, func) => {
        e.stopPropagation()
        e.preventDefault()
        pagination.value.page += 1
        await func()
    }
    const tabsCount = ref({
        colleagues: 0,
        incoming: 0,
        outgoing: 0,
    })
    const getTabsCount = async () => {
        const response = await get('/colleagues/tab_counts/')
        tabsCount.value = response.parsedBody
    }
    const addColleague = async (colleague) => {
        loading.value = true
        try {
            await post(`/colleagues/not_colleague/${colleague.id}/add/`, {})
            showNotification('Запрос на добавление в коллеги успешно отправлен')
        } catch (e) {
            showNotification('Ошибка! Повторите позже!', 'danger')
        }
        loading.value = false
    }
    const fetchIncomingColleagues = async (q?, fromPagination=false) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(pagination.value.page))
            url.searchParams.append('page_size', String(pagination.value.pageSize))
            if (q) {
                url.searchParams.append('search', q)
            }
            const response = await get(`/colleagues/incoming/${url.search}`)
            pagination.value.next = response.parsedBody.next
            pagination.value.total = response.parsedBody.count
            let result
            if (fromPagination) {
                result = incomingColleagues.value.concat(response.parsedBody.results)
            } else {
                result = response.parsedBody.results
            }
            incomingColleagues.value = result.map(x => ({
                ...x,
                actionText: 'Запрос на добавлении в коллеги ожидает вашего подтверждения',
            }))
        } catch (e) {
            showNotification('Ошибка при загрузке коллег! Повторите позже!', 'danger')
            console.error(e)
        }
    }
    const fetchOutgoingColleagues = async (q?, fromPagination=false) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(pagination.value.page))
            url.searchParams.append('page_size', String(pagination.value.pageSize))
            if (q) {
                url.searchParams.append('search', q)
            }
            const response = await get(`/colleagues/outgoing/${url.search}`)
            pagination.value.next = response.parsedBody.next
            pagination.value.total = response.parsedBody.count
            let result
            if (fromPagination) {
                result = outgoingColleagues.value.concat(response.parsedBody.results)
            } else {
                result = response.parsedBody.results
            }
            outgoingColleagues.value = result.map(x => ({
                ...x,
                actionText: 'Запрос на добавлении в коллеги ожидает подтверждения пользователя',
            }))

        } catch (e) {
            showNotification('Ошибка при загрузке коллег! Повторите позже!', 'danger')
            console.error(e)
        }
    }
    const cancelOutgoingRequest = async (id) => {
        try {
            const response = await post(`/colleagues/outgoing/${id}/cancel/`, {})
            if (response.ok) {
                await fetchOutgoingColleagues()
                showNotification('Запрос успешно отменен')
            } else {
                showNotification('Ошибка! Повторите позже!', 'danger')
            }

        } catch (e) {
            console.error(e)
        }
    }
    const acceptIncomingRequest = async (id) => {
        try {
            const response = await post(`/colleagues/incoming/${id}/accept/`, {})
            if (response.ok) {
                await fetchIncomingColleagues()
                showNotification('Пользователь успешно добавлен в коллеги')
            } else {
                showNotification('Ошибка! Повторите позже!', 'danger')
            }

        } catch (e) {

            console.error(e)
        }
    }
    const rejectIncomingRequest = async (id) => {
        try {
            const response = await post(`/colleagues/incoming/${id}/reject/`, {})
            if (response.ok) {
                await fetchIncomingColleagues()
                showNotification('Заявка успешно отклонена')
            } else {
                showNotification('Ошибка! Повторите позже!', 'danger')
            }
        } catch (e) {

            console.error(e)
        }
    }
    const fetchColleagues = async (q?, fromPagination=false) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(pagination.value.page))
            url.searchParams.append('page_size', String(pagination.value.pageSize))
            if (q) {
                url.searchParams.append('search', q)
            }
            const response = await get(`/colleagues/${url.search}`)
            pagination.value.next = response.parsedBody.next
            pagination.value.total = response.parsedBody.count
            let result
            if (fromPagination) {
                result = colleagues.value.concat(response.parsedBody.results)
            } else {
                result = response.parsedBody.results
            }
            colleagues.value = result
        } catch (e) {
            showNotification('Ошибка при загрузке коллег! Повторите позже!', 'danger')
            console.error(e)
        }
    }

    const removeColleague = async (id) => {
        try {
            const response = await post(`/colleagues/${id}/remove/`, {})
            if (response.ok) {
                await fetchColleagues()
                showNotification('Пользователь успешно удален из коллег')
            } else {
                showNotification('Ошибка! Повторите позже!', 'danger')
            }

        } catch (e) {
            console.error(e)
        }
    }

    const fetchNotColleagues = async (search?, size?: number) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(pagination.value.page))
            url.searchParams.append('page_size', size ? String(size) : String(pagination.value.pageSize))
            if (search) {
                url.searchParams.append('search', search)
            }
            const response = await get(`/colleagues/not_colleague/${url.search}`)
            pagination.value.next = response.parsedBody.next
            pagination.value.total = response.parsedBody.count
            notColleagues.value = response.parsedBody.results
        } catch (e) {
            showNotification('Ошибка при загрузке коллег! Повторите позже!', 'danger')
            console.error(e)
        }
    }

    return {
        loading,
        tabsCount,
        colleagues,
        pagination,
        notColleagues,
        incomingColleagues,
        outgoingColleagues,
        next,
        getTabsCount,
        addColleague,
        removeColleague,
        fetchColleagues,
        fetchNotColleagues,
        acceptIncomingRequest,
        fetchIncomingColleagues,
        fetchOutgoingColleagues,
        cancelOutgoingRequest,
        rejectIncomingRequest,
    }
}
