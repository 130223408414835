

import { defineComponent, ref, watch, onMounted, } from 'vue'
import { useUserProfileData, } from '@/composition/userProfile';
import { useCommmonNotification, } from '@/composition/notification';
export default defineComponent({
    name: 'SettingsGeneral',
    setup() {
        const {getUserProfileData, updateUserProfileData, } = useUserProfileData()
        const showInPublic = ref(false)
        watch(getUserProfileData, () => {
            if (getUserProfileData) {
                showInPublic.value = !getUserProfileData.value.hidden_in_public
            }
        }, { deep: true, })
        const loading = ref(false)
        const {showNotification, hideNotification, } = useCommmonNotification()
        const save = async () => {
            loading.value = true
            try {
                await updateUserProfileData({'hidden_in_public': !showInPublic.value, })
                showNotification('Успешно сохранено')
                setTimeout(hideNotification, 2000)
            } catch (e) {

            }

            loading.value = false
        }

        onMounted(()=> {
            if (getUserProfileData)
                showInPublic.value = !getUserProfileData.value.hidden_in_public
        })

        const buttonParams = {
            width: '144px',
        }
        return {
            showInPublic,
            save,
            getUserProfileData,
            loading,
            buttonParams,
        }
    },
});
