<template>
    <div class="chart">
        <vue3-chart-js v-if="width > 780" v-bind="{ ...barChart }" width="100%" height="auto"/>
        <vue3-chart-js v-else v-bind="{ ...miniBarChart }" width="100%" height="auto"/>
        <div v-if="width <= 780" class="chart__description" :class="defineClass">Для просмотра процента решения вопросов в рамках профессиональной компетенции нажмите на нужную фиолетовую точку</div>
    </div>
</template>

<script>
// component
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import {ref, computed,} from 'vue';

export default {
    name: 'Chart',
    components: {
        Vue3ChartJs,
    },
    props: {
        chartData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const width = ref(window.innerWidth)
        const updateWidth = () => {
            width.value = window.innerWidth;
        }
        const max = props.chartData.map(a => a.max)
        const titleTooltip = (tooltipItem) => {
            return 'В рамках данной компетенции вопросов решено '
        }
        const defineClass = computed(() => {
            if (props.chartData.length === 2 || props.chartData.length === 4 || props.chartData.length === 5 || props.chartData.length === 6) {
                return 'chart__description-relative'
            } else {
                return 'chart__description-absolute'
            }
        })
        window.addEventListener('resize', updateWidth);
        const options = {
            maintainAspectRatio: false,
            scale: {
                display: true,
                min: 0,
                max: 100,
                stepSize: 25,
                ticks: {
                    showLabelBackdrop: false,
                },
            },
            scales: {
                r: {
                    pointLabels: {
                        font: {
                            size: 14,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: titleTooltip,
                        font: {
                            size: 14,
                        },
                        label: function(context) {
                            const label = `${context.label}: ${context.formattedValue}%`
                            return label;
                        },
                    },
                },
            },
        };
        const optionsForMini = {
            maintainAspectRatio: false,
            elements: {
                point: {
                    radius: 4,
                },
            },
            scale: {
                display: true,
                min: 0,
                max: 100,
                stepSize: 25,
                font: {
                    size: 16,
                },
                ticks: {
                    showLabelBackdrop: false,
                },
            },
            scales: {
                r: {
                    pointLabels: {
                        font: {
                            size: 0,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: titleTooltip,
                        font: {
                            size: 14,
                        },
                        label: function(context) {
                            const label = `${context.label}: ${context.formattedValue}%`
                            return label;
                        },
                    },
                },
            },
        };
        const data = {
            labels: props.chartData.map(a => a.competence),
            datasets: [
                {
                    data: props.chartData.map(a => a.my),
                    borderColor: 'rgb(142, 28, 113)',
                    backgroundColor: 'rgba(143, 28, 113, 0.1)',
                }
            ],
        };
        const dataForMini = {
            labels: props.chartData.map(a => a.competence),
            datasets: [
                {
                    data: props.chartData.map(a => a.my),
                    borderColor: 'rgb(142, 28, 113)',
                    backgroundColor: 'rgba(143, 28, 113, 0.1)',
                    pointBackgroundColor: 'rgb(142, 28, 113)',
                    pointHoverBackgroundColor: '#fff',
                }
            ],
        };
        const barChart = {
            type: 'radar',
            data: data,
            options: options,
            
        };
        const miniBarChart = {
            type: 'radar',
            data: dataForMini,
            options: optionsForMini,
        };
        
        return {
            barChart,
            miniBarChart,
            defineClass,
            width,
        }
    },
};
</script>

<style lang="scss" scoped>
.chart {
    position: relative;
    &__description {
        font-weight: 500;
        font-size: 16px;
        @media (max-width: 450px) {
            font-size: 14px;
        }
    }
    &__description-absolute {
        position: absolute;
        top: 86%;
        @media (max-width: 450px) {
            top: 88%;
        }
    }
    &__description-relative {
        position: relative;
        margin-top: 15px;
    }
}
canvas {
    max-height: 650px;
    min-height: 450px;
    @media (max-width: 780px) {
        min-height: 400px;
        max-height: 450px;
    }
    @media (max-width: 450px) {
        max-height: 320px;
        min-height: 320px;
    }
}
</style>