<template lang="pug">
button.mob-burger(@click="setDrawerVisibility")(:class="{ 'mob-burger_active' : drawerVisibility }")
    span.mob-burger__line
    span.mob-burger__line
    span.mob-burger__line
</template>

<script>

export default {
    name: 'Burger',
    props: {
        drawerVisibility: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        setDrawerVisibility(){
            this.$emit('set-drawer-visibility', this.drawerVisibility);
        },
    },
}
</script>

<style lang="scss" scoped>
    .mob-burger {
        display: none;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
        background: none;
        position: relative;
        outline: none;
        z-index: 3;
        margin-left: 32px;

        &:hover {
            opacity: 0.8;
        }

        @media (max-width: $media-aside-menu-goes-to-burger) {
            display: flex;
        }
    }

    .mob-burger__line {
        width: 100%;
        height: 2px;
        background: $dark-color;
        position: absolute;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        transition: .3s all ease;
    }

    .mob-burger__line:nth-child(1) {
        top: 2px;
        left: 0;
    }

    .mob-burger__line:nth-child(2) {
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
    }

    .mob-burger__line:nth-child(3) {
        bottom: 2px;
        left: 0;
    }

    .mob-burger_active {
        width: 24px;
        height: 24px;
    }

    .mob-burger_active .mob-burger__line:nth-child(1) {
        top: 50%;
        transform: translate(0, -50%) rotate(45deg) scaleX(1.2);
    }

    .mob-burger_active .mob-burger__line:nth-child(2) {
        opacity: 0;
    }

    .mob-burger_active .mob-burger__line:nth-child(3) {
        bottom: 50%;
        transform: translate(0, 50%) rotate(-45deg) scaleX(1.2);
    }

</style>
