
import {defineComponent, ref,} from 'vue'

import {tabs,} from '@/modules/constants/tabs'
import {useSearch,} from '@/composition/search'

import StaffList from '@/components/School/Staff/StaffList.vue'
import Tabs from '@/components/Generic/tabs/index.vue'
import SearchBlock from '@/components/Generic/search-block/index.vue'

export default defineComponent({
    name: 'SchoolStaff',
    components: {
        StaffList,
        Tabs,
        SearchBlock,
    },

    setup() {
        const tabItems = ref(tabs.staff)

        const activeTab = ref<number>(0)

        const filter = ref<number>(0)

        const changeTab = (tab: number) => {
            filter.value = tab;
            activeTab.value = tab;
        }

        const updateCount = (counts: number[]) => {
            tabItems.value.forEach((el, index) => {
                el.count = counts[index]
            })
        }

        const updateCountCurrent = (count: number) => {
            tabItems.value[activeTab.value].count = count
        }

        const {searchString, onSearch,} = useSearch()

        return {
            tabItems,
            activeTab,
            filter,
            updateCount,
            updateCountCurrent,
            changeTab,
            searchString,
            onSearch,
        }
    },
});
