<template lang="pug">
transition(name="fade" mode="out-in")
    .container-tests(v-if="!loadingHook")
        ResultList(
            :dataTest="items"
            :isUploading="isUploading"
            :visibleMoreBtn="visibleMoreBtn"
            :activeInputOnSearch="activeInputOnSearch"
            @load-data="loadData"
            @load-data-on-search="loadDataWithSearch"
            @open-modal-survey="openModal")

        ModalWindow(
            :is-visible="isModalVisible"
            isBig="true"
            :title="modalInfoData.Header"
            :addInput="false"
            @close="closeModalInfo"
        )
            ModalInfo(:modalData="modalInfoData" @on-modal-close="closeModalInfo")
    .container-tests-loading(v-else)
        LoadingIndicator(v-if="isUploading")
        p.par(v-else) Завершенных опросов нет

</template>

<script>
import {onMounted, defineComponent, ref, computed,} from 'vue'

// composition
import {useResultSurveys,} from '@/composition/schoolSurveys';
import { useCommmonNotification, } from '@/composition/notification';
import {useModal,} from '@/composition/modal';

// components
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'
import ResultList from '@/components/School/Survey/ResultList.vue'

import { get, } from '@/modules/http'

export default defineComponent({
    name: 'SchoolTestResult',
    components: {
        LoadingIndicator,
        ModalWindow,
        ModalInfo,
        ResultList,
    },
    setup(_, {emit, }) {
        const { showNotification, } = useCommmonNotification()
        const {
            isModalVisible,
            onModalClose,
        } = useModal()
        const {
            resultSurveysRead,
        } = useResultSurveys()       
        
        const data = ref([])
        const modalInfoData = ref({})
        const next = ref('')
        const visibleMoreBtn = ref(true)
        const isUploading = ref(false)
        const activeInputOnSearch = ref(false)
        const loadingHook = ref(true)
        const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}` 
        
        const fetchNext = (response) => {
            if (response.next !== null) {
                next.value = response.next.replace(apiURL, '')
                visibleMoreBtn.value = true
                return next.value
            }
            next.value = response.next
            visibleMoreBtn.value = false
            return next.value
        }
        const loadDataWithSearch = async(string) => {
            try {
                visibleMoreBtn.value = false
                activeInputOnSearch.value = true
                isUploading.value = true
                const response = await resultSurveysRead(string)
                data.value = response.results
                fetchNext(response)
                isUploading.value = false
                activeInputOnSearch.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
                activeInputOnSearch.value = false
                loadingHook.value = true
            }
        }
        const loadData = async() => {
            try{
                isUploading.value = true
                const response = await get(next.value)            
                data.value = data.value.concat(response.parsedBody.results)
                fetchNext(response.parsedBody)
                isUploading.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
                loadingHook.value = true
            }
        }

        // modal
        const openModal = async (info) => {
            modalInfoData.value = info
            isModalVisible.value = true
        }
        const closeModalInfo = () => {
            isModalVisible.value = false
            document.documentElement.style.overflow = 'visible';
        }

        // mounted
        onMounted(async (s) => {
            try {
                isUploading.value = true
                const response = await resultSurveysRead('')
                data.value = response.results
                fetchNext(response)
                if (response.results.length > 0) {
                    loadingHook.value = false
                }
                isUploading.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
            }
        });

        return {
            resultSurveysRead,
            isUploading,
            activeInputOnSearch,
            visibleMoreBtn,
            modalInfoData,
            isModalVisible,
            onModalClose,
            closeModalInfo,
            loadingHook,
            items: computed(() => {
                return data.value.map(item => ({
                    id: item.id,
                    employee: item.user.full_name,
                    teacher_id: item.user.id,
                    text: item.survey.title,
                    auditoryes: item.auditoryes.length > 0 ? item.auditoryes : '-',
                    date_end: item.end_date,
                    data: item,
                }))
            }),
            loadData,
            loadDataWithSearch,
            openModal,                               
        }
    },
})
</script>