export const minLength = inputValue => {
    return  inputValue.length < 4
        ? 'Значение должно быть не менее 4 символов'
        : null
}

export const minLengthPass = inputValue => {
    return  inputValue.length < 8
        ? 'Пароль должен содержать не менее 8 символов'
        : null
}


export const maxLength = inputValue => {
    return  inputValue.length > 6
        ? 'Значение должно быть не более 6 символов' : null
}

export const isPhone = (inputValue) => {
    const regEx = /^(\+7)?[\s\-]\(?[489][0-9]{2}\)?[\s\-][0-9]{3}[\s\-][0-9]{2}[\s\-][0-9]{2}$/
    return regEx.test(inputValue) ? null : 'Некорректно введен номер телефона'
}

export const isRepresentativePhone = (inputValue) => {
    if (inputValue.length === 0) return null;
    const regEx = /^(\+7)?[\s\-]\(?[489][0-9]{2}\)?[\s\-][0-9]{3}[\s\-][0-9]{2}[\s\-][0-9]{2}$/
    return regEx.test(inputValue) ? null : 'Некорректно введен номер телефона'
}

export const isEmail = (inputValue) => {
    if (!!inputValue === false) return "Поле не может быть пустым";
    const regEx = /\S+@\S+\.\S+/
    return regEx.test(inputValue) ? null : 'Неверно введен email-адрес'
}

export const isRepresentativeEmail = (inputValue) => {
    if (inputValue.length === 0) return null;
    const regEx = /\S+@\S+\.\S+/
    return regEx.test(inputValue) ? null : 'Неверно введен email-адрес'
}

export const isNumber = (inputValue) => {
    const regEx = /^\d+$/
    return  regEx.test(inputValue) ? null : 'Код должен содержать только цифры'
}

export const isNotOnlyNumbers = (inputValue) => {
    const regEx = /^\d+$/
    return  regEx.test(inputValue) ? 'Пароль не должен содержать только цифры' : null
}

export const isHaveSpecChars = (inputValue) => {
    const regEx = /(?=.*[!@#$%^&*_])/
    return regEx.test(inputValue) ? null : 'Пароль должен содержать хотя бы один символ !@#$%^&*'
}

export const isHasLoverCase = (inputValue) => {
    const regEx = /(?=.*[a-z])/
    return regEx.test(inputValue) ? null : 'Пароль должен содержать символы в нижнем регистре'
}

export const isBadPassword = (inputValue) => {
    const regEx = /(?=.*[А-я\s])/
    const res = !regEx.test(inputValue)

    return res ? null : 'Пароль должен содержать только латинские символы и не должен содержать пробелов'
}

export const isHasUpperCase = (inputValue) => {
    const regEx = /(?=.*[A-Z])/
    return regEx.test(inputValue) ? null : "Пароль должен содержать минимум одну заглавную букву";
}

export const isNotEmpty = (inputValue) => {
    if (inputValue == null) return 'Поле не может быть пустым'
    return inputValue.length > 0 ? null : 'Поле не может быть пустым'
}

export const isNotValidSnils = (inputValue) => {
    if (inputValue.length === 0) return null
    if (inputValue.length !== 0 && inputValue.length !== 14) {
        return 'СНИЛС должен содержать 11 цифр'
    }
}

export const isNotValidDateOfBirth = (inputValue) => {
    if (!inputValue) return null
    if (inputValue.length !== 0 && inputValue.length !== 10) {
        return 'Заполните полностью дату родения'
    }
}
export class ValidationError extends Error {
    fields;
    constructor(fields) {
        super();
        this.fields = fields
    }
}
export default  {
    minLength,
    minLengthPass,
    maxLength,
    isPhone,
    isEmail,
    isNumber,
    isNotOnlyNumbers,
    isNotEmpty,
    isHaveSpecChars,
    isHasLoverCase,
    isHasUpperCase,
    isBadPassword,
    isNotValidSnils,
    isNotValidDateOfBirth,
    ValidationError,
}
