<template lang="pug">
template(v-if="route.path == '/diagnostics/cases/completed'")
    template(v-if="!loadingHook")
        template(v-if="items.length !== 0")
            Table(:headers="headersCompleted" :items="items" :rowHover="true" @rowClick="onRowClick" :is_hover="true")
            .more-btn-block__for-table(v-if="visibleMoreBtn")
                btn.btn--outline(v-if="!isUploading"
                    text="Показать еще" 
                    @mousedown="upload")
                LoadingIndicator(v-else)
        template(v-else)
            p.par Новых тестов нет            
        template(v-else)
            p.par Завершенных кейсов нет 
    template(v-else)
        .loading-indicator
            LoadingIndicator
router-view
</template>

<script>
import {onMounted, ref, computed, } from 'vue';
import {useRoute, } from 'vue-router'

// components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

// compositions
import {useCase,} from '@/composition/diagnosticCase';

export default {
    components: {
        Table,
        LoadingIndicator,
    },
    emits: ['on-modal-open'],
    setup(_, {emit,  }) {
        const route = useRoute()
        const {
            caseList,
            caseRead,
            caseResultRead,
            caseResultList,
            checkNextUrl,
            loadData,            
            headersCompleted,
            modalInfoData,
            visibleMoreBtn,
            isUploading,             
        } = useCase();
        
        // const
        const data = ref([])
        const loadingHook = ref(true)
        // function
        onMounted(async () => {
            emit('modal-close', false)
            const response = await caseList('completed');
            data.value = response.results
            checkNextUrl(response)
            loadingHook.value = false
        });
        const onRowClick = async (row) => {
            const response = await caseRead(row.id)
            modalInfoData.value.modal.Header = 'Информация о кейсе'
            modalInfoData.value.modal.HeaderAccept = `Вы уверены, что хотите начать решать кейс "${response.name}"?`
            modalInfoData.value.modal.HeaderQuiz = 'Решение кейса'
            modalInfoData.value.modal.HeaderDecline = 'Вы уверены, что хотите прекратить решать кейс? Ваши результаты будут сохранены. Вы сможете продолжить решать кейс позднее.'
            modalInfoData.value.type = 'case'
            modalInfoData.value.name = response.name
            modalInfoData.value.annotation = response.annotation !== null ? response.annotation : ''
            modalInfoData.value.attempting.number = response.attempting.number
            modalInfoData.value.attempting.max_attempting = response.attempting.max_attempting
            modalInfoData.value.attempting.next = response.attempting.next
            modalInfoData.value.status_for_user = response.status_for_user.last
            modalInfoData.value.connectId = response.status_for_user.id
            modalInfoData.value.connectIdTwo = ''
            modalInfoData.value.score = row.score
            modalInfoData.value.id = row.id
            modalInfoData.value.count_questions = response.count_questions
            modalInfoData.value.answer_content_type_id = response.answer_content_type_id
            modalInfoData.value.subject_area = row.subject_area
            modalInfoData.value.competence = row.competence
            modalInfoData.value.questions = response.questions                   
            emit('on-modal-open', modalInfoData.value)
        }
        const upload = () => loadData(data)

        return {
            // useCase
            caseList,
            caseResultRead,
            caseResultList,
            headersCompleted,

            // const
            loadingHook,
            visibleMoreBtn,
            isUploading,            
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    text: x.name,
                    subject_area: x.subject_area,
                    competence: x.competence,
                    attempting: x.attempting.number + ' из ' + x.attempting.max_attempting,
                    score: x.points !== null ? `${x.points} из 5` : 'На проверке',
                }))
            }),
            route,
            // function
            onRowClick,
            checkNextUrl,
            upload,
        }
    },
}
</script>

<style scoped>
</style>
