
import {defineComponent, PropType, } from 'vue'

import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'
import FieldBlockInputError from '@/components/Generic/field-block-input-error/index.vue'
// import Btn from '@/components/Generic/btn/index.vue'

export default defineComponent({
    name: 'FieldBlock',
    components: {
        FieldBlockInput,
        FieldBlockInputError,
        // Btn,
    },
    props: {
        fields: {
            type: Object as PropType<object>,
            required: true,
        },
        submitText: {
            type: String as PropType<string>,
            default: () => {
                return 'Отправить'
            },
        },
        isSubmitDisabled: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        isFormDisabled: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        loading: {
            type: Boolean as PropType<boolean>,
        },
        nonFieldErrors: {
            type: Array,
            default: () => [],
        },
        buttonParams: {
            type: Object as PropType<object>,
            default: () => {{}},
        },
        className: {
            type: String as PropType<string>,
            default: () => {
                return 'field-block__form'
            },
        },
    },
    setup(props, { emit,}) {
        const onSubmit = ($event) => {
            emit('submit', $event)
        }
        return { onSubmit,}
    },
});
