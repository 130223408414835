
import {ref, defineComponent,} from 'vue'
import { useRouter, } from 'vue-router'

//impport modules
import {tabs,} from '@/modules/constants/tabs'

//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'

//import composition
import {useTabs,} from '@/composition/tabs'
import {useUserProfileData,} from '@/composition/userProfile'
import {useCKed, } from '@/composition/wys';
import {useModal,} from '@/composition/modal';
import {useAudit,} from '@/composition/audit';

export default defineComponent({
    components: {
        Tabs,
        TitleBlock,
        ModalWindow,
        ModalInfo,
    },
    setup() {
        const router = useRouter()
        const {isOnModerationIcon,} = useUserProfileData()
        const {tabItems, activeTab,} = useTabs(tabs.audit)
        const {setOnModerationIcon,} = useUserProfileData()
        setOnModerationIcon(false)
        // Composition
        const {
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
        } = useModal()
        const {
            modalInfoData,
            acceptBtns,
        } = useAudit();
        //const 
        const modalInfoBtn = ref()
        const status = ref()
        // function
        const checking = () => {            
            isModalVisible.value = false
            router.push(`/audit/process/check/${modalInfoData.value.id}`)
        }
        const result = () => {
            isModalVisible.value = false
            router.push(`/audit/completed/result/${modalInfoData.value.id}`)
        }
        const openModal = (response) => {
            modalInfoData.value = response 
            isModalVisible.value = true            
            status.value = modalInfoData.value.status
            if (status.value == '2') modalInfoBtn.value = [
                {
                    text: 'Проверить кейс',
                    event: 'checking-case',
                    class: '',
                }
            ] 
            if (status.value == '3') modalInfoBtn.value = [
                {
                    text: 'Проверить кейс',
                    event: 'checking-case',
                    class: '',
                }
            ]  
            if (status.value == '4') modalInfoBtn.value = [
                {
                    text: 'Посмотреть данные о проверке',
                    event: 'result-case',
                    class: '',
                }
            ]     
        }
        return {
            tabItems,
            activeTab,
            isOnModerationIcon,
            ...useCKed(),
            // emit func
            openModal,
            //useAudit
            modalInfoData,
            acceptBtns,
            // modal info
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
            // const
            modalInfoBtn,
            // func
            checking,
            result,
        }
    },

})


