<template lang="pug">
template(v-if="data && route.path !== '/audit/process'")
    a.select.login-block__back-link.profile__form__back-link(@click="$router.push({path: `/audit/process`})" )
        img(src="@/assets/img/back.svg")
        span Вернуться
    h4 {{ data.case.name }}
    div.result
        fieldset
            legend Попытка
            div {{data.attempting}} из {{data.case.max_attempting}}
        fieldset
            legend Пользователь
            div {{data.user.full_name}}
    Collapse(v-for="(item, index) in data.caseanswer_set" )
        template(#title="") Вопрос {{ index + 1}}
        template(#subtitle="") {{ item.question_text }}
        template(#content="")
            .teacher-answer
                .teacher-answer__text(v-if="item.answer.length > 0")
                    b Ответ пользователя
                    .wysiwyg-block(v-html="item.answer")
                .teacher-answer__docs(v-if="item.files.length > 0" :class="[item.answer.length > 0 ? 'teacher-answer__docs-mt' : 'teacher-answer__docs-mt--none']")
                    b Документы пользователя
                    .teacher-answer__doc(v-for="file in item.files")
                        a(:href="`${file.absolute_url}`" target="_blank")
                            span {{file.name}}
            //- div(v-html="htmlContent")
            b Комментарий
            //- div {{ item.comment }}
            .field
                ckeditor(
                    :editor="editor"
                    v-model="item.comment"
                    :config="editorConfig"
                )
            btn( text="Сохранить" class="btn btn--do-action" @click="saveAnswer(item)" :disabled="!item.comment")
    .recomend
        b Общие рекомендации
        .field
            ckeditor(
                :editor="editor"
                v-model="recomendation"
                :config="editorConfig"
            )
        b Итоговые баллы
        //- div {{points}}
        .radio__container
            label.field-block__label.field-block__label-radio
                input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" value="1" v-model="points")
                span.field-block__radio
                span 1
            label.field-block__label.field-block__label-radio
                input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" value="2" v-model="points")
                span.field-block__radio
                span 2
            label.field-block__label.field-block__label-radio
                input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" value="3" v-model="points")
                span.field-block__radio
                span 3
            label.field-block__label.field-block__label-radio
                input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" value="4" v-model="points")
                span.field-block__radio
                span 4
            label.field-block__label.field-block__label-radio
                input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" value="5" v-model="points")
                span.field-block__radio
                span 5
        div
            btn( text="Отправить рекомендации пользователю" @click="completeCheck(), $router.push({path: '/audit/completed'})" :loading="loading" class="btn btn--do-action" :disabled="!recomendation || !points || disabled")
</template>

<script>
import {defineComponent, ref, computed,} from 'vue'
import {useRoute, useRouter,} from 'vue-router'
// component
import Collapse from '@/components/Generic/collapse/collapse.vue'
// composition
import {useAudit,} from '@/composition/audit'
import { useCKed, } from '@/composition/wys';
import {useCommmonNotification,} from '@/composition/notification'

export default defineComponent({
    components: {
        Collapse,
    },
    setup() {
        document.documentElement.style.overflow = 'auto';
        const {showNotification,} = useCommmonNotification();
        const route = useRoute();
        const router = useRouter();

        // const
        const recomendation = ref()
        const points = ref()
        const data = ref(null)
        const loading = ref(false)
        // func
        const { 
            caseCheckRead, 
            case_answer_comment_update, 
            case_check_status_on_check, 
            case_check_update,
        } = useAudit();
        // my events
        const getData = async () => {
            const response = await caseCheckRead(route.params.id);
            data.value = response
            await case_check_status_on_check(route.params.id)
            if (response === null) {
                router.push("/")
            }
        };
        const saveAnswer = async (item) => {
            await case_answer_comment_update(item.id, item);
            showNotification('Сохранено');
        } 
        const completeCheck = async () => {
            loading.value = true
            const data = {
                id: route.params.id,
                points: points.value,
                recommendation: recomendation.value,
            }
            await case_check_update(route.params.id, data);
            showNotification('Ваши рекомендации отправлены');
            loading.value = false
        }
        getData()

        const disabled = computed(() => {
            const answers = ref([])
            for (const answer of data.value.caseanswer_set) {
                answer.comment.length === 0 ? answers.value.push(true) : answers.value.push(false)
            }
            return answers.value.includes(true);
        })
        const htmlContent = '<h1 style="color:red">Hello Gowtham</h1><i>t<span style="color: red">e<span style="color: green">s</span></span>t</i><b>Hello!</b>'
        return {
            route,
            // consts
            data,
            recomendation,
            points,
            loading,
            // func
            saveAnswer,
            completeCheck,
            ...useCKed(),
            htmlContent,
            // computed
            disabled,
        }
    },
})
</script>


<style lang='scss' scoped>
h4 {
    font-size: 21px;
    line-height: 140%;
    margin-bottom: 30px;
    font-weight: bold;
    color: #1B1B1A;
}
.result {
    display: flex;

}
fieldset {
    margin-bottom: 30px;
    margin-right: 30px;
    border: none;
    line-height: 25px;
    font-weight: 500;
    font-size: 16px;
    legend {
        font-size: 13px;
        color: #6D6D6D;
    }
    div {
    }
}
@media screen and (max-width:1035px)  {}
@media screen and (max-width:750px) {
    h4 {
        font-size: 18px;
    }
}
.recomend {
    display: flex;
    flex-direction: column;
}
p {
    margin-top: 5px;
    margin-bottom: 20px; 
}
b {
    max-height: 22px;
}

.radio__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 20px;
}
.field-block__label {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    margin-right: 20px;
}
.field-block__radio {
    margin: 0;
}

.field {
    margin-top: 20px;
    margin-bottom: 30px; 
}
h1 {
    margin-left: 20px;
}
.teacher-answer {
    margin-bottom: 1rem;
    &__docs-mt {
        margin-top: 1rem;
    }
    &__docs-mt--none {
        margin-top: 0;
    }
    &__doc {
        &:nth-last-of-type(1) {
            margin-top: 13px;
        }
        a {
            cursor: pointer;
            text-decoration: underline;
            color: $mainFontColor;
            &:hover {
                color: $accent-color-hover;
                text-decoration: none;
            }
        }
    }
}
.wysiwyg-block {
    margin-top: 13px;
    &:last-child {
        margin-bottom: 0;
    }
}
</style>
