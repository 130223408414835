<template lang="pug">
svg(version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 0 0' xml:space='preserve')
      circle(fill='#8F1C70' stroke='none' cx='31' cy='50' r='6')
        animate(attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.1')
      circle(fill='#8F1C70' stroke='none' cx='51' cy='50' r='6')
        animate(attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.2')
      circle(fill='#8F1C70' stroke='none' cx='71' cy='50' r='6')
        animate(attributeName='opacity' dur='1s' values='0;1;0' repeatCount='indefinite' begin='0.3')
</template>

<script>
import {defineComponent,} from 'vue'

export default defineComponent({
    name: 'LoadingIndicator',
})
</script>


<style lang="scss" scoped>
    svg {
        width: 100px;
        height: 100px;
        display: inline-block;
        align-self: center;
    }
</style>
