import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lk-page container container_aside" }
const _hoisted_2 = { class: "aside-cont" }
const _hoisted_3 = { class: "lk-main" }
const _hoisted_4 = { class: "event" }
const _hoisted_5 = { class: "container-lk" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_myHeader = _resolveComponent("myHeader")
  const _component_lkAside = _resolveComponent("lkAside")
  const _component_titleBlock = _resolveComponent("titleBlock")
  const _component_tabs = _resolveComponent("tabs")
  const _component_router_view = _resolveComponent("router-view")
  const _component_myFooter = _resolveComponent("myFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_myHeader),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_lkAside)
      ]),
      _createVNode("main", _hoisted_3, [
        _createVNode("section", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_titleBlock, {
              labelCount: _ctx.eventsCount,
              searchBlock: "",
              onOnSearch: _ctx.goSearch,
              onOnClear: _ctx.goSearch
            }, {
              default: _withCtx(() => [
                _createTextVNode("Мои " + _toDisplayString(_ctx.eventTypeText), 1 /* TEXT */)
              ]),
              _: 1
            }, 8 /* PROPS */, ["labelCount", "onOnSearch", "onOnClear"]),
            _createVNode(_component_tabs, {
              class: "tab-container--underline",
              "tab-items": _ctx.tabItems,
              "active-tab": _ctx.activeTab
            }, null, 8 /* PROPS */, ["tab-items", "active-tab"]),
            _createVNode(_component_router_view, {
              "search-string": _ctx.searchString,
              onEventsLoaded: _ctx.onEventsLoaded,
              onEventsChanged: _ctx.onEventsChanged
            }, null, 8 /* PROPS */, ["search-string", "onEventsLoaded", "onEventsChanged"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_myFooter)
  ], 64 /* STABLE_FRAGMENT */))
}