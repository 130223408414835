
import {computed, defineComponent, ref,} from 'vue'
import {useRoute,} from 'vue-router'

//import types
import {Practice,} from '@/types/common';

//WYSIWIG
//import func
import {commonFields,} from '@/modules/fields'
import {get, post, postFormData, put,} from '@/modules/http'

//import composition
import {useCommmonNotification,} from '@/composition/notification'
import {sleep,} from '@/modules/helpers';

//import components
import FieldBlockInput from '@/components/Generic/field-block-input/index.vue'
import {useCKed,} from '@/composition/wys';
import BaseAttached from '@/components/Generic/attached/index.vue';
import SelectListInput from '@/components/Generic/select-list-input/index.vue'
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'
import SelectWithSearch from '@/components/Generic/select-with-search/index.vue';
import ProfilePracticesGroup from '@/components/Profile/profile-practice-group/index.vue';
import ProfileImageLoader from '@/components/Profile/profile-image-loader/index.vue'


export default defineComponent({
    name: 'ProfilePracticesOpen',
    components: {
        BaseAttached,
        FieldBlockInput,
        SelectListInput,
        TrashIcon,
        SelectWithSearch,
        ProfilePracticesGroup,
        ProfileImageLoader,
    },
    setup() {
        const {showNotification,} = useCommmonNotification();

        const inputField = ref<HTMLElement>({} as HTMLElement);
        const formElement = ref<HTMLElement>({} as HTMLElement);
        const buttons = [
            {
                text: 'Ok',
                event: 'close',
            }
        ];
        commonFields.name.modifier = '--fullwidth';
        const nameField = ref(commonFields.name);
        nameField.value.modifier = '--fullwidth'

        const isModalVisible = ref(false);

        const onModalClose = () => {
            isModalVisible.value = false;
        };
        const practice = ref({} as Practice);

        const route = useRoute();

        const isLoading = ref(false);
        const isSaving = ref(false);

        const id = computed(() => {
            return route.params.id;
        });
        const fetchPractice = async () => {
            const response = await get<Practice>(`/profile/practices/${id.value}/`);

            isLoading.value = true;
            if (response.ok) {
                practice.value = response.parsedBody;
                nameField.value.inputValue = practice.value.title;
            } else {
                throw new Error(response.statusText);
            }
        };
        const getValueAfterUpdate = (fields) => {
            practice.value.additional_material = fields.additional_material
            practice.value.how = fields.how
            practice.value.idea = fields.idea
            practice.value.material = fields.material
        }

        fetchPractice();

        isLoading.value = true;
        const isFileUploading = ref<boolean>(false);
        async function postFile(file) {
            isFileUploading.value = true;
            const formData = new FormData();
            const oldPractice = practice.value
            formData.append('file', file.files[0]);
            formData.append('content_type_id', String(practice.value.content_type_id));
            formData.append('object_id', String(practice.value.id));

            const response = await postFormData('/upload_file/', formData)
            isFileUploading.value = false;
            if (response.ok) {
                await fetchPractice();
            } else {
                throw new Error(response.statusText);
            }
            getValueAfterUpdate(oldPractice)
            file.value = ''
        }
        const onFileDelete = async (id: number) => {
            const oldPractice = practice.value
            const response = await post(
                '/profile/practices/practice_file_remove/',
                {
                    file_id: id,
                }
            );
            if (response.ok) {
                showNotification('Файл удален');
                await fetchPractice();
            } else {
                showNotification('Ошибка удаления файла', 'error');
            }
            getValueAfterUpdate(oldPractice)
        };
        const savePractice = async () => {
            isLoading.value = true;
            isSaving.value = true;
            if (nameField.value.inputValue.trim() === '') {
                nameField.value.errorMessages = ['Поле не может быть пустым'];
                window.scrollTo(0, inputField.value.offsetTop);
                return;
            }
            practice.value.title = nameField.value.inputValue;


            const response = await put<Practice>(
                `/profile/practices/${id.value}/`,
                {
                    ...practice.value,
                    groups: practice.value.groups.map(x => x.id),
                }
            );
            isLoading.value = false;
            if (response.ok) {
                practice.value = response.parsedBody;
                showNotification('Изменения по практике сохранены');
                window.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
                await sleep(2000);
            } else {
                showNotification('Ошибка сохранения', 'error');
            }
            isSaving.value = false;
        };

        // start
        const groups = ref(false)
        const checkGroups = (data) => {
            if (data.value.length != 0) {
                groups.value = true
            } else {
                groups.value = true
            }
        }
        const isSaveButtonDisabled = computed(() => {
            return !practice?.value?.groups?.length || !practice?.value?.groups.every(x => x.id);
        });
        const updateImage = (newImage: string | null) => {
            practice.value.image = newImage;
        }
        return {
            practice,
            isLoading,
            isSaving,
            savePractice,
            buttons,
            onModalClose,
            isModalVisible,
            nameField,
            formElement,
            postFile,
            ...useCKed(),
            checkGroups,
            groups,
            updateImage,
            onFileDelete,
            isSaveButtonDisabled,
            isFileUploading,
        }
    },
});
