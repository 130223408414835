<template lang="pug">
transition(name="fade" mode="out-in")
    .container-tests(v-if="!loadingHook")
        ResultList(
            :dataTest="items"
            :isUploading="isUploading"
            :visibleMoreBtn="visibleMoreBtn"
            :activeInputOnSearch="activeInputOnSearch"
            @load-data="loadData"
            @load-data-on-search="loadDataWithSearch"
            @open-modal-test="openModal")

        ModalWindow(
            :is-visible="isModalVisible"
            isBig="true"
            :title="modalInfoData.Header"
            :buttons="modalInfoBtn"
            :addInput="false"
            @close="closeModalInfo"
            @ok="goDetailTest"
        )
            ModalInfo(:modalData="modalInfoData" @on-modal-close="closeModalInfo")
    .container-tests-loading(v-else)
        LoadingIndicator(v-if="isUploading")
        p.par(v-else) Завершенных тестов нет

</template>

<script>
import {onMounted, defineComponent, ref, computed,} from 'vue'
import { useRouter, } from 'vue-router'

import {useResultTests,} from '@/composition/schoolTests';
import { useCommmonNotification, } from '@/composition/notification';
import {useModal,} from '@/composition/modal';

import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'
import ResultList from '@/components/School/Test/ResultList.vue'

import { get, } from '@/modules/http'

export default defineComponent({
    name: 'SchoolTestResult',
    components: {
        LoadingIndicator,
        ModalWindow,
        ModalInfo,
        ResultList,
    },
    setup(_, {emit, }) {
        const router = useRouter()
        const { showNotification, } = useCommmonNotification()
        const {
            isModalVisible,
            onModalClose,
        } = useModal()

        const {
            resultTestsRead,
            downloadTestResults,
        } = useResultTests()

        const data = ref([])
        const modalInfoData = ref({})
        const next = ref('')
        const modalInfoBtn = [{
            text: 'Посмотреть ответы сотрудника',
            event: 'ok',
            class: '',
        }]
        const visibleMoreBtn = ref(true)
        const isUploading = ref(false)
        const activeInputOnSearch = ref(false)
        const loadingHook = ref(true)
        const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`
        
        const fetchNext = (response) => {
            if (response.next !== null) {
                next.value = response.next.replace(apiURL, '')
                visibleMoreBtn.value = true
                return next.value
            }
            next.value = response.next
            visibleMoreBtn.value = false
            return next.value
        }

        const loadDataWithSearch = async(string) => {
            try {
                visibleMoreBtn.value = false
                activeInputOnSearch.value = true
                isUploading.value = true
                const response = await resultTestsRead(string)
                data.value = response.results
                fetchNext(response)
                isUploading.value = false
                activeInputOnSearch.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
                activeInputOnSearch.value = false
                loadingHook.value = true
            }
        }

        const loadData = async() => {
            try{
                isUploading.value = true
                const response = await get(next.value)            
                data.value = data.value.concat(response.parsedBody.results)
                fetchNext(response.parsedBody)
                isUploading.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
                loadingHook.value = true
            }
        }

        const openModal = async (info) => {
            modalInfoData.value = info
            isModalVisible.value = true
        }

        const closeModalInfo = () => {
            isModalVisible.value = false
            document.documentElement.style.overflow = 'visible';
        }

        const goDetailTest = () => {
            document.documentElement.style.overflow = 'visible';
            router.push(`/school/test/${modalInfoData.value.id}`)
        }

        onMounted(async (s) => {
            try {
                isUploading.value = true
                const response = await resultTestsRead('')
                data.value = response.results
                fetchNext(response)
                if (response.results.length > 0) {
                    loadingHook.value = false
                }
                isUploading.value = false
            } catch(e) {
                showNotification('Ошибка получения данных о результатах тестов', 'error')
                isUploading.value = false
            }
        });

        return {
            resultTestsRead,
            isUploading,
            activeInputOnSearch,
            visibleMoreBtn,
            modalInfoBtn,
            modalInfoData,
            isModalVisible,
            onModalClose,
            closeModalInfo,
            loadingHook,
            items: computed(() => {
                return data.value.map(item => ({
                    id: item['id'],
                    employee: `${item.employee.last_name ? item.employee.last_name : ''} ${item.employee.first_name ? item.employee.first_name : ''} ${item.employee.middle_name ? item.employee.middle_name : ''}`,
                    teacher_id: item.employee.id,
                    text: item['test']['name'],
                    subject_area: item['test']['subject_area'],
                    competence: item['test']['competence'],
                    attempting: `${item.attempting} из ${item.test.max_attempting}`,
                    my_attempting: item.attempting,
                    data_test: item.test,
                    max_points: item.max_points,
                    my_point: item.point_count,
                }))
            }),
            loadData,
            loadDataWithSearch,
            downloadTestResults,
            openModal,
            goDetailTest,
            router,
        }
    },

});
</script>

<style lang="scss">
.container-tests {
    &-loading {
        text-align: center;
        .par {
            text-align: start;
            margin-top: 20px;
            font-weight: 500;
            font-size: 20px;
        }
    }
    .modal-window__message a {
        text-decoration: none;
    }
}
</style>