
import { defineComponent, PropType, ref, watch, } from 'vue'

export default defineComponent({
    name: 'ProfileImageLoader',
    props: {
        image: {
            type: String as PropType<string>,
            required: true,
        },
    },
    setup (props, { emit, }) {
        const fileInputElem = ref(null) as any

        const previewImage = ref(props.image)

        const selectImage = () => {
            fileInputElem.value.click()
        }

        const  pickFile =  () => {
            const file = fileInputElem.value.files
            if (file && file[0]) {
                const fileReader = new FileReader
                fileReader.onload = e => {
                    previewImage.value = e.target?.result as string
                    emit ('save-image', previewImage.value )
                }
                fileReader.readAsDataURL(file[0])
                // emit('input', file[0])
            }
        }

        const saveImage = () => {
            emit ('save-image', previewImage.value )
            return false
        }
        watch(() => props.image, () => {
            previewImage.value = props.image;
        })
        return {
            fileInputElem,
            selectImage,
            previewImage,
            pickFile,
        }
    },
})
