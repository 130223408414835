
import { defineComponent, ref, PropType, } from 'vue';
import {useRoute,} from 'vue-router';

export default defineComponent({
    name: 'SearchBlock',
    data: function() {
        return {
            isMobile: false,
        }
    },
    props:{
        nameSearch:{
            type: String as PropType<string>,
            default: 'Поиск',
        },
        isFullwidth: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    emits: ['on-search', 'on-clear'],
    setup (_, { emit, }) {
        const searchString = ref<string>('')
        const inputElem = ref<HTMLElement>({} as HTMLElement)
        const route = useRoute()

        const clearInput = () => {
            searchString.value = ''
            emit('on-clear')
            inputElem.value.blur()
        }

        const onBlur = () => {
            if (searchString.value.trim() === '' && route.path !== '/school/tests') {
                emit('on-search')
            }
        }

        return {
            searchString,
            clearInput,
            inputElem,
            onBlur,
        }
    },
    methods: {
        onResize(){
            this.isMobile = window.innerWidth <= 450;
        },
    },
    created() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
})
