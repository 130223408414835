
import { defineComponent, PropType, } from 'vue';

export default defineComponent({
    name: 'TitleBlock',
    props: {
        text: {
            type: String as PropType<string>,
        },
        labelCount: Number as PropType<number>,
        searchBlock: Boolean as PropType<boolean>,
        attention: Boolean as PropType<boolean>,
        nameSearch: String as PropType<string>,
        attentionText: String as PropType<string>,
        titleBtn: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },

    setup (_, { emit, }) {
        const goSearch = (s: string) => {
            emit('on-search', s)
        }

        return {
            goSearch,
        }
    },
})
