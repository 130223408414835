<template lang="pug">
.tutor-item(v-if="tutor !== null")
    .tutor-item__img.hover
        img(:src='tutor.avatar', :alt='tutor.full_name' v-if="tutor.avatar" @click='showModal')
        temp-avatar(v-else typeImage="svg" full @click='showModal')        
    .tutor-item__info
        .tutor-item__info-title Мой тьютор
        .tutor-item__info-name.hover(@click="showModal") {{tutor.full_name}}
    ModalTutor(
        v-if="visible"
        @on-close-modal="closeModal"
        )
        .modal-info__tutor
            a.modal-info__tutor-img.hover(v-if="!tutor.hidden_in_public" :href="`${publicLink}/teachers/${tutor.id}`" target="_blank")
                img(:src='tutor.avatar', :alt='tutor.full_name' v-if="tutor.avatar" @click='showModal')
                temp-avatar(v-else typeImage="svg" full)
            .modal-info__tutor-img.hover(v-else)
                img(:src='tutor.avatar', :alt='tutor.full_name' v-if="tutor.avatar" @click='showModal')
                temp-avatar(v-else typeImage="svg" full)                   
            .modal-info__tutor-data
                .tutor-info
                    b ФИО
                    p(v-if="!tutor.hidden_in_public")
                        a.hover(:href="`${publicLink}/teachers/${tutor.id}`" target="_blank") {{tutor.full_name}}
                    p(v-else) {{tutor.full_name}}
                .tutor-info
                    b Email
                    p.tutor-info__email
                        a.hover(:href="`mailto:${tutor.email}`") {{tutor.email}}
                .tutor-info(v-if="tutor.jobs.length > 0 && tutor.jobs[0].qualification !== null")
                    b Квалификационная категория
                    p {{tutor.jobs[0].qualification.name}}
                .tutor-info(v-if="tutor.jobs.length > 0 && tutor.jobs[0].positions.length > 0")
                    b Должность
                    p {{createList(tutor.jobs[0].positions)}}
                .tutor-info(v-if="tutor.jobs.length > 0")
                    b Образовательная организация
                    p
                        a.hover(:href="`${publicLink}/organization/${tutor.jobs[0].educational_organization.slug}`" target="_blank") {{tutor.jobs[0].educational_organization.name}}
                .tutor-info(v-if="tutor.jobs.length > 0 && tutor.jobs[0].subjects.length > 0")
                    b Предметы
                    p {{createList(tutor.jobs[0].subjects)}}                                                        
</template>

<script>
import {ref,} from 'vue'
import TempAvatar from "@/components/Generic/temp-avatar/index.vue";
import ModalTutor from "@/components/IndividualRoute/my-tutor/ModalTutor.vue";
// composition
import { useIndividualRoute, } from "@/composition/individualRoute";

export default {
    components: { TempAvatar, ModalTutor, },
    props: {
        tutor: {
            type: Object,
        },
    },
    setup(props) {
        const { showModal, visible, closeModal,} = useIndividualRoute();
        const publicLink = `${process.env.VUE_APP_PUBLIC_URL}`;
        const createList = (list) => {
            const positionString = ref([])
            list.map((item) => {
                positionString.value.push(item.name)
            })
            return positionString.value.join(', ')
        }

        return {
            publicLink,
            createList,
            visible,
            closeModal,
            showModal,
        };
    },
};
</script>

<style lang="scss" scoped>
.tutor-item {
    display: flex;
    &__img {
        width: 60px;
        min-width: 60px;
        height: 60px;
        margin-right: 16px;
        img,
        svg {
            border-radius: 5px;
            object-fit: cover;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #6d6d6d;
        }
        &-name {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.2;
            color: #1b1b1a;
        }
    }
    .modal-info {
        &__tutor {
            display: flex;
            @media (max-width: $media-tablet) {
                flex-direction: column;
            }
            &-img {
                width: 200px;
                min-width: 200px;
                height: 200px;
                margin-right: 40px;
                @media (max-width: $media-tablet) {
                    margin-bottom: 15px;
                }
                img,
                svg {
                    border-radius: 5px;
                    object-fit: cover;
                    width: 100%;
                }
            }
            &-data {
                .tutor-info {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    b {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22px;
                        color: #6d6d6d
                    }
                    a, p {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                    a {
                        color: #101010;
                        &:hover {
                            color: $accent-color-hover;
                        }
                    }
                    &__email {
                        word-break: break-all;
                    }
                }
            }        
        }
    }
    .hover {
        &:hover {
            color: $accent-color-hover;
            cursor: pointer;
        }
    }
}
</style>
