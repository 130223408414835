import { ref, computed, } from 'vue'

import {post, put,} from '@/modules/http'


const step = ref(0)
/*
* 0,1 - Пользователь впервые зашел на сайт и поулчил онбординг
* 2 - Пользователь впервые зашел на сайт и дошел до второго шага
* 3 - Пользователь впервые зашел на сайт и заполнил данные по первому и второму шагу
* 4 - пользователь заполнял данные на первом шаге -можно закрыть онборд
* 5 - пользователь заполнял данные на втором шаге - можно закрыть онборд
* */
const isOnboardingVisible = computed(() => {
    if (step.value !== 3 && step.value !== 6) {
        document.documentElement.style.overflow = "hidden";
        return true;
    }
    document.documentElement.style.overflow = "visible";
    return false;
});

export const useOnboarding = ()=> {
    const setStep = (value: number) => {
        step.value = value
    }

    const saveStep  = async () => {
        try {
            await put('/profile/', {
                'onboarding_step': step.value,
            })
        }
        catch (e) {
            console.error(e)
        }
    }
    const nextStep = async () => {
        step.value = step.value + 1
        await saveStep()
    }
    const prevStep = async () => {
        step.value = step.value - 1
        await saveStep()
    }
    const open = async () => {
        step.value = 4
        await saveStep()
    }
    const close = async () => {
        step.value = 6
        await saveStep()
    }

    const onClose = async () => {
        if (step.value >= 3)
            await close()
    }

    return {
        isOnboardingVisible,
        step: computed(() => step.value),
        nextStep,
        prevStep,
        open,
        close,
        saveStep,
        setStep,
        onClose,
    }
}
