<template lang="pug">
.quiz
    template(v-if="mode === 'case'")
        template(v-for="(question, index) in quiz.questions" :key="index")
            template(v-if="index === questionIndex")
                .thisId {{question.id}}
                .statistic Вопрос {{ quiz.count_questions - quiz.questions.length + index + 1 }} из {{ quiz.count_questions }}
                .question {{ question.text }}
                .field
                    ckeditor(
                        :editor="editor"
                        v-model="answers"
                        :config="editorConfig"
                    )
                .field
                    .cases-form__files(v-for="(file, index) in files")
                        .cases-form__file-name {{ file.file.name }} 
                        .cases-form__file-delete
                            TrashIcon(alt="Удалить" @click.prevent="onFileDelete(index)")                                              
                    base-attached.wysiwyg-block__attached.wysiwyg-block__cases(
                        @change="($event) => postFile($event.target)"
                        :loading="isFileUploading"
                    ) Добавить файл
    template(v-else-if="mode === 'test'")
        div(v-for="(question, index) in quiz.questions" :key="index")
            div(v-if="index === questionIndex")
                .thisId {{question.id}}
                .statistic Вопрос {{ quiz.count_questions - quiz.questions.length + index + 1 }} из {{ quiz.count_questions }}
                .question {{ question.text }}
                ul(v-if="question.type_answer === 'one'")
                    li(v-for="response in question.choice_set" :key="response.id")
                        label.field-block__label.field-block__label-radio
                            input.field-block__input-radio(type="radio" :checked="modelValue" :name="index" :value="response.id" v-model="answers")
                            span.field-block__radio
                            span {{ response.text }}
                ul(v-else)
                    li(v-for="response in question.choice_set" :key="response.id")
                        label.field-block__label.field-block__label-checkbox
                            input.field-block__input-checkbox(type="checkbox" :checked="modelValue" :name="index" :value="response.id" v-model="answers" )
                            span.field-block__checkbox
                            span {{ response.text }}
    template(v-else-if="mode === 'survey'")
        div(v-for="(question, index) in quiz.questions")
            div(v-if="index === questionIndex" ref="classSurvey" :class="[question.is_required ? 'is_required' : 'not_required']")
                .thisId {{question.id}}
                .statistic Вопрос {{ quiz.count_questions - quiz.questions.length + index + 1 }} из {{ quiz.count_questions }} {{question.is_required ? '(обязательный)' : '(необязательный)'}}
                .question {{ question.text }}
                ul(v-if="question.multiple_answer === false")
                    li(v-for="response in question.surveychoice_set" :key="response.id")
                        label.field-block__label.field-block__label-radio
                            input.field-block__input-radio(type="radio" :checked="modelValue" :disabled="finalDisabled" :name="index" :value="response.id" v-model="answers")
                            span.field-block__radio
                            span {{ response.text }}
                ul(v-else)
                    li(v-for="response in question.surveychoice_set" :key="response.id")
                        label.field-block__label.field-block__label-checkbox
                            input.field-block__input-checkbox(type="checkbox" :checked="modelValue" :disabled="finalDisabled" :name="index" :value="response.id" v-model="answers" )
                            span.field-block__checkbox
                            span {{ response.text }}                            
    div(v-if="questionIndex !== quiz.questions.length - 1")
        btn(v-if="mode === 'test'" :disabled="answers.length === 0" text="Следующий вопрос" @click="sendAnswer(); next()" ,class="btn btn--do-action")
        btn(v-else-if="mode === 'case'" :disabled="answers.length === 0 && files.length === 0" text="Следующая задача" @click="nextQuestionCase()" class="btn btn--do-action")
        btn(v-else-if="mode === 'survey'" :disabled="requiredSurvey && answers.length === 0" text="Далее" @click="nextSurvey()" class="btn btn--do-action")
    div(v-else)
        btn(v-if="mode === 'test'" :disabled="answers.length === 0" text="Завершить" @click="sendAnswer(); complete();" class="btn btn--do-action")
        btn(v-else-if="mode === 'case'" :disabled="answers.length === 0 && files.length === 0" text="Завершить" @click="nextQuestionCase()" :loading="loading" class="btn btn--do-action")
        btn(v-else-if="mode === 'survey'" :disabled="requiredSurvey && answers.length === 0" text="Завершить" @click="nextSurvey()" :loading="loading" class="btn btn--do-action")
</template>

<script>
import { ref, watchEffect, } from 'vue';
import { useRouter, } from 'vue-router'
import {postFormData, post, } from '@/modules/http'

// components
import Btn from '@/components/Generic/btn/index.vue';
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'
// composition
import {useCommmonNotification,} from '@/composition/notification'
import {useTest, } from '@/composition/diagnosticTest';
import {useCase, } from '@/composition/diagnosticCase';
import {useMonitoring,} from '@/composition/monitoring';
import { useCKed, } from '@/composition/wys';
import BaseAttached from '@/components/Generic/attached/index.vue';
import {sleep,} from '@/modules/helpers';

export default {
    components: {
        Btn,
        BaseAttached,
        TrashIcon,
    },
    props: {
        quiz: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
        },
    },
    setup( props, {emit,  } ) {
        const {showNotification,} = useCommmonNotification();
        const router = useRouter()
        const dataTest = ref('')
        const answers = ref([]);
        const files = ref([]);
        const isFileUploading = ref(false);
        const loading = ref(false)
        const questionIndex = ref(0);
        const classSurvey = ref([])
        const requiredSurvey = ref(true)
        const finalDisabled = ref(false)
        const {
            sendAnswerOnTest,
        } = useTest();
        const {
            sendAnswerOnCase,
        } = useCase();
        const {
            sendAnswerOnSurvey,
        } = useMonitoring();   

        watchEffect(() => {
            if (props.mode === 'survey') {
                classSurvey.value.classList.value === 'not_required' ? requiredSurvey.value = false : requiredSurvey.value = true
            }
        },
        {
            flush: 'post',
        })
        const postFile = (file, data) => {
            isFileUploading.value = true;            
            data = {
                file: file.files[0],
            }
            files.value.push(data)
            isFileUploading.value = false;
            file.value = ''
        };
        const onFileDelete = async (index) => {
            files.value.splice(index, 1)
            showNotification('Файл удален');
        };
        const sendAnswer = async () => {
            const questionId = document.querySelector('.thisId').innerText
            const data = {}
            //set data
            data.test_result =  props.quiz.id
            data.question = Number(questionId)
            if(typeof answers.value === 'number') {
                const arr = []
                arr.push(answers.value)
                data.choice_answer = arr
            }
            else {
                data.choice_answer = Object.values(answers.value)
            }
            //send data
            const result = await sendAnswerOnTest(data)
            //clean v-model
            answers.value = []
        };
        const next = () => {
            questionIndex.value++;
        }
        const complete = async () => {
            document.documentElement.style.overflow = 'auto';
            if (props.mode === 'test') {
                emit('on-modal-close', false)
                router.push(`/diagnostics/tests/completed/result/${props.quiz.id}`)
                await sleep(3000)
                emit('get-all-tests')
            } else if (props.mode === 'case') {
                loading.value = true
                await sleep(500)
                showNotification('Кейс отправлен на проверку');
                await sleep(1000)
                loading.value = false
                emit('on-modal-close', false)
                router.push('/diagnostics/cases/completed')
            }
            else {
                finalDisabled.value = true
                loading.value = true
                await sleep(500)
                showNotification('Благодарим за прохождение опроса!');
                await sleep(1000)
                loading.value = false
                emit('on-modal-close', false)
                router.push('/monitoring/completed')
                finalDisabled.value = false
            }
        }
        const nextQuestionCase = async () => {
            try {
                const questionId = document.querySelector('.thisId').innerText
                const data = {}
                //set data
                data.case_result =  props.quiz.id
                data.question = Number(questionId)
                data.answer = answers.value.length === 0 ? '' : answers.value
                //send data
                const result = await sendAnswerOnCase(data)
                if (result === null) {
                    throw new SyntaxError('Произошла ошибка')
                }
                if (files.value.length > 0) {
                    const formData = new FormData();
                    files.value.map((file) => {
                        formData.append('file', file.file);
                    })
                    formData.append('content_type_id', String(props.quiz.answer_content_type_id))
                    formData.append('object_id', String(result.id));
                    const response = await postFormData('/upload_file/', formData)
                }
                //clean v-model
                answers.value = ''
                files.value = []
                if (questionIndex.value !== props.quiz.questions.length - 1) {
                    next()
                } else {
                    complete()
                }
            } catch {
                showNotification('Произошла ошибка. Попробуйте снова', 'danger');
            }
        };

        const nextSurvey = async() => {
            const questionId = document.querySelector('.thisId').innerText
            const data = {}
            //set data
            data.survey_result =  props.quiz.id
            data.question = Number(questionId)
            if(typeof answers.value === 'number') {
                const arr = []
                arr.push(answers.value)
                data.choice_answer = arr
            }
            else {
                data.choice_answer = Object.values(answers.value)
            }
            //send data
            const result = await sendAnswerOnSurvey(data)

            if (questionIndex.value !== props.quiz.questions.length - 1) {
                //clean v-model
                answers.value = []
                next()
            } else {
                complete()
            }                      
        }

        return {
            router,
            dataTest,
            questionIndex,
            answers,
            sendAnswer,
            nextSurvey,
            nextQuestionCase,
            next,
            complete,
            files,
            postFile,
            onFileDelete,
            isFileUploading,
            loading,
            requiredSurvey,
            classSurvey,
            finalDisabled,
            // useDiagnostic
            sendAnswerOnTest,
            sendAnswerOnCase,
            ...useCKed(),
        };
    },
};
</script>

<style lang='scss' scoped>
.quiz {
    width: 100%;
    .question {
        margin-bottom: 20px;
    }
    ul {
        margin: 0px;
        margin-bottom: 32px;
        li {
            list-style: none;
            padding-left: 0;
            label {
                margin: 0;
                margin-bottom: 15px;
                cursor: pointer;
            }
        }
    }
}
.thisId {
    display: none;
}
.statistic {
    font-weight: bold;
    margin-bottom: 15px;
}

.field {
    margin-bottom: 20px;
    .cases-form {
        &__files {
            display: flex;
            align-items: center;
            padding: 0 0 20px;
            &:nth-last-child(2) {
                padding: 0;
            }
        }
        &__file-delete {
            margin-left: 12px;
            cursor: pointer;
        }
        &__file-name {
            @media(max-width: 780px) {
                font-size: 14px;
            }
        }
        a:hover {
            color: $accent-color-hover;
            text-decoration: none;
        }
    }
    .wysiwyg-block__cases {
        .attached__text {
            @media(max-width: 780px) {
                font-size: 14px;
            }
        }
    }
}
</style>
