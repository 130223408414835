import { ref, computed, onMounted, } from 'vue'
// import inputMask from 'inputmask'


import { InputField, } from '@/types/forms'

export const useInput = (field: InputField, emit?): any => {
    const inputElem = ref<HTMLElement>({} as HTMLElement)

    // onMounted( ()=> {
    //     inputMask({ mask: field.inputMask, }).mask(inputElem.value)
    // })
 
    const inputValue = computed(() => { return field.inputValue })

    const inputType = ref(field.inputType)
    return {
        inputType,
        inputElem,
    }
}

export const useInputPassword = (field: InputField, emit?): object => {
    const { inputType, inputElem, } = useInput(field)

    const showPasswordIconAlt = ref('Показать пароль')
    const showPasswordIconTitle = ref('Показать пароль')
    const eyeOpen = ref(false)

    const togglePassword = () => {
        inputType.value = (inputType.value === 'password') ? 'text' : 'password'
        eyeOpen.value = !eyeOpen.value
        showPasswordIconAlt.value = (showPasswordIconAlt.value === 'Показать пароль') ? 'Скрыть пароль' : 'Показать пароль'
        showPasswordIconTitle.value = (showPasswordIconTitle.value === 'Показать пароль') ? 'Скрыть пароль' : 'Показать пароль'
    }

    onMounted(() => {
        inputElem.value.setAttribute('autocomplete', 'password-suggested')
    })

    return {
        inputType,
        inputElem,
        showPasswordIconAlt,
        showPasswordIconTitle,
        eyeOpen,
        togglePassword,
    }
}



