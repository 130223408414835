import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "header__nav-ul" }
const _hoisted_2 = { class: "header__nav-ul" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("nav", {
    class: ["header__nav", {'header__nav__mr-3': !_ctx.settings.telegram_url && !_ctx.settings.vk_url && !_ctx.settings.zen_ya_url}]
  }, [
    _createVNode("ul", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.lists, (list, index) => {
        return (_openBlock(), _createBlock("li", {
          class: ["header__nav-li", {'header__nav-li--active': list.active, 'con-tooltip': list.tooltip}],
          key: index
        }, [
          (!list.tooltip)
            ? (_openBlock(), _createBlock("a", {
                key: 0,
                class: "header__nav-link",
                href: list.link,
                innerHTML: list.name
              }, null, 8 /* PROPS */, ["href", "innerHTML"]))
            : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                (list.link)
                  ? (_openBlock(), _createBlock("a", {
                      key: 0,
                      class: ["header__nav-link", {'con-tooltip__title--none-cursor': !list.cursor}],
                      href: list.link,
                      innerHTML: list.name
                    }, null, 10 /* CLASS, PROPS */, ["href", "innerHTML"]))
                  : (_openBlock(), _createBlock("a", {
                      key: 1,
                      class: ["header__nav-link", {'con-tooltip__title--none-cursor': !list.cursor}],
                      innerHTML: list.name
                    }, null, 10 /* CLASS, PROPS */, ["innerHTML"])),
                _createVNode("div", {
                  class: ["tooltip", {'tooltip--mt-1': list.bigMargin}]
                }, [
                  _createVNode("ul", _hoisted_2, [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(list.children, (item, index) => {
                      return (_openBlock(), _createBlock("li", null, [
                        _createVNode("a", {
                          class: "header__nav-link",
                          href: item.link
                        }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, ["href"])
                      ]))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ])
                ], 2 /* CLASS */)
              ], 64 /* STABLE_FRAGMENT */))
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}