import { useAuth, } from '@/composition/state'
import {useUserProfileData,} from '@/composition/userProfile';

export default async (from, next) => {
    const { isLoggedIn,   } = useAuth()
    const isLog = await  isLoggedIn()
    if (!isLog) {
        return  next({
            path: '/login',
        })
    }
    const {  fetchUserProfileData, getUserProfileData, } = useUserProfileData()
    await fetchUserProfileData()
    if (getUserProfileData.value.profile_verified) return next({
        path:'/',
    })
    return next()
}
