import { ref, } from 'vue'

export const usePagination = (size= 6) => {
    const page = ref(1)
    const pageSize = ref(size)
    const isLoading = ref(false)
    const isUploading = ref(false)
    const isNeedToUpload = ref(false)
    return {
        page,
        pageSize,
        isLoading,
        isUploading,
        isNeedToUpload,
    }
}
