<template lang='pug'>
a.select.login-block__back-link.profile__form__back-link(@click="this.$router.go(-1)" )
    img(src="@/assets/img/back.svg")
    span Вернуться
h4 {{ testResult.name }}
.loading-page(v-if="isUploading")
    LoadingIndicator
div(v-else)
    div.result
        fieldset
            legend Попытка
            div {{testResult.attempting.number}} из {{testResult.attempting.max_attempting}}
        fieldset
            legend Результат
            div {{testResult.result.my_result}} из {{testResult.result.max_result}}
    Chart(v-if="chartData.length > 0" :chartData="chartData")
    .table__detail-attempt(:class="defineClass")
        Table(:headers="headersResult" :items="testSortedData" :is_hover="true" :detail_attempt="true")
</template>

<script>
import {ref, onMounted, computed, } from 'vue';
import {useRoute, } from 'vue-router'
// composition
import {useTest,} from '@/composition/diagnosticTest';
// component
import Chart from '@/components/Generic/chart/index';
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default {
    components: {
        Chart,
        Table,
        LoadingIndicator,
    },
    setup() {
        document.documentElement.style.overflow = 'auto';
        const route = useRoute()
        // const
        const testData = ref([])
        const chartData = ref([])
        const isUploading = ref(true)
        const testResult = ref({
            attempting: {
                number: '',
                max_attempting: '',
            },
            result: {
                my_result:'',
                max_result: '',
            },
            questions: [],
        })
        // composition
        const {
            testResultRead,
            headersResult,
        } = useTest();
        // function
        const getChartData = async () => {
            const response = await testResultRead(route.params.id);
            testData.value = response.testanswer_set
            const questions = response.testanswer_set
            const questionsList = []
            let my_result = 0
            let max_result = 0
            //get unic competence
            const allCompetence = [];
            for (let i = 0; i < questions.length; i++) {
                const element = questions[i];
                allCompetence.push(element.competence);
            }
            const unicCompetence = [...new Set(allCompetence)];
            //get correct
            const testArray = []
            for (let i = 0; i < unicCompetence.length; i++) {
                const element = unicCompetence[i];
                let my = 0;
                let max = 0;
                for (let j = 0; j < questions.length; j++) {
                    const question = questions[j];
                    if (element == question.competence && question.is_correct == true) {
                        max += 1;
                        my += 1;
                    } else if (element == question.competence && question.is_correct == false) {
                        max += 1;
                    }
                }
                my = my * 100 / max
                max = 100
                const chartElement = {
                    competence: element,
                    max,
                    my,
                };
                testArray.push(chartElement)
            }
            chartData.value = testArray
            // get min and max point
            // get min and max result
            for (let i = 0; i < questions.length; i++) {
                const question = {
                    id: '',
                    name: '',
                    correct: false,
                    answers: [],
                }
                const element = questions[i];
                question.name = element.question_text
                question.correct = element.is_correct
                const answersForObject = []
                for (let j = 0; j < element.choice_answer.length; j++) {
                    const answer = {}
                    answer.name = element.choice_answer[j].text;
                    answersForObject.push(answer)
                }
                question.answers = answersForObject
                if (element.is_correct == true) {
                    my_result += element.points
                    max_result += element.points
                } else {
                    max_result += element.points
                }
                questionsList.push(question)
            }

            testResult.value.name = response.test.name
            testResult.value.attempting.number = response.attempting
            testResult.value.attempting.max_attempting = response.test.attempting.max_attempting
            testResult.value.result.my_result = my_result
            testResult.value.result.max_result = max_result
            testResult.value.questions = questionsList
            isUploading.value = false
        };
        getChartData()
        onMounted(() => {
            document.documentElement.style.overflow = 'auto';
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
        })
        const defineClass = computed(() => {
            if (chartData.value.length === 3 || chartData.value.length === 1) {
                return 'big-margin'
            }
        })
        return {
            // useTest
            testResultRead,
            headersResult,
            // const
            testResult,
            chartData,
            testSortedData: computed(() => {
                return testData.value.map(x => ({
                    text: x.question_text,
                    answers: {
                        answer: {
                            text: x.choice_answer.map(x => x.text),
                            correct: x.is_correct,
                        },
                    },
                }))
            }),
            defineClass,
            isUploading,
        }
    },
}
</script>

<style lang='scss' scoped>
h4 {
    font-size: 21px;
    line-height: 140%;
    margin-bottom: 30px;
    font-weight: bold;
    color: #1B1B1A;
}
.table__detail-attempt {
    margin-top: 30px;
}
.loading-page {
    text-align: center;
}
.big-margin {
    @media (max-width: 450px) {
        margin-top: 60px;
    }
    @media (max-width: 360px) {
        margin-top: 80px;
    }
}
.result {
    display: flex;

}
fieldset {
    margin-bottom: 30px;
    margin-right: 30px;
    border: none;
    line-height: 25px;
    font-weight: 500;
    font-size: 16px;
    legend {
        font-size: 13px;
        color: #6D6D6D;
    }
}
@media screen and (max-width:1035px)  {}
@media screen and (max-width:750px) {
    h4 {
        font-size: 18px;
    }
}
</style>
