
import { defineComponent, ref, onMounted, } from 'vue'

import { get, put, } from '@/modules/http'

import { Notification, } from '@/types/common'

import { useCommmonNotification,} from '@/composition/notification'

import NotLists from '@/components/Generic/notifications/index.vue'

export default defineComponent({
    name: 'Notifications',
    components: {
        NotLists,
    },
    setup () {
        const notifications = ref<Notification[]>([])

        const isLoading = ref(true)

        const { setCount,  } = useCommmonNotification()

        const fetchNotiff = async () => {
            try {
                const response =  await get('/notif', {}, true)
                if (response.ok) {
                    notifications.value = response.parsedBody
                }
                else {
                    console.error('Ошибка при загрузке уведомлений')
                }
            }
            catch(e) {
                console.error(e)
            }
            isLoading.value = false
        }

        const markAllAsViewed = async () => {
            if (notifications.value.length > 0) {
                // notifications.value.forEach ( async (el) => {
                //     await put('/notif/'+ el.id, { viewed: true, }, {}, true)
                // })
            }
        }

        onMounted(async () => {
            await fetchNotiff()
            markAllAsViewed()
            window.setTimeout( () => setCount(0), 10)
        })

        return {
            notifications,
        }
    },
});
