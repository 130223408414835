
/* eslint-disable */
import { defineComponent, reactive, ref, computed, } from 'vue'
import { useFieldValidator, } from '@/composition/fieldValidator.ts'

import { commonFields,  } from '@/modules/fields'

import FieldBlock from '@/components/Generic/field-block/index.vue'
import { useChangePassword, } from '@/composition/userProfile'
import { useCommmonNotification, } from '@/composition/notification'

export default defineComponent({
    name: 'SettingsPassword',
    component: {
        FieldBlock,
    },
    setup () {

        const old_password = commonFields.pass
        const new_password1 = commonFields.newPass
        const new_password2 = commonFields.repeatPass
        const form = reactive(
            {
                old_password,
                new_password1,
                new_password2,
            })
        const { validateForm,  } = useFieldValidator()
        
        const {showNotification, hideNotification, } = useCommmonNotification()
        const nonFieldErrors = ref([])
        const resetErrors = () => {
            form.old_password.errorMessages = []
            form.new_password1.errorMessages = []
            form.new_password2.errorMessages = []
            nonFieldErrors.value = []
        }
        const { changePassword, } = useChangePassword()
        const loading = ref(false)
        const changePasswordHandler = async() => {
            loading.value = true
            resetErrors()
            const isErrors = validateForm(form)
            if (isErrors)  { return loading.value = false}
            try {
                await changePassword({
                    old_password: old_password.inputValue,
                    new_password1: new_password1.inputValue,
                    new_password2: new_password2.inputValue,
                })
                showNotification('Успешно сохранено')
                setTimeout(hideNotification, 2000)
            } catch (e) {
                for(let key in e.fields) {
                    const err = e.fields[key]
                    if (form[key]) {
                        if (key === 'old_password') {
                            form[key].errorMessages = err.includes('Неверный пароль') ? err : ["Значение должно быть не более 128 символов"]
                        }
                        else {
                            const rusError = err.includes('Пароли не совпадают') ? err : ["Значение должно быть не более 128 символов"]
                            form[key].errorMessages = err && Array.isArray(err) ? [...rusError] : [rusError];
                        }
                    } else {
                        form.new_password1.errorMessages = ["Пароль слишком простой. Он должен состоять из восьми символов и более"];
                        form.new_password2.errorMessages = ["Пароль слишком простой. Он должен состоять из восьми символов и более"];
                    }
                }
            }
            loading.value = false
        }
        const disabled = computed(() => {
            return !form.old_password.inputValue || !form.new_password1.inputValue || !form.new_password2.inputValue
        })

        const buttonParams = {
            width:'144px',
            text: 'Сохранить'
        }

        return {
            form,
            changePasswordHandler,
            loading,
            disabled,
            nonFieldErrors,
            buttonParams,
        }
    },
})
