
import { defineComponent, PropType, } from 'vue';
export default defineComponent({
    name: 'Tabs',
    emits: ['change-tab'],
    props:  {
        tabItems: {
            type: Array as PropType<object[]>,
            required: true,
        },
        activeTab: {
            type: Number as PropType<number>,
            required: true,
            default: 0,
        },
        nonRouter: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        className: {
            type: String as PropType<string>,
        },
    },

})

