
import { defineComponent, } from 'vue'
import { useEnv, } from '@/composition/env';

export default defineComponent({
    name: 'LoginLogo',
    setup() {
        const { publicURL, } = useEnv()
        return {
            publicURL,
        }
    },
})

