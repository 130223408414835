
import { defineComponent,  onMounted,  ref,  } from 'vue'

import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'

import EventBlock from '@/components/Events/events-block/index.vue'

import { useEvents, } from '@/composition/events'

export default defineComponent({
    name: 'Favorites',
    components: {
        EventBlock,
        Tabs,
        TitleBlock,
    },
    setup (){
        const  tabItems = ref([
            {
                content: 'Все',
                count: 0,
                type: 'base_events',
            },
            {
                content: 'Мероприятия',
                count:0,
                type: 'events',
            },
            {
                content: 'Курсы',
                count: 0,
                type: 'courses',
            },
            {
                content: 'Олимпиады',
                count: 0,
                type: 'olympiads',
            }            
        ])

        const eventType = ref('base_events')

        const activeTab = ref(0)

        const { searchString, } = useEvents({
            eventType: eventType.value,
            filter: 'actual',
        })

        const eventCount = ref<number>(0)

        const { getEventCountFavorites, } = useEvents({
            eventType: eventType.value,
            s: '',
        })

        const goSearch =  async (s: string) => {
            searchString.value = s
            tabItems.value.forEach( async el => {
                el.count = await getEventCountFavorites(el.type, s)
            })
            eventCount.value = await getEventCountFavorites('base_events')
        }

        const onEventsChanged = async ({ fromFavorites, s, }) => {
            if (!fromFavorites) return
            tabItems.value.forEach( async (el, ind) => {
                if (ind === activeTab.value) {
                    el.count = el.count - 1
                }
                else el.count = await getEventCountFavorites(el.type, s);
            })
            eventCount.value = await getEventCountFavorites('base_events', '')
        }

        tabItems.value.forEach( async el => {
            el.count = await getEventCountFavorites(el.type)
        })

        onMounted(async ()=> {
            eventCount.value = await getEventCountFavorites('base_events')
        })

        const changeTab = (tab: number) => {
            switch (tab) {
            case 0: eventType.value = 'base_events'
                break
            case 1: eventType.value = 'events'
                break;
            case 2: eventType.value = 'courses'
                break;
            case 3: eventType.value = 'olympiads'
                break;                
            }
            activeTab.value = tab
        }

        return {
            tabItems,
            changeTab,
            eventType,
            activeTab,
            goSearch,
            searchString,
            eventCount,
            onEventsChanged,
        }
    },
});
