<template lang="pug">
transition(name="fade" mode="out-in")
    .detail-test(v-if="!loadingHook")
        a.select.login-block__back-link.profile__form__back-link(@click="$router.push({path: `/school/tests/`})" )
            img(src="@/assets/img/back.svg")
            span Вернуться
        DetailTest(:detailTest="detailTest" :items="items")
    .detail-test__loading(v-else)
        LoadingIndicator(v-if="isUploading")
        .error-block(v-else)
            a.select.login-block__back-link.profile__form__back-link(@click="$router.push({path: `/school/tests/`})" )
                img(src="@/assets/img/back.svg")
                span Вернуться
            p Произошла ошибка получения данных
</template>

<script>
import {defineComponent, onMounted, ref, computed,} from 'vue'
import { useRouter, } from 'vue-router'

import {useResultTests,} from '@/composition/schoolTests';
import { useCommmonNotification, } from '@/composition/notification';

import DetailTest from '@/components/School/Test/DetailTest.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'DetailSchoolTest',
    components: {
        LoadingIndicator,
        DetailTest,
    },
    setup() {
        const { showNotification, } = useCommmonNotification()
        const route = useRouter()
        const id = route.currentRoute.value.params.id
        const loadingHook = ref(true)
        const isUploading = ref(true)
        const detailTest = ref([])
        const { detailTestRead, } = useResultTests()

        const fetchDetailTest = async() => {
            try {
                const response = await detailTestRead(id)
                if (response === null) {
                    throw new SyntaxError('Ошибка')
                }
                detailTest.value = response
                loadingHook.value = false
            } catch (e) {
                showNotification('Ошибка получения данных о результате теста', 'error')
                isUploading.value = false
            }
        }
        onMounted( async() => {
            window.scroll({
                top: 0,
                behavior: 'smooth',
            });
            await fetchDetailTest()
        })
        return {
            route,
            id,
            loadingHook,
            isUploading,
            detailTest,
            fetchDetailTest,
            items: computed(() => {
                return detailTest.value.questions.map(item => ({
                    id: item.question,
                    is_correct: item.is_correct,
                    text: item.question_text,
                    answers: {
                        answer: {
                            text: item.choice_answer.map(x => x.text),
                            correct: item.is_correct,
                        },
                    },
                }))
            }),
        }
    },
})
</script>

<style lang="scss" scoped>
.detail-test {
    &__loading {
        text-align: center;
        .error-block {
            text-align: start;
        }
    }
}
</style>