import {  computed, } from 'vue'
import {  useRoute, } from 'vue-router'

export const useTabs = (tabItems: any[]): any => {
    const route = useRoute()
    const activeTab = computed (() => {
        return tabItems.findIndex((elem) => elem.content === route.meta.tab)
    })
    return {
        tabItems,
        activeTab,
    }
}
