<template lang="pug">

div(v-for="(value, name) in modalData") 
    template(v-if="name === 'name'")
        h3 {{ value }}
    template(v-if="name === 'username'") 
        b Педагог
        p
            a.hover(v-if="!modalData.hidden_in_public" :href="`${pathPublic}/teachers/${modalData.teacher_id}`" target="_blank") {{ value }}
            a.hover(v-else) {{ value }}
    template(v-if="name === 'test_name' || name === 'survey_name' && value.length > 0") 
        b Название
        p {{ value }}        
    template(v-if="name === 'annotation'") 
        b(v-if="value.length > 0") Аннотация
        p.modal-annotation(v-html="value")
    template(v-if="name === 'subject_area'") 
        b Предметные области
        .list(v-if="Array.isArray(value)" )
                .item(v-for="item in value") {{item.name}}
    template(v-if="name === 'competence'") 
        b Профессиональные компетенции
        .list(v-if="Array.isArray(value)" )
                .item(v-for="item in value") {{item.name}}
    template(v-if="name === 'quantity_questions'") 
        b Количество вопросов
        .quantity-questions
            p(v-if="value[0] !== 0" :class="[margins === true ? 'mb-20' : 'mb-null']") {{`Обязательных вопросов - ${value[0]}.`}}
            p(v-if="value[1] !== 0" :class="{'mb-null': modalData.auditoryes === null && modalData.quantity_answers.length === 0}") {{`Необязательных вопросов - ${value[1]}.`}}
    template(v-if="name === 'quantity_answers' && value.length > 0") 
        b Количество полученных ответов
        .quantity-questions
            p(v-if="value[0] !== 0" :class="[value[1] === 0 ? 'mb-20' : 'mb-null']") На обязательные вопросы - {{value[2]}} из {{value[0]}}.
            p(v-if="value[1] !== 0" :class="{'mb-null': modalData.auditoryes === null}") На необязательные вопросы - {{value[3]}} из {{value[1]}}.
    template(v-if="name === 'auditoryes' && value !== null") 
        b Аудитория
        .list.list-auditoryes(v-if="Array.isArray(value)" )
                .item(v-for="item in value") {{item.name}}                     
template(v-if="modalData.type === 'check'")  
    b Номер попытки 
    .item--string {{modalData.attempting.number}} из {{ modalData.attempting.max_attempting }} доступных.
template(v-if="modalData.max_points && modalData.my_point >= 0")
    b Итоговые баллы
    .item--string {{modalData.my_point}} из {{modalData.max_points}}
template(v-if="modalData.type === 'test'")
    b Попытки
    template(v-if="modalData.attempting.max_attempting > 0 && !loading")
        template(v-if="modalData.attempting.number === 0")
            template(v-if="modalData.status_for_user === 'new'")
                template(v-if="modalData.attempting.max_attempting === 1")
                    div Первая попытка прохождения теста - не начата.
                template(v-else)
                    div Первая попытка прохождения теста - не начата.
                    div Вторая попытка прохождения теста - станет доступна после первой попытки.
        template(v-if="modalData.attempting.number !== 0")
            template(v-if="modalData.status_for_user === 'process'")
                div(v-for="attempt in modalData.allAttempts") {{ attempt.text }}
                    a.cursor-pointer(v-if="attempt.is_completed" @click="getResult(attempt.id, 'tests')") Посмотреть результат
                div(v-if="modalData.attempting.number !== modalData.attempting.max_attempting") {{nextAttempt}} попытка прохождения теста - станет доступна после {{currentAttempt}} попытки.
            template(v-if="modalData.status_for_user === 'completed'")
                div(v-for="attempt in modalData.allAttempts") {{ attempt.text }}
                    a.cursor-pointer(v-if="attempt.is_completed" @click="getResult(attempt.id, 'tests')") Посмотреть результат
                div(v-if="modalData.allAttempts.length !== modalData.attempting.max_attempting") 
                    span(v-if="modalData.attempting.next === null") {{nextAttempt}} попытка прохождения теста - не начата
                    span(v-else) {{nextAttempt}} попытка прохождения теста - станет доступна в {{modalData.attempting.next}}
    div(v-else)
        LoadingIndicator
        
template(v-if="modalData.type === 'case'")  
    b Попытки
    template(v-if="modalData.attempting.max_attempting > 0 && !loading")
        template(v-if="modalData.attempting.number === 0")
            template(v-if="modalData.status_for_user === 'new'")
                template(v-if="modalData.attempting.max_attempting === 1")
                    div Первая попытка прохождения кейса - не начата.
                template(v-else)
                    div Первая попытка прохождения кейса - не начата.
                    div Вторая попытка прохождения кейса - станет доступна после первой попытки.
        template(v-if="modalData.attempting.number !== 0")
            template(v-if="modalData.status_for_user === 'process'")
                div(v-for="attempt in modalData.allAttempts") {{ attempt.text }}
                    a.cursor-pointer(v-if="attempt.is_completed !== null" @click="getResult(attempt.id, 'cases')") Посмотреть результат
                div(v-if="modalData.attempting.number > modalData.allAttempts.length && modalData.status_for_user === 'process'") {{nextAttempt}} попытка прохождения кейса - в процессе.
                div(v-if="modalData.attempting.number === 1 && modalData.status_for_user === 'process'") Вторая попытка прохождения кейса - {{`станет доступна после первой попытки`}}.
                div(v-if="modalData.attempting.number !== modalData.attempting.max_attempting && modalData.attempting.number !== 1") {{nextAttempt}} попытка прохождения кейса - {{`станет доступна после ${currentAttempt} попытки`}}.
            template(v-if="modalData.status_for_user === 'completed'")
                div(v-for="attempt in modalData.allAttempts") {{ attempt.text }}
                    a.cursor-pointer(v-if="attempt.is_completed !== null" @click="getResult(attempt.id, 'cases')") Посмотреть результат
                div(v-if="modalData.allAttempts.length !== modalData.attempting.max_attempting") 
                    span(v-if="modalData.attempting.next === null") {{nextAttempt}} попытка прохождения кейса - не начата
                    span(v-else) {{nextAttempt}} попытка прохождения кейса - станет доступна в {{modalData.attempting.next}}
    div(v-else)
        LoadingIndicator                    

</template>

<script>
import {computed, } from 'vue'
import {useRouter,} from 'vue-router';

//components
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default {
    name: 'ModalInfo',
    components: {
        LoadingIndicator,
    },
    props: {
        modalData: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, {emit,  }) {
        const router = useRouter();
        const pathPublic = process.env.VUE_APP_PUBLIC_URL
        const getResult = (path, type) => {
            document.documentElement.style.overflow = 'auto';
            emit('on-modal-close', false)
            router.push(`/diagnostics/${type}/completed/result/${path}`)
        }
        const nextAttempt = computed(() => {
            if (props.modalData.allAttempts.length === 0) return 'Первая'
            else if (props.modalData.allAttempts.length === 1) return 'Вторая'
            else if (props.modalData.allAttempts.length === 2) return 'Третья'
            else if (props.modalData.allAttempts.length === 3) return 'Четвертая'
            else if (props.modalData.allAttempts.length === 4) return 'Пятая'
            else return `${props.modalData.allAttempts.length + 1}`
        })
        const currentAttempt = computed(() => {
            if (props.modalData.allAttempts.length === 1) return 'первой'
            else if (props.modalData.allAttempts.length === 2) return 'второй'
            else if (props.modalData.allAttempts.length === 3) return 'третьей'
            else if (props.modalData.allAttempts.length === 4) return 'четвертой'
            else if (props.modalData.allAttempts.length === 5) return 'пятой'
            else return `${props.modalData.allAttempts.length + 1}`
        })
        const margins = computed(() => {
            if (router.currentRoute.value.path === '/monitoring/actual' || router.currentRoute.value.path === '/monitoring/process') {
                if (props.modalData.quantity_questions[1] === 0 && props.modalData.auditoryes === null) return false
                if (props.modalData.quantity_questions[1] !== 0) return false 
                return true
            }
            if (props.modalData.quantity_questions[1] === 0) return true
        })
        return {
            getResult,
            pathPublic,
            nextAttempt,
            currentAttempt,
            margins,
            router,
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
}
.paragraph {
    margin-bottom: 10px;
}
.list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.list-auditoryes {
    margin-bottom: 0;
}
.item {
    font-size: 14px;
    padding: 5px 8px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    margin: 5px 10px 5px 0px;
}
.item--string {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.hover {
    &:hover {
        color: $accent-color-hover;
        cursor: pointer;
    }
}
.cursor-pointer {
    cursor: pointer;
}
.quantity-questions {
    .mb-null {
        margin-bottom: 0;
    }
    .mb-20 {
        margin-bottom: 20px;
    }
}
</style>

<style lang="scss">
.modal-annotation {
    img {
        max-width: fit-content;
        max-height: 200px;
        object-fit: contain;
    }
}
</style>
