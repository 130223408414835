import { ref, } from 'vue'
import {get, post,} from '@/modules/http'
import {useRoute,} from 'vue-router'
import { Colleague, } from '@/types/common.ts'
import {useCommmonNotification,} from '@/composition/notification';
import {useCookie,} from '@/composition/cookie';
import moment from 'moment';

export const useMessages = () => {
    const {getCookie,} = useCookie()
    const route = useRoute()
    const token = ref(getCookie('token'))
    
    const chatId = ref(route.params.id)
    const newMsg = ref(false)

    // ws
    const webSocket = ref({
        data: {},
        connection: false,
        error: true,
    })
    // pagination
    const pagination = ref({
        page: 1,
        pageSize: 10,
        total: 0,
        next: undefined,
        success: true,
    })
    const paginationModal = ref({
        page: 1,
        pageSize: 8,
        total: 0,
        next: null,
    })
    const paginationChat = ref({
        page: 1,
        pageSize: 7,
        total: 0,
        next: undefined,
        success: true,
        onScroll: true,
    })
    const availableUsers = ref([])
    const chats = ref([])
    const allMessages = ref<Colleague[]>([])
    
    const colleague = ref({
        data: null,
    })

    //loaders
    const loading = ref(true)
    const loadingModal = ref(false)

    const {showNotification,} = useCommmonNotification()

    //working with dates
    const dateFormatting = (resultData, type='day') => {
        const data = new Date(resultData)
        if (type === 'day') {
            resultData = moment(data).format('DD.MM.YYYY')
            return resultData
        }
        resultData = moment(data).format('HH:mm')
        return resultData
    }
    const searchEquelDates = (res) => {
        if (res.length > 0) {
            let i = res.length - 1
            while (i > 0) {
                dateFormatting(res[i].created) === dateFormatting(res[i - 1].created) ? res[i].showDate = false : res[i].showDate = true
                i--
            }
            res[0].showDate = true
        }
        return res
    }

    // api
    const fetchAllChats = async (search?, size?: number) => {
        try {
            loading.value = true
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(pagination.value.page))
            url.searchParams.append('page_size', size ? String(size) : String(pagination.value.pageSize))
            if (search) {
                url.searchParams.append('search', search)
            }
            const response = await get(`/chat_list/${url.search}`)
            pagination.value.next = response.parsedBody.next
            pagination.value.total = response.parsedBody.count
            chats.value = response.parsedBody.results
            pagination.value.success = true
            return
        } catch (e) {
            showNotification('Ошибка при загрузке чатов! Повторите позже!', 'danger')
            pagination.value.success = false
            console.error(e)
        } finally {
            loading.value = false
        }
    }
    const fetchAvailableUsers = async (search?, size?: number) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(paginationModal.value.page))
            url.searchParams.append('page_size', size ? String(size) : String(paginationModal.value.pageSize))
            if (search) {
                url.searchParams.append('search', search)
            }
            const response = await get(`/chat_users_list/${url.search}`)
            paginationModal.value.next = response.parsedBody.next
            paginationModal.value.total = response.parsedBody.count
            availableUsers.value = response.parsedBody.results
        } catch (e) {
            showNotification('Ошибка при загрузке коллег! Повторите позже!', 'danger')
            console.error(e)
        }
    }
    const fetchColleague = async (id: number) =>{
        try {
            const response = await get(`/chat_list/${id ? id : chatId.value}/`)
            colleague.value.data = response.parsedBody.colleague
            return response.parsedBody
        } catch(e) {
            showNotification('Ошибка при загрузке сообщений! Повторите позже!', 'danger')
        }
    }
    const fetchAllMessages = async (size?: number) => {
        try {
            const url = new URL(window.location.origin)
            url.searchParams.append('page', String(paginationChat.value.page))
            url.searchParams.append('page_size', size ? String(size) : String(paginationChat.value.pageSize))         
            const response = await get(`/chat_messages_list/${url.search}&chat=${chatId.value}`)
            paginationChat.value.next = response.parsedBody.next
            paginationChat.value.total = response.parsedBody.count
            allMessages.value = response.parsedBody.results.reverse()
            searchEquelDates(allMessages.value)
            paginationChat.value.success = true
        } catch (e) {
            showNotification('Ошибка при загрузке сообщений! Повторите позже!', 'danger')
            paginationChat.value.success = false
            console.error(e)
        }
    }
    const createDialogue = async (id: number) => {
        try {
            loadingModal.value = true
            const response = await post(`/chat_list/`, {
                colleague: id,
            })
            return response.parsedBody
        } catch (e) {
            showNotification('Ошибка при создании диалога! Повторите позже!', 'danger')
            console.error(e)
        } finally {
            loadingModal.value = false
        }
    }

    const createSocket = async() => {
        const ws = await new WebSocket(`${process.env.VUE_APP_API_BASE_URL_WS}/ws/chat/${chatId.value}/?token=${token.value}`)
        webSocket.value.data = ws
        ws.onopen = (e) => {
            webSocket.value.connection = true
            webSocket.value.error = false
        }
        ws.onmessage = (e) => {
            newMsg.value = true;
            const newMessage = JSON.parse(e.data)
            allMessages.value.push(newMessage)
            searchEquelDates(allMessages.value)
        }
        ws.onerror = (e) => {
            showNotification('Ошибка соединения! Повторите позже!', 'danger')
            webSocket.value.connection = false
            webSocket.value.error = true
        }
        ws.onclose = (e) => {
            webSocket.value.connection = false
            webSocket.value.error = e.wasClean
            ws.close()
            if (route.name === 'messagesDetail' && !webSocket.value.error) {
                setTimeout(async () => {
                    createSocket()
                }, 3000) 
            }
        }      
        return ws
    }
    
    return {
        pagination,
        paginationModal,
        paginationChat,
        loading,
        loadingModal,
        colleague,
        chats,
        availableUsers,
        allMessages,
        webSocket,
        newMsg,
        fetchAllChats,
        fetchAvailableUsers,
        fetchAllMessages,
        fetchColleague,
        createDialogue,
        dateFormatting,
        searchEquelDates,
        createSocket,
    }
}