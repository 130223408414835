import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import FontBGPLugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import numberedList from '@ckeditor/ckeditor5-list/src/list';
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
// import Blockquote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';

export const useCKed = () => {
    return {
        editor: ClassicEditor,
        editorConfig: {
            plugins: [
                Autoformat,
                EssentialsPlugin,
                Heading,
                BoldPlugin,
                ItalicPlugin,
                LinkPlugin,
                ParagraphPlugin,
                FontBGPLugin,
                FontFamily,
                FontSizePlugin,
                FontColor,
                Alignment,
                numberedList,
                IndentPlugin,
                // Blockquote,
                Underline,
                Superscript,
                Subscript,
                Strikethrough,
                SpecialCharacters,
                SpecialCharactersEssentials
                // CKFinder
            ],
            toolbar: {
                items: [
                    'heading',
                    '|',
                    'fontSize',
                    'fontFamily',
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    '|',
                    'alignment',
                    '|',
                    'numberedList',
                    'bulletedList',
                    '|',
                    'indent',
                    'outdent',
                    '|',
                    'subscript',
                    'superscript',
                    'specialCharacters',
                    // 'blockquote',
                    'link',
                    'imageUpload',
                    'insertTable',
                    '|',
                    // 'ckfinder',
                    'uploadImage',
                    '|',
                    'undo',
                    'redo'
                ],
                shouldNotGroupWhenFull: false,
            },
            // ckfinder: {
            //     // Upload the images to the server using the CKFinder QuickUpload command.
            //     uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/api/v1/lk/wysiwyg_file_upload/`,
            //
            //     // Define the CKFinder configuration (if necessary).
            //     // options: {
            //     //     resourceType: 'Images',
            //     // },
            // },
            indentBlock: {
                offset: 1,
                unit: 'em',
            },
            language: 'ru',
        },
    };
}

