import {ref, } from 'vue';
import moment from 'moment';

import {get, } from '@/modules/http';
import {useCommmonNotification,} from '@/composition/notification';
import {useCookie,} from '@/composition/cookie';

export function useResultSurveys() {
    const {showNotification,} = useCommmonNotification()
    const {getCookie,} = useCookie()
    const isUploadingBtn = ref(false)

    // Получить результаты опросов всей школы
    const resultSurveysRead = async (search) => {
        const page = 1
        const pageSize = 10
        const response = await get(`/school_moderator/survey_result/?page=${page}&page_size=${pageSize}&search=${search}`)
        if (response.ok) {
            return response.parsedBody
        }
        return null
    };
    // Получить деталку теста для модератора
    const detailSurveyRead = async (id) => {
        const response = await get(`/school_moderator/survey_result/${id}`)
        if (response.ok) {
            return response.parsedBody
        }
        return null
    }

    // Скачать данные в excel
    const downloadSurveysResults = async() => {
        try {
            isUploadingBtn.value = true
            const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`
            const token = getCookie('token')
            await fetch(`${apiURL}/school_moderator/survey_result_export/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if ((response.ok === true) && (response.status === 200)) {
                    return response.blob();
                }
                return null
            }).then((data) => {
                if (data === null) {
                    throw new SyntaxError('Данные необнаружены')
                }
                const Filename = `Surveys_Result ${moment().format(
                    'DD.MM.YYYY HH-mm'
                )}.xlsx`;
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', Filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                isUploadingBtn.value = false
            })
        } catch(e) {
            showNotification('Произошла ошибка при скачивании документа', 'danger')
            isUploadingBtn.value = false
        }
    }

    const modalInfoData = ref ({
        Header: String,
        id: Number,
        teacher_id: Number,
        username: String,
        hidden_in_public: Boolean,
        survey_name: String,
        annotation: String,
        quantity_questions: Array,
        quantity_answers: Array,
        auditoryes: Array,
    })    

    const headersResultSurveys = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Сотрудник',
            value: 'employee',
        },
        { 
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Аудитория',
            value: 'auditoryes',
        },
        {
            text: 'Дата прохождения',
            value: 'end_date',
        }
    ]
    return {
        resultSurveysRead,
        detailSurveyRead,
        headersResultSurveys,
        downloadSurveysResults,
        modalInfoData,
        isUploadingBtn,
    }
}