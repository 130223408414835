<template lang="pug">
template(v-if="route.path === '/diagnostics/tests/completed'")
    template(v-if="!loadingHook")
        template(v-if="items.length !== 0")
            Table(:headers="headersCompleted" :items="items" :rowHover="true" @rowClick="onRowClick" :is_hover="true")
            .more-btn-block__for-table(v-if="visibleMoreBtn")
                btn.btn--outline(v-if="!isUploading"
                    text="Показать еще" 
                    @mousedown="upload")
                LoadingIndicator(v-else)
        template(v-else)
            p.par Новых тестов нет
    template(v-else)
        .loading-indicator
            LoadingIndicator
router-view
</template>

<script>
import {onMounted, ref, computed, } from 'vue';
import {useRoute,} from 'vue-router';

// components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

// compositions
import {useTest,} from '@/composition/diagnosticTest';

export default {
    components: {
        Table,
        LoadingIndicator,
    },
    emits: ['on-modal-open', 'modal-indo-btn'],
    setup(_, {emit,  }) {
        const {
            testList,
            testRead,
            testResultList,
            checkNextUrl,
            loadData,
            headersCompleted,
            modalInfoData,
            visibleMoreBtn,
            isUploading,            
        } = useTest();  

        // const
        const route = useRoute()
        const data = ref([])
        const loadingHook = ref(true)

        // function
        onMounted(async () => {
            const response = await testList('completed')
            data.value = response.results
            checkNextUrl(response)
            loadingHook.value = false
        });
        const onRowClick = async (row) => {
            const response = await testRead(row.id)
            modalInfoData.value.modal.Header = 'Информация о тесте'
            modalInfoData.value.modal.HeaderAccept = `Вы уверены, что хотите начать проходить тест "${response.name}"?`
            modalInfoData.value.modal.HeaderQuiz = 'Тестирование'
            modalInfoData.value.modal.HeaderDecline = 'Вы уверены, что хотите прекратить прохождение теста? Ваши результаты будут сохранены. Вы сможете продолжить прохождение теста позднее.'
            modalInfoData.value.type = 'test'
            modalInfoData.value.name = response.name
            modalInfoData.value.annotation = response.annotation
            modalInfoData.value.attempting.number = response.attempting.number
            modalInfoData.value.attempting.max_attempting = response.attempting.max_attempting
            modalInfoData.value.attempting.next = response.attempting.next
            modalInfoData.value.status_for_user = response.status_for_user.last
            modalInfoData.value.id = row.id
            modalInfoData.value.count_questions = response.count_questions
            modalInfoData.value.subject_area = row.subject_area
            modalInfoData.value.competence = row.competence
            modalInfoData.value.questions = response.questions
            emit('on-modal-open', modalInfoData.value)
        }
        const upload = () => loadData(data)

        return {
            // useTest
            testList,
            testResultList,
            headersCompleted,
            // const
            loadingHook,
            visibleMoreBtn,
            isUploading,
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    text: x.name,
                    subject_area: x.subject_area,
                    competence: x.competence,
                    attempting: x.attempting.number + ' из ' + x.attempting.max_attempting,
                    score: `${x.score.my_result !== null ? x.score.my_result : 0} из ${x.score.max_result}`,
                }))
            }),

            route,
            // function
            onRowClick,
            checkNextUrl,
            upload,
        }
    },
}
</script>

<style scoped>
</style>
