<template lang="pug">
.profile__content.profile__content--column.route__content
    p.par(v-if="status === 3") Тьютор составил для вас индивидуальный маршрут. Если в нем имеются платные мероприятия или курсы, то в процессе прохождения маршрута их нужно будет оплатить. Если у вас возникли вопросы по поводу него, пожалуйста, свяжитесь с тьютором
    p.par(v-if="status === 4") Вы отправили разработанный маршрут на проверку тьютору. В ближайшее время он либо одобрит маршрут, либо свяжется с вами.
MyTutor.my-tutor__block(:tutor="tutor")
.list-events
    .list-events__title Список курсов и мероприятий в маршруте
    Table(:headers="headersOnConformition" :items="items" :rowHover="true" :is_hover="true" @rowClick="onRowClick")
TableStatistic.mini-statistics-block(
    v-if="visibleTableInfo"
    title="Промежуточная статистика маршрута"
    :dataTable="dataTableInfo"
)
TableStatistic.mini-statistics-block(
    v-if="paidEvents.length > 0"
    title="Мероприятия и курсы к оплате"
    :dataTable="paidEvents"
    :hasLink="true"
)  
.buttons-container(v-if="status === 3")
    button.btn.btn--do-action.route-btn.btn-left(@click="openModal(true)") Подтвердить
    button.btn.btn--do-action.route-btn(@click="openModal(false)") Отправить на доработку
</template>

<script>
import {computed, ref,} from 'vue';

import MyTutor from "@/components/IndividualRoute/my-tutor/MyTutor.vue";
import Table from '@/components/Generic/table';
import TableStatistic from '@/components/IndividualRoute/TableStatistics.vue';
// composition
import {useIndividualRoute,} from '@/composition/individualRoute';

export default {
    components: {
        MyTutor,
        Table,
        TableStatistic,
    },
    props: {
        tutor: {
            type: Object,
        },
        status: {
            type: Number,
        },
        dataRoute: {
            type: Object,
        },
    },
    emits: ['on-modal-open'],
    setup(props, {emit, }) {
        const {
            headersOnConformition, 
            normalizeData,
            findPaidEvents,
        } = useIndividualRoute()
        const paidEvents = findPaidEvents(props.dataRoute.curriculum_event_info)
        const dataTableInfo = [
            {
                name: 'Количество часов',
                value: props.dataRoute.hours_count !== null ? `${props.dataRoute.hours_count} ч.` : '-',
            },
            {
                name: 'Дата начала',
                value: props.dataRoute.start_date ? normalizeData(props.dataRoute.start_date) : '-',
            },
            {
                name: 'Дата окончания',
                value: props.dataRoute.end_date ? normalizeData(props.dataRoute.end_date) : '-',
            }
        ]
        const visibleTableInfo = computed(() => {
            if (props.dataRoute.start_date === null && props.dataRoute.end_date === null && props.dataRoute.hours_count === null) {
                return false
            }
            return true
        })
        //function
        const openModal = (confirm) => {
            emit('on-modal-open', confirm)
        }
        const onRowClick = (row) => {
            const type = row.type === 'Курс' ? 'courses' : 'events'
            window.open(`${process.env.VUE_APP_PUBLIC_URL}/${type}/${row.about.slug}`, "_blank")
        }
        return {
            // const
            headersOnConformition,
            dataTableInfo,
            paidEvents,
            visibleTableInfo,
            items: computed(() => {
                return props.dataRoute.curriculum_event_info.map(x => ({
                    id: x.id,
                    date_at: x.about.date_at_iso ? normalizeData(x.about.date_at_iso) : '-',
                    date_end: x.about.date_end_iso ? normalizeData(x.about.date_end_iso) : '-',
                    text: x.about.name,
                    type: x.type === 'event' ? 'Мероприятие' : 'Курс',
                    hours: x.about.hours !== null ? `${x.about.hours} ч.` : '-',
                    competence: x.about.competence !== null ? x.about.competence.name : '-',
                    about: x.about,
                }))
            }),
            // function
            openModal,
            onRowClick,
            findPaidEvents,
        }
    },
};
</script>
