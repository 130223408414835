<template lang="pug">
template(v-if="route.path === '/individual-route/completed'")
    template(v-if="!loading")
        template(v-if="items.length !== 0")
            Table(:headers="headersCompletedRoutes" :items="items" :rowHover="true" @rowClick="onRowClick" :is_hover="true")
            .more-btn-block__for-table(v-if="visibleMoreBtn")
                btn.btn--outline(v-if="!isUploading"
                    text="Показать еще" 
                    @mousedown="upload")
                LoadingIndicator(v-else)
        template(v-else)
            p.par Завершенных маршрутов нет
    template(v-else)
        .loading-indicator
            LoadingIndicator
router-view        
</template>

<script>
import {onMounted, ref, computed,} from 'vue';
import {useRouter, useRoute,} from 'vue-router'

//components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
//composition
import {useIndividualRoute,} from '@/composition/individualRoute';

export default {
    components: {
        LoadingIndicator,
        Table,
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const loading = ref(true)
        const {
            completedRouteList,
            checkNextUrl,
            loadData,            
            headersCompletedRoutes,
            visibleMoreBtn,
            isUploading,
            normalizeData,
        } = useIndividualRoute();

        const data = ref([])
        // function
        const onRowClick = async (row) => {
            router.push(`/individual-route/completed/result/${row.id}`)
        }
        const upload = () => loadData(data)
        const numberEvents = (data, typeEvent) => {
            const numberEvent = ref(0)
            data.map((event) => {
                if (event.type === typeEvent) {
                    numberEvent.value += 1
                }
            })
            return numberEvent.value.toString()
        }
        onMounted(async () => {
            loading.value = true
            const response = await completedRouteList();
            data.value = response.results
            checkNextUrl(response)
            loading.value = false
        });
        return {
            route,
            loading,
            headersCompletedRoutes,
            visibleMoreBtn,
            isUploading,
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    date_at: x.start_date !== null ? normalizeData(x.start_date) : '-',
                    date_end: x.end_date !== null ? normalizeData(x.end_date) : '-',
                    text: x.hours_count !== null ? `${x.hours_count} ч.` : '-',
                    number_events: numberEvents(x.curriculum_event_info, 'event'),
                    number_courses: numberEvents(x.curriculum_event_info, 'course'),
                }))
            }),            
            onRowClick,
            checkNextUrl,
            upload,               
        }
    },
}
</script>