import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EventsBlock = _resolveComponent("EventsBlock")

  return (_openBlock(), _createBlock(_component_EventsBlock, {
    "event-type": "base_events",
    limit: 3,
    page: 1,
    reccomended: ""
  }))
}