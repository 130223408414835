
import {defineComponent, PropType,} from 'vue'

import {SchoolPerson,} from '@/types/school'

import {usePerson,} from '@/composition/person'
import {useEnv,} from '@/composition/env'

export default defineComponent({
    name: 'ColleagueItem',
    props: {
        collegue: {
            type: Object as PropType<SchoolPerson>,
            required: true,
        },
    },
    setup(props, emit) {
        const {
            localPerson,
            isSaving,
            acceptCollegue,
            declineCollegue,
            sendCollRequest,
            rejectCollRequest,
        } = usePerson(props.collegue, emit)

        // const orgs = localPerson.value.orgs.join(' ,')

        const orgs = localPerson.value.positions

        const {publicURL,} = useEnv()

        return {
            localPerson,
            isSaving,
            orgs,
            publicURL,
            acceptCollegue,
            declineCollegue,
            sendCollRequest,
            rejectCollRequest,
        }
    },

})
