<template lang="pug">
.test-block
    h4.test-block__title {{detailTest.test.name}}
    .test-block__info
        .main-info__block-result
            .result
                fieldset
                    legend Попытка
                    div {{detailTest.attempting}} из {{detailTest.test.max_attempting}}
                fieldset
                    legend Сотрудник
                    div
                        a(:href="`${pathPublic}/teachers/${detailTest.employee.id}`" target="_blank") {{`${detailTest.employee.last_name ? detailTest.employee.last_name : ''} ${detailTest.employee.first_name ? detailTest.employee.first_name : ''} ${detailTest.employee.middle_name ? detailTest.employee.middle_name : ''}`}}    
                fieldset
                    legend Результат
                    div {{detailTest.point_count}} из {{detailTest.max_points}}
        .questions__block
            Table(:headers="headersDetailTest" :items="items" :is_hover="true" :detail_attempt="true")
</template>

<script>
import {defineComponent, onMounted, } from 'vue'

import {useResultTests,} from '@/composition/schoolTests';

import Table from '@/components/Generic/table/index.vue';

export default defineComponent({
    name: 'DetailSchoolTest',
    components: {
        Table,
    },
    props: {
        detailTest: {
            type: Object,
        },
        items: {
            type: Array,
        },
    },
    setup(props) {
        const pathPublic = process.env.VUE_APP_PUBLIC_URL
        const tableHeaders = ['Попытка', 'Сотрудник', 'Итоговые баллы']
        const { headersDetailTest, } = useResultTests()

        return {
            pathPublic,
            tableHeaders,
            headersDetailTest,
        }
    },
})
</script>

<style lang="scss" scoped>
.test-block {
    &__title {
        font-size: 21px;
        line-height: 140%;
        color: #1B1B1A;
        font-weight: bold;
    }
    .main-info__block-result {
        margin: 30px 0;
        .result {
            display: flex;
            a {
                color: #101010;
                &:hover {
                    color: $accent-color-hover;
                }
            }
        }
        fieldset {
            margin-bottom: 30px;
            margin-right: 30px;
            border: none;
            line-height: 25px;
            font-weight: 500;
            font-size: 16px;
            @media(max-width: 350px) {
                margin-right: 10px;
            }
            legend {
                font-size: 13px;
                color: #6d6d6d;
            }
        }
    }
}
</style>