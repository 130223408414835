import { ref, } from 'vue'

export const useToggle = (initialState: boolean) => {
    const isVisible = ref(initialState)
    const show = () => {
        isVisible.value = true
    }
    const hide = () => {
        isVisible.value = false
    }
    const toggleVisibility = () => {
        isVisible.value = !isVisible.value
    }
    return {
        isVisible,
        show,
        hide,
        toggleVisibility,
    }
}
