import {get, post, } from '@/modules/http';
import {ref, } from 'vue';
import { useCommmonNotification, } from '@/composition/notification';

export function useCase() {
    const { showNotification, } = useCommmonNotification()

    // const для checkNextUrl
    const visibleMoreBtn = ref(false)
    const isUploading = ref(false)
    const next = ref('')
    const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

    const caseRead = async (id) => {
        const response = await get(`/case/${id}/`);        
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const caseList = async (status) => {       
        const page = 1
        const pageSize = 10
        const response = await get(`/case/?page=${page}&page_size=${pageSize}&filter=${status}`);
        if (response.ok) { 
            return response.parsedBody;
        }
        return null;
    };
    const caseResultRead = async (id) => {
        const response = await get(`/case_result/${id}`);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const caseResultList = async () => {
        const pageSize = 1000000
        const response = await get(`/case_result/?page_size=${pageSize}`);
        if (response.ok) {            
            return response.parsedBody;
        }
        return null;
    };
    const caseResultCreate = async (data) => {
        const response = await post('/case_result/', data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    const sendAnswerOnCase = async (data) => {
        const response = await post('/case_answer/', data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // Проверка параметра next в response для подгрузки данных
    const checkNextUrl = (response) => {
        if (response.next !== null) {
            next.value = response.next.replace(apiURL, '')
            visibleMoreBtn.value = true
            return next.value
        }
        next.value = response.next
        visibleMoreBtn.value = false
        return next.value
    }
    // Подгрузка данных "Показать еще"
    const loadData = async(data) => {
        try{
            isUploading.value = true
            const response = await get(next.value)
            if (response.ok) {
                data.value = data.value.concat(response.parsedBody.results)
                checkNextUrl(response.parsedBody)
                isUploading.value = false
            } else {
                throw new SyntaxError('Произошла ошибка')
            }
        } catch(e) {
            showNotification('Ошибка получения данных', 'error')
            isUploading.value = false
        }
    }    
    
    const modalInfoData = ref ({
        modal: {
            Header: String,
            HeaderAccept: String,
            BodyAccept: String,
            HeaderQuiz: String,
            HeaderDecline: String,
            BodyDecline: String,
        },
        type: String,
        name: String,
        annotation: String,
        attempting: {
            number: Number,
            max_attempting: Number,
            next: String,
        },
        status_for_user: '',
        connectId: Number,
        connectIdTwo: Number,
        count_questions: Number,
        answer_content_type_id: Number,
        id: Number,
        subject_area: Array,
        allAttempts: Array,
        competence: Array,
        questions: Array,
    })
    const headersNew = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Доступно попыток',
            value: 'attemptings',
        }
    ]
    const headersProcess = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Попытка',
            value: 'attemptings',
        }
    ]
    const headersCompleted = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Пройдено попыток',
            value: 'attemptings',
        },
        {
            text: 'Баллы',
            value: 'scores',
        }
    ]
    return {
        caseRead,
        caseList,
        caseResultRead,
        caseResultList,
        caseResultCreate,
        sendAnswerOnCase,
        checkNextUrl,
        loadData,
        // header
        headersNew,
        headersProcess,
        headersCompleted,
        // const
        modalInfoData,
        visibleMoreBtn,
        isUploading,
        next,        
    }
}
