export enum workStatus {
    working = 'working',
    not_working = 'not_working',
    claim_created = 'claim_created',
    change_data_request = 'change_data_request',
    delete_data_request = 'delete_data_request',
    moderator = 'moderator',
    reject_moderator = 'reject_moderator',
    reject = 'reject'
}
