import { SelectListItem, } from '@/types/common'

import { get, http, } from '@/modules/http'

export const useAutocomplete = () => {

    const requestForVariants = async (endpoint: string, searchText: string, mock = false, page= 1) => {
        try {

            const response = !mock ? await  get<SelectListItem[]>(`${endpoint}/?search=${searchText}&page=${page}`) :  await  get<SelectListItem[]>(endpoint, {}, true)
            if (response.status === 200) {
                return response.parsedBody
            }
            else return {
                next_page: null,
                results: [],
            }
        }
        catch (e) {
            return {
                next_page: null,
                results: [],
            }
        }
    }

    const  requestForAddress = async (adress: string): Promise<SelectListItem[]> => {
        const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
        const  token = '56d17025d7e62c0fb70f1186f24bf57b5c1a3d52'

        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Token ' + token,
            } ,
            body: JSON.stringify({query: adress,}),
        } as RequestInit
        try {
            const response = await http(new Request(url, options))
            const suggestions: SelectListItem[] = response.parsedBody.suggestions.map( (el) => {
                return {
                    id: Symbol(Date.now()), 'name' : el.value,
                }
            })
            return suggestions

        }
        catch (e) {
            console.error(e)
        }
        return  []
    }

    return {
        requestForVariants,
        requestForAddress,
    }
}

