<template lang="pug">
.modal.modal-fixed.modal--big
    .modal__bg(@click.self="$emit('close')")
    .modal__content
        .modal-window__btn-close(@click.prevent ="$emit('close')")
            .modal-window__btn-close-wrapper
                span.modal-window__btn-close-line
                span.modal-window__btn-close-line
        .modal__head
            .modal__title Добавить коллегу
            .modal__search
                SelectBlockModal(name_search='Поиск по ФИО' @search="onSearch")
        .modal__body(ref="scroll")
            .modal__lists
                ColleagueList(
                    :colleagues="notColleagues"
                    :loading="loading"
                    btnText="Добавить"
                    @onClick="addToColleaguesHandler"
                )
        .modal__footer
            btn.btn.btn--small.btn--do-action(@click="loadMore($event)" :disabled="notColleagues.length === pagination.total" text="Загрузить ещё")
</template>

<script>
import {defineComponent, ref, } from 'vue'

import SelectBlockModal from '@/components/Generic/select-block-modal/index.vue'
import {useColleagues,} from '@/composition/colleagues';

export default defineComponent({
    name: 'ColleagueAdd',
    components: {
        SelectBlockModal,
    },
    emits: ['close'],
    async setup(props) {
        const {
            notColleagues,
            pagination,
            loading,
            fetchNotColleagues,
            addColleague,
        } = useColleagues(props)

        const searchText = ref('')

        const onSearch = async (q) => {
            searchText.value = q
            await fetchNotColleagues(searchText.value, notColleagues.value.pageSize)
        }

        const scroll = ref(null)

        const loadMore = async () => {
            await fetchNotColleagues(searchText.value, notColleagues.value.length + pagination.value.pageSize)

            const {offsetHeight, scrollHeight,} = scroll.value

            scroll.value.scrollTo({
                top: scrollHeight + offsetHeight,
                left: 0,
                behavior: 'smooth',
            });
        }
        const addToColleaguesHandler = async (colleague) => {
            await addColleague(colleague)
            await fetchNotColleagues(searchText.value, notColleagues.value.length - 1)
        }
        await fetchNotColleagues()
        return {
            searchText,
            notColleagues,
            pagination,
            loading,
            scroll,
            onSearch,
            loadMore,
            fetchNotColleagues,
            addToColleaguesHandler,
        }
    },

})
</script>
<style lang="scss" scoped>
.modal__content  {
    .modal-window__btn-close {
        @media (max-width: 415px) {
        right: 10px;
        top: 10px;
        }
    }
}

</style>
