import {get,} from '@/modules/http';
import {sleep,} from '@/modules/helpers';
import {useCommmonNotification,} from '@/composition/notification';

export function useMoodle() {
    const getMoodle = async (eventType: string, eventTypeText: string, slug: string) => {
        const {showNotification,} = useCommmonNotification()
        const response = await get(`/${eventType}/${slug}/moodle_login_url/`);
        if (response.ok) {
            window.open(response.parsedBody.login_url, '_blank')
            return response.parsedBody;
        }
        showNotification(`${eventTypeText}. Обратитесь к администрации сайта`, 'danger')
        await sleep(1500);
        return null;
    }
    return {
        getMoodle,
    }
}