import {get, post, } from '@/modules/http';
import {ref, } from 'vue';
import moment from 'moment';
import { useCommmonNotification, } from '@/composition/notification';

export function useMonitoring() {
    const acceptBtns = [
        {
            text: 'Да',
            event: 'ok',
            class: '',
        },
        {
            text: 'Нет',
            event: 'close',
            class: 'btn--outline',
        }
    ]
    const { showNotification, } = useCommmonNotification()

    // const для checkNextUrl
    const visibleMoreBtn = ref(false)
    const isUploading = ref(false)
    const next = ref('')
    const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

    // Получить список тестов
    const surveyList = async (status) => {
        const page = 1
        const pageSize = 10
        const url = `/survey/?page=${page}&page_size=${pageSize}&filter=${status}`
        const response = await get(url);
        if (response.ok) {   
            return response.parsedBody;
        }
        return null;
    };
    // деталка опроса
    const surveyReadId = async (id) => {
        const url = `/survey/${id}/`
        const response = await get(url);
        if (response.ok) {   
            return response.parsedBody;
        }
        return null;
    };
    // Связать опрос с вопросами
    const surveyResultCreate = async (data) => {
        const response = await post('/survey_result/', data);
        if (response.ok) {            
            return response.parsedBody;
        }
        return null;
    };
    // Отправить ответ по опросу
    const sendAnswerOnSurvey = async (data) => {
        const response = await post('/survey_answer/', data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // Получить деталку результата с ответами по опросу
    const detailAnswerOnSurvey = async (id) => {
        const response = await get(`/survey_result/${id}/`);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };      
    // Проверка параметра next в response для подгрузки данных
    const checkNextUrl = (response) => {
        if (response.next !== null) {
            next.value = response.next.replace(apiURL, '')
            visibleMoreBtn.value = true
            return next.value
        }
        next.value = response.next
        visibleMoreBtn.value = false
        return next.value
    }
    // Подгрузка данных "Показать еще"
    const loadData = async(data) => {
        try{
            isUploading.value = true
            const response = await get(next.value)
            if (response.ok) {
                data.value = data.value.concat(response.parsedBody.results)
                checkNextUrl(response.parsedBody)
                isUploading.value = false
            } else {
                throw new SyntaxError('Произошла ошибка')
            }
        } catch(e) {
            showNotification('Ошибка получения данных', 'error')
            isUploading.value = false
        }
    }
    const typeQuestion = (questions) => {
        const required = ref(0)
        const unrequired = ref(0)
        questions.map((question) => {
            question.is_required ? required.value++ : unrequired.value++
        })
        return [required.value, unrequired.value]
    }
    const normalizeData = (resultData) => {
        const data = new Date(resultData)
        resultData = moment(data).format('DD.MM.YYYY')
        return resultData
    }
    const headersActual = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Дата завершения',
            value: 'date_end',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Аудитория',
            value: 'auditoryes',
        }
    ]

    const headersCompleted = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Дата прохождения',
            value: 'date_end',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Аудитория',
            value: 'auditoryes',
        }
    ]
    const modalInfoData = ref ({
        modal: {
            Header: String,
            HeaderAccept: String,
            HeaderQuiz: String,
            HeaderDecline: String,
        },
        name: String,
        annotation: String,
        questions: Array,
        count_questions: Number,
        id: Number,
        mode: String,
        quantity_questions: Array,
        quantity_answers: Array,
        auditoryes: Array,
        status_for_user: Object,
    })
    return {
        // function
        surveyList,
        surveyReadId,
        surveyResultCreate,
        sendAnswerOnSurvey,
        detailAnswerOnSurvey,
        checkNextUrl,
        loadData,
        normalizeData,
        typeQuestion,
        // const
        acceptBtns,
        visibleMoreBtn,
        isUploading,
        next,
        headersActual,
        headersCompleted,
        modalInfoData,
    }
}