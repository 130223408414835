import { ref, watch, } from 'vue'
import { del, } from "@/modules/http";

import { SelectListItem, } from '@/types/common'

export const useSelectList = (listArr: SelectListItem[] = []) => {

    const list = ref<SelectListItem[]>(listArr)

    const addItem = () => {
        list.value.push({
            id: null,
            name: '',
        })
    }

    const deleteItem = (index: number) => {
        list.value.splice(index, 1)
    }

    const deleteItemByID = async (toDeleteID: number) => {
        const index = list.value.findIndex( (item: SelectListItem) => item.id === toDeleteID)
        if (toDeleteID !== null) {
            await del(`/profile/hobbies/${toDeleteID}/`, {});
        }
        deleteItem(index)
    }

    const onEnter = () => {
        addItem()
    }
     
  
    return {
        list,
        addItem,
        deleteItem,
        deleteItemByID,
        onEnter,
    }
}
