<template lang="pug">
Tabs.tab-container--underline(:tab-items="tabItems" :active-tab="activeTab" className="tab-container__inner")
.profile__content.profile__content--column
    p.par(v-if="router.path == '/diagnostics/tests/process' || router.path == '/diagnostics/tests/new' || router.path == '/diagnostics/tests/completed'") Тестирование предполагает решение некоторого количества вопросов с закрытой формой ответа. По итогам прохождения будет рассчитан результат прохождения и предоставлены рекомендации по развитию профессиональных компетенций.
router-view(v-slot="{ Component }")
    suspense
        component(:is="Component" :currentPage="currentPage" @on-modal-open="openModal" @set-remove-row="setRemoveRow")
//- modal info
ModalWindow(
    :is-visible="isModalVisible"
    isBig="true"
    :title="modalInfoData.modal.Header"
    :buttons="modalInfoBtn"
    :addInput="false"
    @close="closeModalInfo"
    @ok="openAcceptModal"
)
    ModalInfo(:modalData="modalInfoData" :loading="loading" @on-modal-close="closeModalInfo")
//- modal accept
ModalWindow(
    :is-visible="isModalVisibleTwo"
    :title="modalInfoData.modal.HeaderAccept"
    :buttons="acceptBtns"
    @ok="start"
    @close="onModalCloseTwo"
)
ModalWindow(
    :is-visible="isModalVisibleQuiz"
    :title="modalInfoData.modal.HeaderQuiz"
    message=" "
    @close="stopQuiz"
)
    Quiz(:quiz="quizData", mode="test" @on-modal-close="endQuiz" @get-all-tests="getAllResults")
ModalWindow(
    :is-visible="isModalVisibleStopQuiz"
    :title="modalInfoData.modal.HeaderDecline"
    @close="onModalCloseStopQuiz"
    @ok="decline"
    :buttons="acceptBtns"
)
</template>

<script>
import {defineComponent, ref, } from 'vue'
import {useRoute,} from 'vue-router';

//import modules
import {tabs,} from '@/modules/constants/tabs'

//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'
import Quiz from '@/components/Generic/quiz/index.vue'

//import composition
import {useTabs,} from '@/composition/tabs'
import {useDiagnostic,} from '@/composition/diagnostic';
import {useTest,} from '@/composition/diagnosticTest';
import {useModal,} from '@/composition/modal';

export default defineComponent({
    components: {
        Tabs,
        ModalWindow,
        ModalInfo,
        Quiz,
    },
    setup() {
        let removeRow = {}
        const setRemoveRow = (row) => {
            removeRow = row
        }
        const router = useRoute();
        const {
            tabItems,
            activeTab,
        } = useTabs(tabs.diagnosticTestsNested)
        // composition const
        const {
            acceptBtns,
            normalizeData,
        } = useDiagnostic();
        const {
            testRead,
            testResultList,
            testResultCreate,
            modalInfoData,
        } = useTest();
        const {
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
        } = useModal()
        const {
            isModalVisible: isModalVisibleTwo,
            modalTitle: modalTitleTwo,
            modalMessage: modalMessageTwo,
            onModalClose: onModalCloseTwo,
        } = useModal()
        const {
            isModalVisible: isModalVisibleQuiz,
            modalTitle: modalTitleQuiz,
            modalMessage: modalMessageQuiz,
            onModalClose: onModalCloseQuiz,
        } = useModal()
        const {
            isModalVisible: isModalVisibleStopQuiz,
            modalTitle: modalTitleStopQuiz,
            modalMessage: modalMessageStopQuiz,
            onModalClose: onModalCloseStopQuiz,
        } = useModal()
        //const
        const status = ref()
        const modalInfoBtn = ref()
        const connectId = ref()
        const loading = ref(true)
        const quizData = ref({
            id: Number,
            questions: Array,
            count_questions: Number,
        })
        const allResultTests = ref([])

        // function
        const getAllResults = async () => {
            const answer = await testResultList();
            allResultTests.value = answer.results
        }
        const openModal = async(response) => {
            loading.value = true
            if (response.attempting.next != null) {
                response.attempting.next = normalizeData(response.attempting.next)
            }
            modalInfoData.value = response
            status.value = modalInfoData.value.status_for_user
            if (status.value === 'new') modalInfoBtn.value = [
                {
                    text: 'Начать тест',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'process') modalInfoBtn.value = [
                {
                    text: 'Продолжить тестирование',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'completed') modalInfoBtn.value = [
                {
                    text: 'Пройти повторно',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value === 'completed' && response.attempting.number === response.attempting.max_attempting) modalInfoBtn.value = []
            if (response.attempting.next !== null) modalInfoBtn.value = []
            document.documentElement.style.overflow = 'hidden';
            isModalVisible.value = true

            if (status.value === 'process' || status.value === 'completed') {
                if (allResultTests.value.length === 0) {
                    await getAllResults()
                }
                const resultAttempts = ref([])
                for (let i = 0; i < allResultTests.value.length; i++) {
                    const element = allResultTests.value[i];
                    if (response.id === element.test.id) {
                        if (element.attempting === 1) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Первая попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                                is_completed: element.is_completed,
                            })
                        } else if (element.attempting === 2) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Вторая попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                                is_completed: element.is_completed,
                            })
                        } else if (element.attempting === 3) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Третья попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                                is_completed: element.is_completed,
                            })
                        } else if (element.attempting === 4) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Четвертая попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                                is_completed: element.is_completed,
                            })
                        } else if (element.attempting === 5) {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `Пятая попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                                is_completed: element.is_completed,
                            })
                        } else {
                            resultAttempts.value.unshift({
                                id: element.id,
                                text: `${element.attempting} попытка прохождения теста - ${element.is_completed ? 'пройдена' : 'в процессе'}. `,
                            })
                        }
                        modalInfoData.value.allAttempts = resultAttempts.value
                    }
                }
            }
            loading.value = false
        }
        const openAcceptModal = () => {
            isModalVisibleTwo.value = true
        }
        const start = async () => {
            const status = modalInfoData.value.status_for_user
            if (status !== 'process') {
                const data = {
                    'test': modalInfoData.value.id,
                }
                // get connection id
                const response = await testResultCreate(data)
                connectId.value = response.id
                const responseQuestions = await testRead(modalInfoData.value.id)
                modalInfoData.value.questions = responseQuestions.questions
            }
            else {
                connectId.value = modalInfoData.value.allAttempts[modalInfoData.value.allAttempts.length - 1].id
            }
            quizData.value.id = connectId.value
            quizData.value.count_questions = modalInfoData.value.count_questions
            quizData.value.questions = modalInfoData.value.questions

            isModalVisible.value = false
            isModalVisibleTwo.value = false
            isModalVisibleQuiz.value = true
            setRemoveRow(modalInfoData.value.id)
        }
        const stopQuiz = async () => {
            isModalVisibleStopQuiz.value = true
        }
        const endQuiz = async () => {
            isModalVisibleQuiz.value = false
        }
        const decline = async () => {
            isModalVisibleQuiz.value = false
            isModalVisibleStopQuiz.value = false
            document.documentElement.style.overflow = 'visible';
            await getAllResults()
        }
        const closeModalInfo = () => {
            isModalVisible.value = false
            document.documentElement.style.overflow = 'visible';
        }
        return {
            // const
            router,
            tabItems,
            activeTab,
            modalInfoData,
            modalInfoBtn,
            connectId,
            quizData,
            status,
            // function
            openAcceptModal,
            start,
            stopQuiz,
            endQuiz,
            decline,
            setRemoveRow,
            closeModalInfo,
            getAllResults,
            // emit func
            openModal,
            // modal info
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
            loading,
            // modal accept
            isModalVisibleTwo,
            modalTitleTwo,
            modalMessageTwo,
            onModalCloseTwo,
            // modal quiz
            isModalVisibleQuiz,
            modalTitleQuiz,
            modalMessageQuiz,
            onModalCloseQuiz,
            // modal decline
            isModalVisibleStopQuiz,
            modalTitleStopQuiz,
            modalMessageStopQuiz,
            onModalCloseStopQuiz,
            //useDiagnostic
            acceptBtns,
            // useTest
            testResultCreate,
        }
    },

})


</script>

<style lang="scss" scoped>

</style>
