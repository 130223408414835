import { InputField, } from '@/types/forms'
import validators  from '@/modules/validators'

export const createInput = (prop?: object): InputField => {
    return {
        subText: 'Телефон',
        inputType:'text',
        inputDisabled:false,
        inputValue:'',
        inputMask: '',
        errorMessages: [],
        validators: [],
        modifier: '',
        labelField: '',
        ...prop,
    }
}

export const commonFields = {
    get address(): InputField {
        return {
            subText: 'Адрес',
            inputType:'text',
            inputValue:'',
            inputDisabled: false,
            inputMask: '',
            validators: [ validators.isNotEmpty ],
            errorMessages: [],
        }
    },
    get phone(): InputField {
        return  {
            subText: 'Телефон',
            inputType: 'tel',
            inputDisabled:false,
            inputValue: sessionStorage.getItem('reg-phone') || '+7 ',
            inputMask: '+7 (###) ###-##-##',
            validators: [
                validators.isPhone,
                validators.isNotEmpty
            ],
            errorMessages: [],
        }
    },
    get pass(): InputField {
        return  {
            subText: 'Текущий пароль',
            inputType: 'password',
            inputDisabled: false,
            inputValue: '',
            inputMask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9a-яA-Я!@#$%^&_*]/, },},},
            validators: [
                validators.isNotEmpty
            ],
            errorMessages: [],
        }
    },
    get newPass(): InputField {
        return  {
            subText: 'Новый пароль',
            inputType: 'password',
            inputDisabled: false,
            inputValue: '',
            inputMask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9a-яA-Я!@#$%^&_*]/, },},},
            validators: [
                validators.isNotEmpty,
                validators.isNotOnlyNumbers,
                validators.isBadPassword,
                // validators.isHaveSpecChars,
                validators.isHasUpperCase,
                validators.isHasLoverCase,
                validators.minLengthPass
            ],
            errorMessages: [],
        }
    },
    get repeatPass(): InputField {
        return  {
            subText: 'Повторите пароль',
            inputType: 'password',
            inputDisabled: false,
            inputValue: '',
            inputMask: { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9a-яA-Я!@#$%^&_*]/, },},},
            validators: [
                validators.isNotEmpty,
                validators.isNotOnlyNumbers,
                validators.isBadPassword,
                // validators.isHaveSpecChars,
                validators.isHasUpperCase,
                validators.isHasLoverCase,
                validators.minLengthPass
            ],
            errorMessages: [],
        }
    },
    get code(): InputField {
        return {
            subText: 'Код',
            inputType: 'text',
            inputValue: '',
            inputDisabled: false,
            inputMask: '######',
            validators: [
                validators.isNumber,
                validators. minLength
            ],
            errorMessages: [],
            modifier: '--small',
            maxLength: 4,
            isCode: true,
        }
    },

    get email(): InputField {
        return {
            subText: 'Email',
            inputType:'email',
            inputValue:'',
            inputDisabled: false,
            inputMask: '',
            validators: [ validators.isEmail ],
            errorMessages: [],
        }
    },
    get name(): InputField {
        return {
            subText: 'Название',
            inputType:'text',
            inputValue:'',
            inputDisabled: false,
            inputMask: '',
            validators: [ validators.isNotEmpty ],
            errorMessages: [],
        }
    },
    get snils(): InputField {
        return {
            subText: 'СНИЛС',
            inputType: 'text',
            inputValue: '',
            inputDisabled: false,
            inputMask: { mask: '###-###-### ##', tokens: { 'Z': { pattern: /[0-9]/, },},},
            validators: [ validators.isNotValidSnils ],
            errorMessages: [],
        }
    },
    get date_of_birth(): InputField {
        return {
            subText: 'Дата Рождения: ГГ-ММ-ДД',
            inputType: 'text',
            inputValue: '',
            inputDisabled: false,
            inputMask: { mask: '####-##-##', tokens: { 'Z': { pattern: /[0-9]/, },},},
            validators: [ validators.isNotValidDateOfBirth ],
            errorMessages: [],
        }
    },
}

