<template lang="pug">
.messages-detail
    a.profile__form__back-link.profile__form__back-link--fixed
        .select.login-block__back-link(@click="returnToMainPage")
            img(src="@/assets/img/back.svg")
            span Вернуться
    .messages-msg
        #messages__body.messages-msg__body
            template(v-if="loadedHook")
                template(v-if="allMessages.length > 0")
                    msgList(
                        :msgs="allMessages")
                .messages-msg__body--empty(v-else)
                    img(:src="require('@/assets/icons/messages-grey.svg')")
                    p(v-if="paginationChat.success") Поприветствуйте коллегу
                    p(v-else) Произошла ошибка загрузки сообщений! 
            .loader-block(v-else)
                .loading-indicator
                    LoadingIndicator
        .messages-msg__footer
            from.messages-msg__form
                .messages-msg__text-send
                    textarea.messages-msg__text-send-input(
                        type="text"
                        ref="textarea"
                        placeholder="Сообщение"
                        :disabled="!webSocket.connection"
                        @input="resize"
                        @keydown.enter="sendMessageHandler($event)"
                    )
                    button.messages-msg__text-send-btn(
                        @click="sendMessageHandler"
                        :disabled="!webSocket.connection"
                    )
                        img(src="@/assets/img/send.svg")
</template>
<script>
import { defineComponent, ref, onMounted, onUpdated, onUnmounted, computed, watch, } from "vue";
import {useRouter,} from 'vue-router'

// composition
import { fixedPage, } from "@/composition/fixedBlock.ts";
import { useMessages, } from "@/composition/messages";

// components
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'


export default defineComponent({
    name: "MessagesDetail",
    components: {
        LoadingIndicator,
    },
    emits: ['show-detail-title', 'come-back-to-main'],
    setup(_, {emit,}) {
        const router = useRouter()

        const { controlClasses, } = fixedPage();
        const {
            allMessages,
            newMsg,
            webSocket,
            colleague,
            paginationChat,
            fetchAllMessages,
            fetchColleague,
            createSocket,
        } = useMessages();

        const loadedHook = ref(false);
        const firstUpdated = ref(true)
        const loadingMsgs = ref(false)
        const scrollbarBottom = ref(true);

        // computed 
        const detailPath = computed(() => {
            return router.currentRoute.value.name;
        })
        // watch
        /* Функция отслеживания пути, для того,
        чтобы при уходе со страницы (причем можно и через
        браузерные кнопки) закрывалось соединение сокета и
        заново показывался живо чат
        */
        watch(detailPath, () => {
            if (detailPath.value !== 'messagesDetail') {
                webSocket.value.data.close();
                const jivo = document.getElementsByTagName('jdiv')[0];
                jivo['style'].display = 'block';
            };
        })

        // function && data for styles
        const textarea = ref(null);
        const convertToNumber = (string = "0") => {
            return string.replace(/[^.\d]/g, "");
        };
        const resize = () => {
            const hieghtBeforeResize = textarea.value.style.height;
            const messagesBody = document.getElementById("messages__body");
            const mbBeforeResize = messagesBody.style.marginBottom || "0";
            textarea.value.style.height = "auto";
            if (textarea.value.scrollHeight <= 76) {
                textarea.value.style.height = '63px'
            } else {
                textarea.value.style.height = textarea.value.scrollHeight + "px";
            }
            
            messagesBody.style.marginBottom = convertToNumber(textarea.value.style.height) - 63 + "px";
            if (window.innerWidth > 768) {
                convertToNumber(messagesBody.style.marginBottom) > 240
                    ? (messagesBody.style.marginBottom = "240px")
                    : messagesBody.style.marginBottom;
            } else {
                convertToNumber(messagesBody.style.marginBottom) > 110
                    ? (messagesBody.style.marginBottom = "110px")
                    : messagesBody.style.marginBottom;
            }
            if (hieghtBeforeResize !== textarea.value.style.height) {
                const difference =
                    convertToNumber(messagesBody.style.marginBottom) -
                    convertToNumber(mbBeforeResize);
                window.scroll(0, window.scrollY + difference);
                messagesBody.style.minHeight = convertToNumber(messagesBody.style.minHeight) - difference + 'px';
            }
        };
        const minHeightBody = () => {
            const messagesBody = document.getElementById("messages__body")
            const loader = document.getElementsByClassName("loader-block")[0]
            const inputMsg = document.getElementsByClassName("messages-msg__text-send-input")[0]
            const minHeight = inputMsg.getBoundingClientRect().y - messagesBody.getBoundingClientRect().y + 'px'
            messagesBody.style.minHeight = minHeight
            loader.style.minHeight = minHeight
        }
        

        const loadMoreOnScrollHandler = async() => {
            if (paginationChat.value.next !== null && paginationChat.value.onScroll && window.scrollY < 20) {
                paginationChat.value.onScroll = false
                const messagesBody = document.getElementById("messages__body")
                const heightBeforeChange = messagesBody.offsetHeight
                await fetchAllMessages(allMessages.value.length + paginationChat.value.pageSize)
                const newScroll = messagesBody.offsetHeight - heightBeforeChange + window.scrollY
                window.scroll(0, newScroll)
            }
        }

        const sendMessageHandler = (e) => {
            if (!e.shiftKey) {
                e.preventDefault();
                if (textarea.value.value.trim().length === 0) {
                    textarea.value.value = '';
                    resize();
                    return;
                }
                scrollbarBottom.value = Math.ceil(window.innerHeight + window.pageYOffset) >= document.body.offsetHeight;
                const data = {
                    text: textarea.value.value.trim(),
                };
                webSocket.value.data.send(JSON.stringify(data));
                textarea.value.value = '';
                resize();
                return;
            }
        }

        const scrollToBottom = () => {
            if (scrollbarBottom.value && newMsg.value) {
                window.scrollTo(0, document.body.offsetHeight);
            };
            newMsg.value = false;
        }

        const returnToMainPage = () => {
            window.removeEventListener('scroll', loadMoreOnScrollHandler)
            webSocket.value.data.close()
            router.push(`/messages`)
            emit('come-back-to-main', webSocket)
        }

        // hooks
        onMounted(async() => {
            try {
                await createSocket()
                await fetchColleague()
                emit("show-detail-title", colleague.value.data.full_name_initials)
                controlClasses();
                minHeightBody();
                await fetchAllMessages()
            } catch (e) {
                console.log(e);
            } finally {
                const jivo = document.getElementsByTagName('jdiv')[0];
                jivo['style'].display = 'none';
                firstUpdated.value = false;
                loadedHook.value = true;
            }
        });

        onUpdated(() => {
            if (firstUpdated.value === false) {
                window.scroll({
                    top: 1000000,
                    left: 0,
                });
                window.addEventListener('scroll', loadMoreOnScrollHandler)
            }
            firstUpdated.value = true
            paginationChat.value.onScroll = true
            scrollToBottom();
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', loadMoreOnScrollHandler)
        })

        return {
            loadingMsgs,
            loadedHook,
            paginationChat,
            allMessages,
            webSocket,
            returnToMainPage,
            // results,
            textarea,
            resize,
            sendMessageHandler,
        };
    },
});
</script>

<style lang="scss">
.loader-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
