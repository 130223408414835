const publicURL = process.env.VUE_APP_PUBLIC_URL
export const mainMenu =  [
    {
        name: 'О проекте',
        link: `${publicURL}/about/`,
        active: true,
        tooltip: false,
        bigMargin: false,
        cursor: true,
        children: [],
    },
    {
        name: 'Педагогам',
        link: ``,
        tooltip: true,
        bigMargin: false,
        cursor: false,
        children: [
            {
                name: 'Сообщества',
                link: `${publicURL}/community/`,
            },
            {
                name: 'Инновационные практики',
                link: `${publicURL}/practice/`,
            },
            {
                name: 'Новости',
                link: `${publicURL}/news/`,
            },
            {
                name: 'Организации',
                link: `${publicURL}/organization/`,
            },
            {
                name: 'Педагоги',
                link: `${publicURL}/teachers/`,
            },
            {
                name: 'ЦНППМ',
                link: `${publicURL}/cnppm/`,
            }
        ],
    },
    {
        name: 'Курсы',
        link: `${publicURL}/courses/`,
        tooltip: false,
        bigMargin: false,
        cursor: true,
        children: [],
    },
    {
        name: 'Мероприятия',
        link: `${publicURL}/events/`,
        tooltip: false,
        bigMargin: false,
        cursor: true,
        children: [],
    },
    {
        name: 'Олимпиады',
        link: `${publicURL}/olympiads`,
        tooltip: false,
        bigMargin: false,
        cursor: true,
        children: [],
    },    
    {
        name: 'Региональный центр<br />финансовой грамотности',
        link: `${publicURL}/community/regionalnyij-tsentr-finansovoj-gramotnosti-volgogr/`,
        tooltip: true,
        bigMargin: true,
        cursor: true,
        children: [
            {
                name: 'О центре',
                link: `${publicURL}/rcfg-about/`,
            },
            {
                name: 'Документы',
                link: `${publicURL}/rcfg-documents/`,
            },
            {
                name: 'Учебно-методические материалы',
                link: `${publicURL}/rcfg-materials/`,
            },
            {
                name: 'Новости',
                link: `${publicURL}/rcfg-news/`,
            },
            {
                name: 'Мероприятия',
                link: `${publicURL}/rcfg-events/`,
            }
        ],
    }
]
