import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_checkbox = _resolveComponent("checkbox")

  return (_openBlock(), _createBlock(_component_checkbox, {
    class: "field-block--wide",
    text: "Отображать мой профиль публично на странице школы",
    submitText: "Сохранить",
    modelValue: _ctx.showInPublic,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.showInPublic = $event)),
    onSubmit: _ctx.save,
    loading: _ctx.loading,
    buttonParams: _ctx.buttonParams
  }, null, 8 /* PROPS */, ["modelValue", "onSubmit", "loading", "buttonParams"]))
}