import {getDomain,} from '@/modules/helpers';

export const useCookie = () => {
    const getCookie = (name: string) => {
        const matches = document.cookie.match(new RegExp(
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : null;
    }
    const setCookie = (name: string, value: string, maxAge = 100 ) => {
        const domain =  getDomain()
        document.cookie = `${name}=${value}; max-age=${maxAge}; path=/; domain=${domain};`
    }

    const deleteCookie = (name: string) => {
        const domain =  getDomain()
        document.cookie = `${name}=; max-age=-1; path=/; domain=${domain};`
    }

    return {
        getCookie,
        setCookie,
        deleteCookie,
    }
}
