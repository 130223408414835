
import { defineComponent, PropType, ref,  } from 'vue'
import { maska, } from 'maska'

import { SelectListItem,  } from '@/types/common.ts'

import { useAutocomplete, } from '@/composition/autocomplete.ts'

import SelectAutocomplete from '@/components/Generic/select-autocomplete/index.vue'
import TrashIcon from '@/components/Generic/icons/TrashIcon.vue'

export default defineComponent({
    name: 'SingleListInput',
    components: {
        SelectAutocomplete,
        TrashIcon,
    },
    directives: {
        maska,
    },
    props: {
        listItem: {
            type: Object as PropType<object>,
            default: () => { return  { id: null, name: '', }
            },
        },
        listItemType: {
            type: String as PropType<string>,
            required: true,
        },
        ind: {
            type: Number as PropType<number>,
            required: true,
        },
        list: {
            type: Array as PropType<SelectListItem[]>,
        },
        noTrash: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup(props, { emit, }) {
        const inputText = ref(props.listItem)

        const inputMask = { mask: 'Z*', tokens: { 'Z': { pattern: /[0-9А-яё.\-"\s*]/, },},}


        return {
            inputText,
            inputMask,
        }
    },
})
