<template>
    <div class="drawer">
        <transition name="fade">
            <div class="drawer__bg" @click="closeDrawer" v-if="drawerVisibility"></div>
        </transition>
        <transition name="fade">
            <div class="drawer__menu" v-if="drawerVisibility">
                <lkAside :is-mobile="true"/>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Drawer',
    props: {
        drawerVisibility: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        closeDrawer(){
            this.$emit('set-drawer-visibility', true);
        },
    },
}
</script>

<style lang="scss" scoped>
    .drawer {
        position: absolute;
    }

    .drawer__bg {
        top: 96px;
        right: 0;
        bottom: 0;
        left: 0;
        height: calc(100% - 96px);
        box-sizing: border-box;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        min-height: calc(100vh - 96px);
        @media(max-width: 560px) {
            /*top: 98px;*/
            /*height: calc(100% - 98px);*/
        }
        @include media-smartphone-down{
            top: 58px;
            height: calc(100% - 58px);
        }
    }

    .drawer__menu {
        position: fixed;
        top: 96px;
        right: 0;
        height: calc(100% - 96px);
        padding: 40px 30px;
        box-sizing: border-box;
        background: $light-gray-color;
        z-index: 999;
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
        @media(max-width: 560px) {
            /*top: 98px;*/
            /*height: calc(100% - 98px);*/
        }
        @include media-smartphone-down{
            top: 58px;
            height: calc(100% - 58px);
        }
    }

    .fade {
        &-enter-active {
            transition: opacity .3s;
        }

        &-bg-enter {
            opacity: 0;
        }

        &-bg-enter-to {
            opacity: 1;
        }
    }


</style>
