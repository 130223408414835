
import {defineComponent, PropType, computed, ref, } from 'vue';
import {useEnv,} from '@/composition/env';
import {useAuth,} from '@/composition/state.ts';
import {useRedirect,} from '@/composition/redirect.ts';
import {useUserProfileData,} from '@/composition/userProfile'

export default defineComponent({
    name: 'LkAside',
    props: {
        isMobile: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup() {
        const asideMenuList = ref([
            {
                id: '1',
                link: '/',
                imgUrl: 'dashboard.svg',
                spanText: 'Сводка',
            },
            {
                id: '2',
                link: '/profile/',
                imgUrl: 'profile.svg',
                spanText: 'Мой профиль',
            },
            {
                id: '3',
                link: '/school/',
                imgUrl: 'school.svg',
                spanText: ' Моя школа',
                onlyModerator: true,

            },
            {
                id: '4',
                link: '/events/',
                imgUrl: 'events.svg',
                spanText: 'Мои мероприятия',
            },
            {
                id: '5',
                link: '/courses/',
                imgUrl: 'courses.svg',
                spanText: 'Мои курсы',
            },
            {
                id: '6',
                link: '/olympiads/',
                imgUrl: 'olympiads.svg',
                spanText: 'Мои олимпиады',
            },            
            {
                id: '7',
                link: '/certificates/',
                imgUrl: 'certificates.svg',
                spanText: 'Мои сертификаты',
            },
            {
                id: '8',
                link: '/individual-route/',
                imgUrl: 'route.svg',
                spanText: 'Индивидуальный маршрут',
            },            
            {
                id: '9',
                link: '/diagnostics/',
                imgUrl: 'diagnostics.svg',
                spanText: 'Диагностический блок',
            },
            {
                id: '10',
                link: '/monitoring/',
                imgUrl: 'monitoring.svg',
                spanText: 'Мониторинг',
            },
            {
                id: '11',
                link: '/audit/',
                imgUrl: 'checking.svg',
                spanText: 'Проверки педагогов',
                onlyTutor: true,
            }
        ])
        const asideSecondMenuList = ref([
            {
                id: '1',
                link: '/collegues',
                imgUrl: 'collegues.svg',
                spanText: 'Коллеги',
                count: '3',
            },
            {
                id: '2',
                link: '/messages',
                imgUrl: 'messages.svg',
                spanText: 'Переписка',
                count: '6',
            },
            {
                id: '3',
                link: '/favorites',
                imgUrl: 'favorites.svg',
                spanText: 'Избранное',
                count: '',
            }
        ])

        const {logOut,} = useAuth()
        const {redirectPublic,} = useRedirect()
        const {isDev, isStage,} = useEnv()
        const exit = async () => {
            logOut()
            redirectPublic('');
        }
        const {getUserProfileData: userData,} = useUserProfileData()
        const profileVerified = computed(() => userData.value?.profile_verified)
        const isSchoolModerator = computed(() => userData.value?.is_school_moderator)
        const isTutor = computed(() => userData.value?.is_tutor)

        return {
            isDev,
            isStage,
            exit,
            profileVerified,
            isSchoolModerator,
            isTutor,
            asideMenuList,
            asideSecondMenuList,
        }
    },
})
