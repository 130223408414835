
import { defineComponent, PropType, ref, } from 'vue';
import { maska, } from 'maska'

import  { InputField, } from '@/types/forms'

import { useInput, useInputPassword,  } from '@/composition/input'

import fieldBlockInputError from '@/components/Generic/field-block-input-error/index.vue'

export default defineComponent({
    name: 'FieldBlockInput',
    components: {
        fieldBlockInputError,
    },
    emits: ['del-field'],
    directives: {
        maska,
    },
    props: {
        field: {
            type: Object as PropType<InputField>,
            required: true,
        },
        modificator: {
            type: String as PropType<string>,
            default:'',
        },
    },
    setup (props, { emit, }) {
        const input =  (props.field.inputType === 'password') ? useInputPassword(props.field, emit) : useInput(props.field, emit)
        const inputClass = ref((props.field.modifier!==undefined) ? ('field-block__input-wrapper' + props.field.modifier) : '')

        const maxLength = ref()
        maxLength.value = props.field.maxLength || null

        return {
            ...input,
            inputClass,
            maxLength,
        }
    },
});
