import {post,} from '@/modules/http';
import {sleep,} from '@/modules/helpers';
import {ref,} from 'vue';
import {useCommmonNotification,} from '@/composition/notification';

export function usePayment() {
    const createPayment = async (type: string, id: number, slug: string, name: string) => {
        const {showNotification,} = useCommmonNotification()
        const response = await post(`/${type}/${slug}/pay/`, {
            name,
            type,
        });
        if (response.ok) {
            document.location.href = response.parsedBody.confirmation_url
            return response.parsedBody;
        }
        showNotification('Возникла Ошибка. Повторите позже', 'danger')
        
        await sleep(2000);
        return null;
    };
    const handlePayment = async (paymentId: number) => {
        console.log(paymentId);
    };
    return {
        createPayment,
        handlePayment,
    }
}
