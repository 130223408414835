import { ref, computed, watch, } from 'vue'

import  { UserProfile, } from '@/types/user.ts'

import {put, get, post,} from '@/modules/http.ts'
import { ValidationError, } from '@/modules/validators'

import { useAuth, } from '@/composition/state.ts'
import { useRedirect, } from './redirect'

const userProfileData = ref<UserProfile>()
const isOnModerationIcon = ref(false)

const { logOut, } = useAuth()

export const useChangePassword = () => {
    const changePassword = async(data) => {
        const response = await put<any>('/password_change/', data)
        if (response.ok) {
            return true
        }
        throw new ValidationError(response.parsedBody);
    }
    return {
        changePassword,
    }
}

const refreshToken = async() => {
    const { putToken, getToken, } = useAuth()
    const response = await post('/jwt/refresh/', {
        token: getToken(),
    })
    if (response.ok && response.parsedBody.token) {
        await putToken(response.parsedBody.token)
    }
}


export const useUserProfileData  = (): any => {
    const { logOut, } = useAuth()
    //mutations
    const setUserProfileData = (data: UserProfile) => {
        userProfileData.value = data
    }
    const setOnModerationIcon = (flag: boolean) => {
        isOnModerationIcon.value = flag
    }
    //getters
    const getUserProfileData =  computed(() =>  { return userProfileData.value })
    //actions
    const fetchUserProfileData = async () => {
        const response = await get( '/profile/')
        if (response.ok) {
            setUserProfileData ( {...userProfileData.value, ...response.parsedBody, })
            refreshToken()
        }
        else if (response.status === 401) {
            await logOut()
            return false
        }
        else throw new Error(response.statusText)
    }

    //returns true if updated
    const updateUserProfileData = async (data): Promise<object> => {
        const response = await put<UserProfile>('/profile/', data)
        if (response.ok) {
            setUserProfileData (response.parsedBody)
            const res = {
                data: response.parsedBody,
                ok: true,
            };
            return res
        }
        else if (response.status === 401) {
            await logOut()
            const res = {
                data: response.parsedBody,
                ok: false,
            };
            return res;
        }
        else {
            const res = {
                data: response.parsedBody,
                ok: false,
            };
            return res;
        }
    }

    return {
        getUserProfileData,
        userProfileData,
        isOnModerationIcon,
        fetchUserProfileData,
        updateUserProfileData,
        setUserProfileData,
        setOnModerationIcon,
    }
}
