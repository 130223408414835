import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lk-page container container_aside" }
const _hoisted_2 = { class: "aside-cont" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_myHeader = _resolveComponent("myHeader")
  const _component_lkAside = _resolveComponent("lkAside")
  const _component_lkMain = _resolveComponent("lkMain")
  const _component_myFooter = _resolveComponent("myFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_myHeader),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_lkAside)
      ]),
      _createVNode(_component_lkMain)
    ]),
    _createVNode(_component_myFooter)
  ], 64 /* STABLE_FRAGMENT */))
}