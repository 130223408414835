
import {defineComponent,} from 'vue'
//impport modules
import {tabs,} from '@/modules/constants/tabs.ts'
//import composition
import {useTabs,} from '@/composition/tabs.ts'
//import components
import Tabs from '@/components/Generic/tabs/index.vue'
import TitleBlock from '@/components/Generic/title-block/index.vue'


export default defineComponent({
    components: {
        Tabs,
        TitleBlock,
        props: {
            id: String,
        },
    },
    setup() {
        const {tabItems, activeTab,} = useTabs(tabs.school)
        return {
            tabItems,
            activeTab,
        }
    },

})


