

import { defineComponent, } from 'vue'
export default defineComponent({
    name: 'Checkbox',
    props: {
        text: String,
        submitText: String,
        modelValue: Boolean,
        loading: Boolean,
        visibleBtn: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue', 'submit'],
    setup(_, {emit,}) {
        const onChange = (e) => {
            emit('update:modelValue', e.target.checked)
        }
        const onSubmit = (e) => {
            emit('submit')
        }
        return {
            onChange,
            onSubmit,
        }
    },
});
