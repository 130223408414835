
import { defineComponent, ref, onMounted, PropType,  } from 'vue'

import {SelectListItem, Work,} from '@/types/common.ts'

import { get, put, } from '@/modules/http'
import { extractID, } from '@/modules/helpers'

import { useCommmonNotification, } from '@/composition/notification.ts'
import { useModal,} from '@/composition/modal'
import { useUserProfileData, } from '@/composition/userProfile'
import  { useDeleteDialog, } from '@/composition/deleteDialog.ts'

import SingleWork from '@/components/Profile/single-work/index.vue'
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import {useOnboarding,} from '@/composition/onboarding';


export default defineComponent({
    name: 'WorkEditor',
    components: {
        SingleWork,
        ModalWindow,
    },
    props: {
        isOnboarding: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        isNoChanges: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
        btnModeration: {
            type: String as PropType<string>,
            default: () => {
                return 'Отправить на модерацию'
            },
        },
        isVisibleMissBtn: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    emits:['loaded', 'next-step'],
    setup: function (props, {emit,} ) {
        const isTabLoaded = ref(false)
        const {setOnModerationIcon,} = useUserProfileData()
        const {showNotification,} = useCommmonNotification()
        const works = ref<Work[]>([])
        
        const empty = () => {
            return {
                id: Symbol(Date.now()),
                name: '',
            }
        }

        const { step, saveStep, } = useOnboarding()

        const excludeOrgs = ref([] as SelectListItem[])

        const getNewWork = (): Work => {
            return {
                id: Symbol(Date.now()),
                'educational_organization': {
                    id: Symbol(Date.now()),
                    name: '',
                },
                positions: [empty()],
                subjects: [empty()],
                qualification: {
                    id: Symbol(Date.now()),
                    name: '',
                },
                verified: false,
            }

        }

        const submitDisabled = ref(true)
        submitDisabled.value =  !(step.value > 3 && props.isOnboarding)

        const  isWorkChanged = ref(false)
        // watch(submitDisabled, () => {
        //     if (props.isOnboarding) {
        //         isWorkChanged.value = !submitDisabled.value
        //         submitDisabled.value = false
        //     }
        // })


        let jsonString = ''
        const isWorksVerifiedCommon = ref(false)
        const fetchWorks = async () => {
            const response = await get('/profile/jobs/')
            if (response.ok) {
                works.value = response.parsedBody.jobs
                isWorksVerifiedCommon.value = response.parsedBody.verified
                if (works.value.length === 0 && props.isOnboarding) {
                    submitDisabled.value = true
                    if (props.isOnboarding) {
                        isWorkChanged.value = !submitDisabled.value
                        submitDisabled.value = false
                    }
                    works.value.push(getNewWork());
                    return
                }
                if (works.value.length === 0) submitDisabled.value = true
                for (let i = 0; i < works.value.length; i++) {
                    if (works.value[i].qualification === null) {
                        works.value[i].qualification = empty()
                    }
                    excludeOrgs.value.push(works.value[i].educational_organization)
                }
                jsonString = await JSON.stringify(works.value)

            } else {
                console.error('Error while loading works')
            }
            emit('loaded')
        }

        onMounted(async () => {
            await fetchWorks()
            isTabLoaded.value = true
            const hasNonVerified = works.value.findIndex(el => el.verified === false) >= 0
            setOnModerationIcon(hasNonVerified || !isWorksVerifiedCommon.value)
        })

        const addWork = () => {
            works.value.push(getNewWork())
        }

        const buttons = [
            {
                text: 'Понятно',
                event: 'close',
                class: '',
            }
        ]
        const { modalTitle, modalMessage, isModalVisible, onModalClose, } = useModal()
        const loading = ref(false)
        const onWorkSubmit = async () => {
            loading.value = true
            works.value = works.value.filter(el => el.educational_organization.name !== '')
            for (let i = 0; i < works.value.length; i++) {
                if (works.value[i].positions.length > 0) {
                    works.value[i].positions = works.value[i].positions.filter(el => el?.name.trim() !== '')
                }
                if (works.value[i].subjects.length > 0) {
                    works.value[i].subjects = works.value[i].subjects.filter(el => el?.name?.trim() !== '')
                }
            }

            if (props.isOnboarding) {
                if (!isWorkChanged.value) {
                    loading.value = false
                    emit('next-step')
                    return
                }
            }

            const second = JSON.stringify(works.value)
            submitDisabled.value = jsonString === second
            if (submitDisabled.value)  {
                loading.value = false
                return
            }

            const data = works.value.map((work) => {
                return {
                    id: work.id || null,
                    positions: extractID(work.positions).filter(el => el !== null && typeof el !== 'symbol'),
                    subjects: extractID(work.subjects).filter(el => el !== null && typeof el !== 'symbol'),
                    qualification: (work.qualification === null) ? null : work.qualification.id,
                    'educational_organization': work.educational_organization.id,
                }
            })
            try {
                const response = await put('/profile/jobs/', {
                    jobs: data,
                })
                loading.value = false
                if (response.ok) {
                    setOnModerationIcon(true)
                    if (props.isOnboarding) {
                        emit('next-step')
                        return
                    }
                    isModalVisible.value = true
                } else {
                    showNotification('Ошибка при выполнении запроса!')
                }
            } catch (e) {
                showNotification('Ошибка при выполнении запроса!')
            }
            loading.value = false
            submitDisabled.value = true
            jsonString = JSON.stringify(works.value)
        }

        const onWorkChanged = (newWork) => {
            const index = works.value.findIndex(work => work.id === newWork.id);
            if (index !== -1) {
                works.value[index] = {...works.value[index], ...newWork,}
                excludeOrgs.value = []
                works.value.forEach(work => excludeOrgs.value.push(work.educational_organization))
            }
            if (newWork && index!=-1) {
                const second = JSON.stringify(works.value)
                submitDisabled.value = jsonString === second

            }
            //check all neccessary fields are not empty
            works.value.forEach(work => {
                let isError = false
                if (work.educational_organization.name && work.educational_organization.name.trim() === '')
                    isError = true
                if (work.qualification.name.trim() === '')
                    isError = true
                if (work.positions && work.positions.length > 0) {
                    isError = (work.positions[work.positions.length - 1].name === '') || isError
                    isError = (work.positions[work.positions.length - 1].id === null) || isError
                }
                if (work.positions && work.positions.filter(el => el.name !== '').length === 0) isError = true
                if (work.positions && work.positions.filter(el => el.id !== null).length === 0) isError = true
                submitDisabled.value = submitDisabled.value || isError
            })
            if (props.isOnboarding &&  works.value.length === 0) submitDisabled.value = true
            if (props.isOnboarding) {
                isWorkChanged.value = !submitDisabled.value
                submitDisabled.value = false
            }
        }


        if (props.isOnboarding) {
            isWorkChanged.value = !submitDisabled.value
            submitDisabled.value = false
        }

        if (props.isOnboarding)
            if (props.isNoChanges) {
                submitDisabled.value = false
                isWorkChanged.value = false
            }
            else  {
                submitDisabled.value = false
                isWorkChanged.value = false
            }

        const deleteWork = (id) => {
            works.value = works.value.filter(el => el.educational_organization.id !== id)
            const work = works.value.find(work => work.educational_organization.id === id)
            if (work) {
                if (work.educational_organization.name!=='') {
                    submitDisabled.value = jsonString === JSON.stringify(works.value)
                    excludeOrgs.value = excludeOrgs.value.filter(org => org.id !== work.educational_organization.id )
                }
            }
            submitDisabled.value = jsonString === JSON.stringify(works.value)
            if (props.isOnboarding &&  works.value.length === 0)
                submitDisabled.value = true

            if (props.isOnboarding) {
                isWorkChanged.value = !submitDisabled.value
                submitDisabled.value = false
            }
            onWorkChanged({})
        }

        const {  showDeleteDialog, setTitle,  } = useDeleteDialog()
        setTitle('Вы действительно хотите удалить данные\u00A0о работе?')

        return {
            works,
            addWork,
            deleteWork,
            onWorkSubmit,
            isTabLoaded,
            buttons,
            modalMessage,
            isModalVisible,
            onModalClose,
            modalTitle,
            showDeleteDialog,
            onWorkChanged,
            loading,
            submitDisabled,
            excludeOrgs,

        }
    },
});
