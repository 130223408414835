import {get, post, } from '@/modules/http';
import {ref, } from 'vue';
import { useCommmonNotification, } from '@/composition/notification';

export function useTest() {
    const { showNotification, } = useCommmonNotification()

    // const для checkNextUrl
    const visibleMoreBtn = ref(false)
    const isUploading = ref(false)
    const next = ref('')
    const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

    // Получить информацию по тесту
    const testRead = async (id) => {
        const response = await get(`/test/${id}/`);
        if (response.ok) { 
            return response.parsedBody;
        }
        return null;
    };
    // Получить список тестов
    const testList = async (status) => {
        const page = 1
        const pageSize = 10
        const url = `/test/?page=${page}&page_size=${pageSize}&filter=${status}`
        const tests = await get(url);
        if (tests.ok) {   
            return tests.parsedBody;
        }
        return null;
    };
    // Получить результаты теста
    const testResultRead = async (id) => {
        const response = await get(`/test_result/${id}`);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // Старый метод получения всех завершенных тестов
    const testResultList = async () => {
        const pageSize = 1000000
        const response = await get(`/test_result/?page_size=${pageSize}`);
        if (response.ok) {          
            return response.parsedBody;
        }
        return null;
    };
    // Связать тест с вопросами
    const testResultCreate = async (data) => {
        const response = await post('/test_result/', data);
        if (response.ok) {            
            return response.parsedBody;
        }
        return null;
    };
    // Отправить ответ по воросу
    const sendAnswerOnTest = async (data) => {
        const response = await post('/test_answer/', data);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };
    // Проверка параметра next в response для подгрузки данных
    const checkNextUrl = (response) => {
        if (response.next !== null) {
            next.value = response.next.replace(apiURL, '')
            visibleMoreBtn.value = true
            return next.value
        }
        next.value = response.next
        visibleMoreBtn.value = false
        return next.value
    }
    // Подгрузка данных "Показать еще"
    const loadData = async(data) => {
        try{
            isUploading.value = true
            const response = await get(next.value)
            if (response.ok) {
                data.value = data.value.concat(response.parsedBody.results)
                checkNextUrl(response.parsedBody)
                isUploading.value = false
            } else {
                throw new SyntaxError('Произошла ошибка')
            }
        } catch(e) {
            showNotification('Ошибка получения данных', 'error')
            isUploading.value = false
        }
    }
    
    const headersNew = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Доступно попыток',
            value: 'attemptings',
        }
    ]
    const headersProcess = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Попытка',
            value: 'attemptings',
        }
    ]
    const headersCompleted = [
        {
            text: 'ИД',
            value: 'id',
        },
        { 
            text: 'Название',
            value: 'name',
        },
        {
            text: 'Предметная область',
            value: 'subject_area',
        },
        {
            text: 'Профессиональные компетенции',
            value: 'competence',
        },
        {
            text: 'Пройдено попыток',
            value: 'attemptings',
        },
        {
            text: 'Баллы',
            value: 'scores',
        }
    ]
    const headersResult = [
        {
            text: 'Вопросы',
            value: 'questions',
        },
        {
            text: 'Ваш ответ',
            value: 'answers',
        }
    ]
    const modalInfoData = ref ({
        modal: {
            Header: String,
            HeaderAccept: String,
            BodyAccept: String,
            HeaderQuiz: String,
            BodyQuiz: String,
            HeaderDecline: String,
            BodyDecline: String,
        },
        type: String,
        name: String,
        annotation: String,
        attempting: {
            number: Number,
            max_attempting: Number,
            next: String,
        },
        status_for_user: '',
        id: Number,
        count_questions: Number,
        subject_area: Array,
        allAttempts: Array,
        competence: Array,
        questions: Array,
    })
    return {
        testRead,
        testList,
        testResultCreate,
        sendAnswerOnTest,
        testResultRead,
        testResultList,
        checkNextUrl,
        loadData,
        // headers
        headersNew,
        headersProcess,
        headersCompleted,
        headersResult,
        // const
        modalInfoData,
        visibleMoreBtn,
        isUploading,
        next,
    }
}
