import { ref, } from 'vue';

import { get, } from '@/modules/http';
import { usePagination, } from './pagination';

export  const useCerts = () => {
    const certs = ref([])
    const totalCount = ref(0)
    const { page, isLoading, isUploading, pageSize, isNeedToUpload,} = usePagination()

    const fetchCerts = async (s = '') => {
        isLoading.value = page.value === 1
        isUploading.value = page.value > 1

        try {
            // ?search=${s}
            const response = await get(`/certificate/?page=${page.value}&page_size=${pageSize.value}`)

            if (response.ok) {
                totalCount.value = response.parsedBody.count;
                certs.value = page.value === 1 ? response.parsedBody.results : [...certs.value, ...response.parsedBody.results];
                isNeedToUpload.value = totalCount.value > pageSize.value * page.value
            }
        }
        catch (e) {
            console.error(e)
        }

        page.value = page.value + 1
        isUploading.value = false
        isLoading.value = false
    }

    return {
        certs,
        totalCount,
        isLoading,
        isUploading,
        page,
        isNeedToUpload,
        fetchCerts,
    }
}
