
import { defineComponent,} from 'vue';
import { useMessages, } from "@/composition/messages";

import TempAvatar from '@/components/Generic/temp-avatar/index.vue';

export default defineComponent({
    name: 'MsgList',
    props: ['msgs'],
    components: {TempAvatar, },
    setup(props) {
      const { dateFormatting,} = useMessages();
      const domen = process.env.VUE_APP_API_BASE_URL
      const lineBreak = (str) => {
        const correctStr = str.replace(/\r\n|\n/gi, '<br>')
        return correctStr
      }

      return {
        domen,
        dateFormatting,
        lineBreak,
      }
    },
})
