<template lang="pug">
template(v-if="!loadingHook")
    template(v-if="items.length !== 0")
        Table(:headers="headersNew" :items="items" :rowHover="true" @rowClick="onRowClick" :is_hover="true")
        .more-btn-block__for-table(v-if="visibleMoreBtn")
            btn.btn--outline(v-if="!isUploading"
                text="Показать еще" 
                @mousedown="upload")
            LoadingIndicator(v-else)
    template(v-else)
        p.par Новых тестов нет
template(v-else)
    .loading-indicator
        LoadingIndicator
</template>

<script>
import {onMounted, ref, computed, } from 'vue';
// components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

// compositions
import {useTest,} from '@/composition/diagnosticTest';

export default {
    components: {
        Table,
        LoadingIndicator,
    },
    emits: ['on-modal-open', 'set-remove-row'],
    setup(_, {emit,  }) {
        const {
            testList,
            testRead,
            checkNextUrl,
            loadData,
            headersNew,
            modalInfoData,
            visibleMoreBtn,
            isUploading,
        } = useTest();

        // const
        const data = ref([])
        const loadingHook = ref(true)

        onMounted(async () => {
            const response = await testList('new');
            data.value = response.results
            checkNextUrl(response)
            loadingHook.value = false
        });
        const onRowClick = async (row) => {
            const response = await testRead(row.id)
            modalInfoData.value.modal.Header = 'Информация о тесте'
            modalInfoData.value.modal.HeaderAccept = `Вы уверены, что хотите начать проходить тест "${response.name}"?`
            modalInfoData.value.modal.HeaderQuiz = 'Тестирование'
            modalInfoData.value.modal.HeaderDecline = 'Вы уверены, что хотите прекратить прохождение теста? Ваши результаты будут сохранены. Вы сможете продолжить прохождение теста позднее.'
            modalInfoData.value.type = 'test'
            modalInfoData.value.name = response.name
            modalInfoData.value.annotation = response.annotation
            modalInfoData.value.attempting.number = response.attempting.number
            modalInfoData.value.attempting.max_attempting = response.attempting.max_attempting
            modalInfoData.value.attempting.next = response.attempting.next
            modalInfoData.value.status_for_user = response.status_for_user.last
            modalInfoData.value.id = row.id
            modalInfoData.value.count_questions = response.count_questions
            modalInfoData.value.subject_area = row.subject_area
            modalInfoData.value.competence = row.competence
            modalInfoData.value.questions = response.questions
            emit('on-modal-open', modalInfoData.value)
        }
        const removeRow = (id) => {
            data.value = data.value.filter(x => x.id != id)
        }
        const upload = () => loadData(data)

        emit('set-remove-row', removeRow)
        return {
            // useTest
            testList,
            headersNew,

            // const
            loadingHook,
            visibleMoreBtn,
            isUploading,
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    text: x.name,
                    subject_area: x.subject_area,
                    competence: x.competence,
                    attempting: x.attempting.max_attempting,
                }))
            }),
            // function
            onRowClick,
            checkNextUrl,
            upload,
        }
    },
}
</script>
