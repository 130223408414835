
import { defineComponent, } from 'vue';

export default defineComponent ( {
    name: 'FieldBlockInputError',
    props: {
        errorMessages: {
            type: Array,
            default: () => { return [] },
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
})

