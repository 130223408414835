<template lang="pug">
//done
button.btn(v-bind="$attrs" type="submit" :disabled="disabled || loading" @click="$emit('click', $event)")
        span.btn__text(:class="{ 'btn__text--loading' : loading }") {{ text }}
        img.btn__loading-ico(src="@/assets/img/loading.svg" v-if="loading")
</template>

<script>
import { PropType, } from 'vue';

export default {
    name: 'Btn',
    data: function(){
        return {
            test: 'asdds',
        }
    },
    props: {
        loading: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: 'Отправить',
        },
        width: {
            type: String,
            default: '260px',
        },
        height: {
            type: String,
            default: '50px',
        },
    },
    emits: ['click'],
}
</script>

<style lang="scss" scoped>

</style>
