<template lang="pug">
input.search-block__input.search-block__input_bigwidth(v-if="search" v-model="searchQuery" placeholder='Поиск по ФИО и названию кейса')
//- input.search-block__input.search-block__input_bigwidth(v-if="search" v-model="searchQuery" placeholder='Поиск по ФИО, названию кейса, предметной области и проффесиональным компетенциям')
.table__wrapper
    table.table
        thead
            template(v-for="item in headers")
                th(v-if="item.value == 'id' || item.value == 'data_test' || item.value == 'max_points' || item.value == 'my_point' " style="display: none;") {{ item }}
                th(v-else :class="{'question__detail-column': detail_attempt && item.text === 'Вопросы'}") {{ item.text }}
        tbody
            tr(v-for="item in todosByTitle" @click="onRowClick(item)" :class="{'tr__is-hover' : is_hover, 'tr__detail-attempt': detail_attempt}")
                template(v-for="(value, name) in item")
                    td(v-if="name === 'id' || name === 'data_test' || name === 'data' || name === 'max_points' || name === 'my_point' || name === 'my_attempting' || name === 'teacher_id' || name === 'is_correct' || name === 'about' || name === 'status_for_user' || name === 'annotation'" style="display:none;" )
                    td(v-else)
                        template(v-if="name == 'answers'")
                            ul.answers
                                template(v-for="(value, name) in value.answer")
                                    template(v-if="name == 'correct'")
                                        template(v-if="value === true")
                                            img(:src="require('@/assets/icons/correct.svg')")
                                        template(v-if="value === false")
                                            img(:src="require('@/assets/icons/incorrect.svg')")
                                li(v-for="(value, name) in value")
                                    template(v-if="typeof value === 'object'")
                                        template(v-for="answer in value.text")
                                            .answer-item(:class="[value.correct === true ? 'correct-answer' : 'false-answer']") {{ answer }}
                                    template(v-else)
                                        template(v-if="name === 'answer'") {{ value }}
                        template(v-else-if="Array.isArray(value)")
                            ul
                                li(v-for="(value, name) in value")
                                    template(v-if="typeof value === 'object'") {{ value.name }}
                                    template(v-else) {{ value }}
                        template(v-else-if="name == 'id'") {{ value }}
                        template(v-else-if="name == 'correct'")
                            template(v-if="value === true")
                                img(:src="require('@/assets/icons/correct.svg')")
                            template(v-if="value === false")
                                img(:src="require('@/assets/icons/incorrect.svg')")
                        template(v-else) {{ value }}
</template>

<script>
import {ref, } from 'vue';
export default {
    name: 'Table',
    props: {
        headers: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        search: {
            type: Boolean,
            default: false,
        },
        is_hover: {
            type: Boolean,
            default: false,
        },
        detail_attempt: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['onRowClick'],
    setup(_, {props, emit,  }) {
        const searchQuery = ref('')
        const onRowClick = (row) => {
            emit('row-click', row);
        };

        return {
            searchQuery,
            onRowClick,
        };
    },
    // computed: {
    //     todosByTitle() {
    //         return this.items.filter(item => {
    //             return item.text.indexOf(this.searchQuery) > -1
    //         })
    //     },
    // },
    computed: {
        todosByTitle() {
            const value = this.searchQuery.toLowerCase().slice(1);
            return this.items.filter(function(item){
                return  item.text.toLowerCase().includes(value) || item.name.toLowerCase().includes(value)
                // ||
                //     item.subject_area.filter(function(element) {
                //         return element.name
                //     })
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.table__wrapper {
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        margin-top: 15px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(163, 163, 163);
        border-radius: 10px;
    }
}
.table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    text-align: left;
    overflow: hidden;
    font-size: 16px;
    border-radius: 10px 10px 0px 0px;
    thead {
        background-color: #f2f3f4;
        th {
            padding: 0.5rem 1rem;
            font-weight: normal;
            max-width: 740px;
        }
    }
    tr {
        cursor: pointer;
        border-bottom: 1px solid #dadada;
        &:last-child {
            border-bottom: none;
        }
        td {
            min-width: 100px;
            max-width: 400px;
            padding: 1rem 1rem;
            vertical-align: center;
        }
    }
    .tr {
        &__is-hover {
            &:hover {
                background-color: #f2f3f4;
            }
        }
        &__detail-attempt {
            cursor: auto;
        }
    }
    .question {
        &__detail-column {
            width: 50%;
        }
    }
    li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .answers {
        display: flex;
        align-items: center;
        img {
            margin-right: 15px;
        }
    }
    .false-answer {
    color: $error-color;
    font-weight: 500;
    }
    .correct-answer {
        color: rgb(1, 185, 1);
        font-weight: 500;
    }
}

@media screen and (max-width:1035px)  {}
@media screen and (max-width:750px)  {
    .table {
        font-size: 13px;
    }
}
input {
    margin-bottom: 20px;
}
.search-block__input_bigwidth:focus {
    width: 100% !important;

}
</style>
