
import { defineComponent, computed, ref, } from 'vue'
import {useRouter,} from 'vue-router'

//import common
import { mainMenu, } from '@/modules/constants/menus'
import { excerpt,} from '@/modules/helpers'

//import composition
import {  useUserProfileData, } from '@/composition/userProfile'
import { useDeleteDialog, } from '@/composition/deleteDialog';
import {useSettings,} from '@/composition/settings';

//import components
import CommonNotification from '@/components/Generic/common-notification/index.vue'
import HeaderNav from '@/components/Generic/header-nav/index.vue'
import UserDropdown from '@/components/Generic/user-dropdown/index.vue'
import Burger from '@/components/Generic/burger/index.vue'
import Drawer from '@/components/Generic/drawer/index.vue';
import ModalWindow from  '@/components/Generic/modal-window/index.vue'
import HeaderSocials from '@/components/Generic/header-socials/index.vue'

export default defineComponent({
    name: 'Header',
    components: {
        HeaderNav,
        CommonNotification,
        UserDropdown,
        Burger,
        Drawer,
        ModalWindow,
        HeaderSocials,
    },
    setup () {
        const router = useRouter()
        const { getUserProfileData: userData, } = useUserProfileData()

        const profileVerified = computed(() => userData.value?.profile_verified)

        const { isDelDialogVisible, closeDeleteDialog, delDialogButtons, approveDelete, modalTitle, } =  useDeleteDialog();

        const {  settingsSite: settings, fetchSettings, } = useSettings(true);
        fetchSettings();

        const email = computed(() => excerpt( userData.value.email, 12))
        const drawerVisibilityState = ref(false)

        const socialsBlockClasses = computed(() => {
            const socialLinks = [settings.value['telegram_url'], settings.value['vk_url'], settings.value['zen_ya_url']];
            const checkUndefined = socialLinks.filter(link => link === "").length;
            return checkUndefined;
        })

        const hiddenScrollbar = () => {
            if (drawerVisibilityState.value) {
                document.documentElement.style.overflow = 'hidden';
            } else {
                document.documentElement.style.overflow = 'visible';
            }
        }
        hiddenScrollbar();

        const setDrawerVisibility = (state) => {
            drawerVisibilityState.value = !drawerVisibilityState.value;
            hiddenScrollbar();
        }
        
        return {
            router,
            userData,
            mainMenu,
            email,
            settings,
            publicURL: process.env.VUE_APP_PUBLIC_URL,
            isDelDialogVisible,
            closeDeleteDialog,
            delDialogButtons,
            approveDelete,
            modalTitle,
            profileVerified,
            drawerVisibilityState,
            setDrawerVisibility,
            socialsBlockClasses,
        }
    },
})
