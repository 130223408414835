
import {ref, computed, defineComponent, onMounted, watch,} from 'vue'

import {get,} from '@/modules/http'
import {PropType,} from 'vue'
import {SchoolPerson,} from '@/types/common.ts'
import {useCommmonNotification,} from '@/composition/notification'
import {tabs,} from '@/modules/constants/tabs'

import StaffCard from '@/components/School/Staff/StaffCard.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: 'StaffList',
    components: {
        StaffCard,
        LoadingIndicator,
    },
    props: {
        filter: {
            type: Number as PropType<number>,
            default: 0,
        },
        searchString: {
            type: String as PropType<string>,
            default: '',
        },
    },
    emits: ['update', 'update-current'],
    setup(props, {emit,}) {
        const isLoading = ref(true)
        const paginationLoading = ref(false)
        const isNeedToLoad = ref(true)

        const page = ref<number>(1)
        const perPage = ref<number>(10)

        const persons = ref<SchoolPerson[]>([])

        const {showNotification,} = useCommmonNotification()

        const filter = computed(() => props.filter)
        const searchString = computed(() => props.searchString)


        const fetchPersons = async (search='', fromPagination=false, disableLoader=false) => {
            isLoading.value = true && !fromPagination && !disableLoader
            paginationLoading.value = fromPagination
            try {
                const tab = tabs.staff.find(x => x.id === filter.value)
                const response = await get(
                    `${tab?.link}?page=${page.value}&page_size=${perPage.value}&search=${search}`, {})
                if (response.ok) {
                    if (response.parsedBody.next) {
                        isNeedToLoad.value = true
                    } else {
                        isNeedToLoad.value = false
                    }
                    if (fromPagination) {
                        persons.value = persons.value.concat(response.parsedBody.results)
                    } else {
                        persons.value = response.parsedBody.results
                    }
                } else {
                    showNotification('Ошибка при загрузке списка сотрудников', 'danger')
                }
            } catch (e) {
                console.error(e)
                showNotification('Ошибка при загрузке списка сотрудников', 'danger')
            }
            isLoading.value = false
            paginationLoading.value = false
        }
        const getMore = async () => {
            page.value = page.value + 1
            await fetchPersons(searchString.value, true)
        }
        const fetchPersonsCountByType = async (s = '') => {
            try {
                const response = await get('/school_moderator/tab_counts/')
                const partnersCount = response.parsedBody.working
                const claimCount = response.parsedBody.claim_created
                const changeDataRequestCount = response.parsedBody.claim_changed
                const deleteDataRequestCount = response.parsedBody.claim_deleted
                const makeNotWorkingCount = response.parsedBody.not_working
                return [
                    partnersCount,
                    claimCount,
                    changeDataRequestCount,
                    deleteDataRequestCount,
                    makeNotWorkingCount
                ]
            } catch (e) {
                console.error(e)
            }
            return [0, 0, 0]

        }

        const searchPersons = async () => {
            page.value = 1
            await fetchPersons(searchString.value)
        }

        onMounted(async () => {
            const counts = await fetchPersonsCountByType()
            emit('update', counts)
            await fetchPersons()
            isLoading.value = false
        })


        watch(filter, async () => {
            page.value = 1
            await fetchPersons()
        })

        watch(searchString, async () => {
            page.value = 1
            await searchPersons()
            persons.value;
        })

        const updateList = async (person) => {
            await fetchPersons(searchString.value, false, true)
            const counts = await fetchPersonsCountByType(searchString.value)
            emit('update', counts)
            page.value = 1
        }

        return {
            persons,
            isLoading,
            isNeedToLoad,
            paginationLoading,
            fetchPersons,
            updateList,
            getMore,

        }
    },
})
