<template lang="pug">
.profile__content.profile__content--column.route__content
    p.par Вы отправили заявку на составление маршрута тьютором. В ближайшее время он составит для вас маршрут, и вы сможете с ним ознакомиться и подтвердить.
    p.par Если по поводу составленного маршрута у вас возникнут вопросы, то вы можете связаться с тьютором и скорректировать его.
MyTutor.my-tutor__block(:tutor="tutor")

</template>

<script>
import MyTutor from "@/components/IndividualRoute/my-tutor/MyTutor.vue";

export default {
    components: {
        MyTutor,
    },
    props: {
        tutor: {
            type: Object,
        },
    },
};
</script>
