import { SelectListItem, } from '@/types/common.ts'


export const extractID = ( arr: SelectListItem[]) => {
    if (arr.length > 0 )
        return arr.map<number| null| symbol>(el => el.id)
    else return []
}

export const excerpt = (str: string, count: number) =>  {
    return  (str.substring(count).length > 2) ? (str.substring(0, count) + '...') : str
}

export const getCookie = (name: string) => {
    const matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
}

export const getDomain = (): string => {
    if (window) {
        if (window.location.hostname !== 'localhost') {
            return  process.env.VUE_APP_BASE_DOMAIN
        }
        else return 'localhost'
    }
    return process.env.VUE_APP_BASE_DOMAIN
}

export const sleep = ms => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms);
    });
};
// export const debounce = function  (fn, timeOut: number) {
//     let timerID;
//     const func =  () => {
//         const args = arguments;
//         clearTimeout(timerID);
//         timerID = setTimeout(function() {
//             fn.apply(func, args);
//         }, timeOut);
//     };
// }

export function safePromise(promise): Promise<[any, any]> {
    return promise.then(data => {
        return [null, data];
    })
        .catch(err => [err]);
}
