<template lang="pug">
myHeader
.lk-page.container.container_aside
    .aside-cont
        lkAside
    main.lk-main
        section.profile
            .container-lk.container-lk__audit
                TitleBlock Мониторинг
                tabs.tab-container--underline(:tab-items="tabItems" :active-tab = "activeTab" )
                router-view(v-slot="{ Component }")
                    suspense
                        component(:is="Component" @on-modal-open="openModal" :currentPage="currentPage")
//- modal info
ModalWindow(
    :is-visible="isModalVisible"
    isBig="true"
    :title="modalInfoData.modal.Header"
    :buttons="modalInfoBtn"
    :addInput="false"
    @close="closeModalInfo"
    @ok="openAcceptModal"
)
    ModalInfo(:modalData="modalInfoData" :loading="loading" @on-modal-close="closeModalInfo")
//- modal accept
ModalWindow(
    :is-visible="isModalVisibleTwo"
    :title="modalInfoData.modal.HeaderAccept"
    :buttons="acceptBtns"
    @ok="start"
    @close="onModalCloseTwo"
)
ModalWindow(
    :is-visible="isModalVisibleQuiz"
    :title="modalInfoData.modal.HeaderQuiz"
    message=" "
    @close="stopQuiz"
)
    Quiz(:quiz="quizData", mode="survey" @on-modal-close="endQuiz")
ModalWindow(
    :is-visible="isModalVisibleStopQuiz"
    :title="modalInfoData.modal.HeaderDecline"
    @close="onModalCloseStopQuiz"
    @ok="decline"
    :buttons="acceptBtns"
)
myFooter
</template>
<script>
import {ref, defineComponent,} from 'vue'
import { useRoute, useRouter, } from 'vue-router'
//modules
import { tabs, } from "@/modules/constants/tabs";

//import components
import Tabs from "@/components/Generic/tabs/index.vue";
import TitleBlock from "@/components/Generic/title-block/index.vue";
import ModalWindow from '@/components/Generic/modal-window/index.vue'
import ModalInfo from '@/components/Generic/modal-info/index.vue'
import Quiz from '@/components/Generic/quiz/index.vue'

//import composition
import { useTabs, } from "@/composition/tabs";
import {useModal,} from '@/composition/modal';
import {useMonitoring,} from '@/composition/monitoring';

export default defineComponent({
    components: {
        Tabs,
        TitleBlock,
        ModalWindow,
        ModalInfo,
        Quiz,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { tabItems, activeTab, } = useTabs(tabs.monitoring);
        // composition const
        const {
            acceptBtns,
            modalInfoData,
            surveyReadId,
            surveyResultCreate,
        } = useMonitoring();
        const {
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
        } = useModal()
        const {
            isModalVisible: isModalVisibleTwo,
            modalTitle: modalTitleTwo,
            modalMessage: modalMessageTwo,
            onModalClose: onModalCloseTwo,
        } = useModal()
        const {
            isModalVisible: isModalVisibleQuiz,
            modalTitle: modalTitleQuiz,
            modalMessage: modalMessageQuiz,
            onModalClose: onModalCloseQuiz,
        } = useModal()
        const {
            isModalVisible: isModalVisibleStopQuiz,
            modalTitle: modalTitleStopQuiz,
            modalMessage: modalMessageStopQuiz,
            onModalClose: onModalCloseStopQuiz,
        } = useModal()        
        // const
        const quizData = ref({
            id: Number,
            questions: Array,
            count_questions: Number,
        })
        const modalInfoBtn = ref()
        const status = ref()
        const connectId = ref()

        // function
        const openModal = async(response) => {
            modalInfoData.value = response
            document.documentElement.style.overflow = 'hidden';
            status.value = modalInfoData.value.status_for_user
            if (status.value.last === 'new') modalInfoBtn.value = [
                {
                    text: 'Пройти опрос',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value.last === 'process') modalInfoBtn.value = [
                {
                    text: 'Продолжить',
                    event: 'ok',
                    class: '',
                }
            ]
            if (status.value.last === 'completed') modalInfoBtn.value = []
            isModalVisible.value = true
        }
        const start = async() => {
            const status = modalInfoData.value.status_for_user
            if (status.last !== 'process') {
                const data = {
                    'survey': modalInfoData.value.id,
                }
                // get connection id
                const response = await surveyResultCreate(data)
                connectId.value = response.id
                const responseQuestions = await surveyReadId(modalInfoData.value.id)
                modalInfoData.value.questions = responseQuestions.questions
            }
            else {
                connectId.value = modalInfoData.value.status_for_user.id
            }
            quizData.value.id = connectId.value
            quizData.value.count_questions = modalInfoData.value.count_questions
            quizData.value.questions = modalInfoData.value.questions

            isModalVisible.value = false
            isModalVisibleTwo.value = false
            isModalVisibleQuiz.value = true            
        }
        const openAcceptModal = () => {
            isModalVisibleTwo.value = true
        }          
        const stopQuiz = async () => {
            isModalVisibleStopQuiz.value = true
        }
        const endQuiz = async () => {
            isModalVisibleQuiz.value = false
        }
        const decline = async () => {
            isModalVisibleQuiz.value = false
            isModalVisibleStopQuiz.value = false
            document.documentElement.style.overflow = 'visible';
            if (route.path === '/monitoring/actual') {
                router.push('/monitoring/process')
            }
        }
        const closeModalInfo = () => {
            isModalVisible.value = false
            document.documentElement.style.overflow = 'visible';
        }      
        return {
            tabItems,
            activeTab,
            acceptBtns,
            modalInfoData,
            modalInfoBtn,
            connectId,
            quizData,
            // emit function
            openModal,
            // function
            openAcceptModal,
            start,
            closeModalInfo,
            stopQuiz,
            endQuiz,
            decline,            
            // modal info
            isModalVisible,
            modalTitle,
            modalMessage,
            onModalClose,
            // modal accept
            isModalVisibleTwo,
            modalTitleTwo,
            modalMessageTwo,
            onModalCloseTwo,
            // modal quiz
            isModalVisibleQuiz,
            modalTitleQuiz,
            modalMessageQuiz,
            onModalCloseQuiz,
            // modal decline
            isModalVisibleStopQuiz,
            modalTitleStopQuiz,
            modalMessageStopQuiz,
            onModalCloseStopQuiz,
        };
    },
});
</script>
