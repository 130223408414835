
import { defineComponent, watch, onBeforeUnmount, } from 'vue'

import { useCommmonNotification, } from '@/composition/notification'
import {useRoute,} from 'vue-router';

export default defineComponent({
    name:'CommonNotification',
    setup () {
        const { isVisible:isNotifVisible, hideNotification, message, notificationClass, } = useCommmonNotification()

        let timerID = 0

        watch (isNotifVisible, () => {
            if (isNotifVisible.value)
                timerID = setTimeout(hideNotification, 3000)
            else {
                clearTimeout(timerID)
            }
        })

        onBeforeUnmount(() => {
            if (timerID!==0)
                clearTimeout(timerID)
        })

        return {
            isNotifVisible,
            hideNotification,
            message,
            notificationClass,
        }
    },
})
