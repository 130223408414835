import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "msg-wrap" }
const _hoisted_2 = { class: "msg-el__img" }
const _hoisted_3 = { class: "msg-el__text" }
const _hoisted_4 = { class: "msg-el__header" }
const _hoisted_5 = { class: "msg-el__name" }
const _hoisted_6 = { class: "msg-el__date" }
const _hoisted_7 = { class: "msg-el__job" }
const _hoisted_8 = { class: "msg-el__message" }
const _hoisted_9 = { class: "msg-el__message-author" }
const _hoisted_10 = { class: "msg-el__message-text" }
const _hoisted_11 = {
  key: 1,
  class: "msg-el__message-author"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_temp_avatar = _resolveComponent("temp-avatar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.chats, (chat, index) => {
      return (_openBlock(), _createBlock("div", {
        class: "msg-el",
        key: index,
        onClick: $event => (_ctx.$emit('onClick', chat))
      }, [
        _createVNode("div", _hoisted_2, [
          (chat.colleague.avatar !== null)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: `${_ctx.domen}/${chat.colleague.avatar}`,
                alt: chat.name
              }, null, 8 /* PROPS */, ["src", "alt"]))
            : (_openBlock(), _createBlock(_component_temp_avatar, {
                key: 1,
                typeImage: "img"
              }))
        ]),
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, _toDisplayString(chat.colleague.full_name), 1 /* TEXT */),
            _createVNode("div", _hoisted_6, _toDisplayString(_ctx.dateLastMessage(chat)), 1 /* TEXT */)
          ]),
          _createVNode("div", _hoisted_7, _toDisplayString(_ctx.getOrganizations(chat.colleague)), 1 /* TEXT */),
          _createVNode("div", _hoisted_8, [
            (chat.last_message !== null)
              ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                  _createVNode("span", _hoisted_9, _toDisplayString(chat.last_message.sender.full_name_initials) + ":", 1 /* TEXT */),
                  _createVNode("span", _hoisted_10, " " + _toDisplayString(chat.last_message.text), 1 /* TEXT */)
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createBlock("span", _hoisted_11, "Сообщений нет"))
          ])
        ])
      ], 8 /* PROPS */, ["onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}