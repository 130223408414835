import {get, post, put,} from '@/modules/http';
import {ref, } from 'vue';
import moment from 'moment';
import { useCommmonNotification, } from '@/composition/notification';

export function useIndividualRoute() {
    const { showNotification, } = useCommmonNotification()
    const visible = ref(false)

    // const для checkNextUrl
    const visibleMoreBtn = ref(false)
    const isUploading = ref(false)
    const next = ref('')
    const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

    const showModal = () => {
        visible.value = true
        document.documentElement.style.overflow = 'hidden';
    }
    const closeModal = () => {
        visible.value = false
        document.documentElement.style.overflow = 'visible';
    }
    const getTutor = async () => {
        const response = await get(`/curriculum_my_tutor/`);        
        if (response.ok) {
            if (Object.keys(response.parsedBody).length === 0) {
                return null
            };
            return response.parsedBody.curriculum_tutor;
        }
        return null;
    }
    const getStatusRoute = async() => {
        const response = await get(`/curriculum/`);        
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    }
    const createRoute = async(creator) => {
        const response = await post(`/curriculum/`, {
            creator: creator,
        });        
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    }
    const changeStatuRoute = async(id, status) => {
        const response = await put(`/curriculum/${id}`, {
            status: status,
        });        
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    }
    const completedRouteList = async () => {       
        const page = 1
        const pageSize = 10
        const response = await get(`/curriculum_completed/?page=${page}&page_size=${pageSize}`);
        if (response.ok) { 
            return response.parsedBody;
        }
        return null;
    };

    const routeResultRead = async (id) => {
        const response = await get(`/curriculum_completed/${id}`);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };

    const getNewListEvent = async () => {
        const response = await get(`/check_curriculum/`);
        if (response.ok) {
            return response.parsedBody;
        }
        return null;
    };      

    const normalizeData = (resultData) => {
        const data = new Date(resultData)
        resultData = moment(data).format('DD.MM.YYYY')
        return resultData
    }
    const findPaidEvents = (allEvents): any => {
        interface PaidEvent {
            name: string;
            value: string;
            link: string;
        }
        const paidEvents: PaidEvent[] = []
        allEvents.map((event) => {
            if (event.about.payment_on && !event.about.is_paid) {
                const paidEvent: PaidEvent = {
                    name: event.about.name,
                    value: `${event.about.price} руб.`,
                    link: `${process.env.VUE_APP_PUBLIC_URL}/${event.type === 'event' ? 'events' : 'courses'}/${event.about.slug}`,
                }
                paidEvents.push(paidEvent)
            }
        })
        return paidEvents
    }
    const getNumberCompleted = (allEvents, typeEvent) => {
        const completedEvents = ref(0)
        const generalEvents = ref(0)
        allEvents.map((event) => {
            if (event.type === typeEvent) {
                event.passing_status ? completedEvents.value += 1 : completedEvents.value += 0
                generalEvents.value += 1
            }
        })
        const finalValue = `${completedEvents.value} из ${generalEvents.value}`
        return finalValue
    }

    const checkNextUrl = (response) => {
        if (response.next !== null) {
            next.value = response.next.replace(apiURL, '')
            visibleMoreBtn.value = true
            return next.value
        }
        next.value = response.next
        visibleMoreBtn.value = false
        return next.value
    }
    // Подгрузка данных "Показать еще"
    const loadData = async(data) => {
        try{
            isUploading.value = true
            const response = await get(next.value)
            if (response.ok) {
                data.value = data.value.concat(response.parsedBody.results)
                checkNextUrl(response.parsedBody)
                isUploading.value = false
            } else {
                throw new SyntaxError('Произошла ошибка')
            }
        } catch(e) {
            showNotification('Ошибка получения данных', 'error')
            isUploading.value = false
        }
    }       

    const headersOnConformition = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Дата начала',
            value: 'date_at',
        },
        {
            text: 'Дата окончания',
            value: 'date_end',
        },
        {
            text: 'Название события',
            value: 'name',
        },
        {
            text: 'Форма проведения',
            value: 'type',
        },
        {
            text: 'Количество часов',
            value: 'hours',
        },
        {
            text: 'Профессиональная компетенция',
            value: 'competence',
        }
    ]

    const headersOnProcessingOrResult = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Дата начала',
            value: 'date_at',
        },
        {
            text: 'Дата окончания',
            value: 'date_end',
        },
        {
            text: 'Название события',
            value: 'name',
        },
        {
            text: 'Форма проведения',
            value: 'type',
        },
        {
            text: 'Количество часов',
            value: 'hours',
        },
        {
            text: 'Профессиональная компетенция',
            value: 'competence',
        },
        {
            text: 'Статус прохождения',
            value: 'status',
        }
    ]

    const headersCompletedRoutes = [
        {
            text: 'ИД',
            value: 'id',
        },
        {
            text: 'Дата начала',
            value: 'date_at',
        },
        {
            text: 'Дата окончания',
            value: 'date_end',
        },
        {
            text: 'Количество часов',
            value: 'hours',
        },
        {
            text: 'Количество мероприятий',
            value: 'number_events',
        },
        {
            text: 'Количество курсов',
            value: 'number_courses',
        }
    ]

    return {
        // const
        visible,
        visibleMoreBtn,
        isUploading,
        headersOnConformition,
        headersOnProcessingOrResult,
        headersCompletedRoutes,
        // function
        showModal,
        closeModal,
        getTutor,
        getStatusRoute,
        createRoute,
        changeStatuRoute,
        routeResultRead,
        getNewListEvent,
        normalizeData,
        findPaidEvents,
        getNumberCompleted,
        completedRouteList,
        checkNextUrl,
        loadData,
    }
}