<template lang="pug">
transition(name="fade" mode="out-in")
    div(v-if="!loadingHook")
        template(v-if="items.length !== 0")
            Table(:headers="headersCompleted" :items="items" @rowClick="onRowClick" :rowHover="true" :is_hover="true")
            .more-btn-block__for-table(v-if="visibleMoreBtn")
                btn.btn--outline(v-if="!isUploading"
                    text="Показать еще" 
                    @mousedown="upload")
                LoadingIndicator(v-else)
        template(v-else)
            p.par Завершенных опросов нет
    div(v-else)
        .loading-indicator
            LoadingIndicator
</template>

<script>
import {onMounted, ref, computed, } from 'vue';
// components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

// compositions
import {useMonitoring,} from '@/composition/monitoring';

export default {
    components: {
        Table,
        LoadingIndicator,
    },
    emits: ['on-modal-open'],
    setup(_, {emit, }) {
        const {
            surveyList,
            surveyReadId,
            detailAnswerOnSurvey,
            checkNextUrl,
            loadData,
            normalizeData,
            headersCompleted,
            visibleMoreBtn,
            isUploading,
            modalInfoData,
        } = useMonitoring();

        // const
        const data = ref([])
        const loadingHook = ref(true)

        onMounted(async () => {
            const response = await surveyList('completed');
            data.value = response.results
            checkNextUrl(response)
            loadingHook.value = false
        });
        const onRowClick = async(row) => {
            const response = await surveyReadId(row.id)
            const responseWithAnswer = await detailAnswerOnSurvey(response.status_for_user.id)
            modalInfoData.value.modal.Header = 'Опрос'
            modalInfoData.value.modal.HeaderAccept = `Вы уверены, что хотите начать проходить опрос "${row.text}"?`
            modalInfoData.value.modal.HeaderQuiz = 'Опрос'
            modalInfoData.value.modal.HeaderDecline = 'Вы уверены, что хотите прекратить прохождение опроса? Ваши ответы будут сохранены. Вы сможете продолжить опрос позже.'
            modalInfoData.value.mode = 'survey'
            modalInfoData.value.id = row.id
            modalInfoData.value.name = row.text
            modalInfoData.value.annotation = row.annotation
            modalInfoData.value.status_for_user = row.status_for_user
            modalInfoData.value.quantity_questions = [responseWithAnswer.count_is_required, responseWithAnswer.count_not_required]
            modalInfoData.value.quantity_answers = [...modalInfoData.value.quantity_questions, responseWithAnswer.count_answers_is_required, responseWithAnswer.count_answers_not_required]
            modalInfoData.value.auditoryes = row.auditoryes !== '-' ? row.auditoryes : null
            modalInfoData.value.count_questions = response.count_questions
            modalInfoData.value.questions = response.questions
            emit('on-modal-open', modalInfoData.value)
        }

        const upload = () => loadData(data)

        return {
            // useMonitoring
            surveyList,
            headersCompleted,
            // const
            loadingHook,
            visibleMoreBtn,
            isUploading,
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    date_end: normalizeData(x.status_for_user.result_end_date),
                    text: x.title,
                    auditoryes: x.auditoryes.length !== 0 ? x.auditoryes : '-',
                    annotation: x.annotation,
                    status_for_user: x.status_for_user,                    
                }))
            }),
            // function
            onRowClick,
            checkNextUrl,
            upload,            
        }
    },
}
</script>
