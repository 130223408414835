import {post,} from '@/modules/http'

import {UserCreds,} from '@/types/user'


export const useFieldValidator = () => {

    const validateField = (field) => {
        field.errorMessages = field.validators
            .map(validator => validator(field.inputValue))
            .filter (error => error != null)
        return (field.errorMessages.length > 0)
    }

    const validateForm = (fields) => {
        const validationErrors =  Object.values(fields).map( field =>  validateField(field))
        return validationErrors.filter(error => error).length > 0;
    }

    const validateRegOnServer = async (userData) =>  {
        const response = await post<UserCreds>('/register/validate/', userData)
        if (response.ok ) {
            return true
        }
        else if (response.status === 400) {
            return response.parsedBody
        }
        else { return false }
    }

    const correctValidatePass = (valEng) => {
        const checkArr = valEng.includes('This password is too common.')
        if (checkArr) return ["Пароль слишком простой. Он должен состоять из восьми символов и более"]
        return ["Значение должно быть не более 128 символов"];
    }

    const notificationName = (fieldName, emailText) => {
        if (fieldName === 'email' || fieldName === 'representative_email') {
            const checkEmailArr = emailText.includes("Enter a valid email address.") || emailText.includes("Введите правильный адрес электронной почты.");
            return checkEmailArr
                ? ["Неверно введен email-адрес"]
                : ["Этот email-адрес уже используется"];
        }
        return ["Значение должно быть не более 100 символов"];
    }   
    return {
        validateField,
        validateRegOnServer,
        validateForm,
        correctValidatePass,
        notificationName,
    };

}
