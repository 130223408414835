import {ref, toRefs, watch,} from 'vue'

import { SchoolPerson, } from '@/types/school'

import {del, post,} from '@/modules/http.ts'
import { useCommmonNotification, } from '@/composition/notification'


export const usePerson = (person, emit) => {
    const localPerson = ref(person)
    const isSaving = ref(false)
    watch(() => person, (newVal) => {
        localPerson.value = newVal
    })
    const { showNotification, } = useCommmonNotification()

    const updatePersonList = () => {
        emit('update', localPerson.value)
    }
    const acceptPerson = async () => {
        isSaving.value = true
        try {
            const response = await post(`/school_moderator/job_moderation_claim/${localPerson.value?.id}/confirm/`, {})
            if (response.ok) {
                updatePersonList()
                showNotification('Заявка одобрена')
            }
            else {
                showNotification('Ошибка при одобрении запроса пользователя', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при одобрении запроса пользователя', 'danger')
            console.error(e)
        }
        isSaving.value = false
    }

    const rejectPerson = async () => {
        isSaving.value = true
        try {
            const response = await post(`/school_moderator/job_moderation_claim/${localPerson.value?.id}/reject/`, {}, {})
            if (response.ok) {
                updatePersonList()
                showNotification('Заявка отклонена')
            }
            else {
                showNotification('Ошибка при одобрении запроса пользователя', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при одобрении запроса пользователя', 'danger')
        }
        isSaving.value = false
    }

    const makeModer = async () => {
        isSaving.value = true
        try {
            const response = await post(`/school_moderator/user/${localPerson.value?.user?.id}/appoint_a_moderator/`, {}, {})
            if (response.ok) {
                updatePersonList()
                showNotification('Статус пользователя измененен')
            }
            else {
                showNotification('Ошибка при одобрении запроса пользователя', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при одобрении запроса пользователя', 'danger')
        }
        isSaving.value = false
    }

    const unMakeModer = async () => {
        isSaving.value = true
        try {
            const response = await post(`/school_moderator/user/${localPerson.value?.user?.id}/dismiss_a_moderator/`, {}, {})
            if (response.ok) {
                updatePersonList()
                showNotification('Статус пользователя изменен')
            }
            else {
                showNotification('Ошибка при снятии прав модератора', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при снятии прав модератора', 'danger')
        }
        isSaving.value = false
    }

    const makeNotWorking  = async() => {
        isSaving.value = true
        try {
            const response = await post(`/school_moderator/user/${localPerson.value?.user?.id}/dismiss/`, {}, {})
            if (response.ok) {
                localPerson.value = response.parsedBody
                updatePersonList()
                showNotification('Статус пользователя измененен')
            }
            else {
                showNotification('Ошибка при снятии прав модератора', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при снятии прав модератора', 'danger')
        }
        isSaving.value = false
    }

    // Collegues incoming requests
    const acceptCollegue = async () => {
        isSaving.value = true
        try {
            const response = await post (`/users/1/collegues/${localPerson.value.user?.id}`, { }, {}, true)
            if (response.ok) {
                localPerson.value = response.parsedBody
                updatePersonList()
                showNotification('Входящий запрос на добавление в коллеги принят')
            }
            else {
                showNotification('Ошибка', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка', 'danger')
        }
        isSaving.value = false
    }

    const declineCollegue = async () => {
        isSaving.value = true
        try {
            const response = await del (`/users/1/collsreqs/${localPerson.value.user?.id}`, {}, {}, true)
            if (response.ok) {
                localPerson.value = response.parsedBody
                updatePersonList()
                showNotification('Входящий запрос на добавление в коллеги отклонен')
            }
            else {
                showNotification('Ошибка', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка', 'danger')
        }
        isSaving.value = false
    }

    // Collegues outcoming requests

    const  sendCollRequest  = async () => {
        try {
            const response = await post (`/users/1/collsreqs/${localPerson.value.user?.id}`, {}, {}, true)
            if (response.ok) {
                localPerson.value = response.parsedBody
                updatePersonList()
                showNotification('Входящий запрос на добавление в коллеги отклонен')
            }
            else {
                showNotification('Ошибка', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка', 'danger')
        }
    }

    const rejectCollRequest = async () => {
        try {
            const response = await post (`/users/1/collsreqs/${localPerson.value.user?.id}`, {}, {}, true)
            if (response.ok) {
                localPerson.value = response.parsedBody
                updatePersonList()
                showNotification('Входящий запрос на добавление в коллеги отклонен')
            }
            else {
                showNotification('Ошибка', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка', 'danger')
        }
    }

    return {
        localPerson,
        isSaving,
        acceptPerson,
        rejectPerson,
        makeModer,
        unMakeModer,
        updatePersonList,
        makeNotWorking,
        acceptCollegue,
        declineCollegue,
        sendCollRequest,
        rejectCollRequest,
    }
}
