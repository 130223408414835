<template lang="pug">
myHeader
.lk-page.container.container_aside
    .aside-cont
        lkAside
    main.lk-main.lk-main--wide
        section.messages
          .container-lk
            titleBlock {{router.currentRoute.value.name === 'messages' ? title = 'Переписка' : title}}
            .flex-container(v-if="router.currentRoute.value.path === '/messages'")
                .messages-talk
                    selectBlockModal(
                        name_search='Поиск по ФИО'
                        btn_modal="Новая беседа"
                        classesSearch="search-block__input--m-0"
                        @open-modal="openModal"
                        @search="onSearch"
                    )
                        span(v-if="width > 768") Новая беседа
                        span.icon(v-else)
                            img(src="@/assets/icons/edit.svg")
                    template(v-if="loadedHook")
                        talkList(v-if="chats.length > 0" :chats="chats" @onClick="openPrivateDialogue($event)")
                        .more-btn-block__for-table(v-if="pagination.next !== null && pagination.success")
                            btn.btn.btn--outline(
                                v-if="!loading"
                                text="Показать еще"
                                @mousedown="loadMore")
                            LoadingIndicator(v-else)
                        p.par(v-if="chats.length === 0 && pagination.success") Беседы отсутствуют
                        p.par(v-if="!pagination.success") Произошла ошибка загрузки данных
                    template(v-else)
                        .loading-indicator
                            LoadingIndicator
                suspense
                    template(#default)                    
                        ModalMessages(
                            v-if="showNewMsgModal"
                            @close="closeModal"
                            @createDialogue="createDialogueHandler"
                        )
            router-view(
                @showDetailTitle="title = `Переписка с ${$event}`"
                @comeBackToMain="updateChatsHandler"
            )
myFooter(v-if="router.currentRoute.value.path === '/messages'")
</template>

<script>
import { ref, defineComponent, onMounted, } from "vue";
import {useRouter,} from 'vue-router'
// composition
import { useMessages, } from "@/composition/messages";

// import components
import TitleBlock from "@/components/Generic/title-block/index.vue";
import SelectBlockModal from "@/components/Generic/select-block-modal/index.vue";
import ModalMessages from "@/components/Messages/ModalMessages.vue"
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

export default defineComponent({
    name: "Messages",
    components: {
        TitleBlock,
        SelectBlockModal,
        ModalMessages,
        LoadingIndicator,
    },
    setup(props, {emit,}) {
        const router = useRouter()
        const { 
            pagination,
            loading,
            chats,
            // webSocket,
            fetchAllChats,
        } = useMessages();

        const loadedHook = ref(false)
        const width = ref(window.innerWidth)
        const searchText = ref("");
        const showNewMsgModal = ref(false)
        const title = ref('Переписка')
        const updateWidth = () => {
            width.value = window.innerWidth;
        }
        window.addEventListener('resize', updateWidth);

        //modal function
        const openModal = async () => {
            // await fetchAvailableUsers();
            showNewMsgModal.value = true
            document.documentElement.style.overflow = 'hidden';
        }
        const closeModal = () => {
            showNewMsgModal.value = false
            document.documentElement.style.overflow = 'visible';
        }
        const openPrivateDialogue = async(item) => {
            router.push(`/messages/${item.id}`)
        }
        
        const createDialogueHandler = async() => {
            showNewMsgModal.value = false
            await fetchAllChats()
        }

        const onSearch = async q => {
            searchText.value = q
            await fetchAllChats(searchText.value)
        }
        const loadMore = async() => {
            await fetchAllChats(searchText.value, chats.value.length + pagination.value.pageSize)
        }

        const updateChatsHandler = async() => {
            loadedHook.value = false
            await fetchAllChats()
            window.scroll({
                top: 0,
            });
            loadedHook.value = true
        }
        
        onMounted(async() => {
            await fetchAllChats()
            loadedHook.value = true
        })

        return {
            router,
            chats,
            title,
            loading,
            width,
            pagination,
            loadedHook,
            showNewMsgModal,
            openModal,
            closeModal,
            openPrivateDialogue,
            createDialogueHandler,
            onSearch,
            loadMore,
            updateChatsHandler,
        };
    },
});
</script>
