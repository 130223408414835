
import { defineComponent,  PropType, ref, onMounted, watch, computed, } from 'vue'
//import types
import { LKEvent, } from '@/types/common'
//import common
import { get, } from '@/modules/http.ts'
//import composition
import { useSplide, } from '@/composition/splide.ts'
import {useDisabled,} from '@/composition/globalDisabled'
//import components
import EventItem from '@/components/Events/event-item/EventItem.vue'
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import { Splide, SplideSlide, } from '@splidejs/vue-splide';


export default defineComponent({
    name:'EventsBlock',
    components: {
        EventItem,
        LoadingIndicator,
        Splide,
        SplideSlide,
    },
    emits: ['events-loaded', 'events-changed', 'events-changed-current'],
    props: {
        filter: {
            type: String as PropType<string>,
            default: '',
        },
        searchString: {
            type: String as PropType<string>,
            default: '',
        },
        eventType: {
            type: String as PropType<string>,
            default: 'events',
            validator: (value: string) =>  ['events', 'courses', 'base_events'].indexOf(value) !== -1,
        },
        limit:{
            type: Number as PropType<number>,
            default: 6,
        },
        upload: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
        isRecommended: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        favorites: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup(props, {emit,}) {
        const events = ref<LKEvent[]>([])

        const isLoading = ref(true)
        const isNeedToUpload = ref(false)

        const eventTypeTextMore = ref((props.eventType === 'courses')  ? 'курсов' : 'мероприятий')
        if (props.eventType === 'olympiads') {
            eventTypeTextMore.value = 'олимпиад'
        }

        let page = 1
        const pageSize = ref(6)

        const eventFilter = computed(() =>  props.filter)
        const eventSearch = computed(() => props.searchString)
        const eventType = computed(() => props.eventType)
        
        const countEvents = async () => {
            if (props.eventType !== 'all') {
                const response = await get<LKEvent[]>(`/${props.eventType}/?&filter=${eventFilter.value}`, {})
                emit('events-loaded', response.parsedBody.count)
            }
        }
        const {
            disabled,
            activeDisabled,
            isDisabled,
        } = useDisabled()

        const isUploading = ref(false)

        const fetchEvents = async () => {
            isLoading.value = page === 1
            isUploading.value = page > 1
            try {
                if (props.eventType === 'rec') {
                    const response = await get<LKEvent[]>('/base_events/recommeded', {})
                    if (response.ok) {
                        events.value = response.parsedBody.results
                        emit('events-loaded', response.parsedBody.count)
                        emit('events-changed', response.parsedBody.count)
                        return
                    }
                    else {
                        emit('events-loaded', 0)
                        return
                    }
                }
                else {
                    let endpoint = ''
                    const favoritesFilter = props.favorites ? '?&is_favorite=true' : ''
                    endpoint = props.eventType === 'base_events' && props.favorites ?
                        `${favoritesFilter}&search=${eventSearch.value || ''}&page=${page}&page_size=${pageSize.value}`
                        : `?page=${page}&page_size=${pageSize.value}&filter=${eventFilter.value}&search=${eventSearch.value || ''}`
                    if (props.favorites &&  props.eventType!=='base_events') endpoint = `${favoritesFilter}&search=${eventSearch.value || ''}&page=${page}&page_size=${pageSize.value}`
                    const response = await get<LKEvent[]>(`/${props.eventType}/${endpoint}`, {})
                    if (response.ok) {
                        events.value = (page === 1) ? response.parsedBody.results : [...events.value, ...response.parsedBody.results ]
                        isNeedToUpload.value  =  (response.parsedBody.next !== null)
                        if (props.favorites) { events.value = events.value.filter(el =>  el.is_favorite === true) }
                        page = page + 1
                    }
                    else {
                        console.error('Ошибка при загрузке мероприятий')
                    }
                }

            }
            catch(e) {
                console.error(e)
            }
            isLoading.value = false
            isUploading.value = false
        }

        onMounted(async () => {
            await countEvents()
            await fetchEvents()
        })

        const allDisabled = () => {
            activeDisabled()
        }

        const unDisabled = (moodle, timeout) => {
            if (moodle) {
                isDisabled()
            } else {
                if (timeout) {
                    setTimeout(() => {
                        isDisabled()
                    }, 1000)                
                } else {
                    isDisabled()
                }        
            }
        }      


        const onEventChange = async (id: number) => {
            const fromFavorites = id < 0
            id = props.favorites ? -id : id
            events.value = events.value.filter(el => el.id !== id)
            emit('events-changed', { fromFavorites, s: eventSearch.value, })
        }

        watch(eventFilter, async () => {
            page = 1
            await fetchEvents()
            isLoading.value = false
        })


        // const searchEvents = async (s: string) => {
        //     isLoading.value = true
        //     page = 1
        //     try {
        //         const favoritesFilter = props.favorites ? '?&is_favorite=true' : ''
        //         const endpoint = `?search=${s}&filter=${eventFilter.value}&page=${page}&page_size=${pageSize.value}${favoritesFilter}`
        //         const response = await get<LKEvent[]>(`/${props.eventType}/${endpoint}/`, {})
        //         if (response.ok) {
        //             events.value = response.parsedBody.results
        //             if (props.favorites) { events.value = events.value.filter(el =>  el.is_favorite === true) }
        //             if (response.parsedBody.next)
        //                 isShowLoadButton.value = true
        //             if (response.parsedBody.count)
        //                 emit('events-changed-current', events.value.length)
        //         }
        //         else {
        //             console.error('Ошибка при загрузке мероприятий')
        //         }
        //     }
        //     catch(e) {
        //         console.error(e)
        //     }
        //     isLoading.value = false
        // }

        watch(eventSearch, () => {
            page = 1
            fetchEvents()
        })

        watch(eventType, () => {
            page = 1
            fetchEvents()
        })

        const { options: splideOptions, } = useSplide()


        return {
            events,
            fetchEvents,
            isLoading,
            isUploading,
            eventTypeTextMore,
            onEventChange,
            splideOptions,
            isNeedToUpload,
            disabled,
            activeDisabled,
            isDisabled,
            allDisabled,
            unDisabled,
        }
    },

})

