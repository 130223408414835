<template lang="pug">
.profile__content.profile__content--column.route__content
    p.par Вы начали самостоятельную разработку индивидуального маршрута. В него добавились все актуальные мероприятия и курсы, на которые вы зарегистировались. Если в нем имеются платные мероприятия или курсы, то в процессе прохождения маршрута их нужно будет оплатить.
    p.par Для появления дополнительных курсов и мероприятий в маршруте вам необходимо зарегистироваться на них. Вы можете это сделать на страницах 
        a(:href="`${publicURL}/courses`" target="_blank") курсов
        span  и 
        a(:href="`${publicURL}/events`" target="_blank") мероприятий.
        span  После добавления нужных - нажать на кнопку "Обновить список".
    p.par Если вы хотите отменить самостоятельное формирование маршрута и отправить заявку на составление маршрута вашему тьютору, то свяжитесь с администрацией сайта.
MyTutor.my-tutor__block(:tutor="tutor")
.list-events.result__list-events
    .list-events__header-block
        .list-events__title Список курсов и мероприятий в маршруте
        btn.btn.btn--do-action.route-btn.update-btn(@click="getNewList" :loading="loadingTable" text="Обновить список")
    Table(v-if="items.length > 0 && !loadingTable" :headers="headersOnConformition" :items="items" :rowHover="true" :is_hover="true" @rowClick="onRowClick")
    .not-found__events(v-if="items.length === 0 && !loadingTable")
        p.par Вы еще не зарегистрировались ни на одно событие.
    template(v-if="loadingTable")
        .loading-indicator
            LoadingIndicator
template(v-if="items.length > 0 && !loadingTable")            
    TableStatistic.mini-statistics-block(
        v-if="visibleTableInfo"
        title="Промежуточная статистика маршрута"
        :dataTable="dataTableInfo"
    )
    TableStatistic.mini-statistics-block(
        v-if="paidEvents.length > 0"
        title="Мероприятия и курсы к оплате"
        :dataTable="paidEvents"
        :hasLink="true"
    )
.buttons-container(v-if="items.length > 0 && !loadingTable")
    button.btn.btn--do-action.route-btn.btn-left(@click="openModal") Отправить на проверку        
</template>

<script>
import {ref, computed,} from 'vue';
import MyTutor from "@/components/IndividualRoute/my-tutor/MyTutor.vue";
import Table from '@/components/Generic/table';
import TableStatistic from '@/components/IndividualRoute/TableStatistics.vue';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'

// composition
import {useIndividualRoute,} from '@/composition/individualRoute';

export default {
    components: {
        MyTutor,
        Table,
        TableStatistic,
        LoadingIndicator,
    },
    props: {
        tutor: {
            type: Object,
        },
        dataRoute: {
            type: Object,
        },
        loadingTable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update-events', 'on-modal-open'],
    setup(props, {emit, }) {
        const {
            headersOnConformition, 
            normalizeData,
            findPaidEvents,
        } = useIndividualRoute()
        const publicURL = process.env.VUE_APP_PUBLIC_URL
        const paidEvents = computed (() => {
            return findPaidEvents(props.dataRoute.curriculum_event_info)
        })
        const dataTableInfo = computed(() => {
            return [
                {
                    name: 'Количество часов',
                    value: props.dataRoute.hours_count !== null ? `${props.dataRoute.hours_count} ч.` : '-',
                },
                {
                    name: 'Дата начала',
                    value: props.dataRoute.start_date ? normalizeData(props.dataRoute.start_date) : '-',
                },
                {
                    name: 'Дата окончания',
                    value: props.dataRoute.end_date ? normalizeData(props.dataRoute.end_date) : '-',
                }
            ]
        })
        const visibleTableInfo = computed(() => {
            if (props.dataRoute.start_date === null && props.dataRoute.end_date === null && props.dataRoute.hours_count === null) {
                return false
            }
            return true
        })
        //function
        const onRowClick = (row) => {
            const type = row.type === 'Курс' ? 'courses' : 'events'
            window.open(`${process.env.VUE_APP_PUBLIC_URL}/${type}/${row.about.slug}`, "_blank")
        }
        const getNewList = () => {
            emit('update-events')
        }
        const openModal = () => {
            emit('on-modal-open')
        }

        return {
            publicURL,
            headersOnConformition,
            visibleTableInfo,
            dataTableInfo,
            paidEvents,
            items: computed(() => {
                return props.dataRoute.curriculum_event_info.map(x => ({
                    id: x.id,
                    date_at: x.about.date_at_iso ? normalizeData(x.about.date_at_iso) : '-',
                    date_end: x.about.date_end_iso ? normalizeData(x.about.date_end_iso) : '-',
                    text: x.about.name,
                    type: x.type === 'event' ? 'Мероприятие' : 'Курс',
                    hours: x.about.hours !== null ? `${x.about.hours} ч.` : '-',
                    competence: x.about.competence !== null ? x.about.competence.name : '-',
                    about: x.about,
                }))
            }),
            // function
            onRowClick,
            getNewList,
            openModal, 
        }
    },
};
</script>

<style lang="scss" scoped>
.not-found__events {
    margin-top: 20px;
}
.loading-indicator {
    margin-top: 40px;
}
</style>
