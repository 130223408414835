<template lang="pug">
.profile__content.profile__content--column.route__content
    p.par В данном разделе вы можете составить свой индивидуальный маршрут обучения, в который войдут актуальные курсы и мероприятия.
    p.par Вы можете разработать его самостоятельно или отправить заявку на разработку прикрепленному тьютору.
    p.par При самостоятельной разработке в ваш маршрут автоматически добавятся все актуальные курсы и мероприятия, на которые вы зарегистировались. После составления маршрут отправится на проверку тьютору, который, возможно, даст какие-либо советы по его дополнению или исправлению.
    p.par При отправке заявки на разработку тьютор самостоятельно составит для вас маршрут. После составления у вас будет возможность скорректировать его и принять.
MyTutor.my-tutor__block(:tutor="tutor")
.buttons-container
    button.btn.btn--do-action.route-btn.btn-left(@click="createRoute(true)") Разработать самостоятельно
    button.btn.btn--do-action.route-btn(@click="createRoute(false)") Отправить заявку на разработку
</template>

<script>
import MyTutor from "@/components/IndividualRoute/my-tutor/MyTutor.vue";

export default {
    components: {
        MyTutor,
    },
    props: {
        tutor: {
            type: Object,
        },
    },
    emits: ['on-modal-open'],
    setup(_, {emit, }) {
        const createRoute = (myself) => {
            emit('on-modal-open', myself)
        }
        return {
            // function
            createRoute,
        }
    },
};
</script>
