<template lang="pug">
.profile__content.profile__content--column.route__content
    p.par Ваш маршрут составлен и принят. В процессе прохождения вы не сможете вносить в него исправления. Все платные мероприятия и курсы вам необходимо оплатить.
    p.par Во время прохождения индивидуального маршрута тьютор будет отмечать те мероприятия и курсы, которые вы прошли.
    p.par Для успешного завершения маршрута вам необходимо пройти все мероприятия и курсы.
MyTutor.my-tutor__block(:tutor="tutor")
TableStatistic.mini-statistics-block(
    v-if="paidEvents.length > 0"
    title="Мероприятия и курсы к оплате"
    :dataTable="paidEvents"
    :hasLink="true"
)
.list-events
    .list-events__title Список курсов и мероприятий в маршруте
    Table(:headers="headersOnProcessingOrResult" :items="items" :rowHover="true" :is_hover="true" @rowClick="onRowClick")
TableStatistic.mini-statistics-block(
    title="Общая статистика"
    :dataTable="dataTableInfo"
    :hasLink="true"
)
</template>

<script>
import {computed,} from 'vue';

import MyTutor from "@/components/IndividualRoute/my-tutor/MyTutor.vue";
import Table from '@/components/Generic/table';
import TableStatistic from '@/components/IndividualRoute/TableStatistics.vue';
// composition
import {useIndividualRoute,} from '@/composition/individualRoute';

export default {
    components: {
        MyTutor,
        Table,
        TableStatistic,
    },
    props: {
        tutor: {
            type: Object,
        },
        dataRoute: {
            type: Object,
        },
    },
    setup(props) {
        const {
            headersOnProcessingOrResult, 
            normalizeData,
            findPaidEvents,
            getNumberCompleted,
        } = useIndividualRoute()
        const paidEvents = findPaidEvents(props.dataRoute.curriculum_event_info)
        const dataTableInfo = [
            {
                name: 'Количество часов',
                value: props.dataRoute.hours_count !== null ? `${props.dataRoute.hours_count} ч.` : '-',
            },
            {
                name: 'Дата начала',
                value: props.dataRoute.start_date ? normalizeData(props.dataRoute.start_date) : '-',
            },
            {
                name: 'Дата окончания',
                value: props.dataRoute.end_date ? normalizeData(props.dataRoute.end_date) : '-',
            },
            {
                name: 'Участий в мероприятиях',
                value: getNumberCompleted(props.dataRoute.curriculum_event_info, 'event'),
            },
            {
                name: 'Пройденных курсов',
                value: getNumberCompleted(props.dataRoute.curriculum_event_info, 'course'),
            }                        
        ]        

        //function
        const onRowClick = (row) => {
            const type = row.type === 'Курс' ? 'courses' : 'events'
            window.open(`${process.env.VUE_APP_PUBLIC_URL}/${type}/${row.about.slug}`, "_blank")
        }

        return {
            headersOnProcessingOrResult,
            paidEvents,
            dataTableInfo,
            items: computed(() => {
                return props.dataRoute.curriculum_event_info.map(x => ({
                    id: x.id,
                    date_at: x.about.date_at_iso ? normalizeData(x.about.date_at_iso) : '-',
                    date_end: x.about.date_end_iso ? normalizeData(x.about.date_end_iso) : '-',
                    text: x.about.name,
                    type: x.type === 'event' ? 'Мероприятие' : 'Курс',
                    hours: x.about.hours !== null ? `${x.about.hours} ч.` : '-',
                    competence: x.about.competence !== null ? x.about.competence.name : '-',
                    about: x.about,
                    status: x.passing_status ? 'Пройдено' : 'Не пройдено',
                }))
            }),            
            //function
            onRowClick,
        }
    },
};
</script>
