import { getCookie, } from '@/modules/helpers.ts'

interface HttpResponse<T> extends Response {
    parsedBody?: HttpResponse<T> | any;
  }

const apiURL = `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_ENTRY}`

// const mockURL = 'https://5fbfad66fd14be0016748ebb.mock?api.io/api/v1/lk'
const mockURL = apiURL;
let globalHeaders: Record<string,string> = { 'Content-type': 'application/json',}

if (getCookie('token')) {
    globalHeaders = { 'Content-type': 'application/json',
        'Authorization': 'Bearer ' + getCookie('token')  as string,}
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> =  await fetch(request)
    try {
        if (request["method"] !== "DELETE") {
            response.parsedBody = await response.json();
        }
    }
    catch (e) {
        console.error(e)
    }
    return response
}


export async function get<T>(
    path: string,
    headers?: Record<string,string>,
    mock?: boolean,
    args: RequestInit = {
        method: 'get',
        headers: {...globalHeaders, ...headers, },
    }): Promise<HttpResponse<T>> {
    if (mock) {
        args.headers = {}
        return await http<T>(new Request(mockURL + path, args))
    }
    return await http<T>(new Request(apiURL + path, args))
}


export async function post<T>(
    path: string,
    body: object | string,
    headers?: Record<string,string>,
    mock?: boolean,
    args: RequestInit = {
        method: 'post',
        body: JSON.stringify(body),
        headers: { ...globalHeaders, ...headers, },
    }): Promise<HttpResponse<T>> {
    if (mock) {
        args.headers = { 'Content-type': 'application/json', }
        return await http<T>(new Request(mockURL + path, args))
    }
    return await http<T>(new Request(apiURL + path, args))
}

export async function postFormData<T>(
    path: string,
    body: any,
    headers: Record<string,string> = {
        'Authorization': 'Bearer ' + getCookie('token'),
    }): Promise<HttpResponse<T>> {
    return await http<T>(new Request(apiURL + path, {
        method: 'post',
        body,
        headers,
    }))
}


export async function put<T> (
    path: string,
    body: object | string,
    headers? : Record<string,string>,
    mock?: boolean,
    args: RequestInit = {
        method: 'put',
        body: JSON.stringify(body),
        headers: { ...globalHeaders, ...headers, },
    }): Promise<HttpResponse<T>> {
    if (mock) {
        args.headers = { 'Content-type': 'application/json', }
        return await http<T>(new Request(mockURL + path, args))
    }
    return await http<T>(new Request(apiURL + path, args))
}

export async function patch<T> (
    path: string,
    body: object | string,
    headers? : Record<string,string>,
    mock?: boolean,
    args: RequestInit = {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { ...globalHeaders, ...headers, },
    }): Promise<HttpResponse<T>> {
    if (mock) {
        args.headers = {}
        return await http<T>(new Request(mockURL + path, args))
    }
    return await http<T>(new Request(apiURL + path, args))
}


export async function del<T> (
    path: string,
    body: object | string,
    headers? : Record<string,string>,
    mock?: boolean,
    args: RequestInit = {
        method: 'delete',
        body: JSON.stringify(body),
        headers: { ...globalHeaders, ...headers, },
    }): Promise<HttpResponse<T>> {
    if (mock) {
        args.headers = {}
        return await http<T>(new Request(mockURL + path, args))
    }
    return await http<T>(new Request(apiURL + path, args))
}

export function addGlobalHeaders( headers: Record<string,string>) {
    globalHeaders = {...globalHeaders, ...headers, }
}

export function resetGlobalHeaders() {
    globalHeaders = { 'Content-type': 'application/json', }
}

export function getGlobalHeaders() {
    return globalHeaders
}

export const getFlatPage = async(pageID: number) => {
    const path = `${process.env.VUE_APP_API_BASE_URL}/api/v1/flatpage/${pageID}/`
    return await http(new Request(path, {
        method: 'get',
        headers: globalHeaders,
    }))
}

export const getPublic = async(path) => {
    const fullPath = `${process.env.VUE_APP_API_BASE_URL}/api/v1/${path}`
    return await http(new Request(fullPath, {
        method: 'get',
        headers: globalHeaders,
    }))
}
