import { ref, } from  'vue'
import { useCommmonNotification, } from '@/composition/notification';

import { getPublic, } from '@/modules/http'
import { FlatPage, } from '@/types/common';

const flatPages = ref<FlatPage[]>([])
const settingsSite = ref([])

export const useSettings = (footer=true) => {
    const { showNotification, } =  useCommmonNotification()
    const fetchPages = async () => {
        try {
            const response = await getPublic('flatpage/')
            if (response.ok) {
                flatPages.value = response.parsedBody
                if (footer) flatPages.value = flatPages.value.filter(x  => x.show_in_footer)
            }
            else {
                showNotification('Ошибка при получении данных о страницах', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при получении данных о страницах', 'danger')
        }
    }

    const fetchSettings = async () => {
        try {
            const response = await getPublic('settings/')
            if (response.ok) {
                settingsSite.value = response.parsedBody.results[0]
            }
            else {
                showNotification('Ошибка при получении настроек', 'danger')
            }
        }
        catch (e) {
            showNotification('Ошибка при получении настроек', 'danger')
        }
    }


    const getPages = () => flatPages.value
    const getSettings = () => settingsSite.value

    return {
        fetchPages,
        getPages,
        fetchSettings,
        getSettings,
        flatPages,
        settingsSite,
    }

}
