<template lang="pug">
#map(style="width: 100%; height: 400px")
</template>

<script>


export default {
    name: 'YandexMap',
    props: {
        coords: {
            type: Array,
        },
    },
    emits: ['add'],
    setup(props, {emit,}) {
        let map
        let placemark
        function createPlacemark(coords) {
            return new window.ymaps.Placemark(coords, {}, {
                preset: 'islands#violetDotIconWithCaption',
                draggable: true,
            });
        }
        const setMarkers = () => {
            map.events.add('click', function (e) {
                const coords = e.get('coords');
                if (placemark) {
                    placemark.geometry.setCoordinates(coords);
                }
                else {
                    placemark = createPlacemark(coords);
                    map.geoObjects.add(placemark);
                }
                emit('add', coords)
            });
        }
        window.ymaps.ready(() => {
            map = new window.ymaps.Map('map', {
                center: props.coords,
                zoom: 10,
            }, {
                searchControlProvider: 'yandex#search',
            });
            placemark = createPlacemark(props.coords);
            map.geoObjects.add(placemark);
            setMarkers();
        });

    },
}
</script>

<style scoped>

</style>
