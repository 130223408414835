<template lang="pug">
template(v-if="route.path === '/audit/process'")
    template(v-if="!loadingHook")
        SearchBlock.title-block__searchBlock(
            v-if="visibleSearch"
            name-search='Поиск по ФИО и названию кейса'
            is-fullwidth
            @on-search="loadDataWithSearch"
            @on-clear="loadDataWithSearch"
        )
        template(v-if="items.length !== 0")
            Table(v-if="!loadingTable" :headers="headersProcess" :items="items" :rowHover="true" @rowClick="onRowClick" :is_hover="true")
            .loading-indicator(v-else)
                LoadingIndicator
            .more-btn-block__for-table(v-if="visibleMoreBtn")
                btn.btn--outline(v-if="!isUploading"
                    text="Показать еще" 
                    @mousedown="upload")
                LoadingIndicator(v-else)            
        template(v-else)
            p.par Кейсов в процессе проверки нет
    template(v-else)
        .loading-indicator
            LoadingIndicator
router-view
</template>

<script>
import {onMounted, ref, computed, } from 'vue';
import {useRoute,} from 'vue-router';

// components
import Table from '@/components/Generic/table';
import LoadingIndicator from '@/components/Generic/loading-indicator/LoadingIndicator.vue'
import SearchBlock from '@/components/Generic/search-block/index.vue';

// compositions
import {useAudit,} from '@/composition/audit';

export default {
    components: {
        Table,
        LoadingIndicator,
        SearchBlock,
    },
    emits: ['on-modal-open', 'modal-indo-btn'],
    setup(_, {emit,  }) {
        const {
            caseCheckList,
            caseCheckRead,
            checkNextUrl,
            loadData,            
            headersProcess,
            modalInfoData,
            visibleMoreBtn,
            isUploading,            
        } = useAudit();
        const route = useRoute();
        // const
        const data = ref([])
        const loadingHook = ref(true)
        const loadingTable = ref(false)
        const visibleSearch = ref(false)

        // function
        onMounted(async () => {
            const response = await caseCheckList('process', '');
            data.value = response.results
            checkNextUrl(response)
            if (data.value.length > 0) visibleSearch.value = true
            loadingHook.value = false            
        });
        const onRowClick = async (row) => {
            const response = await caseCheckRead(row.id)
            modalInfoData.value.modal.Header = 'Информация о решении кейса'
            modalInfoData.value.modal.HeaderAccept = 'Прохождние кейса'
            modalInfoData.value.modal.BodyAccept = `Вы уверены, что хотите продолжить проверку кейса "${response.case.name}"?`
            modalInfoData.value.type = 'check'
            modalInfoData.value.name = response.case.name
            modalInfoData.value.username = response.user.full_name
            modalInfoData.value.teacher_id = response.user.id
            modalInfoData.value.annotation = response.case.annotation !== null ? response.case.annotation : ''
            modalInfoData.value.attempting.number = response.attempting
            modalInfoData.value.attempting.max_attempting = response.case.max_attempting
            modalInfoData.value.status = response.status
            modalInfoData.value.id = response.id
            modalInfoData.value.subject_area = row.subject_area
            modalInfoData.value.competence = row.competence
            modalInfoData.value.questions = response.questions
            emit('on-modal-open', modalInfoData.value)
        }
        const loadDataWithSearch = async (s) => {
            loadingTable.value = true
            const string = ref('')
            s !== undefined ? string.value = s : string.value = ''
            const response = await caseCheckList('process', string.value)
            data.value = response.results
            checkNextUrl(response)
            loadingTable.value = false
        }
        const upload = () => loadData(data)

        return {
            // useCase
            caseCheckList,
            caseCheckRead,
            headersProcess,

            // const
            loadingHook,
            visibleMoreBtn,
            isUploading,
            loadingTable,
            visibleSearch,
            items: computed(() => {
                return data.value.map(x => ({
                    id: x.id,
                    name: x.user.full_name,
                    text: x.case.name,
                    subject_area: x.case.subject_area,
                    competence: x.case.competence,
                    attempting: x.attempting + ' из ' + x.case.max_attempting,
                }))
            }),
            route,
            // function
            onRowClick,
            checkNextUrl,
            upload,
            loadDataWithSearch,
        }
    },
}
</script>

<style scoped>
</style>
